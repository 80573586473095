// import 'bootstrap';
// import 'bootstrap-datepicker';
import 'jquery';
// import './load_service_worker';
// import './font_observer';
import './jquery-1.12.3.min';
// import 'bootstrap.min.js';
import './bundles';
// import Slider from './slider';
import './WebResource';
import './map';

import { start as startUjs } from '@rails/ujs';
import lozad from 'lozad';
// import { start as activestorage } from '@rails/activestorage'; // Only add if required
import { EventListener, Cookiebar } from '@morsedigital/morse-utils';
import ExpandingPanel from './expanding_panels';
import BannerPanel from './banner_panel';
import Pikaday from 'pikaday';

(() => {
  startUjs(); // Start UJS
  // activestorage(); // Start Activestorage
})();

(() => {
  const modules = [Cookiebar('cookie-prompt cookie-alert')];

  EventListener(modules);
})();

// Lazy load images
(() => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();

// (() => {
//   Slider();
// })();

// Expanding Panel
(() => {
  ExpandingPanel();
})(); 

// Banner Panel
(() => {
  BannerPanel();
})();

const picker = new Pikaday({
  field: document.getElementById('datepicker'),
  format: 'DD MM YYYY',
  minDate: new Date(),
  toString(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  },
});
