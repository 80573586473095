function StringBuilder(n) {
  this.strings = [];
  this.append(n);
}
function menuLine() {
  if (jq('#header').find('.main-nav').find('li').hasClass('active')) {
    var n = jq('.main-nav > ul > .active').width(),
      t = jq('.main-nav > ul > .active').position().left;
    jq('.main-nav .line').css({
      left: t,
      width: n,
      display: 'inline-block',
      opacity: '1',
    });
  }
}
function initMap(n, t) {
  var r = parseFloat(n),
    u = parseFloat(t),
    f = new google.maps.StyledMapType(
      [{ stylers: [{ saturation: -100 }, { lightness: 50 }] }],
      { name: 'Styled Map' }
    ),
    e = {
      center: { lat: r, lng: u },
      zoom: 15,
      scrollwheel: !1,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
    },
    i = new google.maps.Map(document.getElementById('map'), e),
    o = new google.maps.Marker({
      map: i,
      position: { lat: r, lng: u },
      icon: '/sf_images/ico-05.svg',
    });
  i.mapTypes.set('map_style', f);
  i.setMapTypeId('map_style');
}
// if (
//   ((window.Modernizr = (function (n, t, i) {
//     function a(n) {
//       c.cssText = n;
//     }
//     function vt(n, t) {
//       return a(y.join(n + ';') + (t || ''));
//     }
//     function h(n, t) {
//       return typeof n === t;
//     }
//     function v(n, t) {
//       return !!~('' + n).indexOf(t);
//     }
//     function lt(n, t) {
//       var u, r;
//       for (u in n)
//         if (((r = n[u]), !v(r, '-') && c[r] !== i)) return t == 'pfx' ? r : !0;
//       return !1;
//     }
//     function yt(n, t, r) {
//       var f, u;
//       for (f in n)
//         if (((u = t[n[f]]), u !== i))
//           return r === !1 ? n[f] : h(u, 'function') ? u.bind(r || t) : u;
//       return !1;
//     }
//     function f(n, t, i) {
//       var r = n.charAt(0).toUpperCase() + n.slice(1),
//         u = (n + ' ' + ot.join(r + ' ') + r).split(' ');
//       return h(t, 'string') || h(t, 'undefined')
//         ? lt(u, t)
//         : ((u = (n + ' ' + st.join(r + ' ') + r).split(' ')), yt(u, t, i));
//     }
//     function pt() {
//       u.input = (function (i) {
//         for (var r = 0, u = i.length; r < u; r++) w[i[r]] = !!(i[r] in o);
//         return (
//           w.list &&
//             (w.list = !!(t.createElement('datalist') && n.HTMLDataListElement)),
//           w
//         );
//       })(
//         'autocomplete autofocus list placeholder max min multiple pattern required step'.split(
//           ' '
//         )
//       );
//       u.inputtypes = (function (n) {
//         for (var u = 0, r, f, e, h = n.length; u < h; u++)
//           o.setAttribute('type', (f = n[u])),
//             (r = o.type !== 'text'),
//             r &&
//               ((o.value = g),
//               (o.style.cssText = 'position:absolute;visibility:hidden;'),
//               /^range$/.test(f) && o.style.WebkitAppearance !== i
//                 ? (s.appendChild(o),
//                   (e = t.defaultView),
//                   (r =
//                     e.getComputedStyle &&
//                     e.getComputedStyle(o, null).WebkitAppearance !==
//                       'textfield' &&
//                     o.offsetHeight !== 0),
//                   s.removeChild(o))
//                 : /^(search|tel)$/.test(f) ||
//                   (r = /^(url|email)$/.test(f)
//                     ? o.checkValidity && o.checkValidity() === !1
//                     : o.value != g)),
//             (ht[n[u]] = !!r);
//         return ht;
//       })(
//         'search tel url email datetime date month week time datetime-local number range color'.split(
//           ' '
//         )
//       );
//     }
//     var u = {},
//       d = !0,
//       s = t.documentElement,
//       e = 'modernizr',
//       ut = t.createElement(e),
//       c = ut.style,
//       o = t.createElement('input'),
//       g = ':)',
//       ft = {}.toString,
//       y = ' -webkit- -moz- -o- -ms- '.split(' '),
//       et = 'Webkit Moz O ms',
//       ot = et.split(' '),
//       st = et.toLowerCase().split(' '),
//       p = { svg: 'http://www.w3.org/2000/svg' },
//       r = {},
//       ht = {},
//       w = {},
//       nt = [],
//       tt = nt.slice,
//       b,
//       l = function (n, i, r, u) {
//         var l,
//           a,
//           c,
//           v,
//           f = t.createElement('div'),
//           h = t.body,
//           o = h || t.createElement('body');
//         if (parseInt(r, 10))
//           while (r--)
//             (c = t.createElement('div')),
//               (c.id = u ? u[r] : e + (r + 1)),
//               f.appendChild(c);
//         return (
//           (l = ['&#173;', '<style id="s', e, '">', n, '</style>'].join('')),
//           (f.id = e),
//           ((h ? f : o).innerHTML += l),
//           o.appendChild(f),
//           h ||
//             ((o.style.background = ''),
//             (o.style.overflow = 'hidden'),
//             (v = s.style.overflow),
//             (s.style.overflow = 'hidden'),
//             s.appendChild(o)),
//           (a = i(f, n)),
//           h
//             ? f.parentNode.removeChild(f)
//             : (o.parentNode.removeChild(o), (s.style.overflow = v)),
//           !!a
//         );
//       },
//       at = function (t) {
//         var i = n.matchMedia || n.msMatchMedia,
//           r;
//         return i
//           ? i(t).matches
//           : (l(
//               '@media ' + t + ' { #' + e + ' { position: absolute; } }',
//               function (t) {
//                 r =
//                   (n.getComputedStyle
//                     ? getComputedStyle(t, null)
//                     : t.currentStyle
//                   ).position == 'absolute';
//               }
//             ),
//             r);
//       },
//       ct = (function () {
//         function r(r, u) {
//           u = u || t.createElement(n[r] || 'div');
//           r = 'on' + r;
//           var f = r in u;
//           return (
//             f ||
//               (u.setAttribute || (u = t.createElement('div')),
//               u.setAttribute &&
//                 u.removeAttribute &&
//                 (u.setAttribute(r, ''),
//                 (f = h(u[r], 'function')),
//                 h(u[r], 'undefined') || (u[r] = i),
//                 u.removeAttribute(r))),
//             (u = null),
//             f
//           );
//         }
//         var n = {
//           select: 'input',
//           change: 'input',
//           submit: 'form',
//           reset: 'form',
//           error: 'img',
//           load: 'img',
//           abort: 'img',
//         };
//         return r;
//       })(),
//       it = {}.hasOwnProperty,
//       rt,
//       k;
//     rt =
//       h(it, 'undefined') || h(it.call, 'undefined')
//         ? function (n, t) {
//             return t in n && h(n.constructor.prototype[t], 'undefined');
//           }
//         : function (n, t) {
//             return it.call(n, t);
//           };
//     Function.prototype.bind ||
//       (Function.prototype.bind = function (n) {
//         var t = this,
//           i,
//           r;
//         if (typeof t != 'function') throw new TypeError();
//         return (
//           (i = tt.call(arguments, 1)),
//           (r = function () {
//             var f, e, u;
//             return this instanceof r
//               ? ((f = function () {}),
//                 (f.prototype = t.prototype),
//                 (e = new f()),
//                 (u = t.apply(e, i.concat(tt.call(arguments)))),
//                 Object(u) === u)
//                 ? u
//                 : e
//               : t.apply(n, i.concat(tt.call(arguments)));
//           }),
//           r
//         );
//       });
//     r.flexbox = function () {
//       return f('flexWrap');
//     };
//     r.flexboxlegacy = function () {
//       return f('boxDirection');
//     };
//     r.canvas = function () {
//       var n = t.createElement('canvas');
//       return !!(n.getContext && n.getContext('2d'));
//     };
//     r.canvastext = function () {
//       return !!(
//         u.canvas &&
//         h(t.createElement('canvas').getContext('2d').fillText, 'function')
//       );
//     };
//     r.webgl = function () {
//       return !!n.WebGLRenderingContext;
//     };
//     r.touch = function () {
//       var i;
//       return (
//         'ontouchstart' in n || (n.DocumentTouch && t instanceof DocumentTouch)
//           ? (i = !0)
//           : l(
//               [
//                 '@media (',
//                 y.join('touch-enabled),('),
//                 e,
//                 ')',
//                 '{#modernizr{top:9px;position:absolute}}',
//               ].join(''),
//               function (n) {
//                 i = n.offsetTop === 9;
//               }
//             ),
//         i
//       );
//     };
//     r.geolocation = function () {
//       return 'geolocation' in navigator;
//     };
//     r.postmessage = function () {
//       return !!n.postMessage;
//     };
//     r.websqldatabase = function () {
//       return !!n.openDatabase;
//     };
//     r.indexedDB = function () {
//       return !!f('indexedDB', n);
//     };
//     r.hashchange = function () {
//       return (
//         ct('hashchange', n) && (t.documentMode === i || t.documentMode > 7)
//       );
//     };
//     r.history = function () {
//       return !!(n.history && history.pushState);
//     };
//     r.draganddrop = function () {
//       var n = t.createElement('div');
//       return 'draggable' in n || ('ondragstart' in n && 'ondrop' in n);
//     };
//     r.websockets = function () {
//       return 'WebSocket' in n || 'MozWebSocket' in n;
//     };
//     r.rgba = function () {
//       return (
//         a('background-color:rgba(150,255,150,.5)'), v(c.backgroundColor, 'rgba')
//       );
//     };
//     r.hsla = function () {
//       return (
//         a('background-color:hsla(120,40%,100%,.5)'),
//         v(c.backgroundColor, 'rgba') || v(c.backgroundColor, 'hsla')
//       );
//     };
//     r.multiplebgs = function () {
//       return (
//         a('background:url(https://),url(https://),red url(https://)'),
//         /(url\s*\(.*?){3}/.test(c.background)
//       );
//     };
//     r.backgroundsize = function () {
//       return f('backgroundSize');
//     };
//     r.borderimage = function () {
//       return f('borderImage');
//     };
//     r.borderradius = function () {
//       return f('borderRadius');
//     };
//     r.boxshadow = function () {
//       return f('boxShadow');
//     };
//     r.textshadow = function () {
//       return t.createElement('div').style.textShadow === '';
//     };
//     r.opacity = function () {
//       return vt('opacity:.55'), /^0.55$/.test(c.opacity);
//     };
//     r.cssanimations = function () {
//       return f('animationName');
//     };
//     r.csscolumns = function () {
//       return f('columnCount');
//     };
//     r.cssgradients = function () {
//       var n = 'background-image:';
//       return (
//         a(
//           (
//             n +
//             '-webkit- '
//               .split(' ')
//               .join(
//                 'gradient(linear,left top,right bottom,from(#9f9),to(white));' +
//                   n
//               ) +
//             y.join('linear-gradient(left top,#9f9, white);' + n)
//           ).slice(0, -n.length)
//         ),
//         v(c.backgroundImage, 'gradient')
//       );
//     };
//     r.cssreflections = function () {
//       return f('boxReflect');
//     };
//     r.csstransforms = function () {
//       return !!f('transform');
//     };
//     r.csstransforms3d = function () {
//       var n = !!f('perspective');
//       return (
//         n &&
//           'webkitPerspective' in s.style &&
//           l(
//             '@media (transform-3d),(-webkit-transform-3d){#modernizr{left:9px;position:absolute;height:3px;}}',
//             function (t) {
//               n = t.offsetLeft === 9 && t.offsetHeight === 3;
//             }
//           ),
//         n
//       );
//     };
//     r.csstransitions = function () {
//       return f('transition');
//     };
//     r.fontface = function () {
//       var n;
//       return (
//         l(
//           '@font-face {font-family:"font";src:url("https://")}',
//           function (i, r) {
//             var f = t.getElementById('smodernizr'),
//               u = f.sheet || f.styleSheet,
//               e = u
//                 ? u.cssRules && u.cssRules[0]
//                   ? u.cssRules[0].cssText
//                   : u.cssText || ''
//                 : '';
//             n = /src/i.test(e) && e.indexOf(r.split(' ')[0]) === 0;
//           }
//         ),
//         n
//       );
//     };
//     r.generatedcontent = function () {
//       var n;
//       return (
//         l(
//           [
//             '#',
//             e,
//             '{font:0/0 a}#',
//             e,
//             ':after{content:"',
//             g,
//             '";visibility:hidden;font:3px/1 a}',
//           ].join(''),
//           function (t) {
//             n = t.offsetHeight >= 3;
//           }
//         ),
//         n
//       );
//     };
//     r.video = function () {
//       var i = t.createElement('video'),
//         n = !1;
//       try {
//         (n = !!i.canPlayType) &&
//           ((n = new Boolean(n)),
//           (n.ogg = i
//             .canPlayType('video/ogg; codecs="theora"')
//             .replace(/^no$/, '')),
//           (n.h264 = i
//             .canPlayType('video/mp4; codecs="avc1.42E01E"')
//             .replace(/^no$/, '')),
//           (n.webm = i
//             .canPlayType('video/webm; codecs="vp8, vorbis"')
//             .replace(/^no$/, '')));
//       } catch (r) {}
//       return n;
//     };
//     r.audio = function () {
//       var i = t.createElement('audio'),
//         n = !1;
//       try {
//         (n = !!i.canPlayType) &&
//           ((n = new Boolean(n)),
//           (n.ogg = i
//             .canPlayType('audio/ogg; codecs="vorbis"')
//             .replace(/^no$/, '')),
//           (n.mp3 = i.canPlayType('audio/mpeg;').replace(/^no$/, '')),
//           (n.wav = i.canPlayType('audio/wav; codecs="1"').replace(/^no$/, '')),
//           (n.m4a = (
//             i.canPlayType('audio/x-m4a;') || i.canPlayType('audio/aac;')
//           ).replace(/^no$/, '')));
//       } catch (r) {}
//       return n;
//     };
//     r.localstorage = function () {
//       try {
//         return localStorage.setItem(e, e), localStorage.removeItem(e), !0;
//       } catch (n) {
//         return !1;
//       }
//     };
//     r.sessionstorage = function () {
//       try {
//         return sessionStorage.setItem(e, e), sessionStorage.removeItem(e), !0;
//       } catch (n) {
//         return !1;
//       }
//     };
//     r.webworkers = function () {
//       return !!n.Worker;
//     };
//     r.applicationcache = function () {
//       return !!n.applicationCache;
//     };
//     r.svg = function () {
//       return (
//         !!t.createElementNS && !!t.createElementNS(p.svg, 'svg').createSVGRect
//       );
//     };
//     r.inlinesvg = function () {
//       var n = t.createElement('div');
//       return (
//         (n.innerHTML = '<svg/>'),
//         (n.firstChild && n.firstChild.namespaceURI) == p.svg
//       );
//     };
//     r.smil = function () {
//       return (
//         !!t.createElementNS &&
//         /SVGAnimate/.test(ft.call(t.createElementNS(p.svg, 'animate')))
//       );
//     };
//     r.svgclippaths = function () {
//       return (
//         !!t.createElementNS &&
//         /SVGClipPath/.test(ft.call(t.createElementNS(p.svg, 'clipPath')))
//       );
//     };
//     for (k in r)
//       rt(r, k) &&
//         ((b = k.toLowerCase()),
//         (u[b] = r[k]()),
//         nt.push((u[b] ? '' : 'no-') + b));
//     return (
//       u.input || pt(),
//       (u.addTest = function (n, t) {
//         if (typeof n == 'object')
//           for (var r in n) rt(n, r) && u.addTest(r, n[r]);
//         else {
//           if (((n = n.toLowerCase()), u[n] !== i)) return u;
//           t = typeof t == 'function' ? t() : t;
//           typeof d != 'undefined' &&
//             d &&
//             (s.className += ' ' + (t ? '' : 'no-') + n);
//           u[n] = t;
//         }
//         return u;
//       }),
//       a(''),
//       (ut = o = null),
//       (function (n, t) {
//         function p(n, t) {
//           var i = n.createElement('p'),
//             r = n.getElementsByTagName('head')[0] || n.documentElement;
//           return (
//             (i.innerHTML = 'x<style>' + t + '</style>'),
//             r.insertBefore(i.lastChild, r.firstChild)
//           );
//         }
//         function c() {
//           var n = r.elements;
//           return typeof n == 'string' ? n.split(' ') : n;
//         }
//         function o(n) {
//           var t = h[n[s]];
//           return t || ((t = {}), e++, (n[s] = e), (h[e] = t)), t;
//         }
//         function l(n, r, u) {
//           if ((r || (r = t), i)) return r.createElement(n);
//           u || (u = o(r));
//           var f;
//           return (
//             (f = u.cache[n]
//               ? u.cache[n].cloneNode()
//               : y.test(n)
//               ? (u.cache[n] = u.createElem(n)).cloneNode()
//               : u.createElem(n)),
//             f.canHaveChildren && !v.test(n) ? u.frag.appendChild(f) : f
//           );
//         }
//         function w(n, r) {
//           if ((n || (n = t), i)) return n.createDocumentFragment();
//           r = r || o(n);
//           for (
//             var f = r.frag.cloneNode(), u = 0, e = c(), s = e.length;
//             u < s;
//             u++
//           )
//             f.createElement(e[u]);
//           return f;
//         }
//         function b(n, t) {
//           t.cache ||
//             ((t.cache = {}),
//             (t.createElem = n.createElement),
//             (t.createFrag = n.createDocumentFragment),
//             (t.frag = t.createFrag()));
//           n.createElement = function (i) {
//             return r.shivMethods ? l(i, n, t) : t.createElem(i);
//           };
//           n.createDocumentFragment = Function(
//             'h,f',
//             'return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(' +
//               c()
//                 .join()
//                 .replace(/\w+/g, function (n) {
//                   return (
//                     t.createElem(n), t.frag.createElement(n), 'c("' + n + '")'
//                   );
//                 }) +
//               ');return n}'
//           )(r, t.frag);
//         }
//         function a(n) {
//           n || (n = t);
//           var u = o(n);
//           return (
//             !r.shivCSS ||
//               f ||
//               u.hasCSS ||
//               (u.hasCSS = !!p(
//                 n,
//                 'article,aside,figcaption,figure,footer,header,hgroup,nav,section{display:block}mark{background:#FF0;color:#000}'
//               )),
//             i || b(n, u),
//             n
//           );
//         }
//         var u = n.html5 || {},
//           v = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
//           y = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
//           f,
//           s = '_html5shiv',
//           e = 0,
//           h = {},
//           i,
//           r;
//         (function () {
//           try {
//             var n = t.createElement('a');
//             n.innerHTML = '<xyz></xyz>';
//             f = 'hidden' in n;
//             i =
//               n.childNodes.length == 1 ||
//               (function () {
//                 t.createElement('a');
//                 var n = t.createDocumentFragment();
//                 return (
//                   typeof n.cloneNode == 'undefined' ||
//                   typeof n.createDocumentFragment == 'undefined' ||
//                   typeof n.createElement == 'undefined'
//                 );
//               })();
//           } catch (r) {
//             f = !0;
//             i = !0;
//           }
//         })();
//         r = {
//           elements:
//             u.elements ||
//             'abbr article aside audio bdi canvas data datalist details figcaption figure footer header hgroup mark meter nav output progress section summary time video',
//           shivCSS: u.shivCSS !== !1,
//           supportsUnknownElements: i,
//           shivMethods: u.shivMethods !== !1,
//           type: 'default',
//           shivDocument: a,
//           createElement: l,
//           createDocumentFragment: w,
//         };
//         n.html5 = r;
//         a(t);
//       })(this, t),
//       (u._version = '2.6.2'),
//       (u._prefixes = y),
//       (u._domPrefixes = st),
//       (u._cssomPrefixes = ot),
//       (u.mq = at),
//       (u.hasEvent = ct),
//       (u.testProp = function (n) {
//         return lt([n]);
//       }),
//       (u.testAllProps = f),
//       (u.testStyles = l),
//       (u.prefixed = function (n, t, i) {
//         return t ? f(n, t, i) : f(n, 'pfx');
//       }),
//       (s.className =
//         s.className.replace(/(^|\s)no-js(\s|$)/, '$1$2') +
//         (d ? ' js ' + nt.join(' ') : '')),
//       u
//     );
//   })(this, this.document)),
//   'undefined' == typeof jQuery)
// )
//   throw new Error("Bootstrap's JavaScript requires jQuery");
// +(function (n) {
//   'use strict';
//   var t = n.fn.jquery.split(' ')[0].split('.');
//   if (
//     (t[0] < 2 && t[1] < 9) ||
//     (1 == t[0] && 9 == t[1] && t[2] < 1) ||
//     t[0] > 2
//   )
//     throw new Error(
//       "Bootstrap's JavaScript requires jQuery version 1.9.1 or higher, but lower than version 3"
//     );
// })(jQuery);
+(function (n) {
  'use strict';
  function u(i) {
    return this.each(function () {
      var r = n(this),
        u = r.data('bs.alert');
      u || r.data('bs.alert', (u = new t(this)));
      'string' == typeof i && u[i].call(r);
    });
  }
  var i = '[data-dismiss="alert"]',
    t = function (t) {
      n(t).on('click', i, this.close);
    },
    r;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 150;
  t.prototype.close = function (i) {
    function e() {
      r.detach().trigger('closed.bs.alert').remove();
    }
    var f = n(this),
      u = f.attr('data-target'),
      r;
    u || ((u = f.attr('href')), (u = u && u.replace(/.*(?=#[^\s]*$)/, '')));
    r = n(u);
    i && i.preventDefault();
    r.length || (r = f.closest('.alert'));
    r.trigger((i = n.Event('close.bs.alert')));
    i.isDefaultPrevented() ||
      (r.removeClass('in'),
      n.support.transition && r.hasClass('fade')
        ? r
            .one('bsTransitionEnd', e)
            .emulateTransitionEnd(t.TRANSITION_DURATION)
        : e());
  };
  r = n.fn.alert;
  n.fn.alert = u;
  n.fn.alert.Constructor = t;
  n.fn.alert.noConflict = function () {
    return (n.fn.alert = r), this;
  };
  n(document).on('click.bs.alert.data-api', i, t.prototype.close);
})(jQuery);
+(function (n) {
  'use strict';
  function i(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.button'),
        f = 'object' == typeof i && i;
      r || u.data('bs.button', (r = new t(this, f)));
      'toggle' == i ? r.toggle() : i && r.setState(i);
    });
  }
  var t = function (i, r) {
      this.$element = n(i);
      this.options = n.extend({}, t.DEFAULTS, r);
      this.isLoading = !1;
    },
    r;
  t.VERSION = '3.3.6';
  t.DEFAULTS = { loadingText: 'loading...' };
  t.prototype.setState = function (t) {
    var r = 'disabled',
      i = this.$element,
      f = i.is('input') ? 'val' : 'html',
      u = i.data();
    t += 'Text';
    null == u.resetText && i.data('resetText', i[f]());
    setTimeout(
      n.proxy(function () {
        i[f](null == u[t] ? this.options[t] : u[t]);
        'loadingText' == t
          ? ((this.isLoading = !0), i.addClass(r).attr(r, r))
          : this.isLoading &&
            ((this.isLoading = !1), i.removeClass(r).removeAttr(r));
      }, this),
      0
    );
  };
  t.prototype.toggle = function () {
    var t = !0,
      i = this.$element.closest('[data-toggle="buttons"]'),
      n;
    i.length
      ? ((n = this.$element.find('input')),
        'radio' == n.prop('type')
          ? (n.prop('checked') && (t = !1),
            i.find('.active').removeClass('active'),
            this.$element.addClass('active'))
          : 'checkbox' == n.prop('type') &&
            (n.prop('checked') !== this.$element.hasClass('active') && (t = !1),
            this.$element.toggleClass('active')),
        n.prop('checked', this.$element.hasClass('active')),
        t && n.trigger('change'))
      : (this.$element.attr('aria-pressed', !this.$element.hasClass('active')),
        this.$element.toggleClass('active'));
  };
  r = n.fn.button;
  n.fn.button = i;
  n.fn.button.Constructor = t;
  n.fn.button.noConflict = function () {
    return (n.fn.button = r), this;
  };
  n(document)
    .on('click.bs.button.data-api', '[data-toggle^="button"]', function (t) {
      var r = n(t.target);
      r.hasClass('btn') || (r = r.closest('.btn'));
      i.call(r, 'toggle');
      n(t.target).is('input[type="radio"]') ||
        n(t.target).is('input[type="checkbox"]') ||
        t.preventDefault();
    })
    .on(
      'focus.bs.button.data-api blur.bs.button.data-api',
      '[data-toggle^="button"]',
      function (t) {
        n(t.target)
          .closest('.btn')
          .toggleClass('focus', /^focus(in)?$/.test(t.type));
      }
    );
})(jQuery);
+(function (n) {
  'use strict';
  function i(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.carousel'),
        f = n.extend({}, t.DEFAULTS, u.data(), 'object' == typeof i && i),
        e = 'string' == typeof i ? i : f.slide;
      r || u.data('bs.carousel', (r = new t(this, f)));
      'number' == typeof i
        ? r.to(i)
        : e
        ? r[e]()
        : f.interval && r.pause().cycle();
    });
  }
  var t = function (t, i) {
      this.$element = n(t);
      this.$indicators = this.$element.find('.carousel-indicators');
      this.options = i;
      this.paused = null;
      this.sliding = null;
      this.interval = null;
      this.$active = null;
      this.$items = null;
      this.options.keyboard &&
        this.$element.on('keydown.bs.carousel', n.proxy(this.keydown, this));
      'hover' == this.options.pause &&
        !('ontouchstart' in document.documentElement) &&
        this.$element
          .on('mouseenter.bs.carousel', n.proxy(this.pause, this))
          .on('mouseleave.bs.carousel', n.proxy(this.cycle, this));
    },
    u,
    r;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 600;
  t.DEFAULTS = { interval: 5e3, pause: 'hover', wrap: !0, keyboard: !0 };
  t.prototype.keydown = function (n) {
    if (!/input|textarea/i.test(n.target.tagName)) {
      switch (n.which) {
        case 37:
          this.prev();
          break;
        case 39:
          this.next();
          break;
        default:
          return;
      }
      n.preventDefault();
    }
  };
  t.prototype.cycle = function (t) {
    return (
      t || (this.paused = !1),
      this.interval && clearInterval(this.interval),
      this.options.interval &&
        !this.paused &&
        (this.interval = setInterval(
          n.proxy(this.next, this),
          this.options.interval
        )),
      this
    );
  };
  t.prototype.getItemIndex = function (n) {
    return (
      (this.$items = n.parent().children('.item')),
      this.$items.index(n || this.$active)
    );
  };
  t.prototype.getItemForDirection = function (n, t) {
    var i = this.getItemIndex(t),
      f =
        ('prev' == n && 0 === i) ||
        ('next' == n && i == this.$items.length - 1),
      r,
      u;
    return f && !this.options.wrap
      ? t
      : ((r = 'prev' == n ? -1 : 1),
        (u = (i + r) % this.$items.length),
        this.$items.eq(u));
  };
  t.prototype.to = function (n) {
    var i = this,
      t = this.getItemIndex(
        (this.$active = this.$element.find('.item.active'))
      );
    if (!(n > this.$items.length - 1) && !(0 > n))
      return this.sliding
        ? this.$element.one('slid.bs.carousel', function () {
            i.to(n);
          })
        : t == n
        ? this.pause().cycle()
        : this.slide(n > t ? 'next' : 'prev', this.$items.eq(n));
  };
  t.prototype.pause = function (t) {
    return (
      t || (this.paused = !0),
      this.$element.find('.next, .prev').length &&
        n.support.transition &&
        (this.$element.trigger(n.support.transition.end), this.cycle(!0)),
      (this.interval = clearInterval(this.interval)),
      this
    );
  };
  t.prototype.next = function () {
    if (!this.sliding) return this.slide('next');
  };
  t.prototype.prev = function () {
    if (!this.sliding) return this.slide('prev');
  };
  t.prototype.slide = function (i, r) {
    var e = this.$element.find('.item.active'),
      u = r || this.getItemForDirection(i, e),
      l = this.interval,
      f = 'next' == i ? 'left' : 'right',
      a = this,
      o,
      s,
      h,
      c;
    return u.hasClass('active')
      ? (this.sliding = !1)
      : ((o = u[0]),
        (s = n.Event('slide.bs.carousel', { relatedTarget: o, direction: f })),
        (this.$element.trigger(s), !s.isDefaultPrevented())
          ? (((this.sliding = !0),
            l && this.pause(),
            this.$indicators.length) &&
              (this.$indicators.find('.active').removeClass('active'),
              (h = n(this.$indicators.children()[this.getItemIndex(u)])),
              h && h.addClass('active')),
            (c = n.Event('slid.bs.carousel', {
              relatedTarget: o,
              direction: f,
            })),
            n.support.transition && this.$element.hasClass('slide')
              ? (u.addClass(i),
                u[0].offsetWidth,
                e.addClass(f),
                u.addClass(f),
                e
                  .one('bsTransitionEnd', function () {
                    u.removeClass([i, f].join(' ')).addClass('active');
                    e.removeClass(['active', f].join(' '));
                    a.sliding = !1;
                    setTimeout(function () {
                      a.$element.trigger(c);
                    }, 0);
                  })
                  .emulateTransitionEnd(t.TRANSITION_DURATION))
              : (e.removeClass('active'),
                u.addClass('active'),
                (this.sliding = !1),
                this.$element.trigger(c)),
            l && this.cycle(),
            this)
          : void 0);
  };
  u = n.fn.carousel;
  n.fn.carousel = i;
  n.fn.carousel.Constructor = t;
  n.fn.carousel.noConflict = function () {
    return (n.fn.carousel = u), this;
  };
  r = function (t) {
    var o,
      r = n(this),
      u = n(
        r.attr('data-target') ||
          ((o = r.attr('href')) && o.replace(/.*(?=#[^\s]+$)/, ''))
      ),
      e,
      f;
    u.hasClass('carousel') &&
      ((e = n.extend({}, u.data(), r.data())),
      (f = r.attr('data-slide-to')),
      f && (e.interval = !1),
      i.call(u, e),
      f && u.data('bs.carousel').to(f),
      t.preventDefault());
  };
  n(document)
    .on('click.bs.carousel.data-api', '[data-slide]', r)
    .on('click.bs.carousel.data-api', '[data-slide-to]', r);
  n(window).on('load', function () {
    n('[data-ride="carousel"]').each(function () {
      var t = n(this);
      i.call(t, t.data());
    });
  });
})(jQuery);
+(function (n) {
  'use strict';
  function r(t) {
    var i = t.attr('data-target'),
      r;
    return (
      i ||
        ((i = t.attr('href')),
        (i = i && /#[A-Za-z]/.test(i) && i.replace(/.*(?=#[^\s]*$)/, ''))),
      (r = i && n(i)),
      r && r.length ? r : t.parent()
    );
  }
  function u(t) {
    (t && 3 === t.which) ||
      (n(o).remove(),
      n(i).each(function () {
        var u = n(this),
          i = r(u),
          f = { relatedTarget: this };
        i.hasClass('open') &&
          ((t &&
            'click' == t.type &&
            /input|textarea/i.test(t.target.tagName) &&
            n.contains(i[0], t.target)) ||
            (i.trigger((t = n.Event('hide.bs.dropdown', f))),
            t.isDefaultPrevented() ||
              (u.attr('aria-expanded', 'false'),
              i
                .removeClass('open')
                .trigger(n.Event('hidden.bs.dropdown', f)))));
      }));
  }
  function e(i) {
    return this.each(function () {
      var r = n(this),
        u = r.data('bs.dropdown');
      u || r.data('bs.dropdown', (u = new t(this)));
      'string' == typeof i && u[i].call(r);
    });
  }
  var o = '.dropdown-backdrop',
    i = '[data-toggle="dropdown"]',
    t = function (t) {
      n(t).on('click.bs.dropdown', this.toggle);
    },
    f;
  t.VERSION = '3.3.6';
  t.prototype.toggle = function (t) {
    var f = n(this),
      i,
      o,
      e;
    if (!f.is('.disabled, :disabled')) {
      if (((i = r(f)), (o = i.hasClass('open')), u(), !o)) {
        if (
          ('ontouchstart' in document.documentElement &&
            !i.closest('.navbar-nav').length &&
            n(document.createElement('div'))
              .addClass('dropdown-backdrop')
              .insertAfter(n(this))
              .on('click', u),
          (e = { relatedTarget: this }),
          i.trigger((t = n.Event('show.bs.dropdown', e))),
          t.isDefaultPrevented())
        )
          return;
        f.trigger('focus').attr('aria-expanded', 'true');
        i.toggleClass('open').trigger(n.Event('shown.bs.dropdown', e));
      }
      return !1;
    }
  };
  t.prototype.keydown = function (t) {
    var e, o, s, h, f, u;
    if (
      /(38|40|27|32)/.test(t.which) &&
      !/input|textarea/i.test(t.target.tagName) &&
      ((e = n(this)),
      t.preventDefault(),
      t.stopPropagation(),
      !e.is('.disabled, :disabled'))
    ) {
      if (
        ((o = r(e)),
        (s = o.hasClass('open')),
        (!s && 27 != t.which) || (s && 27 == t.which))
      )
        return 27 == t.which && o.find(i).trigger('focus'), e.trigger('click');
      h = ' li:not(.disabled):visible a';
      f = o.find('.dropdown-menu' + h);
      f.length &&
        ((u = f.index(t.target)),
        38 == t.which && u > 0 && u--,
        40 == t.which && u < f.length - 1 && u++,
        ~u || (u = 0),
        f.eq(u).trigger('focus'));
    }
  };
  f = n.fn.dropdown;
  n.fn.dropdown = e;
  n.fn.dropdown.Constructor = t;
  n.fn.dropdown.noConflict = function () {
    return (n.fn.dropdown = f), this;
  };
  n(document)
    .on('click.bs.dropdown.data-api', u)
    .on('click.bs.dropdown.data-api', '.dropdown form', function (n) {
      n.stopPropagation();
    })
    .on('click.bs.dropdown.data-api', i, t.prototype.toggle)
    .on('keydown.bs.dropdown.data-api', i, t.prototype.keydown)
    .on('keydown.bs.dropdown.data-api', '.dropdown-menu', t.prototype.keydown);
})(jQuery);
+(function (n) {
  'use strict';
  function i(i, r) {
    return this.each(function () {
      var f = n(this),
        u = f.data('bs.modal'),
        e = n.extend({}, t.DEFAULTS, f.data(), 'object' == typeof i && i);
      u || f.data('bs.modal', (u = new t(this, e)));
      'string' == typeof i ? u[i](r) : e.show && u.show(r);
    });
  }
  var t = function (t, i) {
      this.options = i;
      this.$body = n(document.body);
      this.$element = n(t);
      this.$dialog = this.$element.find('.modal-dialog');
      this.$backdrop = null;
      this.isShown = null;
      this.originalBodyPad = null;
      this.scrollbarWidth = 0;
      this.ignoreBackdropClick = !1;
      this.options.remote &&
        this.$element.find('.modal-content').load(
          this.options.remote,
          n.proxy(function () {
            this.$element.trigger('loaded.bs.modal');
          }, this)
        );
    },
    r;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 300;
  t.BACKDROP_TRANSITION_DURATION = 150;
  t.DEFAULTS = { backdrop: !0, keyboard: !0, show: !0 };
  t.prototype.toggle = function (n) {
    return this.isShown ? this.hide() : this.show(n);
  };
  t.prototype.show = function (i) {
    var r = this,
      u = n.Event('show.bs.modal', { relatedTarget: i });
    this.$element.trigger(u);
    this.isShown ||
      u.isDefaultPrevented() ||
      ((this.isShown = !0),
      this.checkScrollbar(),
      this.setScrollbar(),
      this.$body.addClass('modal-open'),
      this.escape(),
      this.resize(),
      this.$element.on(
        'click.dismiss.bs.modal',
        '[data-dismiss="modal"]',
        n.proxy(this.hide, this)
      ),
      this.$dialog.on('mousedown.dismiss.bs.modal', function () {
        r.$element.one('mouseup.dismiss.bs.modal', function (t) {
          n(t.target).is(r.$element) && (r.ignoreBackdropClick = !0);
        });
      }),
      this.backdrop(function () {
        var f = n.support.transition && r.$element.hasClass('fade'),
          u;
        r.$element.parent().length || r.$element.appendTo(r.$body);
        r.$element.show().scrollTop(0);
        r.adjustDialog();
        f && r.$element[0].offsetWidth;
        r.$element.addClass('in');
        r.enforceFocus();
        u = n.Event('shown.bs.modal', { relatedTarget: i });
        f
          ? r.$dialog
              .one('bsTransitionEnd', function () {
                r.$element.trigger('focus').trigger(u);
              })
              .emulateTransitionEnd(t.TRANSITION_DURATION)
          : r.$element.trigger('focus').trigger(u);
      }));
  };
  t.prototype.hide = function (i) {
    i && i.preventDefault();
    i = n.Event('hide.bs.modal');
    this.$element.trigger(i);
    this.isShown &&
      !i.isDefaultPrevented() &&
      ((this.isShown = !1),
      this.escape(),
      this.resize(),
      n(document).off('focusin.bs.modal'),
      this.$element
        .removeClass('in')
        .off('click.dismiss.bs.modal')
        .off('mouseup.dismiss.bs.modal'),
      this.$dialog.off('mousedown.dismiss.bs.modal'),
      n.support.transition && this.$element.hasClass('fade')
        ? this.$element
            .one('bsTransitionEnd', n.proxy(this.hideModal, this))
            .emulateTransitionEnd(t.TRANSITION_DURATION)
        : this.hideModal());
  };
  t.prototype.enforceFocus = function () {
    n(document)
      .off('focusin.bs.modal')
      .on(
        'focusin.bs.modal',
        n.proxy(function (n) {
          this.$element[0] === n.target ||
            this.$element.has(n.target).length ||
            this.$element.trigger('focus');
        }, this)
      );
  };
  t.prototype.escape = function () {
    this.isShown && this.options.keyboard
      ? this.$element.on(
          'keydown.dismiss.bs.modal',
          n.proxy(function (n) {
            27 == n.which && this.hide();
          }, this)
        )
      : this.isShown || this.$element.off('keydown.dismiss.bs.modal');
  };
  t.prototype.resize = function () {
    this.isShown
      ? n(window).on('resize.bs.modal', n.proxy(this.handleUpdate, this))
      : n(window).off('resize.bs.modal');
  };
  t.prototype.hideModal = function () {
    var n = this;
    this.$element.hide();
    this.backdrop(function () {
      n.$body.removeClass('modal-open');
      n.resetAdjustments();
      n.resetScrollbar();
      n.$element.trigger('hidden.bs.modal');
    });
  };
  t.prototype.removeBackdrop = function () {
    this.$backdrop && this.$backdrop.remove();
    this.$backdrop = null;
  };
  t.prototype.backdrop = function (i) {
    var e = this,
      f = this.$element.hasClass('fade') ? 'fade' : '',
      r,
      u;
    if (this.isShown && this.options.backdrop) {
      if (
        ((r = n.support.transition && f),
        (this.$backdrop = n(document.createElement('div'))
          .addClass('modal-backdrop ' + f)
          .appendTo(this.$body)),
        this.$element.on(
          'click.dismiss.bs.modal',
          n.proxy(function (n) {
            return this.ignoreBackdropClick
              ? void (this.ignoreBackdropClick = !1)
              : void (
                  n.target === n.currentTarget &&
                  ('static' == this.options.backdrop
                    ? this.$element[0].focus()
                    : this.hide())
                );
          }, this)
        ),
        r && this.$backdrop[0].offsetWidth,
        this.$backdrop.addClass('in'),
        !i)
      )
        return;
      r
        ? this.$backdrop
            .one('bsTransitionEnd', i)
            .emulateTransitionEnd(t.BACKDROP_TRANSITION_DURATION)
        : i();
    } else
      !this.isShown && this.$backdrop
        ? (this.$backdrop.removeClass('in'),
          (u = function () {
            e.removeBackdrop();
            i && i();
          }),
          n.support.transition && this.$element.hasClass('fade')
            ? this.$backdrop
                .one('bsTransitionEnd', u)
                .emulateTransitionEnd(t.BACKDROP_TRANSITION_DURATION)
            : u())
        : i && i();
  };
  t.prototype.handleUpdate = function () {
    this.adjustDialog();
  };
  t.prototype.adjustDialog = function () {
    var n =
      this.$element[0].scrollHeight > document.documentElement.clientHeight;
    this.$element.css({
      paddingLeft: !this.bodyIsOverflowing && n ? this.scrollbarWidth : '',
      paddingRight: this.bodyIsOverflowing && !n ? this.scrollbarWidth : '',
    });
  };
  t.prototype.resetAdjustments = function () {
    this.$element.css({ paddingLeft: '', paddingRight: '' });
  };
  t.prototype.checkScrollbar = function () {
    var n = window.innerWidth,
      t;
    n ||
      ((t = document.documentElement.getBoundingClientRect()),
      (n = t.right - Math.abs(t.left)));
    this.bodyIsOverflowing = document.body.clientWidth < n;
    this.scrollbarWidth = this.measureScrollbar();
  };
  t.prototype.setScrollbar = function () {
    var n = parseInt(this.$body.css('padding-right') || 0, 10);
    this.originalBodyPad = document.body.style.paddingRight || '';
    this.bodyIsOverflowing &&
      this.$body.css('padding-right', n + this.scrollbarWidth);
  };
  t.prototype.resetScrollbar = function () {
    this.$body.css('padding-right', this.originalBodyPad);
  };
  t.prototype.measureScrollbar = function () {
    var n = document.createElement('div'),
      t;
    return (
      (n.className = 'modal-scrollbar-measure'),
      this.$body.append(n),
      (t = n.offsetWidth - n.clientWidth),
      this.$body[0].removeChild(n),
      t
    );
  };
  r = n.fn.modal;
  n.fn.modal = i;
  n.fn.modal.Constructor = t;
  n.fn.modal.noConflict = function () {
    return (n.fn.modal = r), this;
  };
  n(document).on(
    'click.bs.modal.data-api',
    '[data-toggle="modal"]',
    function (t) {
      var r = n(this),
        f = r.attr('href'),
        u = n(r.attr('data-target') || (f && f.replace(/.*(?=#[^\s]+$)/, ''))),
        e = u.data('bs.modal')
          ? 'toggle'
          : n.extend({ remote: !/#/.test(f) && f }, u.data(), r.data());
      r.is('a') && t.preventDefault();
      u.one('show.bs.modal', function (n) {
        n.isDefaultPrevented() ||
          u.one('hidden.bs.modal', function () {
            r.is(':visible') && r.trigger('focus');
          });
      });
      i.call(u, e, this);
    }
  );
})(jQuery);
+(function (n) {
  'use strict';
  function r(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.tooltip'),
        f = 'object' == typeof i && i;
      (r || !/destroy|hide/.test(i)) &&
        (r || u.data('bs.tooltip', (r = new t(this, f))),
        'string' == typeof i && r[i]());
    });
  }
  var t = function (n, t) {
      this.type = null;
      this.options = null;
      this.enabled = null;
      this.timeout = null;
      this.hoverState = null;
      this.$element = null;
      this.inState = null;
      this.init('tooltip', n, t);
    },
    i;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 150;
  t.DEFAULTS = {
    animation: !0,
    placement: 'top',
    selector: !1,
    template:
      '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    trigger: 'hover focus',
    title: '',
    delay: 0,
    html: !1,
    container: !1,
    viewport: { selector: 'body', padding: 0 },
  };
  t.prototype.init = function (t, i, r) {
    var f, e, u, o, s;
    if (
      ((this.enabled = !0),
      (this.type = t),
      (this.$element = n(i)),
      (this.options = this.getOptions(r)),
      (this.$viewport =
        this.options.viewport &&
        n(
          n.isFunction(this.options.viewport)
            ? this.options.viewport.call(this, this.$element)
            : this.options.viewport.selector || this.options.viewport
        )),
      (this.inState = { click: !1, hover: !1, focus: !1 }),
      this.$element[0] instanceof document.constructor &&
        !this.options.selector)
    )
      throw new Error(
        '`selector` option must be specified when initializing ' +
          this.type +
          ' on the window.document object!'
      );
    for (f = this.options.trigger.split(' '), e = f.length; e--; )
      if (((u = f[e]), 'click' == u))
        this.$element.on(
          'click.' + this.type,
          this.options.selector,
          n.proxy(this.toggle, this)
        );
      else
        'manual' != u &&
          ((o = 'hover' == u ? 'mouseenter' : 'focusin'),
          (s = 'hover' == u ? 'mouseleave' : 'focusout'),
          this.$element.on(
            o + '.' + this.type,
            this.options.selector,
            n.proxy(this.enter, this)
          ),
          this.$element.on(
            s + '.' + this.type,
            this.options.selector,
            n.proxy(this.leave, this)
          ));
    this.options.selector
      ? (this._options = n.extend({}, this.options, {
          trigger: 'manual',
          selector: '',
        }))
      : this.fixTitle();
  };
  t.prototype.getDefaults = function () {
    return t.DEFAULTS;
  };
  t.prototype.getOptions = function (t) {
    return (
      (t = n.extend({}, this.getDefaults(), this.$element.data(), t)),
      t.delay &&
        'number' == typeof t.delay &&
        (t.delay = { show: t.delay, hide: t.delay }),
      t
    );
  };
  t.prototype.getDelegateOptions = function () {
    var t = {},
      i = this.getDefaults();
    return (
      this._options &&
        n.each(this._options, function (n, r) {
          i[n] != r && (t[n] = r);
        }),
      t
    );
  };
  t.prototype.enter = function (t) {
    var i =
      t instanceof this.constructor
        ? t
        : n(t.currentTarget).data('bs.' + this.type);
    return (
      i ||
        ((i = new this.constructor(t.currentTarget, this.getDelegateOptions())),
        n(t.currentTarget).data('bs.' + this.type, i)),
      t instanceof n.Event &&
        (i.inState['focusin' == t.type ? 'focus' : 'hover'] = !0),
      i.tip().hasClass('in') || 'in' == i.hoverState
        ? void (i.hoverState = 'in')
        : (clearTimeout(i.timeout),
          (i.hoverState = 'in'),
          i.options.delay && i.options.delay.show
            ? void (i.timeout = setTimeout(function () {
                'in' == i.hoverState && i.show();
              }, i.options.delay.show))
            : i.show())
    );
  };
  t.prototype.isInStateTrue = function () {
    for (var n in this.inState) if (this.inState[n]) return !0;
    return !1;
  };
  t.prototype.leave = function (t) {
    var i =
      t instanceof this.constructor
        ? t
        : n(t.currentTarget).data('bs.' + this.type);
    return (
      i ||
        ((i = new this.constructor(t.currentTarget, this.getDelegateOptions())),
        n(t.currentTarget).data('bs.' + this.type, i)),
      t instanceof n.Event &&
        (i.inState['focusout' == t.type ? 'focus' : 'hover'] = !1),
      i.isInStateTrue()
        ? void 0
        : (clearTimeout(i.timeout),
          (i.hoverState = 'out'),
          i.options.delay && i.options.delay.hide
            ? void (i.timeout = setTimeout(function () {
                'out' == i.hoverState && i.hide();
              }, i.options.delay.hide))
            : i.hide())
    );
  };
  t.prototype.show = function () {
    var c = n.Event('show.bs.' + this.type),
      l,
      p,
      e,
      w,
      h;
    if (this.hasContent() && this.enabled) {
      if (
        (this.$element.trigger(c),
        (l = n.contains(
          this.$element[0].ownerDocument.documentElement,
          this.$element[0]
        )),
        c.isDefaultPrevented() || !l)
      )
        return;
      var u = this,
        r = this.tip(),
        a = this.getUID(this.type);
      this.setContent();
      r.attr('id', a);
      this.$element.attr('aria-describedby', a);
      this.options.animation && r.addClass('fade');
      var i =
          'function' == typeof this.options.placement
            ? this.options.placement.call(this, r[0], this.$element[0])
            : this.options.placement,
        v = /\s?auto?\s?/i,
        y = v.test(i);
      y && (i = i.replace(v, '') || 'top');
      r.detach()
        .css({ top: 0, left: 0, display: 'block' })
        .addClass(i)
        .data('bs.' + this.type, this);
      this.options.container
        ? r.appendTo(this.options.container)
        : r.insertAfter(this.$element);
      this.$element.trigger('inserted.bs.' + this.type);
      var f = this.getPosition(),
        o = r[0].offsetWidth,
        s = r[0].offsetHeight;
      y &&
        ((p = i),
        (e = this.getPosition(this.$viewport)),
        (i =
          'bottom' == i && f.bottom + s > e.bottom
            ? 'top'
            : 'top' == i && f.top - s < e.top
            ? 'bottom'
            : 'right' == i && f.right + o > e.width
            ? 'left'
            : 'left' == i && f.left - o < e.left
            ? 'right'
            : i),
        r.removeClass(p).addClass(i));
      w = this.getCalculatedOffset(i, f, o, s);
      this.applyPlacement(w, i);
      h = function () {
        var n = u.hoverState;
        u.$element.trigger('shown.bs.' + u.type);
        u.hoverState = null;
        'out' == n && u.leave(u);
      };
      n.support.transition && this.$tip.hasClass('fade')
        ? r
            .one('bsTransitionEnd', h)
            .emulateTransitionEnd(t.TRANSITION_DURATION)
        : h();
    }
  };
  t.prototype.applyPlacement = function (t, i) {
    var r = this.tip(),
      l = r[0].offsetWidth,
      e = r[0].offsetHeight,
      o = parseInt(r.css('margin-top'), 10),
      s = parseInt(r.css('margin-left'), 10),
      h,
      f,
      u;
    isNaN(o) && (o = 0);
    isNaN(s) && (s = 0);
    t.top += o;
    t.left += s;
    n.offset.setOffset(
      r[0],
      n.extend(
        {
          using: function (n) {
            r.css({ top: Math.round(n.top), left: Math.round(n.left) });
          },
        },
        t
      ),
      0
    );
    r.addClass('in');
    h = r[0].offsetWidth;
    f = r[0].offsetHeight;
    'top' == i && f != e && (t.top = t.top + e - f);
    u = this.getViewportAdjustedDelta(i, t, h, f);
    u.left ? (t.left += u.left) : (t.top += u.top);
    var c = /top|bottom/.test(i),
      a = c ? 2 * u.left - l + h : 2 * u.top - e + f,
      v = c ? 'offsetWidth' : 'offsetHeight';
    r.offset(t);
    this.replaceArrow(a, r[0][v], c);
  };
  t.prototype.replaceArrow = function (n, t, i) {
    this.arrow()
      .css(i ? 'left' : 'top', 50 * (1 - n / t) + '%')
      .css(i ? 'top' : 'left', '');
  };
  t.prototype.setContent = function () {
    var n = this.tip(),
      t = this.getTitle();
    n.find('.tooltip-inner')[this.options.html ? 'html' : 'text'](t);
    n.removeClass('fade in top bottom left right');
  };
  t.prototype.hide = function (i) {
    function f() {
      'in' != u.hoverState && r.detach();
      u.$element.removeAttr('aria-describedby').trigger('hidden.bs.' + u.type);
      i && i();
    }
    var u = this,
      r = n(this.$tip),
      e = n.Event('hide.bs.' + this.type);
    return (
      this.$element.trigger(e),
      e.isDefaultPrevented()
        ? void 0
        : (r.removeClass('in'),
          n.support.transition && r.hasClass('fade')
            ? r
                .one('bsTransitionEnd', f)
                .emulateTransitionEnd(t.TRANSITION_DURATION)
            : f(),
          (this.hoverState = null),
          this)
    );
  };
  t.prototype.fixTitle = function () {
    var n = this.$element;
    (n.attr('title') || 'string' != typeof n.attr('data-original-title')) &&
      n.attr('data-original-title', n.attr('title') || '').attr('title', '');
  };
  t.prototype.hasContent = function () {
    return this.getTitle();
  };
  t.prototype.getPosition = function (t) {
    t = t || this.$element;
    var u = t[0],
      r = 'BODY' == u.tagName,
      i = u.getBoundingClientRect();
    null == i.width &&
      (i = n.extend({}, i, {
        width: i.right - i.left,
        height: i.bottom - i.top,
      }));
    var f = r ? { top: 0, left: 0 } : t.offset(),
      e = {
        scroll: r
          ? document.documentElement.scrollTop || document.body.scrollTop
          : t.scrollTop(),
      },
      o = r ? { width: n(window).width(), height: n(window).height() } : null;
    return n.extend({}, i, e, o, f);
  };
  t.prototype.getCalculatedOffset = function (n, t, i, r) {
    return 'bottom' == n
      ? { top: t.top + t.height, left: t.left + t.width / 2 - i / 2 }
      : 'top' == n
      ? { top: t.top - r, left: t.left + t.width / 2 - i / 2 }
      : 'left' == n
      ? { top: t.top + t.height / 2 - r / 2, left: t.left - i }
      : { top: t.top + t.height / 2 - r / 2, left: t.left + t.width };
  };
  t.prototype.getViewportAdjustedDelta = function (n, t, i, r) {
    var f = { top: 0, left: 0 },
      e,
      u,
      o,
      s,
      h,
      c;
    return this.$viewport
      ? ((e = (this.options.viewport && this.options.viewport.padding) || 0),
        (u = this.getPosition(this.$viewport)),
        /right|left/.test(n)
          ? ((o = t.top - e - u.scroll),
            (s = t.top + e - u.scroll + r),
            o < u.top
              ? (f.top = u.top - o)
              : s > u.top + u.height && (f.top = u.top + u.height - s))
          : ((h = t.left - e),
            (c = t.left + e + i),
            h < u.left
              ? (f.left = u.left - h)
              : c > u.right && (f.left = u.left + u.width - c)),
        f)
      : f;
  };
  t.prototype.getTitle = function () {
    var t = this.$element,
      n = this.options;
    return (
      t.attr('data-original-title') ||
      ('function' == typeof n.title ? n.title.call(t[0]) : n.title)
    );
  };
  t.prototype.getUID = function (n) {
    do n += ~~(1e6 * Math.random());
    while (document.getElementById(n));
    return n;
  };
  t.prototype.tip = function () {
    if (
      !this.$tip &&
      ((this.$tip = n(this.options.template)), 1 != this.$tip.length)
    )
      throw new Error(
        this.type +
          ' `template` option must consist of exactly 1 top-level element!'
      );
    return this.$tip;
  };
  t.prototype.arrow = function () {
    return (this.$arrow = this.$arrow || this.tip().find('.tooltip-arrow'));
  };
  t.prototype.enable = function () {
    this.enabled = !0;
  };
  t.prototype.disable = function () {
    this.enabled = !1;
  };
  t.prototype.toggleEnabled = function () {
    this.enabled = !this.enabled;
  };
  t.prototype.toggle = function (t) {
    var i = this;
    t &&
      ((i = n(t.currentTarget).data('bs.' + this.type)),
      i ||
        ((i = new this.constructor(t.currentTarget, this.getDelegateOptions())),
        n(t.currentTarget).data('bs.' + this.type, i)));
    t
      ? ((i.inState.click = !i.inState.click),
        i.isInStateTrue() ? i.enter(i) : i.leave(i))
      : i.tip().hasClass('in')
      ? i.leave(i)
      : i.enter(i);
  };
  t.prototype.destroy = function () {
    var n = this;
    clearTimeout(this.timeout);
    this.hide(function () {
      n.$element.off('.' + n.type).removeData('bs.' + n.type);
      n.$tip && n.$tip.detach();
      n.$tip = null;
      n.$arrow = null;
      n.$viewport = null;
    });
  };
  i = n.fn.tooltip;
  n.fn.tooltip = r;
  n.fn.tooltip.Constructor = t;
  n.fn.tooltip.noConflict = function () {
    return (n.fn.tooltip = i), this;
  };
})(jQuery);
+(function (n) {
  'use strict';
  function r(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.popover'),
        f = 'object' == typeof i && i;
      (r || !/destroy|hide/.test(i)) &&
        (r || u.data('bs.popover', (r = new t(this, f))),
        'string' == typeof i && r[i]());
    });
  }
  var t = function (n, t) {
      this.init('popover', n, t);
    },
    i;
  if (!n.fn.tooltip) throw new Error('Popover requires tooltip.js');
  t.VERSION = '3.3.6';
  t.DEFAULTS = n.extend({}, n.fn.tooltip.Constructor.DEFAULTS, {
    placement: 'right',
    trigger: 'click',
    content: '',
    template:
      '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
  });
  t.prototype = n.extend({}, n.fn.tooltip.Constructor.prototype);
  t.prototype.constructor = t;
  t.prototype.getDefaults = function () {
    return t.DEFAULTS;
  };
  t.prototype.setContent = function () {
    var n = this.tip(),
      i = this.getTitle(),
      t = this.getContent();
    n.find('.popover-title')[this.options.html ? 'html' : 'text'](i);
    n.find('.popover-content')
      .children()
      .detach()
      .end()
      [this.options.html ? ('string' == typeof t ? 'html' : 'append') : 'text'](
        t
      );
    n.removeClass('fade top bottom left right in');
    n.find('.popover-title').html() || n.find('.popover-title').hide();
  };
  t.prototype.hasContent = function () {
    return this.getTitle() || this.getContent();
  };
  t.prototype.getContent = function () {
    var t = this.$element,
      n = this.options;
    return (
      t.attr('data-content') ||
      ('function' == typeof n.content ? n.content.call(t[0]) : n.content)
    );
  };
  t.prototype.arrow = function () {
    return (this.$arrow = this.$arrow || this.tip().find('.arrow'));
  };
  i = n.fn.popover;
  n.fn.popover = r;
  n.fn.popover.Constructor = t;
  n.fn.popover.noConflict = function () {
    return (n.fn.popover = i), this;
  };
})(jQuery);
+(function (n) {
  'use strict';
  function r(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.tab');
      r || u.data('bs.tab', (r = new t(this)));
      'string' == typeof i && r[i]();
    });
  }
  var t = function (t) {
      this.element = n(t);
    },
    u,
    i;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 150;
  t.prototype.show = function () {
    var t = this.element,
      f = t.closest('ul:not(.dropdown-menu)'),
      i = t.data('target'),
      u;
    if (
      (i || ((i = t.attr('href')), (i = i && i.replace(/.*(?=#[^\s]*$)/, ''))),
      !t.parent('li').hasClass('active'))
    ) {
      var r = f.find('.active:last a'),
        e = n.Event('hide.bs.tab', { relatedTarget: t[0] }),
        o = n.Event('show.bs.tab', { relatedTarget: r[0] });
      (r.trigger(e),
      t.trigger(o),
      o.isDefaultPrevented() || e.isDefaultPrevented()) ||
        ((u = n(i)),
        this.activate(t.closest('li'), f),
        this.activate(u, u.parent(), function () {
          r.trigger({ type: 'hidden.bs.tab', relatedTarget: t[0] });
          t.trigger({ type: 'shown.bs.tab', relatedTarget: r[0] });
        }));
    }
  };
  t.prototype.activate = function (i, r, u) {
    function e() {
      f.removeClass('active')
        .find('> .dropdown-menu > .active')
        .removeClass('active')
        .end()
        .find('[data-toggle="tab"]')
        .attr('aria-expanded', !1);
      i.addClass('active')
        .find('[data-toggle="tab"]')
        .attr('aria-expanded', !0);
      o ? (i[0].offsetWidth, i.addClass('in')) : i.removeClass('fade');
      i.parent('.dropdown-menu').length &&
        i
          .closest('li.dropdown')
          .addClass('active')
          .end()
          .find('[data-toggle="tab"]')
          .attr('aria-expanded', !0);
      u && u();
    }
    var f = r.find('> .active'),
      o =
        u &&
        n.support.transition &&
        ((f.length && f.hasClass('fade')) || !!r.find('> .fade').length);
    f.length && o
      ? f.one('bsTransitionEnd', e).emulateTransitionEnd(t.TRANSITION_DURATION)
      : e();
    f.removeClass('in');
  };
  u = n.fn.tab;
  n.fn.tab = r;
  n.fn.tab.Constructor = t;
  n.fn.tab.noConflict = function () {
    return (n.fn.tab = u), this;
  };
  i = function (t) {
    t.preventDefault();
    r.call(n(this), 'show');
  };
  n(document)
    .on('click.bs.tab.data-api', '[data-toggle="tab"]', i)
    .on('click.bs.tab.data-api', '[data-toggle="pill"]', i);
})(jQuery);
+(function (n) {
  'use strict';
  function i(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.affix'),
        f = 'object' == typeof i && i;
      r || u.data('bs.affix', (r = new t(this, f)));
      'string' == typeof i && r[i]();
    });
  }
  var t = function (i, r) {
      this.options = n.extend({}, t.DEFAULTS, r);
      this.$target = n(this.options.target)
        .on('scroll.bs.affix.data-api', n.proxy(this.checkPosition, this))
        .on(
          'click.bs.affix.data-api',
          n.proxy(this.checkPositionWithEventLoop, this)
        );
      this.$element = n(i);
      this.affixed = null;
      this.unpin = null;
      this.pinnedOffset = null;
      this.checkPosition();
    },
    r;
  t.VERSION = '3.3.6';
  t.RESET = 'affix affix-top affix-bottom';
  t.DEFAULTS = { offset: 0, target: window };
  t.prototype.getState = function (n, t, i, r) {
    var u = this.$target.scrollTop(),
      f = this.$element.offset(),
      e = this.$target.height();
    if (null != i && 'top' == this.affixed) return i > u ? 'top' : !1;
    if ('bottom' == this.affixed)
      return null != i
        ? u + this.unpin <= f.top
          ? !1
          : 'bottom'
        : n - r >= u + e
        ? !1
        : 'bottom';
    var o = null == this.affixed,
      s = o ? u : f.top,
      h = o ? e : t;
    return null != i && i >= u
      ? 'top'
      : null != r && s + h >= n - r
      ? 'bottom'
      : !1;
  };
  t.prototype.getPinnedOffset = function () {
    if (this.pinnedOffset) return this.pinnedOffset;
    this.$element.removeClass(t.RESET).addClass('affix');
    var n = this.$target.scrollTop(),
      i = this.$element.offset();
    return (this.pinnedOffset = i.top - n);
  };
  t.prototype.checkPositionWithEventLoop = function () {
    setTimeout(n.proxy(this.checkPosition, this), 1);
  };
  t.prototype.checkPosition = function () {
    var i, e, o;
    if (this.$element.is(':visible')) {
      var s = this.$element.height(),
        r = this.options.offset,
        f = r.top,
        u = r.bottom,
        h = Math.max(n(document).height(), n(document.body).height());
      if (
        ('object' != typeof r && (u = f = r),
        'function' == typeof f && (f = r.top(this.$element)),
        'function' == typeof u && (u = r.bottom(this.$element)),
        (i = this.getState(h, s, f, u)),
        this.affixed != i)
      ) {
        if (
          (null != this.unpin && this.$element.css('top', ''),
          (e = 'affix' + (i ? '-' + i : '')),
          (o = n.Event(e + '.bs.affix')),
          this.$element.trigger(o),
          o.isDefaultPrevented())
        )
          return;
        this.affixed = i;
        this.unpin = 'bottom' == i ? this.getPinnedOffset() : null;
        this.$element
          .removeClass(t.RESET)
          .addClass(e)
          .trigger(e.replace('affix', 'affixed') + '.bs.affix');
      }
      'bottom' == i && this.$element.offset({ top: h - s - u });
    }
  };
  r = n.fn.affix;
  n.fn.affix = i;
  n.fn.affix.Constructor = t;
  n.fn.affix.noConflict = function () {
    return (n.fn.affix = r), this;
  };
  n(window).on('load', function () {
    n('[data-spy="affix"]').each(function () {
      var r = n(this),
        t = r.data();
      t.offset = t.offset || {};
      null != t.offsetBottom && (t.offset.bottom = t.offsetBottom);
      null != t.offsetTop && (t.offset.top = t.offsetTop);
      i.call(r, t);
    });
  });
})(jQuery);
+(function (n) {
  'use strict';
  function r(t) {
    var i,
      r =
        t.attr('data-target') ||
        ((i = t.attr('href')) && i.replace(/.*(?=#[^\s]+$)/, ''));
    return n(r);
  }
  function i(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.collapse'),
        f = n.extend({}, t.DEFAULTS, u.data(), 'object' == typeof i && i);
      !r && f.toggle && /show|hide/.test(i) && (f.toggle = !1);
      r || u.data('bs.collapse', (r = new t(this, f)));
      'string' == typeof i && r[i]();
    });
  }
  var t = function (i, r) {
      this.$element = n(i);
      this.options = n.extend({}, t.DEFAULTS, r);
      this.$trigger = n(
        '[data-toggle="collapse"][href="#' +
          i.id +
          '"],[data-toggle="collapse"][data-target="#' +
          i.id +
          '"]'
      );
      this.transitioning = null;
      this.options.parent
        ? (this.$parent = this.getParent())
        : this.addAriaAndCollapsedClass(this.$element, this.$trigger);
      this.options.toggle && this.toggle();
    },
    u;
  t.VERSION = '3.3.6';
  t.TRANSITION_DURATION = 350;
  t.DEFAULTS = { toggle: !0 };
  t.prototype.dimension = function () {
    var n = this.$element.hasClass('width');
    return n ? 'width' : 'height';
  };
  t.prototype.show = function () {
    var f, r, e, u, o, s;
    if (
      !this.transitioning &&
      !this.$element.hasClass('in') &&
      ((r =
        this.$parent &&
        this.$parent.children('.panel').children('.in, .collapsing')),
      !(r && r.length && ((f = r.data('bs.collapse')), f && f.transitioning)) &&
        ((e = n.Event('show.bs.collapse')),
        this.$element.trigger(e),
        !e.isDefaultPrevented()))
    ) {
      if (
        (r && r.length && (i.call(r, 'hide'), f || r.data('bs.collapse', null)),
        (u = this.dimension()),
        this.$element
          .removeClass('collapse')
          .addClass('collapsing')
          [u](0)
          .attr('aria-expanded', !0),
        this.$trigger.removeClass('collapsed').attr('aria-expanded', !0),
        (this.transitioning = 1),
        (o = function () {
          this.$element
            .removeClass('collapsing')
            .addClass('collapse in')
            [u]('');
          this.transitioning = 0;
          this.$element.trigger('shown.bs.collapse');
        }),
        !n.support.transition)
      )
        return o.call(this);
      s = n.camelCase(['scroll', u].join('-'));
      this.$element
        .one('bsTransitionEnd', n.proxy(o, this))
        .emulateTransitionEnd(t.TRANSITION_DURATION)
        [u](this.$element[0][s]);
    }
  };
  t.prototype.hide = function () {
    var r, i, u;
    if (
      !this.transitioning &&
      this.$element.hasClass('in') &&
      ((r = n.Event('hide.bs.collapse')),
      this.$element.trigger(r),
      !r.isDefaultPrevented())
    )
      return (
        (i = this.dimension()),
        this.$element[i](this.$element[i]())[0].offsetHeight,
        this.$element
          .addClass('collapsing')
          .removeClass('collapse in')
          .attr('aria-expanded', !1),
        this.$trigger.addClass('collapsed').attr('aria-expanded', !1),
        (this.transitioning = 1),
        (u = function () {
          this.transitioning = 0;
          this.$element
            .removeClass('collapsing')
            .addClass('collapse')
            .trigger('hidden.bs.collapse');
        }),
        n.support.transition
          ? void this.$element[i](0)
              .one('bsTransitionEnd', n.proxy(u, this))
              .emulateTransitionEnd(t.TRANSITION_DURATION)
          : u.call(this)
      );
  };
  t.prototype.toggle = function () {
    this[this.$element.hasClass('in') ? 'hide' : 'show']();
  };
  t.prototype.getParent = function () {
    return n(this.options.parent)
      .find(
        '[data-toggle="collapse"][data-parent="' + this.options.parent + '"]'
      )
      .each(
        n.proxy(function (t, i) {
          var u = n(i);
          this.addAriaAndCollapsedClass(r(u), u);
        }, this)
      )
      .end();
  };
  t.prototype.addAriaAndCollapsedClass = function (n, t) {
    var i = n.hasClass('in');
    n.attr('aria-expanded', i);
    t.toggleClass('collapsed', !i).attr('aria-expanded', i);
  };
  u = n.fn.collapse;
  n.fn.collapse = i;
  n.fn.collapse.Constructor = t;
  n.fn.collapse.noConflict = function () {
    return (n.fn.collapse = u), this;
  };
  n(document).on(
    'click.bs.collapse.data-api',
    '[data-toggle="collapse"]',
    function (t) {
      var u = n(this);
      u.attr('data-target') || t.preventDefault();
      var f = r(u),
        e = f.data('bs.collapse'),
        o = e ? 'toggle' : u.data();
      i.call(f, o);
    }
  );
})(jQuery);
+(function (n) {
  'use strict';
  function t(i, r) {
    this.$body = n(document.body);
    this.$scrollElement = n(n(i).is(document.body) ? window : i);
    this.options = n.extend({}, t.DEFAULTS, r);
    this.selector = (this.options.target || '') + ' .nav li > a';
    this.offsets = [];
    this.targets = [];
    this.activeTarget = null;
    this.scrollHeight = 0;
    this.$scrollElement.on('scroll.bs.scrollspy', n.proxy(this.process, this));
    this.refresh();
    this.process();
  }
  function i(i) {
    return this.each(function () {
      var u = n(this),
        r = u.data('bs.scrollspy'),
        f = 'object' == typeof i && i;
      r || u.data('bs.scrollspy', (r = new t(this, f)));
      'string' == typeof i && r[i]();
    });
  }
  t.VERSION = '3.3.6';
  t.DEFAULTS = { offset: 10 };
  t.prototype.getScrollHeight = function () {
    return (
      this.$scrollElement[0].scrollHeight ||
      Math.max(
        this.$body[0].scrollHeight,
        document.documentElement.scrollHeight
      )
    );
  };
  t.prototype.refresh = function () {
    var t = this,
      i = 'offset',
      r = 0;
    this.offsets = [];
    this.targets = [];
    this.scrollHeight = this.getScrollHeight();
    n.isWindow(this.$scrollElement[0]) ||
      ((i = 'position'), (r = this.$scrollElement.scrollTop()));
    this.$body
      .find(this.selector)
      .map(function () {
        var f = n(this),
          u = f.data('target') || f.attr('href'),
          t = /^#./.test(u) && n(u);
        return (
          (t && t.length && t.is(':visible') && [[t[i]().top + r, u]]) || null
        );
      })
      .sort(function (n, t) {
        return n[0] - t[0];
      })
      .each(function () {
        t.offsets.push(this[0]);
        t.targets.push(this[1]);
      });
  };
  t.prototype.process = function () {
    var n,
      i = this.$scrollElement.scrollTop() + this.options.offset,
      f = this.getScrollHeight(),
      e = this.options.offset + f - this.$scrollElement.height(),
      t = this.offsets,
      r = this.targets,
      u = this.activeTarget;
    if ((this.scrollHeight != f && this.refresh(), i >= e))
      return u != (n = r[r.length - 1]) && this.activate(n);
    if (u && i < t[0]) return (this.activeTarget = null), this.clear();
    for (n = t.length; n--; )
      u != r[n] &&
        i >= t[n] &&
        (void 0 === t[n + 1] || i < t[n + 1]) &&
        this.activate(r[n]);
  };
  t.prototype.activate = function (t) {
    this.activeTarget = t;
    this.clear();
    var r =
        this.selector +
        '[data-target="' +
        t +
        '"],' +
        this.selector +
        '[href="' +
        t +
        '"]',
      i = n(r).parents('li').addClass('active');
    i.parent('.dropdown-menu').length &&
      (i = i.closest('li.dropdown').addClass('active'));
    i.trigger('activate.bs.scrollspy');
  };
  t.prototype.clear = function () {
    n(this.selector)
      .parentsUntil(this.options.target, '.active')
      .removeClass('active');
  };
  var r = n.fn.scrollspy;
  n.fn.scrollspy = i;
  n.fn.scrollspy.Constructor = t;
  n.fn.scrollspy.noConflict = function () {
    return (n.fn.scrollspy = r), this;
  };
  n(window).on('load.bs.scrollspy.data-api', function () {
    n('[data-spy="scroll"]').each(function () {
      var t = n(this);
      i.call(t, t.data());
    });
  });
})(jQuery);
+(function (n) {
  'use strict';
  function t() {
    var i = document.createElement('bootstrap'),
      t = {
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'transitionend',
        OTransition: 'oTransitionEnd otransitionend',
        transition: 'transitionend',
      },
      n;
    for (n in t) if (void 0 !== i.style[n]) return { end: t[n] };
    return !1;
  }
  n.fn.emulateTransitionEnd = function (t) {
    var i = !1,
      u = this,
      r;
    n(this).one('bsTransitionEnd', function () {
      i = !0;
    });
    return (
      (r = function () {
        i || n(u).trigger(n.support.transition.end);
      }),
      setTimeout(r, t),
      this
    );
  };
  n(function () {
    n.support.transition = t();
    n.support.transition &&
      (n.event.special.bsTransitionEnd = {
        bindType: n.support.transition.end,
        delegateType: n.support.transition.end,
        handle: function (t) {
          if (n(t.target).is(this))
            return t.handleObj.handler.apply(this, arguments);
        },
      });
  });
})(jQuery);
!(function (n, t) {
  function u() {
    return new Date(Date.UTC.apply(Date, arguments));
  }
  function e() {
    var n = new Date();
    return u(n.getFullYear(), n.getMonth(), n.getDate());
  }
  function c(n) {
    return function () {
      return this[n].apply(this, arguments);
    };
  }
  function y(t, i) {
    function o(n, t) {
      return t.toLowerCase();
    }
    var u,
      f = n(t).data(),
      e = {},
      s = new RegExp('^' + i.toLowerCase() + '([A-Z])'),
      r;
    i = new RegExp('^' + i.toLowerCase());
    for (r in f) i.test(r) && ((u = r.replace(s, o)), (e[u] = f[r]));
    return e;
  }
  function p(t) {
    var u = {},
      i;
    if (r[t] || ((t = t.split('-')[0]), r[t]))
      return (
        (i = r[t]),
        n.each(v, function (n, t) {
          t in i && (u[t] = i[t]);
        }),
        u
      );
  }
  var s = n(window),
    l = (function () {
      var t = {
        get: function (n) {
          return this.slice(n)[0];
        },
        contains: function (n) {
          for (var i = n && n.valueOf(), t = 0, r = this.length; r > t; t++)
            if (this[t].valueOf() === i) return t;
          return -1;
        },
        remove: function (n) {
          this.splice(n, 1);
        },
        replace: function (t) {
          t &&
            (n.isArray(t) || (t = [t]), this.clear(), this.push.apply(this, t));
        },
        clear: function () {
          this.splice(0);
        },
        copy: function () {
          var n = new l();
          return n.replace(this), n;
        },
      };
      return function () {
        var i = [];
        return i.push.apply(i, arguments), n.extend(i, t), i;
      };
    })(),
    f = function (t, r) {
      this.dates = new l();
      this.viewDate = e();
      this.focusDate = null;
      this._process_options(r);
      this.element = n(t);
      this.isInline = !1;
      this.isInput = this.element.is('input');
      this.component = this.element.is('.date')
        ? this.element.find('.add-on, .input-group-addon, .btn')
        : !1;
      this.hasInput = this.component && this.element.find('input').length;
      this.component && 0 === this.component.length && (this.component = !1);
      this.picker = n(i.template);
      this._buildEvents();
      this._attachEvents();
      this.isInline
        ? this.picker.addClass('datepicker-inline').appendTo(this.element)
        : this.picker.addClass('datepicker-dropdown dropdown-menu');
      this.o.rtl && this.picker.addClass('datepicker-rtl');
      this.viewMode = this.o.startView;
      this.o.calendarWeeks &&
        this.picker.find('tfoot th.today').attr('colspan', function (n, t) {
          return parseInt(t) + 1;
        });
      this._allow_update = !1;
      this.setStartDate(this._o.startDate);
      this.setEndDate(this._o.endDate);
      this.setDaysOfWeekDisabled(this.o.daysOfWeekDisabled);
      this.fillDow();
      this.fillMonths();
      this._allow_update = !0;
      this.update();
      this.showMode();
      this.isInline && this.show();
    },
    h,
    a,
    o,
    v,
    r,
    i;
  f.prototype = {
    constructor: f,
    _process_options: function (t) {
      var u, e, h, f, s;
      this._o = n.extend({}, this._o, t);
      u = this.o = n.extend({}, this._o);
      e = u.language;
      switch (
        (r[e] || ((e = e.split('-')[0]), r[e] || (e = o.language)),
        (u.language = e),
        u.startView)
      ) {
        case 2:
        case 'decade':
          u.startView = 2;
          break;
        case 1:
        case 'year':
          u.startView = 1;
          break;
        default:
          u.startView = 0;
      }
      switch (u.minViewMode) {
        case 1:
        case 'months':
          u.minViewMode = 1;
          break;
        case 2:
        case 'years':
          u.minViewMode = 2;
          break;
        default:
          u.minViewMode = 0;
      }
      if (
        ((u.startView = Math.max(u.startView, u.minViewMode)),
        u.multidate !== !0 &&
          ((u.multidate = Number(u.multidate) || !1),
          (u.multidate = u.multidate !== !1 ? Math.max(0, u.multidate) : 1)),
        (u.multidateSeparator = String(u.multidateSeparator)),
        (u.weekStart %= 7),
        (u.weekEnd = (u.weekStart + 6) % 7),
        (h = i.parseFormat(u.format)),
        u.startDate !== -1 / 0 &&
          (u.startDate = u.startDate
            ? u.startDate instanceof Date
              ? this._local_to_utc(this._zero_time(u.startDate))
              : i.parseDate(u.startDate, h, u.language)
            : -1 / 0),
        1 / 0 !== u.endDate &&
          (u.endDate = u.endDate
            ? u.endDate instanceof Date
              ? this._local_to_utc(this._zero_time(u.endDate))
              : i.parseDate(u.endDate, h, u.language)
            : 1 / 0),
        (u.daysOfWeekDisabled = u.daysOfWeekDisabled || []),
        n.isArray(u.daysOfWeekDisabled) ||
          (u.daysOfWeekDisabled = u.daysOfWeekDisabled.split(/[,\s]*/)),
        (u.daysOfWeekDisabled = n.map(u.daysOfWeekDisabled, function (n) {
          return parseInt(n, 10);
        })),
        (f = String(u.orientation).toLowerCase().split(/\s+/g)),
        (s = u.orientation.toLowerCase()),
        (f = n.grep(f, function (n) {
          return /^auto|left|right|top|bottom$/.test(n);
        })),
        (u.orientation = { x: 'auto', y: 'auto' }),
        s && 'auto' !== s)
      )
        if (1 === f.length)
          switch (f[0]) {
            case 'top':
            case 'bottom':
              u.orientation.y = f[0];
              break;
            case 'left':
            case 'right':
              u.orientation.x = f[0];
          }
        else
          (s = n.grep(f, function (n) {
            return /^left|right$/.test(n);
          })),
            (u.orientation.x = s[0] || 'auto'),
            (s = n.grep(f, function (n) {
              return /^top|bottom$/.test(n);
            })),
            (u.orientation.y = s[0] || 'auto');
    },
    _events: [],
    _secondaryEvents: [],
    _applyEvents: function (n) {
      for (var f, r, u, i = 0; i < n.length; i++)
        (f = n[i][0]),
          2 === n[i].length
            ? ((r = t), (u = n[i][1]))
            : 3 === n[i].length && ((r = n[i][1]), (u = n[i][2])),
          f.on(u, r);
    },
    _unapplyEvents: function (n) {
      for (var f, r, u, i = 0; i < n.length; i++)
        (f = n[i][0]),
          2 === n[i].length
            ? ((u = t), (r = n[i][1]))
            : 3 === n[i].length && ((u = n[i][1]), (r = n[i][2])),
          f.off(r, u);
    },
    _buildEvents: function () {
      this.isInput
        ? (this._events = [
            [
              this.element,
              {
                focus: n.proxy(this.show, this),
                keyup: n.proxy(function (t) {
                  -1 ===
                    n.inArray(t.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) &&
                    this.update();
                }, this),
                keydown: n.proxy(this.keydown, this),
              },
            ],
          ])
        : this.component && this.hasInput
        ? (this._events = [
            [
              this.element.find('input'),
              {
                focus: n.proxy(this.show, this),
                keyup: n.proxy(function (t) {
                  -1 ===
                    n.inArray(t.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) &&
                    this.update();
                }, this),
                keydown: n.proxy(this.keydown, this),
              },
            ],
            [this.component, { click: n.proxy(this.show, this) }],
          ])
        : this.element.is('div')
        ? (this.isInline = !0)
        : (this._events = [
            [this.element, { click: n.proxy(this.show, this) }],
          ]);
      this._events.push(
        [
          this.element,
          '*',
          {
            blur: n.proxy(function (n) {
              this._focused_from = n.target;
            }, this),
          },
        ],
        [
          this.element,
          {
            blur: n.proxy(function (n) {
              this._focused_from = n.target;
            }, this),
          },
        ]
      );
      this._secondaryEvents = [
        [this.picker, { click: n.proxy(this.click, this) }],
        [n(window), { resize: n.proxy(this.place, this) }],
        [
          n(document),
          {
            'mousedown touchstart': n.proxy(function (n) {
              this.element.is(n.target) ||
                this.element.find(n.target).length ||
                this.picker.is(n.target) ||
                this.picker.find(n.target).length ||
                this.hide();
            }, this),
          },
        ],
      ];
    },
    _attachEvents: function () {
      this._detachEvents();
      this._applyEvents(this._events);
    },
    _detachEvents: function () {
      this._unapplyEvents(this._events);
    },
    _attachSecondaryEvents: function () {
      this._detachSecondaryEvents();
      this._applyEvents(this._secondaryEvents);
    },
    _detachSecondaryEvents: function () {
      this._unapplyEvents(this._secondaryEvents);
    },
    _trigger: function (t, r) {
      var u = r || this.dates.get(-1),
        f = this._utc_to_local(u);
      this.element.trigger({
        type: t,
        date: f,
        dates: n.map(this.dates, this._utc_to_local),
        format: n.proxy(function (n, t) {
          0 === arguments.length
            ? ((n = this.dates.length - 1), (t = this.o.format))
            : 'string' == typeof n && ((t = n), (n = this.dates.length - 1));
          t = t || this.o.format;
          var r = this.dates.get(n);
          return i.formatDate(r, t, this.o.language);
        }, this),
      });
    },
    show: function () {
      this.isInline || this.picker.appendTo('body');
      this.picker.show();
      this.place();
      this._attachSecondaryEvents();
      this._trigger('show');
    },
    hide: function () {
      this.isInline ||
        (this.picker.is(':visible') &&
          ((this.focusDate = null),
          this.picker.hide().detach(),
          this._detachSecondaryEvents(),
          (this.viewMode = this.o.startView),
          this.showMode(),
          this.o.forceParse &&
            ((this.isInput && this.element.val()) ||
              (this.hasInput && this.element.find('input').val())) &&
            this.setValue(),
          this._trigger('hide')));
    },
    remove: function () {
      this.hide();
      this._detachEvents();
      this._detachSecondaryEvents();
      this.picker.remove();
      delete this.element.data().datepicker;
      this.isInput || delete this.element.data().date;
    },
    _utc_to_local: function (n) {
      return n && new Date(n.getTime() + 6e4 * n.getTimezoneOffset());
    },
    _local_to_utc: function (n) {
      return n && new Date(n.getTime() - 6e4 * n.getTimezoneOffset());
    },
    _zero_time: function (n) {
      return n && new Date(n.getFullYear(), n.getMonth(), n.getDate());
    },
    _zero_utc_time: function (n) {
      return (
        n &&
        new Date(Date.UTC(n.getUTCFullYear(), n.getUTCMonth(), n.getUTCDate()))
      );
    },
    getDates: function () {
      return n.map(this.dates, this._utc_to_local);
    },
    getUTCDates: function () {
      return n.map(this.dates, function (n) {
        return new Date(n);
      });
    },
    getDate: function () {
      return this._utc_to_local(this.getUTCDate());
    },
    getUTCDate: function () {
      return new Date(this.dates.get(-1));
    },
    setDates: function () {
      var t = n.isArray(arguments[0]) ? arguments[0] : arguments;
      this.update.apply(this, t);
      this._trigger('changeDate');
      this.setValue();
    },
    setUTCDates: function () {
      var t = n.isArray(arguments[0]) ? arguments[0] : arguments;
      this.update.apply(this, n.map(t, this._utc_to_local));
      this._trigger('changeDate');
      this.setValue();
    },
    setDate: c('setDates'),
    setUTCDate: c('setUTCDates'),
    setValue: function () {
      var n = this.getFormattedDate();
      this.isInput
        ? this.element.val(n).change()
        : this.component && this.element.find('input').val(n).change();
    },
    getFormattedDate: function (r) {
      r === t && (r = this.o.format);
      var u = this.o.language;
      return n
        .map(this.dates, function (n) {
          return i.formatDate(n, r, u);
        })
        .join(this.o.multidateSeparator);
    },
    setStartDate: function (n) {
      this._process_options({ startDate: n });
      this.update();
      this.updateNavArrows();
    },
    setEndDate: function (n) {
      this._process_options({ endDate: n });
      this.update();
      this.updateNavArrows();
    },
    setDaysOfWeekDisabled: function (n) {
      this._process_options({ daysOfWeekDisabled: n });
      this.update();
      this.updateNavArrows();
    },
    place: function () {
      var v, o, i;
      if (!this.isInline) {
        var u = this.picker.outerWidth(),
          f = this.picker.outerHeight(),
          h = 10,
          c = s.width(),
          y = s.height(),
          l = s.scrollTop(),
          p =
            parseInt(
              this.element
                .parents()
                .filter(function () {
                  return 'auto' !== n(this).css('z-index');
                })
                .first()
                .css('z-index')
            ) + 10,
          t = this.component
            ? this.component.parent().offset()
            : this.element.offset(),
          a = this.component
            ? this.component.outerHeight(!0)
            : this.element.outerHeight(!1),
          w = this.component
            ? this.component.outerWidth(!0)
            : this.element.outerWidth(!1),
          r = t.left,
          e = t.top;
        this.picker.removeClass(
          'datepicker-orient-top datepicker-orient-bottom datepicker-orient-right datepicker-orient-left'
        );
        'auto' !== this.o.orientation.x
          ? (this.picker.addClass('datepicker-orient-' + this.o.orientation.x),
            'right' === this.o.orientation.x && (r -= u - w))
          : (this.picker.addClass('datepicker-orient-left'),
            t.left < 0 ? (r -= t.left - h) : t.left + u > c && (r = c - u - h));
        i = this.o.orientation.y;
        'auto' === i &&
          ((v = -l + t.top - f),
          (o = l + y - (t.top + a + f)),
          (i = Math.max(v, o) === o ? 'top' : 'bottom'));
        this.picker.addClass('datepicker-orient-' + i);
        'top' === i
          ? (e += a)
          : (e -= f + parseInt(this.picker.css('padding-top')));
        this.picker.css({ top: e, left: r, zIndex: p });
      }
    },
    _allow_update: !0,
    update: function () {
      if (this._allow_update) {
        var r = this.dates.copy(),
          t = [],
          u = !1;
        arguments.length
          ? (n.each(
              arguments,
              n.proxy(function (n, i) {
                i instanceof Date && (i = this._local_to_utc(i));
                t.push(i);
              }, this)
            ),
            (u = !0))
          : ((t = this.isInput
              ? this.element.val()
              : this.element.data('date') || this.element.find('input').val()),
            (t =
              t && this.o.multidate ? t.split(this.o.multidateSeparator) : [t]),
            delete this.element.data().date);
        t = n.map(
          t,
          n.proxy(function (n) {
            return i.parseDate(n, this.o.format, this.o.language);
          }, this)
        );
        t = n.grep(
          t,
          n.proxy(function (n) {
            return n < this.o.startDate || n > this.o.endDate || !n;
          }, this),
          !0
        );
        this.dates.replace(t);
        this.dates.length
          ? (this.viewDate = new Date(this.dates.get(-1)))
          : this.viewDate < this.o.startDate
          ? (this.viewDate = new Date(this.o.startDate))
          : this.viewDate > this.o.endDate &&
            (this.viewDate = new Date(this.o.endDate));
        u
          ? this.setValue()
          : t.length &&
            String(r) !== String(this.dates) &&
            this._trigger('changeDate');
        !this.dates.length && r.length && this._trigger('clearDate');
        this.fill();
      }
    },
    fillDow: function () {
      var i = this.o.weekStart,
        n = '<tr>',
        t;
      for (
        this.o.calendarWeeks &&
        ((t = '<th class="cw">&nbsp;</th>'),
        (n += t),
        this.picker.find('.datepicker-days thead tr:first-child').prepend(t));
        i < this.o.weekStart + 7;

      )
        n += '<th class="dow">' + r[this.o.language].daysMin[i++ % 7] + '</th>';
      n += '</tr>';
      this.picker.find('.datepicker-days thead').append(n);
    },
    fillMonths: function () {
      for (var n = '', t = 0; 12 > t; )
        n +=
          '<span class="month">' +
          r[this.o.language].monthsShort[t++] +
          '</span>';
      this.picker.find('.datepicker-months td').html(n);
    },
    setRange: function (t) {
      t && t.length
        ? (this.range = n.map(t, function (n) {
            return n.valueOf();
          }))
        : delete this.range;
      this.fill();
    },
    getClassNames: function (t) {
      var i = [],
        r = this.viewDate.getUTCFullYear(),
        f = this.viewDate.getUTCMonth(),
        u = new Date();
      return (
        t.getUTCFullYear() < r ||
        (t.getUTCFullYear() === r && t.getUTCMonth() < f)
          ? i.push('old')
          : (t.getUTCFullYear() > r ||
              (t.getUTCFullYear() === r && t.getUTCMonth() > f)) &&
            i.push('new'),
        this.focusDate &&
          t.valueOf() === this.focusDate.valueOf() &&
          i.push('focused'),
        this.o.todayHighlight &&
          t.getUTCFullYear() === u.getFullYear() &&
          t.getUTCMonth() === u.getMonth() &&
          t.getUTCDate() === u.getDate() &&
          i.push('today'),
        -1 !== this.dates.contains(t) && i.push('active'),
        (t.valueOf() < this.o.startDate ||
          t.valueOf() > this.o.endDate ||
          -1 !== n.inArray(t.getUTCDay(), this.o.daysOfWeekDisabled)) &&
          i.push('disabled'),
        this.range &&
          (t > this.range[0] &&
            t < this.range[this.range.length - 1] &&
            i.push('range'),
          -1 !== n.inArray(t.valueOf(), this.range) && i.push('selected')),
        i
      );
    },
    fill: function () {
      var y,
        d = new Date(this.viewDate),
        f = d.getUTCFullYear(),
        g = d.getUTCMonth(),
        p =
          this.o.startDate !== -1 / 0
            ? this.o.startDate.getUTCFullYear()
            : -1 / 0,
        ut =
          this.o.startDate !== -1 / 0 ? this.o.startDate.getUTCMonth() : -1 / 0,
        w = 1 / 0 !== this.o.endDate ? this.o.endDate.getUTCFullYear() : 1 / 0,
        ft = 1 / 0 !== this.o.endDate ? this.o.endDate.getUTCMonth() : 1 / 0,
        et = r[this.o.language].today || r.en.today || '',
        ot = r[this.o.language].clear || r.en.clear || '',
        e,
        b,
        c,
        h,
        s,
        o,
        a,
        it,
        l,
        rt,
        v;
      for (
        this.picker
          .find('.datepicker-days thead th.datepicker-switch')
          .text(r[this.o.language].months[g] + ' ' + f),
          this.picker
            .find('tfoot th.today')
            .text(et)
            .toggle(this.o.todayBtn !== !1),
          this.picker
            .find('tfoot th.clear')
            .text(ot)
            .toggle(this.o.clearBtn !== !1),
          this.updateNavArrows(),
          this.fillMonths(),
          e = u(f, g - 1, 28),
          b = i.getDaysInMonth(e.getUTCFullYear(), e.getUTCMonth()),
          e.setUTCDate(b),
          e.setUTCDate(b - ((e.getUTCDay() - this.o.weekStart + 7) % 7)),
          c = new Date(e),
          c.setUTCDate(c.getUTCDate() + 42),
          c = c.valueOf(),
          s = [];
        e.valueOf() < c;

      ) {
        if (
          e.getUTCDay() === this.o.weekStart &&
          (s.push('<tr>'), this.o.calendarWeeks)
        ) {
          var nt = new Date(
              +e + ((this.o.weekStart - e.getUTCDay() - 7) % 7) * 864e5
            ),
            tt = new Date(Number(nt) + ((11 - nt.getUTCDay()) % 7) * 864e5),
            k = new Date(
              Number((k = u(tt.getUTCFullYear(), 0, 1))) +
                ((11 - k.getUTCDay()) % 7) * 864e5
            ),
            st = (tt - k) / 6048e5 + 1;
          s.push('<td class="cw">' + st + '</td>');
        }
        ((h = this.getClassNames(e)),
        h.push('day'),
        this.o.beforeShowDay !== n.noop) &&
          ((o = this.o.beforeShowDay(this._utc_to_local(e))),
          o === t
            ? (o = {})
            : 'boolean' == typeof o
            ? (o = { enabled: o })
            : 'string' == typeof o && (o = { classes: o }),
          o.enabled === !1 && h.push('disabled'),
          o.classes && (h = h.concat(o.classes.split(/\s+/))),
          o.tooltip && (y = o.tooltip));
        h = n.unique(h);
        s.push(
          '<td class="' +
            h.join(' ') +
            '"' +
            (y ? ' title="' + y + '"' : '') +
            '>' +
            e.getUTCDate() +
            '</td>'
        );
        e.getUTCDay() === this.o.weekEnd && s.push('</tr>');
        e.setUTCDate(e.getUTCDate() + 1);
      }
      for (
        this.picker.find('.datepicker-days tbody').empty().append(s.join('')),
          a = this.picker
            .find('.datepicker-months')
            .find('th:eq(1)')
            .text(f)
            .end()
            .find('span')
            .removeClass('active'),
          n.each(this.dates, function (n, t) {
            t.getUTCFullYear() === f &&
              a.eq(t.getUTCMonth()).addClass('active');
          }),
          (p > f || f > w) && a.addClass('disabled'),
          f === p && a.slice(0, ut).addClass('disabled'),
          f === w && a.slice(ft + 1).addClass('disabled'),
          s = '',
          f = 10 * parseInt(f / 10, 10),
          it = this.picker
            .find('.datepicker-years')
            .find('th:eq(1)')
            .text(f + '-' + (f + 9))
            .end()
            .find('td'),
          f -= 1,
          rt = n.map(this.dates, function (n) {
            return n.getUTCFullYear();
          }),
          v = -1;
        11 > v;
        v++
      )
        (l = ['year']),
          -1 === v ? l.push('old') : 10 === v && l.push('new'),
          -1 !== n.inArray(f, rt) && l.push('active'),
          (p > f || f > w) && l.push('disabled'),
          (s += '<span class="' + l.join(' ') + '">' + f + '</span>'),
          (f += 1);
      it.html(s);
    },
    updateNavArrows: function () {
      if (this._allow_update) {
        var t = new Date(this.viewDate),
          n = t.getUTCFullYear(),
          i = t.getUTCMonth();
        switch (this.viewMode) {
          case 0:
            this.o.startDate !== -1 / 0 &&
            n <= this.o.startDate.getUTCFullYear() &&
            i <= this.o.startDate.getUTCMonth()
              ? this.picker.find('.prev').css({ visibility: 'hidden' })
              : this.picker.find('.prev').css({ visibility: 'visible' });
            1 / 0 !== this.o.endDate &&
            n >= this.o.endDate.getUTCFullYear() &&
            i >= this.o.endDate.getUTCMonth()
              ? this.picker.find('.next').css({ visibility: 'hidden' })
              : this.picker.find('.next').css({ visibility: 'visible' });
            break;
          case 1:
          case 2:
            this.o.startDate !== -1 / 0 &&
            n <= this.o.startDate.getUTCFullYear()
              ? this.picker.find('.prev').css({ visibility: 'hidden' })
              : this.picker.find('.prev').css({ visibility: 'visible' });
            1 / 0 !== this.o.endDate && n >= this.o.endDate.getUTCFullYear()
              ? this.picker.find('.next').css({ visibility: 'hidden' })
              : this.picker.find('.next').css({ visibility: 'visible' });
        }
      }
    },
    click: function (t) {
      var e, f, o, r, c, s, l, h;
      if (
        (t.preventDefault(),
        (r = n(t.target).closest('span, td, th')),
        1 === r.length)
      )
        switch (r[0].nodeName.toLowerCase()) {
          case 'th':
            switch (r[0].className) {
              case 'datepicker-switch':
                this.showMode(1);
                break;
              case 'prev':
              case 'next':
                c =
                  i.modes[this.viewMode].navStep *
                  ('prev' === r[0].className ? -1 : 1);
                switch (this.viewMode) {
                  case 0:
                    this.viewDate = this.moveMonth(this.viewDate, c);
                    this._trigger('changeMonth', this.viewDate);
                    break;
                  case 1:
                  case 2:
                    this.viewDate = this.moveYear(this.viewDate, c);
                    1 === this.viewMode &&
                      this._trigger('changeYear', this.viewDate);
                }
                this.fill();
                break;
              case 'today':
                s = new Date();
                s = u(s.getFullYear(), s.getMonth(), s.getDate(), 0, 0, 0);
                this.showMode(-2);
                l = 'linked' === this.o.todayBtn ? null : 'view';
                this._setDate(s, l);
                break;
              case 'clear':
                this.isInput
                  ? (h = this.element)
                  : this.component && (h = this.element.find('input'));
                h && h.val('').change();
                this.update();
                this._trigger('changeDate');
                this.o.autoclose && this.hide();
            }
            break;
          case 'span':
            r.is('.disabled') ||
              (this.viewDate.setUTCDate(1),
              r.is('.month')
                ? ((o = 1),
                  (f = r.parent().find('span').index(r)),
                  (e = this.viewDate.getUTCFullYear()),
                  this.viewDate.setUTCMonth(f),
                  this._trigger('changeMonth', this.viewDate),
                  1 === this.o.minViewMode && this._setDate(u(e, f, o)))
                : ((o = 1),
                  (f = 0),
                  (e = parseInt(r.text(), 10) || 0),
                  this.viewDate.setUTCFullYear(e),
                  this._trigger('changeYear', this.viewDate),
                  2 === this.o.minViewMode && this._setDate(u(e, f, o))),
              this.showMode(-1),
              this.fill());
            break;
          case 'td':
            r.is('.day') &&
              !r.is('.disabled') &&
              ((o = parseInt(r.text(), 10) || 1),
              (e = this.viewDate.getUTCFullYear()),
              (f = this.viewDate.getUTCMonth()),
              r.is('.old')
                ? 0 === f
                  ? ((f = 11), (e -= 1))
                  : (f -= 1)
                : r.is('.new') && (11 === f ? ((f = 0), (e += 1)) : (f += 1)),
              this._setDate(u(e, f, o)));
        }
      this.picker.is(':visible') &&
        this._focused_from &&
        n(this._focused_from).focus();
      delete this._focused_from;
    },
    _toggle_multidate: function (n) {
      var t = this.dates.contains(n);
      if (
        (n
          ? -1 !== t
            ? this.dates.remove(t)
            : this.dates.push(n)
          : this.dates.clear(),
        'number' == typeof this.o.multidate)
      )
        for (; this.dates.length > this.o.multidate; ) this.dates.remove(0);
    },
    _setDate: function (n, t) {
      (t && 'date' !== t) || this._toggle_multidate(n && new Date(n));
      (t && 'view' !== t) || (this.viewDate = n && new Date(n));
      this.fill();
      this.setValue();
      this._trigger('changeDate');
      var i;
      this.isInput
        ? (i = this.element)
        : this.component && (i = this.element.find('input'));
      i && i.change();
      !this.o.autoclose || (t && 'date' !== t) || this.hide();
    },
    moveMonth: function (n, i) {
      var e;
      if (!n) return t;
      if (!i) return n;
      var u,
        f,
        r = new Date(n.valueOf()),
        o = r.getUTCDate(),
        s = r.getUTCMonth(),
        h = Math.abs(i);
      if (((i = i > 0 ? 1 : -1), 1 === h))
        (f =
          -1 === i
            ? function () {
                return r.getUTCMonth() === s;
              }
            : function () {
                return r.getUTCMonth() !== u;
              }),
          (u = s + i),
          r.setUTCMonth(u),
          (0 > u || u > 11) && (u = (u + 12) % 12);
      else {
        for (e = 0; h > e; e++) r = this.moveMonth(r, i);
        u = r.getUTCMonth();
        r.setUTCDate(o);
        f = function () {
          return u !== r.getUTCMonth();
        };
      }
      for (; f(); ) r.setUTCDate(--o), r.setUTCMonth(u);
      return r;
    },
    moveYear: function (n, t) {
      return this.moveMonth(n, 12 * t);
    },
    dateWithinRange: function (n) {
      return n >= this.o.startDate && n <= this.o.endDate;
    },
    keydown: function (n) {
      var t, i, u, o, r, f;
      if (this.picker.is(':not(:visible)'))
        return 27 === n.keyCode && this.show(), void 0;
      o = !1;
      r = this.focusDate || this.viewDate;
      switch (n.keyCode) {
        case 27:
          this.focusDate
            ? ((this.focusDate = null),
              (this.viewDate = this.dates.get(-1) || this.viewDate),
              this.fill())
            : this.hide();
          n.preventDefault();
          break;
        case 37:
        case 39:
          if (!this.o.keyboardNavigation) break;
          t = 37 === n.keyCode ? -1 : 1;
          n.ctrlKey
            ? ((i = this.moveYear(this.dates.get(-1) || e(), t)),
              (u = this.moveYear(r, t)),
              this._trigger('changeYear', this.viewDate))
            : n.shiftKey
            ? ((i = this.moveMonth(this.dates.get(-1) || e(), t)),
              (u = this.moveMonth(r, t)),
              this._trigger('changeMonth', this.viewDate))
            : ((i = new Date(this.dates.get(-1) || e())),
              i.setUTCDate(i.getUTCDate() + t),
              (u = new Date(r)),
              u.setUTCDate(r.getUTCDate() + t));
          this.dateWithinRange(i) &&
            ((this.focusDate = this.viewDate = u),
            this.setValue(),
            this.fill(),
            n.preventDefault());
          break;
        case 38:
        case 40:
          if (!this.o.keyboardNavigation) break;
          t = 38 === n.keyCode ? -1 : 1;
          n.ctrlKey
            ? ((i = this.moveYear(this.dates.get(-1) || e(), t)),
              (u = this.moveYear(r, t)),
              this._trigger('changeYear', this.viewDate))
            : n.shiftKey
            ? ((i = this.moveMonth(this.dates.get(-1) || e(), t)),
              (u = this.moveMonth(r, t)),
              this._trigger('changeMonth', this.viewDate))
            : ((i = new Date(this.dates.get(-1) || e())),
              i.setUTCDate(i.getUTCDate() + 7 * t),
              (u = new Date(r)),
              u.setUTCDate(r.getUTCDate() + 7 * t));
          this.dateWithinRange(i) &&
            ((this.focusDate = this.viewDate = u),
            this.setValue(),
            this.fill(),
            n.preventDefault());
          break;
        case 13:
          r = this.focusDate || this.dates.get(-1) || this.viewDate;
          this._toggle_multidate(r);
          o = !0;
          this.focusDate = null;
          this.viewDate = this.dates.get(-1) || this.viewDate;
          this.setValue();
          this.fill();
          this.picker.is(':visible') &&
            (n.preventDefault(), this.o.autoclose && this.hide());
          break;
        case 9:
          this.focusDate = null;
          this.viewDate = this.dates.get(-1) || this.viewDate;
          this.fill();
          this.hide();
      }
      o &&
        (this.dates.length
          ? this._trigger('changeDate')
          : this._trigger('clearDate'),
        this.isInput
          ? (f = this.element)
          : this.component && (f = this.element.find('input')),
        f && f.change());
    },
    showMode: function (n) {
      n &&
        (this.viewMode = Math.max(
          this.o.minViewMode,
          Math.min(2, this.viewMode + n)
        ));
      this.picker
        .find('>div')
        .hide()
        .filter('.datepicker-' + i.modes[this.viewMode].clsName)
        .css('display', 'block');
      this.updateNavArrows();
    },
  };
  h = function (t, i) {
    this.element = n(t);
    this.inputs = n.map(i.inputs, function (n) {
      return n.jquery ? n[0] : n;
    });
    delete i.inputs;
    n(this.inputs)
      .datepicker(i)
      .bind('changeDate', n.proxy(this.dateUpdated, this));
    this.pickers = n.map(this.inputs, function (t) {
      return n(t).data('datepicker');
    });
    this.updateDates();
  };
  h.prototype = {
    updateDates: function () {
      this.dates = n.map(this.pickers, function (n) {
        return n.getUTCDate();
      });
      this.updateRanges();
    },
    updateRanges: function () {
      var t = n.map(this.dates, function (n) {
        return n.valueOf();
      });
      n.each(this.pickers, function (n, i) {
        i.setRange(t);
      });
    },
    dateUpdated: function (t) {
      if (!this.updating) {
        this.updating = !0;
        var u = n(t.target).data('datepicker'),
          r = u.getUTCDate(),
          i = n.inArray(t.target, this.inputs),
          f = this.inputs.length;
        if (-1 !== i) {
          if (
            (n.each(this.pickers, function (n, t) {
              t.getUTCDate() || t.setUTCDate(r);
            }),
            r < this.dates[i])
          )
            for (; i >= 0 && r < this.dates[i]; )
              this.pickers[i--].setUTCDate(r);
          else if (r > this.dates[i])
            for (; f > i && r > this.dates[i]; )
              this.pickers[i++].setUTCDate(r);
          this.updateDates();
          delete this.updating;
        }
      }
    },
    remove: function () {
      n.map(this.pickers, function (n) {
        n.remove();
      });
      delete this.element.data().datepicker;
    },
  };
  a = n.fn.datepicker;
  n.fn.datepicker = function (i) {
    var u = Array.apply(null, arguments),
      r;
    return (
      u.shift(),
      this.each(function () {
        var s = n(this),
          e = s.data('datepicker'),
          l = 'object' == typeof i && i,
          v;
        if (!e) {
          var a = y(this, 'date'),
            w = n.extend({}, o, a, l),
            b = p(w.language),
            c = n.extend({}, o, b, a, l);
          s.is('.input-daterange') || c.inputs
            ? ((v = { inputs: c.inputs || s.find('input').toArray() }),
              s.data('datepicker', (e = new h(this, n.extend(c, v)))))
            : s.data('datepicker', (e = new f(this, c)));
        }
        if (
          'string' == typeof i &&
          'function' == typeof e[i] &&
          ((r = e[i].apply(e, u)), r !== t)
        )
          return !1;
      }),
      r !== t ? r : this
    );
  };
  o = n.fn.datepicker.defaults = {
    autoclose: !1,
    beforeShowDay: n.noop,
    calendarWeeks: !1,
    clearBtn: !1,
    daysOfWeekDisabled: [],
    endDate: 1 / 0,
    forceParse: !0,
    format: 'mm/dd/yyyy',
    keyboardNavigation: !0,
    language: 'en',
    minViewMode: 0,
    multidate: !1,
    multidateSeparator: ',',
    orientation: 'auto',
    rtl: !1,
    startDate: -1 / 0,
    startView: 0,
    todayBtn: !1,
    todayHighlight: !1,
    weekStart: 0,
  };
  v = n.fn.datepicker.locale_opts = ['format', 'rtl', 'weekStart'];
  n.fn.datepicker.Constructor = f;
  r = n.fn.datepicker.dates = {
    en: {
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthsShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      today: 'Today',
      clear: 'Clear',
    },
  };
  i = {
    modes: [
      { clsName: 'days', navFnc: 'Month', navStep: 1 },
      { clsName: 'months', navFnc: 'FullYear', navStep: 1 },
      { clsName: 'years', navFnc: 'FullYear', navStep: 10 },
    ],
    isLeapYear: function (n) {
      return (n % 4 == 0 && n % 100 != 0) || n % 400 == 0;
    },
    getDaysInMonth: function (n, t) {
      return [
        31,
        i.isLeapYear(n) ? 29 : 28,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ][t];
    },
    validParts: /dd?|DD?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\[\u3400-\u9fff-`{-~\t\n\r]+/g,
    parseFormat: function (n) {
      var t = n.replace(this.validParts, '\x00').split('\x00'),
        i = n.match(this.validParts);
      if (!t || !t.length || !i || 0 === i.length)
        throw new Error('Invalid date format.');
      return { separators: t, parts: i };
    },
    parseDate: function (e, o, s) {
      function nt() {
        var n = this.slice(0, c[h].length),
          t = c[h].slice(0, n.length);
        return n === t;
      }
      var v, y, h, tt, c, a, it, d, w;
      if (!e) return t;
      if (e instanceof Date) return e;
      if (
        ('string' == typeof o && (o = i.parseFormat(o)),
        (tt = /([\-+]\d+)([dmwy])/),
        (c = e.match(/([\-+]\d+)([dmwy])/g)),
        /^[\-+]\d+[dmwy]([\s,]+[\-+]\d+[dmwy])*$/.test(e))
      ) {
        for (e = new Date(), h = 0; h < c.length; h++)
          switch (((v = tt.exec(c[h])), (y = parseInt(v[1])), v[2])) {
            case 'd':
              e.setUTCDate(e.getUTCDate() + y);
              break;
            case 'm':
              e = f.prototype.moveMonth.call(f.prototype, e, y);
              break;
            case 'w':
              e.setUTCDate(e.getUTCDate() + 7 * y);
              break;
            case 'y':
              e = f.prototype.moveYear.call(f.prototype, e, y);
          }
        return u(e.getUTCFullYear(), e.getUTCMonth(), e.getUTCDate(), 0, 0, 0);
      }
      c = (e && e.match(this.nonpunctuation)) || [];
      e = new Date();
      var p,
        b,
        k = {},
        g = ['yyyy', 'yy', 'M', 'MM', 'm', 'mm', 'd', 'dd'],
        l = {
          yyyy: function (n, t) {
            return n.setUTCFullYear(t);
          },
          yy: function (n, t) {
            return n.setUTCFullYear(2e3 + t);
          },
          m: function (n, t) {
            if (isNaN(n)) return n;
            for (t -= 1; 0 > t; ) t += 12;
            for (t %= 12, n.setUTCMonth(t); n.getUTCMonth() !== t; )
              n.setUTCDate(n.getUTCDate() - 1);
            return n;
          },
          d: function (n, t) {
            return n.setUTCDate(t);
          },
        };
      if (
        ((l.M = l.MM = l.mm = l.m),
        (l.dd = l.d),
        (e = u(e.getFullYear(), e.getMonth(), e.getDate(), 0, 0, 0)),
        (a = o.parts.slice()),
        c.length !== a.length &&
          (a = n(a)
            .filter(function (t, i) {
              return -1 !== n.inArray(i, g);
            })
            .toArray()),
        c.length === a.length)
      ) {
        for (h = 0, it = a.length; it > h; h++) {
          if (((p = parseInt(c[h], 10)), (v = a[h]), isNaN(p)))
            switch (v) {
              case 'MM':
                b = n(r[s].months).filter(nt);
                p = n.inArray(b[0], r[s].months) + 1;
                break;
              case 'M':
                b = n(r[s].monthsShort).filter(nt);
                p = n.inArray(b[0], r[s].monthsShort) + 1;
            }
          k[v] = p;
        }
        for (h = 0; h < g.length; h++)
          (w = g[h]),
            w in k &&
              !isNaN(k[w]) &&
              ((d = new Date(e)), l[w](d, k[w]), isNaN(d) || (e = d));
      }
      return e;
    },
    formatDate: function (t, u, f) {
      var e;
      if (!t) return '';
      'string' == typeof u && (u = i.parseFormat(u));
      e = {
        d: t.getUTCDate(),
        D: r[f].daysShort[t.getUTCDay()],
        DD: r[f].days[t.getUTCDay()],
        m: t.getUTCMonth() + 1,
        M: r[f].monthsShort[t.getUTCMonth()],
        MM: r[f].months[t.getUTCMonth()],
        yy: t.getUTCFullYear().toString().substring(2),
        yyyy: t.getUTCFullYear(),
      };
      e.dd = (e.d < 10 ? '0' : '') + e.d;
      e.mm = (e.m < 10 ? '0' : '') + e.m;
      t = [];
      for (
        var s = n.extend([], u.separators), o = 0, h = u.parts.length;
        h >= o;
        o++
      )
        s.length && t.push(s.shift()), t.push(e[u.parts[o]]);
      return t.join('');
    },
    headTemplate:
      '<thead><tr><th class="prev">&laquo;</th><th colspan="5" class="datepicker-switch"></th><th class="next">&raquo;</th></tr></thead>',
    contTemplate: '<tbody><tr><td colspan="7"></td></tr></tbody>',
    footTemplate:
      '<tfoot><tr><th colspan="7" class="today"></th></tr><tr><th colspan="7" class="clear"></th></tr></tfoot>',
  };
  i.template =
    '<div class="datepicker"><div class="datepicker-days"><table class=" table-condensed">' +
    i.headTemplate +
    '<tbody></tbody>' +
    i.footTemplate +
    '</table></div><div class="datepicker-months"><table class="table-condensed">' +
    i.headTemplate +
    i.contTemplate +
    i.footTemplate +
    '</table></div><div class="datepicker-years"><table class="table-condensed">' +
    i.headTemplate +
    i.contTemplate +
    i.footTemplate +
    '</table></div></div>';
  n.fn.datepicker.DPGlobal = i;
  n.fn.datepicker.noConflict = function () {
    return (n.fn.datepicker = a), this;
  };
  n(document).on(
    'focus.datepicker.data-api click.datepicker.data-api',
    '[data-provide="datepicker"]',
    function (t) {
      var i = n(this);
      i.data('datepicker') || (t.preventDefault(), i.datepicker('show'));
    }
  );
  n(function () {
    n('[data-provide="datepicker-inline"]').datepicker();
  });
})(window.jQuery),
  (function (n) {
    'use strict';
    typeof define == 'function' && define.amd
      ? define(['jquery'], n)
      : typeof exports != 'undefined'
      ? (module.exports = n(require('jquery')))
      : n(jQuery);
  })(function (n) {
    'use strict';
    var t = window.Slick || {};
    t = (function () {
      function i(i, r) {
        var u = this,
          f;
        u.defaults = {
          accessibility: !0,
          adaptiveHeight: !1,
          appendArrows: n(i),
          appendDots: n(i),
          arrows: !0,
          asNavFor: null,
          prevArrow:
            '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
          nextArrow:
            '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
          autoplay: !1,
          autoplaySpeed: 3e3,
          centerMode: !1,
          centerPadding: '50px',
          cssEase: 'ease',
          customPaging: function (t, i) {
            return n(
              '<button type="button" data-role="none" role="button" tabindex="0" />'
            ).text(i + 1);
          },
          dots: !1,
          dotsClass: 'slick-dots',
          draggable: !0,
          easing: 'linear',
          edgeFriction: 0.35,
          fade: !1,
          focusOnSelect: !1,
          infinite: !0,
          initialSlide: 0,
          lazyLoad: 'ondemand',
          mobileFirst: !1,
          pauseOnHover: !0,
          pauseOnFocus: !0,
          pauseOnDotsHover: !1,
          respondTo: 'window',
          responsive: null,
          rows: 1,
          rtl: !1,
          slide: '',
          slidesPerRow: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          swipe: !0,
          swipeToSlide: !1,
          touchMove: !0,
          touchThreshold: 5,
          useCSS: !0,
          useTransform: !0,
          variableWidth: !1,
          vertical: !1,
          verticalSwiping: !1,
          waitForAnimate: !0,
          zIndex: 1e3,
        };
        u.initials = {
          animating: !1,
          dragging: !1,
          autoPlayTimer: null,
          currentDirection: 0,
          currentLeft: null,
          currentSlide: 0,
          direction: 1,
          $dots: null,
          listWidth: null,
          listHeight: null,
          loadIndex: 0,
          $nextArrow: null,
          $prevArrow: null,
          slideCount: null,
          slideWidth: null,
          $slideTrack: null,
          $slides: null,
          sliding: !1,
          slideOffset: 0,
          swipeLeft: null,
          $list: null,
          touchObject: {},
          transformsEnabled: !1,
          unslicked: !1,
        };
        n.extend(u, u.initials);
        u.activeBreakpoint = null;
        u.animType = null;
        u.animProp = null;
        u.breakpoints = [];
        u.breakpointSettings = [];
        u.cssTransitions = !1;
        u.focussed = !1;
        u.interrupted = !1;
        u.hidden = 'hidden';
        u.paused = !0;
        u.positionProp = null;
        u.respondTo = null;
        u.rowCount = 1;
        u.shouldClick = !0;
        u.$slider = n(i);
        u.$slidesCache = null;
        u.transformType = null;
        u.transitionType = null;
        u.visibilityChange = 'visibilitychange';
        u.windowWidth = 0;
        u.windowTimer = null;
        f = n(i).data('slick') || {};
        u.options = n.extend({}, u.defaults, r, f);
        u.currentSlide = u.options.initialSlide;
        u.originalSettings = u.options;
        typeof document.mozHidden != 'undefined'
          ? ((u.hidden = 'mozHidden'),
            (u.visibilityChange = 'mozvisibilitychange'))
          : typeof document.webkitHidden != 'undefined' &&
            ((u.hidden = 'webkitHidden'),
            (u.visibilityChange = 'webkitvisibilitychange'));
        u.autoPlay = n.proxy(u.autoPlay, u);
        u.autoPlayClear = n.proxy(u.autoPlayClear, u);
        u.autoPlayIterator = n.proxy(u.autoPlayIterator, u);
        u.changeSlide = n.proxy(u.changeSlide, u);
        u.clickHandler = n.proxy(u.clickHandler, u);
        u.selectHandler = n.proxy(u.selectHandler, u);
        u.setPosition = n.proxy(u.setPosition, u);
        u.swipeHandler = n.proxy(u.swipeHandler, u);
        u.dragHandler = n.proxy(u.dragHandler, u);
        u.keyHandler = n.proxy(u.keyHandler, u);
        u.instanceUid = t++;
        u.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/;
        u.registerBreakpoints();
        u.init(!0);
      }
      var t = 0;
      return i;
    })();
    t.prototype.activateADA = function () {
      var n = this;
      n.$slideTrack
        .find('.slick-active')
        .attr({ 'aria-hidden': 'false' })
        .find('a, input, button, select')
        .attr({ tabindex: '0' });
    };
    t.prototype.addSlide = t.prototype.slickAdd = function (t, i, r) {
      var u = this;
      if (typeof i == 'boolean') (r = i), (i = null);
      else if (i < 0 || i >= u.slideCount) return !1;
      u.unload();
      typeof i == 'number'
        ? i === 0 && u.$slides.length === 0
          ? n(t).appendTo(u.$slideTrack)
          : r
          ? n(t).insertBefore(u.$slides.eq(i))
          : n(t).insertAfter(u.$slides.eq(i))
        : r === !0
        ? n(t).prependTo(u.$slideTrack)
        : n(t).appendTo(u.$slideTrack);
      u.$slides = u.$slideTrack.children(this.options.slide);
      u.$slideTrack.children(this.options.slide).detach();
      u.$slideTrack.append(u.$slides);
      u.$slides.each(function (t, i) {
        n(i).attr('data-slick-index', t);
      });
      u.$slidesCache = u.$slides;
      u.reinit();
    };
    t.prototype.animateHeight = function () {
      var n = this,
        t;
      n.options.slidesToShow === 1 &&
        n.options.adaptiveHeight === !0 &&
        n.options.vertical === !1 &&
        ((t = n.$slides.eq(n.currentSlide).outerHeight(!0)),
        n.$list.animate({ height: t }, n.options.speed));
    };
    t.prototype.animateSlide = function (t, i) {
      var u = {},
        r = this;
      r.animateHeight();
      r.options.rtl === !0 && r.options.vertical === !1 && (t = -t);
      r.transformsEnabled === !1
        ? r.options.vertical === !1
          ? r.$slideTrack.animate(
              { left: t },
              r.options.speed,
              r.options.easing,
              i
            )
          : r.$slideTrack.animate(
              { top: t },
              r.options.speed,
              r.options.easing,
              i
            )
        : r.cssTransitions === !1
        ? (r.options.rtl === !0 && (r.currentLeft = -r.currentLeft),
          n({ animStart: r.currentLeft }).animate(
            { animStart: t },
            {
              duration: r.options.speed,
              easing: r.options.easing,
              step: function (n) {
                n = Math.ceil(n);
                r.options.vertical === !1
                  ? ((u[r.animType] = 'translate(' + n + 'px, 0px)'),
                    r.$slideTrack.css(u))
                  : ((u[r.animType] = 'translate(0px,' + n + 'px)'),
                    r.$slideTrack.css(u));
              },
              complete: function () {
                i && i.call();
              },
            }
          ))
        : (r.applyTransition(),
          (t = Math.ceil(t)),
          (u[r.animType] =
            r.options.vertical === !1
              ? 'translate3d(' + t + 'px, 0px, 0px)'
              : 'translate3d(0px,' + t + 'px, 0px)'),
          r.$slideTrack.css(u),
          i &&
            setTimeout(function () {
              r.disableTransition();
              i.call();
            }, r.options.speed));
    };
    t.prototype.getNavTarget = function () {
      var i = this,
        t = i.options.asNavFor;
      return t && t !== null && (t = n(t).not(i.$slider)), t;
    };
    t.prototype.asNavFor = function (t) {
      var r = this,
        i = r.getNavTarget();
      i !== null &&
        typeof i == 'object' &&
        i.each(function () {
          var i = n(this).slick('getSlick');
          i.unslicked || i.slideHandler(t, !0);
        });
    };
    t.prototype.applyTransition = function (n) {
      var t = this,
        i = {};
      i[t.transitionType] =
        t.options.fade === !1
          ? t.transformType + ' ' + t.options.speed + 'ms ' + t.options.cssEase
          : 'opacity ' + t.options.speed + 'ms ' + t.options.cssEase;
      t.options.fade === !1 ? t.$slideTrack.css(i) : t.$slides.eq(n).css(i);
    };
    t.prototype.autoPlay = function () {
      var n = this;
      n.autoPlayClear();
      n.slideCount > n.options.slidesToShow &&
        (n.autoPlayTimer = setInterval(
          n.autoPlayIterator,
          n.options.autoplaySpeed
        ));
    };
    t.prototype.autoPlayClear = function () {
      var n = this;
      n.autoPlayTimer && clearInterval(n.autoPlayTimer);
    };
    t.prototype.autoPlayIterator = function () {
      var n = this,
        t = n.currentSlide + n.options.slidesToScroll;
      n.paused ||
        n.interrupted ||
        n.focussed ||
        (n.options.infinite === !1 &&
          (n.direction === 1 && n.currentSlide + 1 === n.slideCount - 1
            ? (n.direction = 0)
            : n.direction === 0 &&
              ((t = n.currentSlide - n.options.slidesToScroll),
              n.currentSlide - 1 == 0 && (n.direction = 1))),
        n.slideHandler(t));
    };
    t.prototype.buildArrows = function () {
      var t = this;
      t.options.arrows === !0 &&
        ((t.$prevArrow = n(t.options.prevArrow).addClass('slick-arrow')),
        (t.$nextArrow = n(t.options.nextArrow).addClass('slick-arrow')),
        t.slideCount > t.options.slidesToShow
          ? (t.$prevArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            t.$nextArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            t.htmlExpr.test(t.options.prevArrow) &&
              t.$prevArrow.prependTo(t.options.appendArrows),
            t.htmlExpr.test(t.options.nextArrow) &&
              t.$nextArrow.appendTo(t.options.appendArrows),
            t.options.infinite !== !0 &&
              t.$prevArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'))
          : t.$prevArrow
              .add(t.$nextArrow)
              .addClass('slick-hidden')
              .attr({ 'aria-disabled': 'true', tabindex: '-1' }));
    };
    t.prototype.buildDots = function () {
      var t = this,
        i,
        r;
      if (t.options.dots === !0 && t.slideCount > t.options.slidesToShow) {
        for (
          t.$slider.addClass('slick-dotted'),
            r = n('<ul />').addClass(t.options.dotsClass),
            i = 0;
          i <= t.getDotCount();
          i += 1
        )
          r.append(n('<li />').append(t.options.customPaging.call(this, t, i)));
        t.$dots = r.appendTo(t.options.appendDots);
        t.$dots
          .find('li')
          .first()
          .addClass('slick-active')
          .attr('aria-hidden', 'false');
      }
    };
    t.prototype.buildOut = function () {
      var t = this;
      t.$slides = t.$slider
        .children(t.options.slide + ':not(.slick-cloned)')
        .addClass('slick-slide');
      t.slideCount = t.$slides.length;
      t.$slides.each(function (t, i) {
        n(i)
          .attr('data-slick-index', t)
          .data('originalStyling', n(i).attr('style') || '');
      });
      t.$slider.addClass('slick-slider');
      t.$slideTrack =
        t.slideCount === 0
          ? n('<div class="slick-track"/>').appendTo(t.$slider)
          : t.$slides.wrapAll('<div class="slick-track"/>').parent();
      t.$list = t.$slideTrack
        .wrap('<div aria-live="polite" class="slick-list"/>')
        .parent();
      t.$slideTrack.css('opacity', 0);
      (t.options.centerMode === !0 || t.options.swipeToSlide === !0) &&
        (t.options.slidesToScroll = 1);
      n('img[data-lazy]', t.$slider).not('[src]').addClass('slick-loading');
      t.setupInfinite();
      t.buildArrows();
      t.buildDots();
      t.updateDots();
      t.setSlideClasses(typeof t.currentSlide == 'number' ? t.currentSlide : 0);
      t.options.draggable === !0 && t.$list.addClass('draggable');
    };
    t.prototype.buildRows = function () {
      var n = this,
        t,
        i,
        r,
        f,
        c,
        u,
        e,
        o,
        s,
        h;
      if (
        ((f = document.createDocumentFragment()),
        (u = n.$slider.children()),
        n.options.rows > 1)
      ) {
        for (
          e = n.options.slidesPerRow * n.options.rows,
            c = Math.ceil(u.length / e),
            t = 0;
          t < c;
          t++
        ) {
          for (
            o = document.createElement('div'), i = 0;
            i < n.options.rows;
            i++
          ) {
            for (
              s = document.createElement('div'), r = 0;
              r < n.options.slidesPerRow;
              r++
            )
              (h = t * e + (i * n.options.slidesPerRow + r)),
                u.get(h) && s.appendChild(u.get(h));
            o.appendChild(s);
          }
          f.appendChild(o);
        }
        n.$slider.empty().append(f);
        n.$slider
          .children()
          .children()
          .children()
          .css({
            width: 100 / n.options.slidesPerRow + '%',
            display: 'inline-block',
          });
      }
    };
    t.prototype.checkResponsive = function (t, i) {
      var r = this,
        f,
        u,
        e,
        o = !1,
        s = r.$slider.width(),
        h = window.innerWidth || n(window).width();
      if (
        (r.respondTo === 'window'
          ? (e = h)
          : r.respondTo === 'slider'
          ? (e = s)
          : r.respondTo === 'min' && (e = Math.min(h, s)),
        r.options.responsive &&
          r.options.responsive.length &&
          r.options.responsive !== null)
      ) {
        u = null;
        for (f in r.breakpoints)
          r.breakpoints.hasOwnProperty(f) &&
            (r.originalSettings.mobileFirst === !1
              ? e < r.breakpoints[f] && (u = r.breakpoints[f])
              : e > r.breakpoints[f] && (u = r.breakpoints[f]));
        u !== null
          ? r.activeBreakpoint !== null
            ? (u !== r.activeBreakpoint || i) &&
              ((r.activeBreakpoint = u),
              r.breakpointSettings[u] === 'unslick'
                ? r.unslick(u)
                : ((r.options = n.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[u]
                  )),
                  t === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(t)),
              (o = u))
            : ((r.activeBreakpoint = u),
              r.breakpointSettings[u] === 'unslick'
                ? r.unslick(u)
                : ((r.options = n.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[u]
                  )),
                  t === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(t)),
              (o = u))
          : r.activeBreakpoint !== null &&
            ((r.activeBreakpoint = null),
            (r.options = r.originalSettings),
            t === !0 && (r.currentSlide = r.options.initialSlide),
            r.refresh(t),
            (o = u));
        t || o === !1 || r.$slider.trigger('breakpoint', [r, o]);
      }
    };
    t.prototype.changeSlide = function (t, i) {
      var r = this,
        u = n(t.currentTarget),
        f,
        e,
        o,
        s;
      u.is('a') && t.preventDefault();
      u.is('li') || (u = u.closest('li'));
      o = r.slideCount % r.options.slidesToScroll != 0;
      f = o ? 0 : (r.slideCount - r.currentSlide) % r.options.slidesToScroll;
      switch (t.data.message) {
        case 'previous':
          e = f === 0 ? r.options.slidesToScroll : r.options.slidesToShow - f;
          r.slideCount > r.options.slidesToShow &&
            r.slideHandler(r.currentSlide - e, !1, i);
          break;
        case 'next':
          e = f === 0 ? r.options.slidesToScroll : f;
          r.slideCount > r.options.slidesToShow &&
            r.slideHandler(r.currentSlide + e, !1, i);
          break;
        case 'index':
          s =
            t.data.index === 0
              ? 0
              : t.data.index || u.index() * r.options.slidesToScroll;
          r.slideHandler(r.checkNavigable(s), !1, i);
          u.children().trigger('focus');
          break;
        default:
          return;
      }
    };
    t.prototype.checkNavigable = function (n) {
      var u = this,
        t,
        i,
        r;
      if (((t = u.getNavigableIndexes()), (i = 0), n > t[t.length - 1]))
        n = t[t.length - 1];
      else
        for (r in t) {
          if (n < t[r]) {
            n = i;
            break;
          }
          i = t[r];
        }
      return n;
    };
    t.prototype.cleanUpEvents = function () {
      var t = this;
      t.options.dots &&
        t.$dots !== null &&
        n('li', t.$dots)
          .off('click.slick', t.changeSlide)
          .off('mouseenter.slick', n.proxy(t.interrupt, t, !0))
          .off('mouseleave.slick', n.proxy(t.interrupt, t, !1));
      t.$slider.off('focus.slick blur.slick');
      t.options.arrows === !0 &&
        t.slideCount > t.options.slidesToShow &&
        (t.$prevArrow && t.$prevArrow.off('click.slick', t.changeSlide),
        t.$nextArrow && t.$nextArrow.off('click.slick', t.changeSlide));
      t.$list.off('touchstart.slick mousedown.slick', t.swipeHandler);
      t.$list.off('touchmove.slick mousemove.slick', t.swipeHandler);
      t.$list.off('touchend.slick mouseup.slick', t.swipeHandler);
      t.$list.off('touchcancel.slick mouseleave.slick', t.swipeHandler);
      t.$list.off('click.slick', t.clickHandler);
      n(document).off(t.visibilityChange, t.visibility);
      t.cleanUpSlideEvents();
      t.options.accessibility === !0 &&
        t.$list.off('keydown.slick', t.keyHandler);
      t.options.focusOnSelect === !0 &&
        n(t.$slideTrack).children().off('click.slick', t.selectHandler);
      n(window).off(
        'orientationchange.slick.slick-' + t.instanceUid,
        t.orientationChange
      );
      n(window).off('resize.slick.slick-' + t.instanceUid, t.resize);
      n('[draggable!=true]', t.$slideTrack).off('dragstart', t.preventDefault);
      n(window).off('load.slick.slick-' + t.instanceUid, t.setPosition);
      n(document).off('ready.slick.slick-' + t.instanceUid, t.setPosition);
    };
    t.prototype.cleanUpSlideEvents = function () {
      var t = this;
      t.$list.off('mouseenter.slick', n.proxy(t.interrupt, t, !0));
      t.$list.off('mouseleave.slick', n.proxy(t.interrupt, t, !1));
    };
    t.prototype.cleanUpRows = function () {
      var n = this,
        t;
      n.options.rows > 1 &&
        ((t = n.$slides.children().children()),
        t.removeAttr('style'),
        n.$slider.empty().append(t));
    };
    t.prototype.clickHandler = function (n) {
      var t = this;
      t.shouldClick === !1 &&
        (n.stopImmediatePropagation(), n.stopPropagation(), n.preventDefault());
    };
    t.prototype.destroy = function (t) {
      var i = this;
      i.autoPlayClear();
      i.touchObject = {};
      i.cleanUpEvents();
      n('.slick-cloned', i.$slider).detach();
      i.$dots && i.$dots.remove();
      i.$prevArrow &&
        i.$prevArrow.length &&
        (i.$prevArrow
          .removeClass('slick-disabled slick-arrow slick-hidden')
          .removeAttr('aria-hidden aria-disabled tabindex')
          .css('display', ''),
        i.htmlExpr.test(i.options.prevArrow) && i.$prevArrow.remove());
      i.$nextArrow &&
        i.$nextArrow.length &&
        (i.$nextArrow
          .removeClass('slick-disabled slick-arrow slick-hidden')
          .removeAttr('aria-hidden aria-disabled tabindex')
          .css('display', ''),
        i.htmlExpr.test(i.options.nextArrow) && i.$nextArrow.remove());
      i.$slides &&
        (i.$slides
          .removeClass(
            'slick-slide slick-active slick-center slick-visible slick-current'
          )
          .removeAttr('aria-hidden')
          .removeAttr('data-slick-index')
          .each(function () {
            n(this).attr('style', n(this).data('originalStyling'));
          }),
        i.$slideTrack.children(this.options.slide).detach(),
        i.$slideTrack.detach(),
        i.$list.detach(),
        i.$slider.append(i.$slides));
      i.cleanUpRows();
      i.$slider.removeClass('slick-slider');
      i.$slider.removeClass('slick-initialized');
      i.$slider.removeClass('slick-dotted');
      i.unslicked = !0;
      t || i.$slider.trigger('destroy', [i]);
    };
    t.prototype.disableTransition = function (n) {
      var t = this,
        i = {};
      i[t.transitionType] = '';
      t.options.fade === !1 ? t.$slideTrack.css(i) : t.$slides.eq(n).css(i);
    };
    t.prototype.fadeSlide = function (n, t) {
      var i = this;
      i.cssTransitions === !1
        ? (i.$slides.eq(n).css({ zIndex: i.options.zIndex }),
          i.$slides
            .eq(n)
            .animate({ opacity: 1 }, i.options.speed, i.options.easing, t))
        : (i.applyTransition(n),
          i.$slides.eq(n).css({ opacity: 1, zIndex: i.options.zIndex }),
          t &&
            setTimeout(function () {
              i.disableTransition(n);
              t.call();
            }, i.options.speed));
    };
    t.prototype.fadeSlideOut = function (n) {
      var t = this;
      t.cssTransitions === !1
        ? t.$slides
            .eq(n)
            .animate(
              { opacity: 0, zIndex: t.options.zIndex - 2 },
              t.options.speed,
              t.options.easing
            )
        : (t.applyTransition(n),
          t.$slides.eq(n).css({ opacity: 0, zIndex: t.options.zIndex - 2 }));
    };
    t.prototype.filterSlides = t.prototype.slickFilter = function (n) {
      var t = this;
      n !== null &&
        ((t.$slidesCache = t.$slides),
        t.unload(),
        t.$slideTrack.children(this.options.slide).detach(),
        t.$slidesCache.filter(n).appendTo(t.$slideTrack),
        t.reinit());
    };
    t.prototype.focusHandler = function () {
      var t = this;
      t.$slider
        .off('focus.slick blur.slick')
        .on('focus.slick blur.slick', '*:not(.slick-arrow)', function (i) {
          i.stopImmediatePropagation();
          var r = n(this);
          setTimeout(function () {
            t.options.pauseOnFocus &&
              ((t.focussed = r.is(':focus')), t.autoPlay());
          }, 0);
        });
    };
    t.prototype.getCurrent = t.prototype.slickCurrentSlide = function () {
      var n = this;
      return n.currentSlide;
    };
    t.prototype.getDotCount = function () {
      var n = this,
        i = 0,
        r = 0,
        t = 0;
      if (n.options.infinite === !0)
        while (i < n.slideCount)
          ++t,
            (i = r + n.options.slidesToScroll),
            (r +=
              n.options.slidesToScroll <= n.options.slidesToShow
                ? n.options.slidesToScroll
                : n.options.slidesToShow);
      else if (n.options.centerMode === !0) t = n.slideCount;
      else if (n.options.asNavFor)
        while (i < n.slideCount)
          ++t,
            (i = r + n.options.slidesToScroll),
            (r +=
              n.options.slidesToScroll <= n.options.slidesToShow
                ? n.options.slidesToScroll
                : n.options.slidesToShow);
      else
        t =
          1 +
          Math.ceil(
            (n.slideCount - n.options.slidesToShow) / n.options.slidesToScroll
          );
      return t - 1;
    };
    t.prototype.getLeft = function (n) {
      var t = this,
        f,
        r,
        u = 0,
        i;
      return (
        (t.slideOffset = 0),
        (r = t.$slides.first().outerHeight(!0)),
        t.options.infinite === !0
          ? (t.slideCount > t.options.slidesToShow &&
              ((t.slideOffset = t.slideWidth * t.options.slidesToShow * -1),
              (u = r * t.options.slidesToShow * -1)),
            t.slideCount % t.options.slidesToScroll != 0 &&
              n + t.options.slidesToScroll > t.slideCount &&
              t.slideCount > t.options.slidesToShow &&
              (n > t.slideCount
                ? ((t.slideOffset =
                    (t.options.slidesToShow - (n - t.slideCount)) *
                    t.slideWidth *
                    -1),
                  (u = (t.options.slidesToShow - (n - t.slideCount)) * r * -1))
                : ((t.slideOffset =
                    (t.slideCount % t.options.slidesToScroll) *
                    t.slideWidth *
                    -1),
                  (u = (t.slideCount % t.options.slidesToScroll) * r * -1))))
          : n + t.options.slidesToShow > t.slideCount &&
            ((t.slideOffset =
              (n + t.options.slidesToShow - t.slideCount) * t.slideWidth),
            (u = (n + t.options.slidesToShow - t.slideCount) * r)),
        t.slideCount <= t.options.slidesToShow &&
          ((t.slideOffset = 0), (u = 0)),
        t.options.centerMode === !0 && t.options.infinite === !0
          ? (t.slideOffset +=
              t.slideWidth * Math.floor(t.options.slidesToShow / 2) -
              t.slideWidth)
          : t.options.centerMode === !0 &&
            ((t.slideOffset = 0),
            (t.slideOffset +=
              t.slideWidth * Math.floor(t.options.slidesToShow / 2))),
        (f =
          t.options.vertical === !1
            ? n * t.slideWidth * -1 + t.slideOffset
            : n * r * -1 + u),
        t.options.variableWidth === !0 &&
          ((i =
            t.slideCount <= t.options.slidesToShow || t.options.infinite === !1
              ? t.$slideTrack.children('.slick-slide').eq(n)
              : t.$slideTrack
                  .children('.slick-slide')
                  .eq(n + t.options.slidesToShow)),
          (f =
            t.options.rtl === !0
              ? i[0]
                ? (t.$slideTrack.width() - i[0].offsetLeft - i.width()) * -1
                : 0
              : i[0]
              ? i[0].offsetLeft * -1
              : 0),
          t.options.centerMode === !0 &&
            ((i =
              t.slideCount <= t.options.slidesToShow ||
              t.options.infinite === !1
                ? t.$slideTrack.children('.slick-slide').eq(n)
                : t.$slideTrack
                    .children('.slick-slide')
                    .eq(n + t.options.slidesToShow + 1)),
            (f =
              (t.options.rtl === !0
                ? i[0]
                  ? (t.$slideTrack.width() - i[0].offsetLeft - i.width()) * -1
                  : 0
                : i[0]
                ? i[0].offsetLeft * -1
                : 0) +
              (t.$list.width() - i.outerWidth()) / 2))),
        f
      );
    };
    t.prototype.getOption = t.prototype.slickGetOption = function (n) {
      var t = this;
      return t.options[n];
    };
    t.prototype.getNavigableIndexes = function () {
      var n = this,
        t = 0,
        i = 0,
        u = [],
        r;
      for (
        n.options.infinite === !1
          ? (r = n.slideCount)
          : ((t = n.options.slidesToScroll * -1),
            (i = n.options.slidesToScroll * -1),
            (r = n.slideCount * 2));
        t < r;

      )
        u.push(t),
          (t = i + n.options.slidesToScroll),
          (i +=
            n.options.slidesToScroll <= n.options.slidesToShow
              ? n.options.slidesToScroll
              : n.options.slidesToShow);
      return u;
    };
    t.prototype.getSlick = function () {
      return this;
    };
    t.prototype.getSlideCount = function () {
      var t = this,
        i,
        r;
      return (
        (r =
          t.options.centerMode === !0
            ? t.slideWidth * Math.floor(t.options.slidesToShow / 2)
            : 0),
        t.options.swipeToSlide === !0
          ? (t.$slideTrack.find('.slick-slide').each(function (u, f) {
              if (f.offsetLeft - r + n(f).outerWidth() / 2 > t.swipeLeft * -1)
                return (i = f), !1;
            }),
            Math.abs(n(i).attr('data-slick-index') - t.currentSlide) || 1)
          : t.options.slidesToScroll
      );
    };
    t.prototype.goTo = t.prototype.slickGoTo = function (n, t) {
      var i = this;
      i.changeSlide({ data: { message: 'index', index: parseInt(n) } }, t);
    };
    t.prototype.init = function (t) {
      var i = this;
      n(i.$slider).hasClass('slick-initialized') ||
        (n(i.$slider).addClass('slick-initialized'),
        i.buildRows(),
        i.buildOut(),
        i.setProps(),
        i.startLoad(),
        i.loadSlider(),
        i.initializeEvents(),
        i.updateArrows(),
        i.updateDots(),
        i.checkResponsive(!0),
        i.focusHandler());
      t && i.$slider.trigger('init', [i]);
      i.options.accessibility === !0 && i.initADA();
      i.options.autoplay && ((i.paused = !1), i.autoPlay());
    };
    t.prototype.initADA = function () {
      var t = this;
      t.$slides
        .add(t.$slideTrack.find('.slick-cloned'))
        .attr({ 'aria-hidden': 'true', tabindex: '-1' })
        .find('a, input, button, select')
        .attr({ tabindex: '-1' });
      t.$slideTrack.attr('role', 'listbox');
      t.$slides.not(t.$slideTrack.find('.slick-cloned')).each(function (i) {
        n(this).attr({
          role: 'option',
          'aria-describedby': 'slick-slide' + t.instanceUid + i + '',
        });
      });
      t.$dots !== null &&
        t.$dots
          .attr('role', 'tablist')
          .find('li')
          .each(function (i) {
            n(this).attr({
              role: 'presentation',
              'aria-selected': 'false',
              'aria-controls': 'navigation' + t.instanceUid + i + '',
              id: 'slick-slide' + t.instanceUid + i + '',
            });
          })
          .first()
          .attr('aria-selected', 'true')
          .end()
          .find('button')
          .attr('role', 'button')
          .end()
          .closest('div')
          .attr('role', 'toolbar');
      t.activateADA();
    };
    t.prototype.initArrowEvents = function () {
      var n = this;
      if (n.options.arrows === !0 && n.slideCount > n.options.slidesToShow) {
        n.$prevArrow
          .off('click.slick')
          .on('click.slick', { message: 'previous' }, n.changeSlide);
        n.$nextArrow
          .off('click.slick')
          .on('click.slick', { message: 'next' }, n.changeSlide);
      }
    };
    t.prototype.initDotEvents = function () {
      var t = this;
      if (t.options.dots === !0 && t.slideCount > t.options.slidesToShow)
        n('li', t.$dots).on('click.slick', { message: 'index' }, t.changeSlide);
      if (t.options.dots === !0 && t.options.pauseOnDotsHover === !0)
        n('li', t.$dots)
          .on('mouseenter.slick', n.proxy(t.interrupt, t, !0))
          .on('mouseleave.slick', n.proxy(t.interrupt, t, !1));
    };
    t.prototype.initSlideEvents = function () {
      var t = this;
      if (t.options.pauseOnHover) {
        t.$list.on('mouseenter.slick', n.proxy(t.interrupt, t, !0));
        t.$list.on('mouseleave.slick', n.proxy(t.interrupt, t, !1));
      }
    };
    t.prototype.initializeEvents = function () {
      var t = this;
      t.initArrowEvents();
      t.initDotEvents();
      t.initSlideEvents();
      t.$list.on(
        'touchstart.slick mousedown.slick',
        { action: 'start' },
        t.swipeHandler
      );
      t.$list.on(
        'touchmove.slick mousemove.slick',
        { action: 'move' },
        t.swipeHandler
      );
      t.$list.on(
        'touchend.slick mouseup.slick',
        { action: 'end' },
        t.swipeHandler
      );
      t.$list.on(
        'touchcancel.slick mouseleave.slick',
        { action: 'end' },
        t.swipeHandler
      );
      t.$list.on('click.slick', t.clickHandler);
      n(document).on(t.visibilityChange, n.proxy(t.visibility, t));
      if (t.options.accessibility === !0)
        t.$list.on('keydown.slick', t.keyHandler);
      if (t.options.focusOnSelect === !0)
        n(t.$slideTrack).children().on('click.slick', t.selectHandler);
      n(window).on(
        'orientationchange.slick.slick-' + t.instanceUid,
        n.proxy(t.orientationChange, t)
      );
      n(window).on('resize.slick.slick-' + t.instanceUid, n.proxy(t.resize, t));
      n('[draggable!=true]', t.$slideTrack).on('dragstart', t.preventDefault);
      n(window).on('load.slick.slick-' + t.instanceUid, t.setPosition);
      n(document).on('ready.slick.slick-' + t.instanceUid, t.setPosition);
    };
    t.prototype.initUI = function () {
      var n = this;
      n.options.arrows === !0 &&
        n.slideCount > n.options.slidesToShow &&
        (n.$prevArrow.show(), n.$nextArrow.show());
      n.options.dots === !0 &&
        n.slideCount > n.options.slidesToShow &&
        n.$dots.show();
    };
    t.prototype.keyHandler = function (n) {
      var t = this;
      n.target.tagName.match('TEXTAREA|INPUT|SELECT') ||
        (n.keyCode === 37 && t.options.accessibility === !0
          ? t.changeSlide({
              data: { message: t.options.rtl === !0 ? 'next' : 'previous' },
            })
          : n.keyCode === 39 &&
            t.options.accessibility === !0 &&
            t.changeSlide({
              data: { message: t.options.rtl === !0 ? 'previous' : 'next' },
            }));
    };
    t.prototype.lazyLoad = function () {
      function f(i) {
        n('img[data-lazy]', i).each(function () {
          var i = n(this),
            r = n(this).attr('data-lazy'),
            u = document.createElement('img');
          u.onload = function () {
            i.animate({ opacity: 0 }, 100, function () {
              i.attr('src', r).animate({ opacity: 1 }, 200, function () {
                i.removeAttr('data-lazy').removeClass('slick-loading');
              });
              t.$slider.trigger('lazyLoaded', [t, i, r]);
            });
          };
          u.onerror = function () {
            i.removeAttr('data-lazy')
              .removeClass('slick-loading')
              .addClass('slick-lazyload-error');
            t.$slider.trigger('lazyLoadError', [t, i, r]);
          };
          u.src = r;
        });
      }
      var t = this,
        e,
        r,
        i,
        u;
      t.options.centerMode === !0
        ? t.options.infinite === !0
          ? ((i = t.currentSlide + (t.options.slidesToShow / 2 + 1)),
            (u = i + t.options.slidesToShow + 2))
          : ((i = Math.max(
              0,
              t.currentSlide - (t.options.slidesToShow / 2 + 1)
            )),
            (u = 2 + (t.options.slidesToShow / 2 + 1) + t.currentSlide))
        : ((i = t.options.infinite
            ? t.options.slidesToShow + t.currentSlide
            : t.currentSlide),
          (u = Math.ceil(i + t.options.slidesToShow)),
          t.options.fade === !0 && (i > 0 && i--, u <= t.slideCount && u++));
      e = t.$slider.find('.slick-slide').slice(i, u);
      f(e);
      t.slideCount <= t.options.slidesToShow
        ? ((r = t.$slider.find('.slick-slide')), f(r))
        : t.currentSlide >= t.slideCount - t.options.slidesToShow
        ? ((r = t.$slider
            .find('.slick-cloned')
            .slice(0, t.options.slidesToShow)),
          f(r))
        : t.currentSlide === 0 &&
          ((r = t.$slider
            .find('.slick-cloned')
            .slice(t.options.slidesToShow * -1)),
          f(r));
    };
    t.prototype.loadSlider = function () {
      var n = this;
      n.setPosition();
      n.$slideTrack.css({ opacity: 1 });
      n.$slider.removeClass('slick-loading');
      n.initUI();
      n.options.lazyLoad === 'progressive' && n.progressiveLazyLoad();
    };
    t.prototype.next = t.prototype.slickNext = function () {
      var n = this;
      n.changeSlide({ data: { message: 'next' } });
    };
    t.prototype.orientationChange = function () {
      var n = this;
      n.checkResponsive();
      n.setPosition();
    };
    t.prototype.pause = t.prototype.slickPause = function () {
      var n = this;
      n.autoPlayClear();
      n.paused = !0;
    };
    t.prototype.play = t.prototype.slickPlay = function () {
      var n = this;
      n.autoPlay();
      n.options.autoplay = !0;
      n.paused = !1;
      n.focussed = !1;
      n.interrupted = !1;
    };
    t.prototype.postSlide = function (n) {
      var t = this;
      t.unslicked ||
        (t.$slider.trigger('afterChange', [t, n]),
        (t.animating = !1),
        t.setPosition(),
        (t.swipeLeft = null),
        t.options.autoplay && t.autoPlay(),
        t.options.accessibility === !0 && t.initADA());
    };
    t.prototype.prev = t.prototype.slickPrev = function () {
      var n = this;
      n.changeSlide({ data: { message: 'previous' } });
    };
    t.prototype.preventDefault = function (n) {
      n.preventDefault();
    };
    t.prototype.progressiveLazyLoad = function (t) {
      t = t || 1;
      var i = this,
        e = n('img[data-lazy]', i.$slider),
        r,
        u,
        f;
      e.length
        ? ((r = e.first()),
          (u = r.attr('data-lazy')),
          (f = document.createElement('img')),
          (f.onload = function () {
            r.attr('src', u)
              .removeAttr('data-lazy')
              .removeClass('slick-loading');
            i.options.adaptiveHeight === !0 && i.setPosition();
            i.$slider.trigger('lazyLoaded', [i, r, u]);
            i.progressiveLazyLoad();
          }),
          (f.onerror = function () {
            t < 3
              ? setTimeout(function () {
                  i.progressiveLazyLoad(t + 1);
                }, 500)
              : (r
                  .removeAttr('data-lazy')
                  .removeClass('slick-loading')
                  .addClass('slick-lazyload-error'),
                i.$slider.trigger('lazyLoadError', [i, r, u]),
                i.progressiveLazyLoad());
          }),
          (f.src = u))
        : i.$slider.trigger('allImagesLoaded', [i]);
    };
    t.prototype.refresh = function (t) {
      var i = this,
        r,
        u;
      u = i.slideCount - i.options.slidesToShow;
      !i.options.infinite && i.currentSlide > u && (i.currentSlide = u);
      i.slideCount <= i.options.slidesToShow && (i.currentSlide = 0);
      r = i.currentSlide;
      i.destroy(!0);
      n.extend(i, i.initials, { currentSlide: r });
      i.init();
      t || i.changeSlide({ data: { message: 'index', index: r } }, !1);
    };
    t.prototype.registerBreakpoints = function () {
      var t = this,
        u,
        f,
        i,
        r = t.options.responsive || null;
      if (n.type(r) === 'array' && r.length) {
        t.respondTo = t.options.respondTo || 'window';
        for (u in r)
          if (
            ((i = t.breakpoints.length - 1),
            (f = r[u].breakpoint),
            r.hasOwnProperty(u))
          ) {
            while (i >= 0)
              t.breakpoints[i] &&
                t.breakpoints[i] === f &&
                t.breakpoints.splice(i, 1),
                i--;
            t.breakpoints.push(f);
            t.breakpointSettings[f] = r[u].settings;
          }
        t.breakpoints.sort(function (n, i) {
          return t.options.mobileFirst ? n - i : i - n;
        });
      }
    };
    t.prototype.reinit = function () {
      var t = this;
      if (
        ((t.$slides = t.$slideTrack
          .children(t.options.slide)
          .addClass('slick-slide')),
        (t.slideCount = t.$slides.length),
        t.currentSlide >= t.slideCount &&
          t.currentSlide !== 0 &&
          (t.currentSlide = t.currentSlide - t.options.slidesToScroll),
        t.slideCount <= t.options.slidesToShow && (t.currentSlide = 0),
        t.registerBreakpoints(),
        t.setProps(),
        t.setupInfinite(),
        t.buildArrows(),
        t.updateArrows(),
        t.initArrowEvents(),
        t.buildDots(),
        t.updateDots(),
        t.initDotEvents(),
        t.cleanUpSlideEvents(),
        t.initSlideEvents(),
        t.checkResponsive(!1, !0),
        t.options.focusOnSelect === !0)
      )
        n(t.$slideTrack).children().on('click.slick', t.selectHandler);
      t.setSlideClasses(typeof t.currentSlide == 'number' ? t.currentSlide : 0);
      t.setPosition();
      t.focusHandler();
      t.paused = !t.options.autoplay;
      t.autoPlay();
      t.$slider.trigger('reInit', [t]);
    };
    t.prototype.resize = function () {
      var t = this;
      n(window).width() !== t.windowWidth &&
        (clearTimeout(t.windowDelay),
        (t.windowDelay = window.setTimeout(function () {
          t.windowWidth = n(window).width();
          t.checkResponsive();
          t.unslicked || t.setPosition();
        }, 50)));
    };
    t.prototype.removeSlide = t.prototype.slickRemove = function (n, t, i) {
      var r = this;
      if (
        (typeof n == 'boolean'
          ? ((t = n), (n = t === !0 ? 0 : r.slideCount - 1))
          : (n = t === !0 ? --n : n),
        r.slideCount < 1 || n < 0 || n > r.slideCount - 1)
      )
        return !1;
      r.unload();
      i === !0
        ? r.$slideTrack.children().remove()
        : r.$slideTrack.children(this.options.slide).eq(n).remove();
      r.$slides = r.$slideTrack.children(this.options.slide);
      r.$slideTrack.children(this.options.slide).detach();
      r.$slideTrack.append(r.$slides);
      r.$slidesCache = r.$slides;
      r.reinit();
    };
    t.prototype.setCSS = function (n) {
      var t = this,
        i = {},
        r,
        u;
      t.options.rtl === !0 && (n = -n);
      r = t.positionProp == 'left' ? Math.ceil(n) + 'px' : '0px';
      u = t.positionProp == 'top' ? Math.ceil(n) + 'px' : '0px';
      i[t.positionProp] = n;
      t.transformsEnabled === !1
        ? t.$slideTrack.css(i)
        : ((i = {}),
          t.cssTransitions === !1
            ? ((i[t.animType] = 'translate(' + r + ', ' + u + ')'),
              t.$slideTrack.css(i))
            : ((i[t.animType] = 'translate3d(' + r + ', ' + u + ', 0px)'),
              t.$slideTrack.css(i)));
    };
    t.prototype.setDimensions = function () {
      var n = this,
        t;
      n.options.vertical === !1
        ? n.options.centerMode === !0 &&
          n.$list.css({ padding: '0px ' + n.options.centerPadding })
        : (n.$list.height(
            n.$slides.first().outerHeight(!0) * n.options.slidesToShow
          ),
          n.options.centerMode === !0 &&
            n.$list.css({ padding: n.options.centerPadding + ' 0px' }));
      n.listWidth = n.$list.width();
      n.listHeight = n.$list.height();
      n.options.vertical === !1 && n.options.variableWidth === !1
        ? ((n.slideWidth = Math.ceil(n.listWidth / n.options.slidesToShow)),
          n.$slideTrack.width(
            Math.ceil(
              n.slideWidth * n.$slideTrack.children('.slick-slide').length
            )
          ))
        : n.options.variableWidth === !0
        ? n.$slideTrack.width(5e3 * n.slideCount)
        : ((n.slideWidth = Math.ceil(n.listWidth)),
          n.$slideTrack.height(
            Math.ceil(
              n.$slides.first().outerHeight(!0) *
                n.$slideTrack.children('.slick-slide').length
            )
          ));
      t = n.$slides.first().outerWidth(!0) - n.$slides.first().width();
      n.options.variableWidth === !1 &&
        n.$slideTrack.children('.slick-slide').width(n.slideWidth - t);
    };
    t.prototype.setFade = function () {
      var t = this,
        i;
      t.$slides.each(function (r, u) {
        i = t.slideWidth * r * -1;
        t.options.rtl === !0
          ? n(u).css({
              position: 'relative',
              right: i,
              top: 0,
              zIndex: t.options.zIndex - 2,
              opacity: 0,
            })
          : n(u).css({
              position: 'relative',
              left: i,
              top: 0,
              zIndex: t.options.zIndex - 2,
              opacity: 0,
            });
      });
      t.$slides
        .eq(t.currentSlide)
        .css({ zIndex: t.options.zIndex - 1, opacity: 1 });
    };
    t.prototype.setHeight = function () {
      var n = this,
        t;
      n.options.slidesToShow === 1 &&
        n.options.adaptiveHeight === !0 &&
        n.options.vertical === !1 &&
        ((t = n.$slides.eq(n.currentSlide).outerHeight(!0)),
        n.$list.css('height', t));
    };
    t.prototype.setOption = t.prototype.slickSetOption = function () {
      var t = this,
        u,
        f,
        e,
        i,
        o = !1,
        r;
      if (
        (n.type(arguments[0]) === 'object'
          ? ((e = arguments[0]), (o = arguments[1]), (r = 'multiple'))
          : n.type(arguments[0]) === 'string' &&
            ((e = arguments[0]),
            (i = arguments[1]),
            (o = arguments[2]),
            arguments[0] === 'responsive' && n.type(arguments[1]) === 'array'
              ? (r = 'responsive')
              : typeof arguments[1] != 'undefined' && (r = 'single')),
        r === 'single')
      )
        t.options[e] = i;
      else if (r === 'multiple')
        n.each(e, function (n, i) {
          t.options[n] = i;
        });
      else if (r === 'responsive')
        for (f in i)
          if (n.type(t.options.responsive) !== 'array')
            t.options.responsive = [i[f]];
          else {
            for (u = t.options.responsive.length - 1; u >= 0; )
              t.options.responsive[u].breakpoint === i[f].breakpoint &&
                t.options.responsive.splice(u, 1),
                u--;
            t.options.responsive.push(i[f]);
          }
      o && (t.unload(), t.reinit());
    };
    t.prototype.setPosition = function () {
      var n = this;
      n.setDimensions();
      n.setHeight();
      n.options.fade === !1 ? n.setCSS(n.getLeft(n.currentSlide)) : n.setFade();
      n.$slider.trigger('setPosition', [n]);
    };
    t.prototype.setProps = function () {
      var n = this,
        t = document.body.style;
      n.positionProp = n.options.vertical === !0 ? 'top' : 'left';
      n.positionProp === 'top'
        ? n.$slider.addClass('slick-vertical')
        : n.$slider.removeClass('slick-vertical');
      (t.WebkitTransition !== undefined ||
        t.MozTransition !== undefined ||
        t.msTransition !== undefined) &&
        n.options.useCSS === !0 &&
        (n.cssTransitions = !0);
      n.options.fade &&
        (typeof n.options.zIndex == 'number'
          ? n.options.zIndex < 3 && (n.options.zIndex = 3)
          : (n.options.zIndex = n.defaults.zIndex));
      t.OTransform !== undefined &&
        ((n.animType = 'OTransform'),
        (n.transformType = '-o-transform'),
        (n.transitionType = 'OTransition'),
        t.perspectiveProperty === undefined &&
          t.webkitPerspective === undefined &&
          (n.animType = !1));
      t.MozTransform !== undefined &&
        ((n.animType = 'MozTransform'),
        (n.transformType = '-moz-transform'),
        (n.transitionType = 'MozTransition'),
        t.perspectiveProperty === undefined &&
          t.MozPerspective === undefined &&
          (n.animType = !1));
      t.webkitTransform !== undefined &&
        ((n.animType = 'webkitTransform'),
        (n.transformType = '-webkit-transform'),
        (n.transitionType = 'webkitTransition'),
        t.perspectiveProperty === undefined &&
          t.webkitPerspective === undefined &&
          (n.animType = !1));
      t.msTransform !== undefined &&
        ((n.animType = 'msTransform'),
        (n.transformType = '-ms-transform'),
        (n.transitionType = 'msTransition'),
        t.msTransform === undefined && (n.animType = !1));
      t.transform !== undefined &&
        n.animType !== !1 &&
        ((n.animType = 'transform'),
        (n.transformType = 'transform'),
        (n.transitionType = 'transition'));
      n.transformsEnabled =
        n.options.useTransform && n.animType !== null && n.animType !== !1;
    };
    t.prototype.setSlideClasses = function (n) {
      var t = this,
        u,
        i,
        r,
        f;
      i = t.$slider
        .find('.slick-slide')
        .removeClass('slick-active slick-center slick-current')
        .attr('aria-hidden', 'true');
      t.$slides.eq(n).addClass('slick-current');
      t.options.centerMode === !0
        ? ((u = Math.floor(t.options.slidesToShow / 2)),
          t.options.infinite === !0 &&
            (n >= u && n <= t.slideCount - 1 - u
              ? t.$slides
                  .slice(n - u, n + u + 1)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')
              : ((r = t.options.slidesToShow + n),
                i
                  .slice(r - u + 1, r + u + 2)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')),
            n === 0
              ? i
                  .eq(i.length - 1 - t.options.slidesToShow)
                  .addClass('slick-center')
              : n === t.slideCount - 1 &&
                i.eq(t.options.slidesToShow).addClass('slick-center')),
          t.$slides.eq(n).addClass('slick-center'))
        : n >= 0 && n <= t.slideCount - t.options.slidesToShow
        ? t.$slides
            .slice(n, n + t.options.slidesToShow)
            .addClass('slick-active')
            .attr('aria-hidden', 'false')
        : i.length <= t.options.slidesToShow
        ? i.addClass('slick-active').attr('aria-hidden', 'false')
        : ((f = t.slideCount % t.options.slidesToShow),
          (r = t.options.infinite === !0 ? t.options.slidesToShow + n : n),
          t.options.slidesToShow == t.options.slidesToScroll &&
          t.slideCount - n < t.options.slidesToShow
            ? i
                .slice(r - (t.options.slidesToShow - f), r + f)
                .addClass('slick-active')
                .attr('aria-hidden', 'false')
            : i
                .slice(r, r + t.options.slidesToShow)
                .addClass('slick-active')
                .attr('aria-hidden', 'false'));
      t.options.lazyLoad === 'ondemand' && t.lazyLoad();
    };
    t.prototype.setupInfinite = function () {
      var t = this,
        i,
        r,
        u;
      if (
        (t.options.fade === !0 && (t.options.centerMode = !1),
        t.options.infinite === !0 &&
          t.options.fade === !1 &&
          ((r = null), t.slideCount > t.options.slidesToShow))
      ) {
        for (
          u =
            t.options.centerMode === !0
              ? t.options.slidesToShow + 1
              : t.options.slidesToShow,
            i = t.slideCount;
          i > t.slideCount - u;
          i -= 1
        )
          (r = i - 1),
            n(t.$slides[r])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', r - t.slideCount)
              .prependTo(t.$slideTrack)
              .addClass('slick-cloned');
        for (i = 0; i < u; i += 1)
          (r = i),
            n(t.$slides[r])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', r + t.slideCount)
              .appendTo(t.$slideTrack)
              .addClass('slick-cloned');
        t.$slideTrack
          .find('.slick-cloned')
          .find('[id]')
          .each(function () {
            n(this).attr('id', '');
          });
      }
    };
    t.prototype.interrupt = function (n) {
      var t = this;
      n || t.autoPlay();
      t.interrupted = n;
    };
    t.prototype.selectHandler = function (t) {
      var i = this,
        u = n(t.target).is('.slick-slide')
          ? n(t.target)
          : n(t.target).parents('.slick-slide'),
        r = parseInt(u.attr('data-slick-index'));
      if ((r || (r = 0), i.slideCount <= i.options.slidesToShow)) {
        i.setSlideClasses(r);
        i.asNavFor(r);
        return;
      }
      i.slideHandler(r);
    };
    t.prototype.slideHandler = function (n, t, i) {
      var u,
        f,
        s,
        o,
        h = null,
        r = this,
        e;
      if (
        ((t = t || !1),
        r.animating !== !0 || r.options.waitForAnimate !== !0) &&
        (r.options.fade !== !0 || r.currentSlide !== n) &&
        !(r.slideCount <= r.options.slidesToShow)
      ) {
        if (
          (t === !1 && r.asNavFor(n),
          (u = n),
          (h = r.getLeft(u)),
          (o = r.getLeft(r.currentSlide)),
          (r.currentLeft = r.swipeLeft === null ? o : r.swipeLeft),
          r.options.infinite === !1 &&
            r.options.centerMode === !1 &&
            (n < 0 || n > r.getDotCount() * r.options.slidesToScroll))
        ) {
          r.options.fade === !1 &&
            ((u = r.currentSlide),
            i !== !0
              ? r.animateSlide(o, function () {
                  r.postSlide(u);
                })
              : r.postSlide(u));
          return;
        }
        if (
          r.options.infinite === !1 &&
          r.options.centerMode === !0 &&
          (n < 0 || n > r.slideCount - r.options.slidesToScroll)
        ) {
          r.options.fade === !1 &&
            ((u = r.currentSlide),
            i !== !0
              ? r.animateSlide(o, function () {
                  r.postSlide(u);
                })
              : r.postSlide(u));
          return;
        }
        if (
          (r.options.autoplay && clearInterval(r.autoPlayTimer),
          (f =
            u < 0
              ? r.slideCount % r.options.slidesToScroll != 0
                ? r.slideCount - (r.slideCount % r.options.slidesToScroll)
                : r.slideCount + u
              : u >= r.slideCount
              ? r.slideCount % r.options.slidesToScroll != 0
                ? 0
                : u - r.slideCount
              : u),
          (r.animating = !0),
          r.$slider.trigger('beforeChange', [r, r.currentSlide, f]),
          (s = r.currentSlide),
          (r.currentSlide = f),
          r.setSlideClasses(r.currentSlide),
          r.options.asNavFor &&
            ((e = r.getNavTarget()),
            (e = e.slick('getSlick')),
            e.slideCount <= e.options.slidesToShow &&
              e.setSlideClasses(r.currentSlide)),
          r.updateDots(),
          r.updateArrows(),
          r.options.fade === !0)
        ) {
          i !== !0
            ? (r.fadeSlideOut(s),
              r.fadeSlide(f, function () {
                r.postSlide(f);
              }))
            : r.postSlide(f);
          r.animateHeight();
          return;
        }
        i !== !0
          ? r.animateSlide(h, function () {
              r.postSlide(f);
            })
          : r.postSlide(f);
      }
    };
    t.prototype.startLoad = function () {
      var n = this;
      n.options.arrows === !0 &&
        n.slideCount > n.options.slidesToShow &&
        (n.$prevArrow.hide(), n.$nextArrow.hide());
      n.options.dots === !0 &&
        n.slideCount > n.options.slidesToShow &&
        n.$dots.hide();
      n.$slider.addClass('slick-loading');
    };
    t.prototype.swipeDirection = function () {
      var i,
        r,
        u,
        n,
        t = this;
      return ((i = t.touchObject.startX - t.touchObject.curX),
      (r = t.touchObject.startY - t.touchObject.curY),
      (u = Math.atan2(r, i)),
      (n = Math.round((u * 180) / Math.PI)),
      n < 0 && (n = 360 - Math.abs(n)),
      n <= 45 && n >= 0)
        ? t.options.rtl === !1
          ? 'left'
          : 'right'
        : n <= 360 && n >= 315
        ? t.options.rtl === !1
          ? 'left'
          : 'right'
        : n >= 135 && n <= 225
        ? t.options.rtl === !1
          ? 'right'
          : 'left'
        : t.options.verticalSwiping === !0
        ? n >= 35 && n <= 135
          ? 'down'
          : 'up'
        : 'vertical';
    };
    t.prototype.swipeEnd = function () {
      var n = this,
        i,
        t;
      if (
        ((n.dragging = !1),
        (n.interrupted = !1),
        (n.shouldClick = n.touchObject.swipeLength > 10 ? !1 : !0),
        n.touchObject.curX === undefined)
      )
        return !1;
      if (
        (n.touchObject.edgeHit === !0 &&
          n.$slider.trigger('edge', [n, n.swipeDirection()]),
        n.touchObject.swipeLength >= n.touchObject.minSwipe)
      ) {
        t = n.swipeDirection();
        switch (t) {
          case 'left':
          case 'down':
            i = n.options.swipeToSlide
              ? n.checkNavigable(n.currentSlide + n.getSlideCount())
              : n.currentSlide + n.getSlideCount();
            n.currentDirection = 0;
            break;
          case 'right':
          case 'up':
            i = n.options.swipeToSlide
              ? n.checkNavigable(n.currentSlide - n.getSlideCount())
              : n.currentSlide - n.getSlideCount();
            n.currentDirection = 1;
        }
        t != 'vertical' &&
          (n.slideHandler(i),
          (n.touchObject = {}),
          n.$slider.trigger('swipe', [n, t]));
      } else
        n.touchObject.startX !== n.touchObject.curX &&
          (n.slideHandler(n.currentSlide), (n.touchObject = {}));
    };
    t.prototype.swipeHandler = function (n) {
      var t = this;
      if (
        t.options.swipe !== !1 &&
        (!('ontouchend' in document) || t.options.swipe !== !1) &&
        (t.options.draggable !== !1 || n.type.indexOf('mouse') === -1)
      ) {
        t.touchObject.fingerCount =
          n.originalEvent && n.originalEvent.touches !== undefined
            ? n.originalEvent.touches.length
            : 1;
        t.touchObject.minSwipe = t.listWidth / t.options.touchThreshold;
        t.options.verticalSwiping === !0 &&
          (t.touchObject.minSwipe = t.listHeight / t.options.touchThreshold);
        switch (n.data.action) {
          case 'start':
            t.swipeStart(n);
            break;
          case 'move':
            t.swipeMove(n);
            break;
          case 'end':
            t.swipeEnd(n);
        }
      }
    };
    t.prototype.swipeMove = function (n) {
      var t = this,
        f,
        e,
        r,
        u,
        i;
      if (
        ((i = n.originalEvent !== undefined ? n.originalEvent.touches : null),
        !t.dragging || (i && i.length !== 1))
      )
        return !1;
      if (
        ((f = t.getLeft(t.currentSlide)),
        (t.touchObject.curX = i !== undefined ? i[0].pageX : n.clientX),
        (t.touchObject.curY = i !== undefined ? i[0].pageY : n.clientY),
        (t.touchObject.swipeLength = Math.round(
          Math.sqrt(Math.pow(t.touchObject.curX - t.touchObject.startX, 2))
        )),
        t.options.verticalSwiping === !0 &&
          (t.touchObject.swipeLength = Math.round(
            Math.sqrt(Math.pow(t.touchObject.curY - t.touchObject.startY, 2))
          )),
        (e = t.swipeDirection()),
        e !== 'vertical')
      ) {
        if (
          (n.originalEvent !== undefined &&
            t.touchObject.swipeLength > 4 &&
            n.preventDefault(),
          (u =
            (t.options.rtl === !1 ? 1 : -1) *
            (t.touchObject.curX > t.touchObject.startX ? 1 : -1)),
          t.options.verticalSwiping === !0 &&
            (u = t.touchObject.curY > t.touchObject.startY ? 1 : -1),
          (r = t.touchObject.swipeLength),
          (t.touchObject.edgeHit = !1),
          t.options.infinite === !1 &&
            ((t.currentSlide === 0 && e === 'right') ||
              (t.currentSlide >= t.getDotCount() && e === 'left')) &&
            ((r = t.touchObject.swipeLength * t.options.edgeFriction),
            (t.touchObject.edgeHit = !0)),
          (t.swipeLeft =
            t.options.vertical === !1
              ? f + r * u
              : f + r * (t.$list.height() / t.listWidth) * u),
          t.options.verticalSwiping === !0 && (t.swipeLeft = f + r * u),
          t.options.fade === !0 || t.options.touchMove === !1)
        )
          return !1;
        if (t.animating === !0) return (t.swipeLeft = null), !1;
        t.setCSS(t.swipeLeft);
      }
    };
    t.prototype.swipeStart = function (n) {
      var t = this,
        i;
      if (
        ((t.interrupted = !0),
        t.touchObject.fingerCount !== 1 ||
          t.slideCount <= t.options.slidesToShow)
      )
        return (t.touchObject = {}), !1;
      n.originalEvent !== undefined &&
        n.originalEvent.touches !== undefined &&
        (i = n.originalEvent.touches[0]);
      t.touchObject.startX = t.touchObject.curX =
        i !== undefined ? i.pageX : n.clientX;
      t.touchObject.startY = t.touchObject.curY =
        i !== undefined ? i.pageY : n.clientY;
      t.dragging = !0;
    };
    t.prototype.unfilterSlides = t.prototype.slickUnfilter = function () {
      var n = this;
      n.$slidesCache !== null &&
        (n.unload(),
        n.$slideTrack.children(this.options.slide).detach(),
        n.$slidesCache.appendTo(n.$slideTrack),
        n.reinit());
    };
    t.prototype.unload = function () {
      var t = this;
      n('.slick-cloned', t.$slider).remove();
      t.$dots && t.$dots.remove();
      t.$prevArrow &&
        t.htmlExpr.test(t.options.prevArrow) &&
        t.$prevArrow.remove();
      t.$nextArrow &&
        t.htmlExpr.test(t.options.nextArrow) &&
        t.$nextArrow.remove();
      t.$slides
        .removeClass('slick-slide slick-active slick-visible slick-current')
        .attr('aria-hidden', 'true')
        .css('width', '');
    };
    t.prototype.unslick = function (n) {
      var t = this;
      t.$slider.trigger('unslick', [t, n]);
      t.destroy();
    };
    t.prototype.updateArrows = function () {
      var n = this,
        t;
      t = Math.floor(n.options.slidesToShow / 2);
      n.options.arrows === !0 &&
        n.slideCount > n.options.slidesToShow &&
        !n.options.infinite &&
        (n.$prevArrow
          .removeClass('slick-disabled')
          .attr('aria-disabled', 'false'),
        n.$nextArrow
          .removeClass('slick-disabled')
          .attr('aria-disabled', 'false'),
        n.currentSlide === 0
          ? (n.$prevArrow
              .addClass('slick-disabled')
              .attr('aria-disabled', 'true'),
            n.$nextArrow
              .removeClass('slick-disabled')
              .attr('aria-disabled', 'false'))
          : n.currentSlide >= n.slideCount - n.options.slidesToShow &&
            n.options.centerMode === !1
          ? (n.$nextArrow
              .addClass('slick-disabled')
              .attr('aria-disabled', 'true'),
            n.$prevArrow
              .removeClass('slick-disabled')
              .attr('aria-disabled', 'false'))
          : n.currentSlide >= n.slideCount - 1 &&
            n.options.centerMode === !0 &&
            (n.$nextArrow
              .addClass('slick-disabled')
              .attr('aria-disabled', 'true'),
            n.$prevArrow
              .removeClass('slick-disabled')
              .attr('aria-disabled', 'false')));
    };
    t.prototype.updateDots = function () {
      var n = this;
      n.$dots !== null &&
        (n.$dots
          .find('li')
          .removeClass('slick-active')
          .attr('aria-hidden', 'true'),
        n.$dots
          .find('li')
          .eq(Math.floor(n.currentSlide / n.options.slidesToScroll))
          .addClass('slick-active')
          .attr('aria-hidden', 'false'));
    };
    t.prototype.visibility = function () {
      var n = this;
      n.options.autoplay && (n.interrupted = document[n.hidden] ? !0 : !1);
    };
    n.fn.slick = function () {
      for (
        var i = this,
          r = arguments[0],
          f = Array.prototype.slice.call(arguments, 1),
          e = i.length,
          u,
          n = 0;
        n < e;
        n++
      )
        if (
          (typeof r == 'object' || typeof r == 'undefined'
            ? (i[n].slick = new t(i[n], r))
            : (u = i[n].slick[r].apply(i[n].slick, f)),
          typeof u != 'undefined')
        )
          return u;
      return i;
    };
  });
appear = (function () {
  'use strict';
  function r() {
    var r = window.scrollY || window.pageYOffset;
    null != t &&
      ((n.velocity = r - t),
      (n.delta = n.velocity >= 0 ? n.velocity : -1 * n.velocity));
    t = r;
    i && clearTimeout(i);
    i = setTimeout(function () {
      t = null;
    }, 30);
  }
  function u(n, t) {
    var i = n.getBoundingClientRect();
    return (
      i.top + i.height >= 0 &&
      i.left + i.width >= 0 &&
      i.bottom - i.height <=
        (window.innerHeight || document.documentElement.clientHeight) + t &&
      i.right - i.width <=
        (window.innerWidth || document.documentElement.clientWidth) + t
    );
  }
  var t = null,
    i = 0,
    n = {};
  return (
    addEventListener('scroll', r, !1),
    function (t) {
      return (function () {
        function b(n, t) {
          return function () {
            var i = this,
              r = arguments;
            clearTimeout(s);
            s = setTimeout(function () {
              n.apply(i, r);
            }, t);
          };
        }
        function f() {
          n.delta < t.delta.speed &&
            (l ||
              ((l = !0),
              e(),
              setTimeout(function () {
                l = !1;
              }, t.delta.timeout)));
          b(function () {
            e();
          }, t.debounce)();
        }
        function a() {
          e();
          addEventListener('scroll', f, !1);
          addEventListener('resize', f, !1);
        }
        function k() {
          i = [];
          s && clearTimeout(s);
          h();
        }
        function h() {
          removeEventListener('scroll', f, !1);
          removeEventListener('resize', f, !1);
        }
        function e() {
          v ||
            (i.forEach(function (n, f) {
              n && u(n, t.bounds)
                ? r[f] &&
                  ((r[f] = !1),
                  p++,
                  t.appear && t.appear(n),
                  t.disappear || t.reappear || (i[f] = null))
                : (r[f] === !1 &&
                    (t.disappear && t.disappear(n),
                    w++,
                    t.reappear || (i[f] = null)),
                  (r[f] = !0));
            }),
            t.reappear ||
              (t.appear && (!t.appear || p !== o)) ||
              (t.disappear && (!t.disappear || w !== o)) ||
              ((v = !0), h(), t.done && t.done()));
        }
        function c() {
          var u, n;
          if (
            !y &&
            ((y = !0),
            t.init && t.init(),
            (u = 'function' == typeof t.elements ? t.elements() : t.elements))
          ) {
            for (o = u.length, n = 0; o > n; n += 1) i.push(u[n]), r.push(!0);
            a();
          }
        }
        var o,
          s,
          l,
          v,
          y = !1,
          i = [],
          r = [],
          p = 0,
          w = 0,
          t = {};
        return function (n) {
          var i, r;
          return (
            (n = n || {}),
            (t = {
              init: n.init,
              elements: n.elements,
              appear: n.appear,
              disappear: n.disappear,
              done: n.done,
              reappear: n.reappear,
              bounds: n.bounds || 0,
              debounce: n.debounce || 50,
              delta: {
                speed: n.deltaSpeed || 50,
                timeout: n.deltaTimeout || 500,
              },
            }),
            addEventListener('DOMContentLoaded', c, !1),
            (i = !1),
            Function('/*@cc_on return document.documentMode===10@*/')() &&
              (i = !0),
            (r =
              'complete' === document.readyState ||
              'loaded' === document.readyState),
            i ? r && c() : (r || 'interactive' === document.readyState) && c(),
            {
              trigger: function () {
                e();
              },
              pause: function () {
                h();
              },
              resume: function () {
                a();
              },
              destroy: function () {
                k();
              },
            }
          );
        };
      })()(t);
    }
  );
})();
jQuery.fn.textPlaceholder = function () {
  return this.each(function () {
    var n = this,
      i,
      t;
    (n.placeholder && 'placeholder' in document.createElement(n.tagName)) ||
      ((i = n.getAttribute('placeholder')),
      (t = jQuery(n)),
      (n.value === '' || n.value == i) &&
        (t.addClass('text-placeholder'), (n.value = i)),
      t.focus(function () {
        t.hasClass('text-placeholder') &&
          ((this.value = ''), t.removeClass('text-placeholder'));
      }),
      t.blur(function () {
        this.value === ''
          ? (t.addClass('text-placeholder'), (this.value = i))
          : t.removeClass('text-placeholder');
      }),
      n.form &&
        jQuery(n.form).submit(function () {
          t.hasClass('text-placeholder') && (n.value = '');
        }));
  });
};
jQuery(document).ready(function () {
  jq('[placeholder]').textPlaceholder();
});
customForm = {
  baseOptions: {
    useNativeDropOnMobileDevices: !1,
    unselectableClass: 'customForm-unselectable',
    labelActiveClass: 'customForm-label-active',
    labelDisabledClass: 'customForm-label-disabled',
    classPrefix: 'customForm-class-',
    hiddenClass: 'customForm-hidden',
    focusClass: 'customForm-focus',
    wrapperTag: 'div',
  },
  customForms: {
    setOptions: function (n) {
      for (var t in n)
        n.hasOwnProperty(t) &&
          typeof n[t] == 'object' &&
          customForm.lib.extend(
            customForm.modules[t].prototype.defaultOptions,
            n[t]
          );
    },
    replaceAll: function () {
      var i, t, n;
      for (i in customForm.modules)
        for (
          t = customForm.lib.queryBySelector(
            customForm.modules[i].prototype.selector
          ),
            n = 0;
          n < t.length;
          n++
        )
          t[n].attributes.normal === undefined &&
            (t[n].customForm
              ? t[n].customForm.refreshState()
              : customForm.lib.hasClass(t[n], 'k-formatting') ||
                (!customForm.lib.hasClass(t[n], 'default') &&
                  customForm.modules[i].prototype.checkElement(t[n]) &&
                  new customForm.modules[i]({ replaces: t[n] })));
    },
    refreshElement: function (n) {
      n && n.customForm && n.customForm.refreshState();
    },
    refreshAll: function () {
      var i, t, n;
      for (i in customForm.modules)
        for (
          t = customForm.lib.queryBySelector(
            customForm.modules[i].prototype.selector
          ),
            n = 0;
          n < t.length;
          n++
        )
          t[n].customForm && t[n].customForm.refreshState();
    },
    destroyAll: function () {
      var i, t, n;
      for (i in customForm.modules)
        for (
          t = customForm.lib.queryBySelector(
            customForm.modules[i].prototype.selector
          ),
            n = 0;
          n < t.length;
          n++
        )
          t[n].customForm && t[n].customForm.destroy();
    },
  },
  isTouchDevice: (function () {
    try {
      return (
        'ontouchstart' in window ||
        (window.DocumentTouch && document instanceof DocumentTouch)
      );
    } catch (n) {
      return !1;
    }
  })(),
  setBaseModule: function (n) {
    customForm.customControl = function (n) {
      this.options = customForm.lib.extend(
        {},
        customForm.baseOptions,
        this.defaultOptions,
        n
      );
      this.init();
    };
    for (var t in n) customForm.customControl.prototype[t] = n[t];
  },
  init: function () {
    return (
      (this.eventPress = this.isTouchDevice ? 'touchstart' : 'mousedown'),
      (this.eventMove = this.isTouchDevice ? 'touchmove' : 'mousemove'),
      (this.eventRelease = this.isTouchDevice ? 'touchend' : 'mouseup'),
      this
    );
  },
  initStyles: function () {
    var i = document.getElementsByTagName('head')[0],
      t = document.createTextNode(
        '.' +
          customForm.baseOptions.unselectableClass +
          '{-moz-user-select:none;-webkit-tap-highlight-color:rgba(255,255,255,0);-webkit-user-select:none;user-select:none;}'
      ),
      n = document.createElement('style');
    n.type = 'text/css';
    n.styleSheet ? (n.styleSheet.cssText = t.nodeValue) : n.appendChild(t);
    i.appendChild(n);
  },
  modules: {},
  plugins: {},
  addModule: function (n) {
    var t, i;
    if (n.name) {
      customForm.modules[n.name] = function () {
        customForm.modules[n.name].superclass.constructor.apply(
          this,
          arguments
        );
      };
      customForm.lib.inherit(
        customForm.modules[n.name],
        customForm.customControl
      );
      for (t in n) customForm.modules[n.name].prototype[t] = n[t];
      customForm.modules[n.name].prototype.onCreateModule();
      for (i in customForm.modules)
        if (customForm.modules[i] != customForm.modules[n.name])
          customForm.modules[i].prototype.onModuleAdded(
            customForm.modules[n.name]
          );
    }
  },
  addPlugin: function (n) {
    if (n && n.name) {
      customForm.plugins[n.name] = function () {
        this.init.apply(this, arguments);
      };
      for (var t in n) customForm.plugins[n.name].prototype[t] = n[t];
    }
  },
}.init();
customForm.setBaseModule({
  init: function () {
    this.options.replaces &&
      ((this.realElement = this.options.replaces),
      (this.realElement.customForm = this),
      this.replaceObject());
  },
  defaultOptions: {},
  checkElement: function () {
    return !0;
  },
  replaceObject: function () {
    this.createWrapper();
    this.attachEvents();
    this.fixStyles();
    this.setupWrapper();
  },
  createWrapper: function () {
    this.fakeElement = customForm.lib.createElement(this.options.wrapperTag);
    this.labelFor = customForm.lib.getLabelFor(this.realElement);
    customForm.lib.disableTextSelection(this.fakeElement);
    customForm.lib.addClass(
      this.fakeElement,
      customForm.lib.getAllClasses(
        this.realElement.className,
        this.options.classPrefix
      )
    );
    customForm.lib.addClass(
      this.realElement,
      customForm.baseOptions.hiddenClass
    );
  },
  attachEvents: function () {
    customForm.lib.event.add(
      this.realElement,
      'focus',
      this.onFocusHandler,
      this
    );
    customForm.lib.event.add(
      this.realElement,
      'blur',
      this.onBlurHandler,
      this
    );
    customForm.lib.event.add(this.fakeElement, 'click', this.onFakeClick, this);
    customForm.lib.event.add(
      this.fakeElement,
      customForm.eventPress,
      this.onFakePressed,
      this
    );
    customForm.lib.event.add(
      this.fakeElement,
      customForm.eventRelease,
      this.onFakeReleased,
      this
    );
    this.labelFor &&
      ((this.labelFor.customForm = this),
      customForm.lib.event.add(this.labelFor, 'click', this.onFakeClick, this),
      customForm.lib.event.add(
        this.labelFor,
        customForm.eventPress,
        this.onFakePressed,
        this
      ),
      customForm.lib.event.add(
        this.labelFor,
        customForm.eventRelease,
        this.onFakeReleased,
        this
      ));
  },
  fixStyles: function () {
    if (customForm.isTouchDevice) {
      var n = 'rgba(255,255,255,0)';
      this.realElement.style.webkitTapHighlightColor = n;
      this.fakeElement.style.webkitTapHighlightColor = n;
      this.labelFor && (this.labelFor.style.webkitTapHighlightColor = n);
    }
  },
  setupWrapper: function () {},
  refreshState: function () {},
  destroy: function () {
    this.fakeElement &&
      this.fakeElement.parentNode &&
      this.fakeElement.parentNode.removeChild(this.fakeElement);
    customForm.lib.removeClass(
      this.realElement,
      customForm.baseOptions.hiddenClass
    );
    this.realElement.customForm = null;
  },
  onFocus: function () {
    customForm.lib.addClass(this.fakeElement, this.options.focusClass);
  },
  onBlur: function () {
    customForm.lib.removeClass(this.fakeElement, this.options.focusClass);
  },
  onFocusHandler: function () {
    this.focused ||
      ((this.focused = !0),
      customForm.isTouchDevice &&
        (customForm.focusedInstance &&
          customForm.focusedInstance.realElement != this.realElement &&
          (customForm.focusedInstance.onBlur(),
          customForm.focusedInstance.realElement.blur()),
        (customForm.focusedInstance = this)),
      this.onFocus.apply(this, arguments));
  },
  onBlurHandler: function () {
    this.pressedFlag ||
      ((this.focused = !1), this.onBlur.apply(this, arguments));
  },
  onFakeClick: function () {
    customForm.isTouchDevice
      ? this.onFocus()
      : this.realElement.disabled || this.realElement.focus();
  },
  onFakePressed: function () {
    this.pressedFlag = !0;
  },
  onFakeReleased: function () {
    this.pressedFlag = !1;
  },
  onCreateModule: function () {},
  onModuleAdded: function () {},
  onControlReady: function () {},
});
customForm.lib = {
  bind: function (n, t) {
    return function () {
      return n.apply(t, arguments);
    };
  },
  browser: (function () {
    var n = navigator.userAgent.toLowerCase(),
      t = {},
      i =
        /(webkit)[ \/]([\w.]+)/.exec(n) ||
        /(opera)(?:.*version)?[ \/]([\w.]+)/.exec(n) ||
        /(msie) ([\w.]+)/.exec(n) ||
        (n.indexOf('compatible') < 0 &&
          /(mozilla)(?:.*? rv:([\w.]+))?/.exec(n)) ||
        [];
    return (
      (t[i[1]] = !0),
      (t.version = i[2] || '0'),
      (t.safariMac = n.indexOf('mac') != -1 && n.indexOf('safari') != -1),
      t
    );
  })(),
  getOffset: function (n) {
    var t, i;
    if (n.getBoundingClientRect) {
      var r =
          window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft,
        u =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop,
        f =
          document.documentElement.clientLeft || document.body.clientLeft || 0,
        e = document.documentElement.clientTop || document.body.clientTop || 0;
      return {
        top: Math.round(n.getBoundingClientRect().top + u - e),
        left: Math.round(n.getBoundingClientRect().left + r - f),
      };
    }
    for (t = 0, i = 0; n.offsetParent; )
      (t += n.offsetLeft), (i += n.offsetTop), (n = n.offsetParent);
    return { top: i, left: t };
  },
  getScrollTop: function () {
    return window.pageYOffset || document.documentElement.scrollTop;
  },
  getScrollLeft: function () {
    return window.pageXOffset || document.documentElement.scrollLeft;
  },
  getWindowWidth: function () {
    return document.compatMode == 'CSS1Compat'
      ? document.documentElement.clientWidth
      : document.body.clientWidth;
  },
  getWindowHeight: function () {
    return document.compatMode == 'CSS1Compat'
      ? document.documentElement.clientHeight
      : document.body.clientHeight;
  },
  getStyle: function (n, t) {
    return document.defaultView && document.defaultView.getComputedStyle
      ? document.defaultView.getComputedStyle(n, null)[t]
      : n.currentStyle
      ? n.currentStyle[t]
      : n.style[t];
  },
  getParent: function (n, t) {
    while (n.parentNode && n.parentNode != document.body) {
      if (n.parentNode.tagName.toLowerCase() == t.toLowerCase())
        return n.parentNode;
      n = n.parentNode;
    }
    return !1;
  },
  isParent: function (n, t) {
    while (n.parentNode) {
      if (n.parentNode === t) return !0;
      n = n.parentNode;
    }
    return !1;
  },
  getLabelFor: function (n) {
    return customForm.lib.getParent(n, 'label')
      ? n.parentNode
      : n.id
      ? customForm.lib.queryBySelector('label[for="' + n.id + '"]')[0]
      : void 0;
  },
  disableTextSelection: function (n) {
    typeof n.onselectstart != 'undefined'
      ? (n.onselectstart = function () {
          return !1;
        })
      : window.opera
      ? n.setAttribute('unselectable', 'on')
      : customForm.lib.addClass(n, customForm.baseOptions.unselectableClass);
  },
  enableTextSelection: function (n) {
    typeof n.onselectstart != 'undefined'
      ? (n.onselectstart = null)
      : window.opera
      ? n.removeAttribute('unselectable')
      : customForm.lib.removeClass(n, customForm.baseOptions.unselectableClass);
  },
  queryBySelector: function (n, t) {
    return this.getElementsBySelector(n, t);
  },
  prevSibling: function (n) {
    while ((n = n.previousSibling)) if (n.nodeType == 1) break;
    return n;
  },
  nextSibling: function (n) {
    while ((n = n.nextSibling)) if (n.nodeType == 1) break;
    return n;
  },
  fireEvent: function (n, t) {
    var i;
    return document.createEvent
      ? ((i = document.createEvent('HTMLEvents')),
        i.initEvent(t, !0, !0),
        !n.dispatchEvent(i))
      : ((i = document.createEventObject()), n.fireEvent('on' + t, i));
  },
  // isParent: function (n, t) {
  //   while (t.parentNode) {
  //     if (n == t) return !0;
  //     t = t.parentNode;
  //   }
  //   return !1;
  // },
  inherit: function (n, t) {
    var i = function () {};
    i.prototype = t.prototype;
    n.prototype = new i();
    n.prototype.constructor = n;
    n.superclass = t.prototype;
  },
  extend: function (n) {
    for (var i, t = 1; t < arguments.length; t++)
      for (i in arguments[t])
        arguments[t].hasOwnProperty(i) && (n[i] = arguments[t][i]);
    return n;
  },
  hasClass: function (n, t) {
    return n.className
      ? n.className.match(new RegExp('(\\s|^)' + t + '(\\s|$)'))
      : !1;
  },
  addClass: function (n, t) {
    this.hasClass(n, t) ||
      (n.className +=
        (!n.className.length ||
        n.className.charAt(n.className.length - 1) === ' '
          ? ''
          : ' ') + t);
  },
  removeClass: function (n, t) {
    this.hasClass(n, t) &&
      (n.className = n.className
        .replace(new RegExp('(\\s|^)' + t + '(\\s|$)'), ' ')
        .replace(/\s+$/, ''));
  },
  toggleClass: function (n, t, i) {
    i ? this.addClass(n, t) : this.removeClass(n, t);
  },
  createElement: function (n, t) {
    var r = document.createElement(n),
      i;
    for (i in t)
      if (t.hasOwnProperty(i))
        switch (i) {
          case 'class':
            r.className = t[i];
            break;
          case 'html':
            r.innerHTML = t[i];
            break;
          case 'style':
            this.setStyles(r, t[i]);
            break;
          default:
            r.setAttribute(i, t[i]);
        }
    return r;
  },
  setStyles: function (n, t) {
    for (var i in t)
      if (t.hasOwnProperty(i))
        switch (i) {
          case 'float':
            n.style.cssFloat = t[i];
            break;
          case 'opacity':
            n.style.filter =
              'progid:DXImageTransform.Microsoft.Alpha(opacity=' +
              t[i] * 100 +
              ')';
            n.style.opacity = t[i];
            break;
          default:
            n.style[i] =
              (typeof t[i] == 'undefined' ? 0 : t[i]) +
              (typeof t[i] == 'number' ? 'px' : '');
        }
    return n;
  },
  getInnerWidth: function (n) {
    return (
      n.offsetWidth -
      (parseInt(this.getStyle(n, 'paddingLeft')) || 0) -
      (parseInt(this.getStyle(n, 'paddingRight')) || 0)
    );
  },
  getInnerHeight: function (n) {
    return (
      n.offsetHeight -
      (parseInt(this.getStyle(n, 'paddingTop')) || 0) -
      (parseInt(this.getStyle(n, 'paddingBottom')) || 0)
    );
  },
  getAllClasses: function (n, t, i) {
    return (
      i || (i = ''),
      t || (t = ''),
      n
        ? n
            .replace(new RegExp('(\\s|^)' + i + '(\\s|$)'), ' ')
            .replace(/[\s]*([\S]+)+[\s]*/gi, t + '$1 ')
        : ''
    );
  },
  getElementsBySelector: function (n, t) {
    var k, p, w, i, d, b, c, a, e, u, l, r, s, f;
    if (typeof document.querySelectorAll == 'function')
      return (t || document).querySelectorAll(n);
    for (k = n.split(','), p = [], w = 0; w < k.length; w++) {
      for (
        i = [t || document],
          d = k[w].replace(/^\s+/, '').replace(/\s+$/, '').split(' '),
          b = 0;
        b < d.length;
        b++
      ) {
        if (
          ((token = d[b].replace(/^\s+/, '').replace(/\s+$/, '')),
          token.indexOf('#') > -1)
        ) {
          var y = token.split('#'),
            o = y[0],
            nt = y[1],
            g = document.getElementById(nt);
          if (o && g.nodeName.toLowerCase() != o) return [];
          i = [g];
          continue;
        }
        if (token.indexOf('.') > -1) {
          var y = token.split('.'),
            o = y[0] || '*',
            tt = y[1],
            u = [],
            l = 0;
          for (r = 0; r < i.length; r++)
            for (
              s =
                o == '*'
                  ? i[r].getElementsByTagName('*')
                  : i[r].getElementsByTagName(o),
                f = 0;
              f < s.length;
              f++
            )
              u[l++] = s[f];
          for (i = [], a = 0, e = 0; e < u.length; e++)
            u[e].className &&
              u[e].className.match(new RegExp('(\\s|^)' + tt + '(\\s|$)')) &&
              (i[a++] = u[e]);
          continue;
        }
        if (token.match(/^(\w*)\[(\w+)([=~\|\^\$\*]?)=?"?([^\]"]*)"?\]$/)) {
          var o = RegExp.$1 || '*',
            h = RegExp.$2,
            it = RegExp.$3,
            v = RegExp.$4;
          for (
            h.toLowerCase() == 'for' &&
              this.browser.msie &&
              this.browser.version < 8 &&
              (h = 'htmlFor'),
              u = [],
              l = 0,
              r = 0;
            r < i.length;
            r++
          )
            for (
              s =
                o == '*'
                  ? i[r].getElementsByTagName('*')
                  : i[r].getElementsByTagName(o),
                f = 0;
              s[f];
              f++
            )
              u[l++] = s[f];
          i = [];
          a = 0;
          switch (it) {
            case '=':
              c = function (n) {
                return n.getAttribute(h) == v;
              };
              break;
            case '~':
              c = function (n) {
                return n
                  .getAttribute(h)
                  .match(new RegExp('(\\s|^)' + v + '(\\s|$)'));
              };
              break;
            case '|':
              c = function (n) {
                return n.getAttribute(h).match(new RegExp('^' + v + '-?'));
              };
              break;
            case '^':
              c = function (n) {
                return n.getAttribute(h).indexOf(v) == 0;
              };
              break;
            case '$':
              c = function (n) {
                return (
                  n.getAttribute(h).lastIndexOf(v) ==
                  n.getAttribute(h).length - v.length
                );
              };
              break;
            case '*':
              c = function (n) {
                return n.getAttribute(h).indexOf(v) > -1;
              };
              break;
            default:
              c = function (n) {
                return n.getAttribute(h);
              };
          }
          for (i = [], a = 0, e = 0; e < u.length; e++)
            c(u[e]) && (i[a++] = u[e]);
          continue;
        }
        for (o = token, u = [], l = 0, r = 0; r < i.length; r++)
          for (s = i[r].getElementsByTagName(o), f = 0; f < s.length; f++)
            u[l++] = s[f];
        i = u;
      }
      p = [].concat(p, i);
    }
    return p;
  },
  scrollSize: (function () {
    function e() {
      n && u();
      t = document.createElement('div');
      n = document.createElement('div');
      n.style.cssText =
        'position:absolute;overflow:hidden;width:100px;height:100px';
      n.appendChild(t);
      document.body.appendChild(n);
    }
    function u() {
      document.body.removeChild(n);
      n = null;
    }
    function f(f) {
      return (
        e(),
        (t.style.cssText = 'height:' + (f ? '100%' : '200px')),
        (r = f ? t.offsetHeight : t.offsetWidth),
        (n.style.overflow = 'scroll'),
        (t.innerHTML = 1),
        (i = f ? t.offsetHeight : t.offsetWidth),
        f && n.clientHeight && (i = n.clientHeight),
        u(),
        r - i
      );
    }
    var t, n, r, i;
    return {
      getWidth: function () {
        return f(!1);
      },
      getHeight: function () {
        return f(!0);
      },
    };
  })(),
  domReady: function (n) {
    function t() {
      i || ((i = !0), n());
    }
    var i = !1;
    if (document.addEventListener)
      document.addEventListener('DOMContentLoaded', t, !1);
    else if (document.attachEvent) {
      if (document.documentElement.doScroll && window == window.top) {
        function r() {
          if (!i && document.body)
            try {
              document.documentElement.doScroll('left');
              t();
            } catch (n) {
              setTimeout(r, 0);
            }
        }
        r();
      }
      document.attachEvent('onreadystatechange', function () {
        document.readyState === 'complete' && t();
      });
    }
    window.addEventListener
      ? window.addEventListener('load', t, !1)
      : window.attachEvent && window.attachEvent('onload', t);
  },
  event: (function () {
    function i(n) {
      if (((n = n || window.event), n.isFixed)) return n;
      if (
        ((n.isFixed = !0),
        (n.preventDefault =
          n.preventDefault ||
          function () {
            this.returnValue = !1;
          }),
        (n.stopPropagation =
          n.stopPropagaton ||
          function () {
            this.cancelBubble = !0;
          }),
        n.target || (n.target = n.srcElement),
        !n.relatedTarget &&
          n.fromElement &&
          (n.relatedTarget =
            n.fromElement == n.target ? n.toElement : n.fromElement),
        n.pageX == null && n.clientX != null)
      ) {
        var t = document.documentElement,
          i = document.body;
        n.pageX =
          n.clientX +
          ((t && t.scrollLeft) || (i && i.scrollLeft) || 0) -
          (t.clientLeft || 0);
        n.pageY =
          n.clientY +
          ((t && t.scrollTop) || (i && i.scrollTop) || 0) -
          (t.clientTop || 0);
      }
      return (
        !n.which &&
          n.button &&
          (n.which =
            n.button & 1 ? 1 : n.button & 2 ? 3 : n.button & 4 ? 2 : 0),
        (n.type === 'DOMMouseScroll' || n.type === 'mousewheel') &&
          ((n.mWheelDelta = 0),
          n.wheelDelta
            ? (n.mWheelDelta = n.wheelDelta / 120)
            : n.detail && (n.mWheelDelta = -n.detail / 3)),
        n
      );
    }
    function r(n, t) {
      var r, u, f, e;
      n = i(n);
      r = this.events[n.type];
      for (u in r)
        (f = r[u]),
          (e = f.call(t || this, n)),
          e === !1 && (n.preventDefault(), n.stopPropagation());
    }
    var t = 0,
      n = {
        add: function (i, u, f, e) {
          i.setInterval && i != window && !i.frameElement && (i = window);
          f.guid || (f.guid = ++t);
          i.events ||
            ((i.events = {}),
            (i.handle = function (n) {
              return r.call(i, n);
            }));
          i.events[u] ||
            ((i.events[u] = {}),
            i.addEventListener
              ? i.addEventListener(u, i.handle, !1)
              : i.attachEvent && i.attachEvent('on' + u, i.handle),
            u === 'mousewheel' && n.add(i, 'DOMMouseScroll', f, e));
          var o = customForm.lib.bind(f, e);
          o.guid = f.guid;
          i.events[u][f.guid] = e ? o : f;
        },
        remove: function (t, i, r) {
          var u = t.events && t.events[i],
            f;
          if (u) {
            delete u[r.guid];
            for (f in u) return;
            t.removeEventListener
              ? t.removeEventListener(i, t.handle, !1)
              : t.detachEvent && t.detachEvent('on' + i, t.handle);
            delete t.events[i];
            for (f in t.events) return;
            try {
              delete t.handle;
              delete t.events;
            } catch (e) {
              t.removeAttribute &&
                (t.removeAttribute('handle'), t.removeAttribute('events'));
            }
            i === 'mousewheel' && n.remove(t, 'DOMMouseScroll', r);
          }
        },
      };
    return n;
  })(),
};
customForm.lib.domReady(function () {
  customForm.initStyles();
});
customForm.addModule({
  name: 'select',
  selector: 'select',
  defaultOptions: {
    showNativeDrop: !1,
    handleDropPosition: !0,
    selectDropPosition: 'bottom',
    wrapperClass: 'select-area',
    focusClass: 'select-focus',
    dropActiveClass: 'select-active',
    selectedClass: 'item-selected',
    currentSelectedClass: 'current-selected',
    disabledClass: 'select-disabled',
    valueSelector: 'span.center',
    optGroupClass: 'optgroup',
    openerSelector: 'a.select-opener',
    selectStructure:
      '<span class="left"></span><span class="center"></span><a class="select-opener"></a>',
    classPrefix: 'select-',
    dropMaxHeight: 200,
    dropFlippedClass: 'select-options-flipped',
    dropHiddenClass: 'options-hidden',
    dropScrollableClass: 'options-overflow',
    dropClass: 'select-options',
    dropClassPrefix: 'drop-',
    dropStructure:
      '<div class="drop-holder"><div class="drop-list"></div></div>',
    dropSelector: 'div.drop-list',
  },
  checkElement: function (n) {
    return !n.size && !n.multiple;
  },
  setupWrapper: function () {
    if (
      (customForm.lib.addClass(this.fakeElement, this.options.wrapperClass),
      this.realElement.parentNode.insertBefore(
        this.fakeElement,
        this.realElement
      ),
      (this.fakeElement.innerHTML = this.options.selectStructure),
      (this.fakeElement.style.width =
        this.realElement.offsetWidth > 0
          ? this.realElement.offsetWidth + 'px'
          : 'auto'),
      customForm.isTouchDevice &&
        customForm.baseOptions.useNativeDropOnMobileDevices &&
        (this.options.showNativeDrop = !0),
      this.options.showNativeDrop &&
        (this.fakeElement.appendChild(this.realElement),
        customForm.lib.removeClass(this.realElement, this.options.hiddenClass),
        customForm.lib.setStyles(this.realElement, {
          top: 0,
          left: 0,
          margin: 0,
          padding: 0,
          opacity: 0,
          border: 'none',
          position: 'absolute',
          width: customForm.lib.getInnerWidth(this.fakeElement) - 1,
          height: customForm.lib.getInnerHeight(this.fakeElement) - 1,
        })),
      (this.opener = customForm.lib.queryBySelector(
        this.options.openerSelector,
        this.fakeElement
      )[0]),
      (this.valueText = customForm.lib.queryBySelector(
        this.options.valueSelector,
        this.fakeElement
      )[0]),
      customForm.lib.disableTextSelection(this.valueText),
      (this.opener.customForm = this),
      this.options.showNativeDrop)
    )
      this.refreshState();
    else {
      this.createDropdown();
      this.refreshState();
      this.onControlReady(this);
      this.hideDropdown(!0);
    }
    this.addEvents();
  },
  addEvents: function () {
    this.options.showNativeDrop
      ? customForm.lib.event.add(this.realElement, 'click', this.onChange, this)
      : customForm.lib.event.add(
          this.fakeElement,
          'click',
          this.toggleDropdown,
          this
        );
    customForm.lib.event.add(this.realElement, 'change', this.onChange, this);
  },
  onFakeClick: function () {},
  onFocus: function () {
    customForm.modules[this.name].superclass.onFocus.apply(this, arguments);
    this.options.showNativeDrop ||
      (customForm.lib.browser.safariMac &&
        this.realElement.setAttribute('size', '2'),
      customForm.lib.event.add(
        this.realElement,
        'keydown',
        this.onKeyDown,
        this
      ),
      customForm.activeControl &&
        customForm.activeControl != this &&
        (customForm.activeControl.hideDropdown(),
        (customForm.activeControl = this)));
  },
  onBlur: function () {
    this.options.showNativeDrop
      ? customForm.modules[this.name].superclass.onBlur.apply(this)
      : (customForm.lib.browser.safariMac &&
          this.realElement.removeAttribute('size'),
        (this.isActiveDrop() && this.isOverDrop()) ||
          (customForm.modules[this.name].superclass.onBlur.apply(this),
          customForm.activeControl === this &&
            (customForm.activeControl = null),
          customForm.isTouchDevice || this.hideDropdown()),
        customForm.lib.event.remove(
          this.realElement,
          'keydown',
          this.onKeyDown
        ));
  },
  onChange: function () {
    this.refreshState();
  },
  onKeyDown: function (n) {
    customForm.tmpFlag = !0;
    setTimeout(function () {
      customForm.tmpFlag = !1;
    }, 100);
    var t = this;
    return (
      (t.keyboardFix = !0),
      setTimeout(function () {
        t.refreshState();
      }, 10),
      n.keyCode == 13 ? (t.toggleDropdown.apply(t), !1) : void 0
    );
  },
  onResizeWindow: function () {
    this.isActiveDrop() && this.hideDropdown();
  },
  onScrollWindow: function () {
    this.isActiveDrop() && this.positionDropdown();
  },
  onOptionClick: function (n) {
    var t =
      n.target && n.target.tagName && n.target.tagName.toLowerCase() == 'li'
        ? n.target
        : customForm.lib.getParent(n.target, 'li');
    return (
      t &&
        ((this.realElement.selectedIndex = parseInt(t.getAttribute('rel'))),
        customForm.isTouchDevice ? this.onFocus() : this.realElement.focus(),
        this.refreshState(),
        this.hideDropdown(),
        customForm.lib.fireEvent(this.realElement, 'change')),
      !1
    );
  },
  onClickOutside: function (n) {
    if (customForm.tmpFlag) {
      customForm.tmpFlag = !1;
      return;
    }
    customForm.lib.isParent(this.fakeElement, n.target) ||
      customForm.lib.isParent(this.selectDrop, n.target) ||
      this.hideDropdown();
  },
  onDropHover: function (n) {
    if (this.keyboardFix) this.keyboardFix = !1;
    else {
      this.hoverFlag = !0;
      var t =
        n.target && n.target.tagName && n.target.tagName.toLowerCase() == 'li'
          ? n.target
          : customForm.lib.getParent(n.target, 'li');
      t &&
        ((this.realElement.selectedIndex = parseInt(t.getAttribute('rel'))),
        this.refreshSelectedClass(parseInt(t.getAttribute('rel'))));
    }
  },
  onDropLeave: function () {
    this.hoverFlag = !1;
  },
  isActiveDrop: function () {
    return !customForm.lib.hasClass(
      this.selectDrop,
      this.options.dropHiddenClass
    );
  },
  isOverDrop: function () {
    return this.hoverFlag;
  },
  createDropdown: function () {
    this.selectDrop && this.selectDrop.parentNode.removeChild(this.selectDrop);
    this.selectDrop = document.createElement('div');
    this.selectDrop.className = this.options.dropClass;
    this.selectDrop.innerHTML = this.options.dropStructure;
    customForm.lib.setStyles(this.selectDrop, { position: 'absolute' });
    this.selectList = customForm.lib.queryBySelector(
      this.options.dropSelector,
      this.selectDrop
    )[0];
    customForm.lib.addClass(this.selectDrop, this.options.dropHiddenClass);
    document.body.appendChild(this.selectDrop);
    this.selectDrop.customForm = this;
    customForm.lib.event.add(
      this.selectDrop,
      'click',
      this.onOptionClick,
      this
    );
    customForm.lib.event.add(
      this.selectDrop,
      'mouseover',
      this.onDropHover,
      this
    );
    customForm.lib.event.add(
      this.selectDrop,
      'mouseout',
      this.onDropLeave,
      this
    );
    this.buildDropdown();
  },
  buildDropdown: function () {
    this.buildDropdownOptions();
    this.positionDropdown();
    this.buildDropdownScroll();
  },
  buildDropdownOptions: function () {
    this.resStructure = '';
    this.optNum = 0;
    for (var n = 0; n < this.realElement.children.length; n++)
      this.resStructure +=
        this.buildElement(this.realElement.children[n]) + '\n';
    this.selectList.innerHTML = this.resStructure;
  },
  buildDropdownScroll: function () {
    this.options.dropMaxHeight &&
      this.selectDrop.offsetHeight > this.options.dropMaxHeight &&
      ((this.selectList.style.height = this.options.dropMaxHeight + 'px'),
      (this.selectList.style.overflow = 'auto'),
      (this.selectList.style.overflowX = 'hidden'),
      customForm.lib.addClass(
        this.selectDrop,
        this.options.dropScrollableClass
      ));
    customForm.lib.addClass(
      this.selectDrop,
      customForm.lib.getAllClasses(
        this.realElement.className,
        this.options.dropClassPrefix,
        customForm.baseOptions.hiddenClass
      )
    );
  },
  parseOptionTitle: function (n) {
    return typeof n == 'string' && /\.(jpg|gif|png|bmp|jpeg)(.*)?$/i.test(n)
      ? n
      : '';
  },
  buildElement: function (n) {
    var t = '',
      r,
      i;
    if (n.tagName.toLowerCase() == 'option')
      return (
        (customForm.lib.prevSibling(n) &&
          customForm.lib.prevSibling(n).tagName.toLowerCase() == 'option') ||
          (t += '<ul>'),
        (r = this.parseOptionTitle(n.title)),
        (t +=
          '<li rel="' +
          this.optNum++ +
          '" class="' +
          (n.className ? n.className + ' ' : '') +
          'customFormcalc"><a href="#">' +
          (r ? '<img src="' + r + '" alt="" />' : '') +
          '<span>' +
          n.innerHTML +
          '</span></a></li>'),
        (customForm.lib.nextSibling(n) &&
          customForm.lib.nextSibling(n).tagName.toLowerCase() == 'option') ||
          (t += '</ul>'),
        t
      );
    if (n.tagName.toLowerCase() == 'optgroup' && n.label) {
      for (
        t += '<div class="' + this.options.optGroupClass + '">',
          t +=
            '<strong class="customFormcalc"><em>' + n.label + '</em></strong>',
          i = 0;
        i < n.children.length;
        i++
      )
        t += this.buildElement(n.children[i]);
      return t + '</div>';
    }
  },
  positionDropdown: function () {
    var n = customForm.lib.getOffset(this.fakeElement),
      i = this.fakeElement.offsetHeight,
      t = this.selectDrop.offsetHeight,
      r =
        n.top - t >= customForm.lib.getScrollTop() &&
        customForm.lib.getScrollTop() + customForm.lib.getWindowHeight() <
          n.top + i + t;
    (this.options.handleDropPosition && r) ||
    this.options.selectDropPosition === 'top'
      ? ((this.selectDrop.style.top = n.top - t + 'px'),
        customForm.lib.addClass(this.selectDrop, this.options.dropFlippedClass))
      : ((this.selectDrop.style.top = n.top + i + 'px'),
        customForm.lib.removeClass(
          this.selectDrop,
          this.options.dropFlippedClass
        ));
    this.selectDrop.style.left = n.left + 'px';
    this.selectDrop.style.width = this.fakeElement.offsetWidth + 'px';
  },
  showDropdown: function () {
    document.body.appendChild(this.selectDrop);
    customForm.lib.removeClass(this.selectDrop, this.options.dropHiddenClass);
    customForm.lib.addClass(this.fakeElement, this.options.dropActiveClass);
    this.positionDropdown();
    var n = this.getFakeActiveOption();
    this.removeClassFromItems(this.options.currentSelectedClass);
    customForm.lib.addClass(n, this.options.currentSelectedClass);
    customForm.lib.event.add(window, 'resize', this.onResizeWindow, this);
    customForm.lib.event.add(window, 'scroll', this.onScrollWindow, this);
    customForm.lib.event.add(
      document,
      customForm.eventPress,
      this.onClickOutside,
      this
    );
    this.positionDropdown();
  },
  hideDropdown: function (n) {
    (this.selectDrop.parentNode &&
      (this.selectDrop.offsetWidth &&
        this.selectDrop.parentNode.removeChild(this.selectDrop),
      n)) ||
      (typeof this.origSelectedIndex == 'number' &&
        (this.realElement.selectedIndex = this.origSelectedIndex),
      customForm.lib.removeClass(
        this.fakeElement,
        this.options.dropActiveClass
      ),
      customForm.lib.addClass(this.selectDrop, this.options.dropHiddenClass),
      customForm.lib.event.remove(window, 'resize', this.onResizeWindow),
      customForm.lib.event.remove(window, 'scroll', this.onScrollWindow),
      customForm.lib.event.remove(
        document.documentElement,
        customForm.eventPress,
        this.onClickOutside
      ),
      customForm.isTouchDevice && this.onBlur());
  },
  toggleDropdown: function () {
    this.realElement.disabled ||
      (customForm.isTouchDevice ? this.onFocus() : this.realElement.focus(),
      (this.dropOpened = !0),
      this.isActiveDrop() ? this.hideDropdown() : this.showDropdown(),
      this.refreshState());
  },
  scrollToItem: function () {
    if (this.isActiveDrop()) {
      var t = this.selectList.offsetHeight,
        n = this.calcOptionOffset(this.getFakeActiveOption()),
        i = this.selectList.scrollTop,
        r = this.getFakeActiveOption().offsetHeight;
      n >= i + t
        ? (this.selectList.scrollTop = n - t + r)
        : n < i && (this.selectList.scrollTop = n);
    }
  },
  getFakeActiveOption: function (n) {
    return customForm.lib.queryBySelector(
      'li[rel="' +
        (typeof n == 'number' ? n : this.realElement.selectedIndex) +
        '"]',
      this.selectList
    )[0];
  },
  calcOptionOffset: function (n) {
    for (
      var r = 0,
        i = customForm.lib.queryBySelector('.customFormcalc', this.selectList),
        t = 0;
      t < i.length;
      t++
    ) {
      if (i[t] == n) break;
      r += i[t].offsetHeight;
    }
    return r;
  },
  childrenHasItem: function (n, t) {
    var r = n.getElementsByTagName('*');
    for (i = 0; i < r.length; i++) if (r[i] == t) return !0;
    return !1;
  },
  removeClassFromItems: function (n) {
    for (
      var i = customForm.lib.queryBySelector('li', this.selectList),
        t = i.length - 1;
      t >= 0;
      t--
    )
      customForm.lib.removeClass(i[t], n);
  },
  setSelectedClass: function (n) {
    customForm.lib.addClass(
      this.getFakeActiveOption(n),
      this.options.selectedClass
    );
  },
  refreshSelectedClass: function (n) {
    this.options.showNativeDrop ||
      (this.removeClassFromItems(this.options.selectedClass),
      this.setSelectedClass(n));
    this.realElement.disabled
      ? (customForm.lib.addClass(this.fakeElement, this.options.disabledClass),
        this.labelFor &&
          customForm.lib.addClass(
            this.labelFor,
            this.options.labelDisabledClass
          ))
      : (customForm.lib.removeClass(
          this.fakeElement,
          this.options.disabledClass
        ),
        this.labelFor &&
          customForm.lib.removeClass(
            this.labelFor,
            this.options.labelDisabledClass
          ));
  },
  refreshSelectedText: function () {
    if (!this.dropOpened && this.realElement.title)
      this.valueText.innerHTML = this.realElement.title;
    else if (this.realElement.options[this.realElement.selectedIndex].title) {
      var n = this.parseOptionTitle(
        this.realElement.options[this.realElement.selectedIndex].title
      );
      this.valueText.innerHTML =
        (n ? '<img src="' + n + '" alt="" />' : '') +
        this.realElement.options[this.realElement.selectedIndex].innerHTML;
    } else
      this.valueText.innerHTML = this.realElement.options[
        this.realElement.selectedIndex
      ].innerHTML;
  },
  refreshState: function () {
    this.origSelectedIndex = this.realElement.selectedIndex;
    this.refreshSelectedClass();
    this.refreshSelectedText();
    this.options.showNativeDrop ||
      (this.positionDropdown(),
      this.selectDrop.offsetWidth && this.scrollToItem());
  },
});
customForm.addModule({
  name: 'checkbox',
  selector: 'input[type="checkbox"]',
  defaultOptions: {
    wrapperClass: 'chk-area',
    focusClass: 'chk-focus',
    checkedClass: 'chk-checked',
    labelActiveClass: 'chk-label-active',
    uncheckedClass: 'chk-unchecked',
    disabledClass: 'chk-disabled',
    chkStructure: '<span></span>',
  },
  setupWrapper: function () {
    customForm.lib.addClass(this.fakeElement, this.options.wrapperClass);
    this.fakeElement.innerHTML = this.options.chkStructure;
    this.realElement.parentNode.insertBefore(
      this.fakeElement,
      this.realElement
    );
    customForm.lib.event.add(this.realElement, 'click', this.onRealClick, this);
    this.refreshState();
  },
  onFakePressed: function () {
    customForm.modules[this.name].superclass.onFakePressed.apply(
      this,
      arguments
    );
    this.realElement.disabled || this.realElement.focus();
  },
  onFakeClick: function () {
    return (
      customForm.modules[this.name].superclass.onFakeClick.apply(
        this,
        arguments
      ),
      (this.tmpTimer = setTimeout(
        customForm.lib.bind(function () {
          this.toggle();
        }, this),
        10
      )),
      !1
    );
  },
  onRealClick: function (n) {
    setTimeout(
      customForm.lib.bind(function () {
        this.refreshState();
      }, this),
      10
    );
    n.stopPropagation();
  },
  toggle: function () {
    return (
      this.realElement.disabled ||
        ((this.realElement.checked = this.realElement.checked ? !1 : !0),
        customForm.lib.fireEvent(this.realElement, 'change')),
      this.refreshState(),
      !1
    );
  },
  refreshState: function () {
    this.realElement.checked
      ? (customForm.lib.addClass(this.fakeElement, this.options.checkedClass),
        customForm.lib.removeClass(
          this.fakeElement,
          this.options.uncheckedClass
        ),
        this.labelFor &&
          customForm.lib.addClass(this.labelFor, this.options.labelActiveClass))
      : (customForm.lib.removeClass(
          this.fakeElement,
          this.options.checkedClass
        ),
        customForm.lib.addClass(this.fakeElement, this.options.uncheckedClass),
        this.labelFor &&
          customForm.lib.removeClass(
            this.labelFor,
            this.options.labelActiveClass
          ));
    this.realElement.disabled
      ? (customForm.lib.addClass(this.fakeElement, this.options.disabledClass),
        this.labelFor &&
          customForm.lib.addClass(
            this.labelFor,
            this.options.labelDisabledClass
          ))
      : (customForm.lib.removeClass(
          this.fakeElement,
          this.options.disabledClass
        ),
        this.labelFor &&
          customForm.lib.removeClass(
            this.labelFor,
            this.options.labelDisabledClass
          ));
  },
});
customForm.addModule({
  name: 'radio',
  selector: 'input[type="radio"]',
  defaultOptions: {
    wrapperClass: 'rad-area',
    focusClass: 'rad-focus',
    checkedClass: 'rad-checked',
    uncheckedClass: 'rad-unchecked',
    disabledClass: 'rad-disabled',
    radStructure: '<span></span>',
  },
  getRadioGroup: function (n) {
    var t = n.getAttribute('name');
    return t
      ? customForm.lib.queryBySelector(
          'input[name="' + t + '"]',
          customForm.lib.getParent('form')
        )
      : [n];
  },
  setupWrapper: function () {
    customForm.lib.addClass(this.fakeElement, this.options.wrapperClass);
    this.fakeElement.innerHTML = this.options.radStructure;
    this.realElement.parentNode.insertBefore(
      this.fakeElement,
      this.realElement
    );
    this.refreshState();
    this.addEvents();
  },
  addEvents: function () {
    customForm.lib.event.add(this.fakeElement, 'click', this.toggleRadio, this);
    this.labelFor &&
      customForm.lib.event.add(this.labelFor, 'click', this.toggleRadio, this);
  },
  onFocus: function () {
    customForm.modules[this.name].superclass.onFocus.apply(this, arguments);
    setTimeout(
      customForm.lib.bind(function () {
        this.refreshState();
      }, this),
      10
    );
  },
  toggleRadio: function () {
    this.realElement.disabled || (this.realElement.checked = !0);
    this.refreshState();
    customForm.lib.fireEvent(this.realElement, 'change');
  },
  refreshState: function () {
    for (
      var i = this.getRadioGroup(this.realElement), n, t = 0;
      t < i.length;
      t++
    )
      (n = i[t].customForm),
        n &&
          (n.realElement.checked
            ? (customForm.lib.addClass(n.fakeElement, n.options.checkedClass),
              customForm.lib.removeClass(
                n.fakeElement,
                n.options.uncheckedClass
              ),
              n.labelFor &&
                customForm.lib.addClass(n.labelFor, n.options.labelActiveClass))
            : (customForm.lib.removeClass(
                n.fakeElement,
                n.options.checkedClass
              ),
              customForm.lib.addClass(n.fakeElement, n.options.uncheckedClass),
              n.labelFor &&
                customForm.lib.removeClass(
                  n.labelFor,
                  n.options.labelActiveClass
                )),
          n.realElement.disabled
            ? (customForm.lib.addClass(n.fakeElement, n.options.disabledClass),
              n.labelFor &&
                customForm.lib.addClass(
                  n.labelFor,
                  n.options.labelDisabledClass
                ))
            : (customForm.lib.removeClass(
                n.fakeElement,
                n.options.disabledClass
              ),
              n.labelFor &&
                customForm.lib.removeClass(
                  n.labelFor,
                  n.options.labelDisabledClass
                )));
  },
});
!(function (n) {
  function o() {
    n[i].glbl ||
      ((f = {
        $wndw: n(window),
        $docu: n(document),
        $html: n('html'),
        $body: n('body'),
      }),
      (t = {}),
      (u = {}),
      (r = {}),
      n.each([t, u, r], function (n, t) {
        t.add = function (n) {
          n = n.split(' ');
          for (var i = 0, r = n.length; r > i; i++) t[n[i]] = t.mm(n[i]);
        };
      }),
      (t.mm = function (n) {
        return 'mm-' + n;
      }),
      t.add(
        'wrapper menu panels panel nopanel current highest opened subopened navbar hasnavbar title btn prev next listview nolistview inset vertical selected divider spacer hidden fullsubopen'
      ),
      (t.umm = function (n) {
        return 'mm-' == n.slice(0, 3) && (n = n.slice(3)), n;
      }),
      (u.mm = function (n) {
        return 'mm-' + n;
      }),
      u.add('parent sub'),
      (r.mm = function (n) {
        return n + '.mm';
      }),
      r.add(
        'transitionend webkitTransitionEnd click scroll keydown mousedown mouseup touchstart touchmove touchend orientationchange'
      ),
      (n[i]._c = t),
      (n[i]._d = u),
      (n[i]._e = r),
      (n[i].glbl = f));
  }
  var i = 'mmenu',
    e = '5.6.4',
    t,
    u,
    r,
    f;
  (n[i] && n[i].version > e) ||
    ((n[i] = function (n, t, i) {
      this.$menu = n;
      this._api = [
        'bind',
        'init',
        'update',
        'setSelected',
        'getInstance',
        'openPanel',
        'closePanel',
        'closeAllPanels',
      ];
      this.opts = t;
      this.conf = i;
      this.vars = {};
      this.cbck = {};
      'function' == typeof this.___deprecated && this.___deprecated();
      this._initMenu();
      this._initAnchors();
      var r = this.$pnls.children();
      return (
        this._initAddons(),
        this.init(r),
        'function' == typeof this.___debug && this.___debug(),
        this
      );
    }),
    (n[i].version = e),
    (n[i].addons = {}),
    (n[i].uniqueId = 0),
    (n[i].defaults = {
      extensions: [],
      navbar: { add: !0, title: 'Menu', titleLink: 'panel' },
      onClick: { setSelected: !0 },
      slidingSubmenus: !0,
    }),
    (n[i].configuration = {
      classNames: {
        divider: 'Divider',
        inset: 'Inset',
        panel: 'Panel',
        selected: 'Selected',
        spacer: 'Spacer',
        vertical: 'Vertical',
      },
      clone: !1,
      openingInterval: 25,
      panelNodetype: 'ul, ol, div',
      transitionDuration: 400,
    }),
    (n[i].prototype = {
      init: function (n) {
        n = n.not('.' + t.nopanel);
        n = this._initPanels(n);
        this.trigger('init', n);
        this.trigger('update');
      },
      update: function () {
        this.trigger('update');
      },
      setSelected: function (n) {
        this.$menu
          .find('.' + t.listview)
          .children()
          .removeClass(t.selected);
        n.addClass(t.selected);
        this.trigger('setSelected', n);
      },
      openPanel: function (r) {
        var e = r.parent(),
          u = this,
          o,
          s,
          f;
        if (e.hasClass(t.vertical)) {
          if (((o = e.parents('.' + t.subopened)), o.length))
            return void this.openPanel(o.first());
          e.addClass(t.opened);
          this.trigger('openPanel', r);
          this.trigger('openingPanel', r);
          this.trigger('openedPanel', r);
        } else {
          if (r.hasClass(t.current)) return;
          s = this.$pnls.children('.' + t.panel);
          f = s.filter('.' + t.current);
          s.removeClass(t.highest)
            .removeClass(t.current)
            .not(r)
            .not(f)
            .not('.' + t.vertical)
            .addClass(t.hidden);
          n[i].support.csstransitions || f.addClass(t.hidden);
          r.hasClass(t.opened)
            ? r
                .nextAll('.' + t.opened)
                .addClass(t.highest)
                .removeClass(t.opened)
                .removeClass(t.subopened)
            : (r.addClass(t.highest), f.addClass(t.subopened));
          r.removeClass(t.hidden).addClass(t.current);
          u.trigger('openPanel', r);
          setTimeout(function () {
            r.removeClass(t.subopened).addClass(t.opened);
            u.trigger('openingPanel', r);
            u.__transitionend(
              r,
              function () {
                u.trigger('openedPanel', r);
              },
              u.conf.transitionDuration
            );
          }, this.conf.openingInterval);
        }
      },
      closePanel: function (n) {
        var i = n.parent();
        i.hasClass(t.vertical) &&
          (i.removeClass(t.opened),
          this.trigger('closePanel', n),
          this.trigger('closingPanel', n),
          this.trigger('closedPanel', n));
      },
      closeAllPanels: function () {
        this.$menu
          .find('.' + t.listview)
          .children()
          .removeClass(t.selected)
          .filter('.' + t.vertical)
          .removeClass(t.opened);
        var i = this.$pnls.children('.' + t.panel),
          n = i.first();
        this.$pnls
          .children('.' + t.panel)
          .not(n)
          .removeClass(t.subopened)
          .removeClass(t.opened)
          .removeClass(t.current)
          .removeClass(t.highest)
          .addClass(t.hidden);
        this.openPanel(n);
      },
      togglePanel: function (n) {
        var i = n.parent();
        i.hasClass(t.vertical) &&
          this[i.hasClass(t.opened) ? 'closePanel' : 'openPanel'](n);
      },
      getInstance: function () {
        return this;
      },
      bind: function (n, t) {
        this.cbck[n] = this.cbck[n] || [];
        this.cbck[n].push(t);
      },
      trigger: function () {
        var u = this,
          i = Array.prototype.slice.call(arguments),
          t = i.shift(),
          n,
          r;
        if (this.cbck[t])
          for (n = 0, r = this.cbck[t].length; r > n; n++)
            this.cbck[t][n].apply(u, i);
      },
      _initMenu: function () {
        this.$menu.attr('id', this.$menu.attr('id') || this.__getUniqueId());
        this.conf.clone &&
          ((this.$menu = this.$menu.clone(!0)),
          this.$menu
            .add(this.$menu.find('[id]'))
            .filter('[id]')
            .each(function () {
              n(this).attr('id', t.mm(n(this).attr('id')));
            }));
        this.$menu.contents().each(function () {
          3 == n(this)[0].nodeType && n(this).remove();
        });
        this.$pnls = n('<div class="' + t.panels + '" />')
          .append(this.$menu.children(this.conf.panelNodetype))
          .prependTo(this.$menu);
        this.$menu.parent().addClass(t.wrapper);
        var i = [t.menu];
        this.opts.slidingSubmenus || i.push(t.vertical);
        this.opts.extensions = this.opts.extensions.length
          ? 'mm-' + this.opts.extensions.join(' mm-')
          : '';
        this.opts.extensions && i.push(this.opts.extensions);
        this.$menu.addClass(i.join(' '));
      },
      _initPanels: function (i) {
        var r = this,
          c = this.__findAddBack(i, 'ul, ol'),
          o,
          f,
          s,
          l,
          h,
          e;
        return (
          this.__refactorClass(c, this.conf.classNames.inset, 'inset').addClass(
            t.nolistview + ' ' + t.nopanel
          ),
          c.not('.' + t.nolistview).addClass(t.listview),
          (o = this.__findAddBack(i, '.' + t.listview).children()),
          this.__refactorClass(o, this.conf.classNames.selected, 'selected'),
          this.__refactorClass(o, this.conf.classNames.divider, 'divider'),
          this.__refactorClass(o, this.conf.classNames.spacer, 'spacer'),
          this.__refactorClass(
            this.__findAddBack(i, '.' + this.conf.classNames.panel),
            this.conf.classNames.panel,
            'panel'
          ),
          (f = n()),
          (s = i
            .add(i.find('.' + t.panel))
            .add(
              this.__findAddBack(i, '.' + t.listview)
                .children()
                .children(this.conf.panelNodetype)
            )
            .not('.' + t.nopanel)),
          this.__refactorClass(s, this.conf.classNames.vertical, 'vertical'),
          this.opts.slidingSubmenus || s.addClass(t.vertical),
          s.each(function () {
            var i = n(this),
              u = i,
              e;
            i.is('ul, ol')
              ? (i.wrap('<div class="' + t.panel + '" />'), (u = i.parent()))
              : u.addClass(t.panel);
            e = i.attr('id');
            i.removeAttr('id');
            u.attr('id', e || r.__getUniqueId());
            i.hasClass(t.vertical) &&
              (i.removeClass(r.conf.classNames.vertical),
              u.add(u.parent()).addClass(t.vertical));
            f = f.add(u);
          }),
          (l = n('.' + t.panel, this.$menu)),
          f.each(function () {
            var e,
              c,
              f = n(this),
              i = f.parent(),
              o = i.children('a, span').first(),
              s,
              h;
            if (
              (i.is('.' + t.panels) || (i.data(u.sub, f), f.data(u.parent, i)),
              i.children('.' + t.next).length ||
                (i.parent().is('.' + t.listview) &&
                  ((e = f.attr('id')),
                  (c = n(
                    '<a class="' +
                      t.next +
                      '" href="#' +
                      e +
                      '" data-target="#' +
                      e +
                      '" />'
                  ).insertBefore(o)),
                  o.is('span') && c.addClass(t.fullsubopen))),
              !f.children('.' + t.navbar).length && !i.hasClass(t.vertical))
            )
              if (
                (i.parent().is('.' + t.listview)
                  ? (i = i.closest('.' + t.panel))
                  : ((o = i
                      .closest('.' + t.panel)
                      .find('a[href="#' + f.attr('id') + '"]')
                      .first()),
                    (i = o.closest('.' + t.panel))),
                (s = !1),
                (h = n('<div class="' + t.navbar + '" />')),
                i.length)
              ) {
                switch (((e = i.attr('id')), r.opts.navbar.titleLink)) {
                  case 'anchor':
                    s = o.attr('href');
                    break;
                  case 'panel':
                  case 'parent':
                    s = '#' + e;
                    break;
                  default:
                    s = !1;
                }
                h.append(
                  '<a class="' +
                    t.btn +
                    ' ' +
                    t.prev +
                    '" href="#' +
                    e +
                    '" data-target="#' +
                    e +
                    '" />'
                )
                  .append(
                    n(
                      '<a class="' +
                        t.title +
                        '"' +
                        (s ? ' href="' + s + '"' : '') +
                        ' />'
                    ).text(o.text())
                  )
                  .prependTo(f);
                r.opts.navbar.add && f.addClass(t.hasnavbar);
              } else
                r.opts.navbar.title &&
                  (h
                    .append(
                      '<a class="' +
                        t.title +
                        '">' +
                        r.opts.navbar.title +
                        '</a>'
                    )
                    .prependTo(f),
                  r.opts.navbar.add && f.addClass(t.hasnavbar));
          }),
          (h = this.__findAddBack(i, '.' + t.listview)
            .children('.' + t.selected)
            .removeClass(t.selected)
            .last()
            .addClass(t.selected)),
          h
            .add(h.parentsUntil('.' + t.menu, 'li'))
            .filter('.' + t.vertical)
            .addClass(t.opened)
            .end()
            .each(function () {
              n(this)
                .parentsUntil('.' + t.menu, '.' + t.panel)
                .not('.' + t.vertical)
                .first()
                .addClass(t.opened)
                .parentsUntil('.' + t.menu, '.' + t.panel)
                .not('.' + t.vertical)
                .first()
                .addClass(t.opened)
                .addClass(t.subopened);
            }),
          h
            .children('.' + t.panel)
            .not('.' + t.vertical)
            .addClass(t.opened)
            .parentsUntil('.' + t.menu, '.' + t.panel)
            .not('.' + t.vertical)
            .first()
            .addClass(t.opened)
            .addClass(t.subopened),
          (e = l.filter('.' + t.opened)),
          e.length || (e = f.first()),
          e.addClass(t.opened).last().addClass(t.current),
          f
            .not('.' + t.vertical)
            .not(e.last())
            .addClass(t.hidden)
            .end()
            .filter(function () {
              return !n(this).parent().hasClass(t.panels);
            })
            .appendTo(this.$pnls),
          f
        );
      },
      _initAnchors: function () {
        var u = this;
        f.$body.on(r.click + '-oncanvas', 'a[href]', function (r) {
          var f = n(this),
            e = !1,
            s = u.$menu.find(f).length,
            l,
            o,
            h,
            c;
          for (l in n[i].addons)
            if (n[i].addons[l].clickAnchor.call(u, f, s)) {
              e = !0;
              break;
            }
          if (
            ((o = f.attr('href')),
            !e && s && o.length > 1 && '#' == o.slice(0, 1))
          )
            try {
              h = n(o, u.$menu);
              h.is('.' + t.panel) &&
                ((e = !0),
                u[
                  f.parent().hasClass(t.vertical) ? 'togglePanel' : 'openPanel'
                ](h));
            } catch (a) {}
          (e && r.preventDefault(),
          e ||
            !s ||
            !f.is('.' + t.listview + ' > li > a') ||
            f.is('[rel="external"]') ||
            f.is('[target="_blank"]')) ||
            (u.__valueOrFn(u.opts.onClick.setSelected, f) &&
              u.setSelected(n(r.target).parent()),
            (c = u.__valueOrFn(
              u.opts.onClick.preventDefault,
              f,
              '#' == o.slice(0, 1)
            )),
            c && r.preventDefault(),
            u.__valueOrFn(u.opts.onClick.close, f, c) && u.close());
        });
      },
      _initAddons: function () {
        var t;
        for (t in n[i].addons)
          n[i].addons[t].add.call(this), (n[i].addons[t].add = function () {});
        for (t in n[i].addons) n[i].addons[t].setup.call(this);
      },
      _getOriginalMenuId: function () {
        var n = this.$menu.attr('id');
        return n && n.length && this.conf.clone && (n = t.umm(n)), n;
      },
      __api: function () {
        var i = this,
          t = {};
        return (
          n.each(this._api, function () {
            var n = this;
            t[n] = function () {
              var r = i[n].apply(i, arguments);
              return 'undefined' == typeof r ? t : r;
            };
          }),
          t
        );
      },
      __valueOrFn: function (n, t, i) {
        return 'function' == typeof n
          ? n.call(t[0])
          : 'undefined' == typeof n && 'undefined' != typeof i
          ? i
          : n;
      },
      __refactorClass: function (n, i, r) {
        return n
          .filter('.' + i)
          .removeClass(i)
          .addClass(t[r]);
      },
      __findAddBack: function (n, t) {
        return n.find(t).add(n.filter(t));
      },
      __filterListItems: function (n) {
        return n.not('.' + t.divider).not('.' + t.hidden);
      },
      __transitionend: function (n, t, i) {
        var f = !1,
          u = function () {
            f || t.call(n[0]);
            f = !0;
          };
        n.one(r.transitionend, u);
        n.one(r.webkitTransitionEnd, u);
        setTimeout(u, 1.1 * i);
      },
      __getUniqueId: function () {
        return t.mm(n[i].uniqueId++);
      },
    }),
    (n.fn[i] = function (t, r) {
      return (
        o(),
        (t = n.extend(!0, {}, n[i].defaults, t)),
        (r = n.extend(!0, {}, n[i].configuration, r)),
        this.each(function () {
          var f = n(this),
            u;
          f.data(i) || ((u = new n[i](f, t, r)), u.$menu.data(i, u.__api()));
        })
      );
    }),
    (n[i].support = {
      touch: 'ontouchstart' in window || navigator.msMaxTouchPoints || !1,
      csstransitions: (function () {
        var i, t;
        if (
          'undefined' != typeof Modernizr &&
          'undefined' != typeof Modernizr.csstransitions
        )
          return Modernizr.csstransitions;
        var u = document.body || document.documentElement,
          r = u.style,
          n = 'transition';
        if ('string' == typeof r[n]) return !0;
        for (
          i = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'],
            n = n.charAt(0).toUpperCase() + n.substr(1),
            t = 0;
          t < i.length;
          t++
        )
          if ('string' == typeof r[i[t] + n]) return !0;
        return !1;
      })(),
    }));
})(jQuery),
  (function (n) {
    var u = 'mmenu',
      r = 'offCanvas',
      i,
      e,
      f,
      t;
    n[u].addons[r] = {
      setup: function () {
        var f, e, o, s, h;
        this.opts[r] &&
          ((f = this.opts[r]),
          (e = this.conf[r]),
          (t = n[u].glbl),
          (this._api = n.merge(this._api, ['open', 'close', 'setPage'])),
          ('top' == f.position || 'bottom' == f.position) &&
            (f.zposition = 'front'),
          'string' != typeof e.pageSelector &&
            (e.pageSelector = '> ' + e.pageNodetype),
          (t.$allMenus = (t.$allMenus || n()).add(this.$menu)),
          (this.vars.opened = !1),
          (o = [i.offcanvas]),
          'left' != f.position && o.push(i.mm(f.position)),
          'back' != f.zposition && o.push(i.mm(f.zposition)),
          this.$menu.addClass(o.join(' ')).parent().removeClass(i.wrapper),
          this.setPage(t.$page),
          this._initBlocker(),
          this['_initWindow_' + r](),
          this.$menu[e.menuInjectMethod + 'To'](e.menuWrapperSelector),
          (s = window.location.hash),
          s &&
            ((h = this._getOriginalMenuId()),
            h && h == s.slice(1) && this.open()));
      },
      add: function () {
        i = n[u]._c;
        e = n[u]._d;
        f = n[u]._e;
        i.add(
          'offcanvas slideout blocking modal background opening blocker page'
        );
        e.add('style');
        f.add('resize');
      },
      clickAnchor: function (n) {
        if (!this.opts[r]) return !1;
        var i = this._getOriginalMenuId();
        return i && n.is('[href="#' + i + '"]')
          ? (this.open(), !0)
          : t.$page
          ? ((i = t.$page.first().attr('id')),
            i && n.is('[href="#' + i + '"]') ? (this.close(), !0) : !1)
          : void 0;
      },
    };
    n[u].defaults[r] = {
      position: 'left',
      zposition: 'back',
      blockUI: !0,
      moveBackground: !0,
    };
    n[u].configuration[r] = {
      pageNodetype: 'div',
      pageSelector: null,
      noPageSelector: [],
      wrapPageIfNeeded: !0,
      menuWrapperSelector: 'body',
      menuInjectMethod: 'prepend',
    };
    n[u].prototype.open = function () {
      if (!this.vars.opened) {
        var n = this;
        this._openSetup();
        setTimeout(function () {
          n._openFinish();
        }, this.conf.openingInterval);
        this.trigger('open');
      }
    };
    n[u].prototype._openSetup = function () {
      var s = this,
        o = this.opts[r],
        u;
      this.closeAllOthers();
      t.$page.each(function () {
        n(this).data(e.style, n(this).attr('style') || '');
      });
      t.$wndw.trigger(f.resize + '-' + r, [!0]);
      u = [i.opened];
      o.blockUI && u.push(i.blocking);
      'modal' == o.blockUI && u.push(i.modal);
      o.moveBackground && u.push(i.background);
      'left' != o.position && u.push(i.mm(this.opts[r].position));
      'back' != o.zposition && u.push(i.mm(this.opts[r].zposition));
      this.opts.extensions && u.push(this.opts.extensions);
      t.$html.addClass(u.join(' '));
      setTimeout(function () {
        s.vars.opened = !0;
      }, this.conf.openingInterval);
      this.$menu.addClass(i.current + ' ' + i.opened);
    };
    n[u].prototype._openFinish = function () {
      var n = this;
      this.__transitionend(
        t.$page.first(),
        function () {
          n.trigger('opened');
        },
        this.conf.transitionDuration
      );
      t.$html.addClass(i.opening);
      this.trigger('opening');
    };
    n[u].prototype.close = function () {
      if (this.vars.opened) {
        var u = this;
        this.__transitionend(
          t.$page.first(),
          function () {
            u.$menu.removeClass(i.current).removeClass(i.opened);
            t.$html
              .removeClass(i.opened)
              .removeClass(i.blocking)
              .removeClass(i.modal)
              .removeClass(i.background)
              .removeClass(i.mm(u.opts[r].position))
              .removeClass(i.mm(u.opts[r].zposition));
            u.opts.extensions && t.$html.removeClass(u.opts.extensions);
            t.$page.each(function () {
              n(this).attr('style', n(this).data(e.style));
            });
            u.vars.opened = !1;
            u.trigger('closed');
          },
          this.conf.transitionDuration
        );
        t.$html.removeClass(i.opening);
        this.trigger('close');
        this.trigger('closing');
      }
    };
    n[u].prototype.closeAllOthers = function () {
      t.$allMenus.not(this.$menu).each(function () {
        var t = n(this).data(u);
        t && t.close && t.close();
      });
    };
    n[u].prototype.setPage = function (u) {
      var e = this,
        f = this.conf[r];
      (u && u.length) ||
        ((u = t.$body.find(f.pageSelector)),
        f.noPageSelector.length && (u = u.not(f.noPageSelector.join(', '))),
        u.length > 1 &&
          f.wrapPageIfNeeded &&
          (u = u.wrapAll('<' + this.conf[r].pageNodetype + ' />').parent()));
      u.each(function () {
        n(this).attr('id', n(this).attr('id') || e.__getUniqueId());
      });
      u.addClass(i.page + ' ' + i.slideout);
      t.$page = u;
      this.trigger('setPage', u);
    };
    n[u].prototype['_initWindow_' + r] = function () {
      t.$wndw.off(f.keydown + '-' + r).on(f.keydown + '-' + r, function (n) {
        if (t.$html.hasClass(i.opened) && 9 == n.keyCode)
          return n.preventDefault(), !1;
      });
      var n = 0;
      t.$wndw.off(f.resize + '-' + r).on(f.resize + '-' + r, function (r, u) {
        if (1 == t.$page.length && (u || t.$html.hasClass(i.opened))) {
          var f = t.$wndw.height();
          (u || f != n) && ((n = f), t.$page.css('minHeight', f));
        }
      });
    };
    n[u].prototype._initBlocker = function () {
      var u = this;
      this.opts[r].blockUI &&
        (t.$blck ||
          (t.$blck = n(
            '<div id="' + i.blocker + '" class="' + i.slideout + '" />'
          )),
        t.$blck
          .appendTo(t.$body)
          .off(f.touchstart + '-' + r + ' ' + f.touchmove + '-' + r)
          .on(
            f.touchstart + '-' + r + ' ' + f.touchmove + '-' + r,
            function (n) {
              n.preventDefault();
              n.stopPropagation();
              t.$blck.trigger(f.mousedown + '-' + r);
            }
          )
          .off(f.mousedown + '-' + r)
          .on(f.mousedown + '-' + r, function (n) {
            n.preventDefault();
            t.$html.hasClass(i.modal) || (u.closeAllOthers(), u.close());
          }));
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      r = 'scrollBugFix',
      i,
      e,
      u,
      f;
    n[t].addons[r] = {
      setup: function () {
        var o = this,
          e = this.opts[r],
          h,
          s;
        this.conf[r];
        ((f = n[t].glbl),
        n[t].support.touch &&
          this.opts.offCanvas &&
          this.opts.offCanvas.blockUI &&
          ('boolean' == typeof e && (e = { fix: e }),
          'object' != typeof e && (e = {}),
          (e = this.opts[r] = n.extend(!0, {}, n[t].defaults[r], e)),
          e.fix)) &&
          ((h = this.$menu.attr('id')),
          (s = !1),
          this.bind('opening', function () {
            this.$pnls.children('.' + i.current).scrollTop(0);
          }),
          f.$docu.on(u.touchmove, function (n) {
            o.vars.opened && n.preventDefault();
          }),
          f.$body
            .on(
              u.touchstart,
              '#' + h + '> .' + i.panels + '> .' + i.current,
              function (n) {
                o.vars.opened &&
                  (s ||
                    ((s = !0),
                    0 === n.currentTarget.scrollTop
                      ? (n.currentTarget.scrollTop = 1)
                      : n.currentTarget.scrollHeight ===
                          n.currentTarget.scrollTop +
                            n.currentTarget.offsetHeight &&
                        (n.currentTarget.scrollTop -= 1),
                    (s = !1)));
              }
            )
            .on(
              u.touchmove,
              '#' + h + '> .' + i.panels + '> .' + i.current,
              function (t) {
                o.vars.opened &&
                  n(this)[0].scrollHeight > n(this).innerHeight() &&
                  t.stopPropagation();
              }
            ),
          f.$wndw.on(u.orientationchange, function () {
            o.$pnls
              .children('.' + i.current)
              .scrollTop(0)
              .css({ '-webkit-overflow-scrolling': 'auto' })
              .css({ '-webkit-overflow-scrolling': 'touch' });
          }));
      },
      add: function () {
        i = n[t]._c;
        e = n[t]._d;
        u = n[t]._e;
      },
      clickAnchor: function () {},
    };
    n[t].defaults[r] = { fix: !0 };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'autoHeight',
      t,
      f,
      u,
      e;
    n[i].addons[r] = {
      setup: function () {
        var u, f;
        this.opts.offCanvas &&
          ((u = this.opts[r]),
          this.conf[r],
          ((e = n[i].glbl),
          'boolean' == typeof u && u && (u = { height: 'auto' }),
          'string' == typeof u && (u = { height: u }),
          'object' != typeof u && (u = {}),
          (u = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], u)),
          'auto' == u.height || 'highest' == u.height) &&
            (this.$menu.addClass(t.autoheight),
            (f = function (i) {
              if (this.vars.opened) {
                var f = parseInt(this.$pnls.css('top'), 10) || 0,
                  e = parseInt(this.$pnls.css('bottom'), 10) || 0,
                  r = 0;
                this.$menu.addClass(t.measureheight);
                'auto' == u.height
                  ? ((i = i || this.$pnls.children('.' + t.current)),
                    i.is('.' + t.vertical) &&
                      (i = i
                        .parents('.' + t.panel)
                        .not('.' + t.vertical)
                        .first()),
                    (r = i.outerHeight()))
                  : 'highest' == u.height &&
                    this.$pnls.children().each(function () {
                      var i = n(this);
                      i.is('.' + t.vertical) &&
                        (i = i
                          .parents('.' + t.panel)
                          .not('.' + t.vertical)
                          .first());
                      r = Math.max(r, i.outerHeight());
                    });
                this.$menu.height(r + f + e).removeClass(t.measureheight);
              }
            }),
            this.bind('opening', f),
            'highest' == u.height && this.bind('init', f),
            'auto' == u.height &&
              (this.bind('update', f),
              this.bind('openPanel', f),
              this.bind('closePanel', f))));
      },
      add: function () {
        t = n[i]._c;
        f = n[i]._d;
        u = n[i]._e;
        t.add('autoheight measureheight');
        u.add('resize');
      },
      clickAnchor: function () {},
    };
    n[i].defaults[r] = { height: 'default' };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'backButton',
      r,
      f,
      e,
      u;
    n[t].addons[i] = {
      setup: function () {
        var e, f, o;
        this.opts.offCanvas &&
          ((e = this),
          (f = this.opts[i]),
          this.conf[i],
          ((u = n[t].glbl),
          'boolean' == typeof f && (f = { close: f }),
          'object' != typeof f && (f = {}),
          (f = n.extend(!0, {}, n[t].defaults[i], f)),
          f.close) &&
            ((o = '#' + e.$menu.attr('id')),
            this.bind('opened', function () {
              location.hash != o && history.pushState(null, document.title, o);
            }),
            n(window).on('popstate', function (n) {
              u.$html.hasClass(r.opened)
                ? (n.stopPropagation(), e.close())
                : location.hash == o && (n.stopPropagation(), e.open());
            })));
      },
      add: function () {
        return window.history && window.history.pushState
          ? ((r = n[t]._c), (f = n[t]._d), void (e = n[t]._e))
          : void (n[t].addons[i].setup = function () {});
      },
      clickAnchor: function () {},
    };
    n[t].defaults[i] = { close: !1 };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'columns',
      t,
      f,
      e,
      u;
    n[i].addons[r] = {
      setup: function () {
        var f = this.opts[r];
        if (
          (this.conf[r],
          (u = n[i].glbl),
          'boolean' == typeof f && (f = { add: f }),
          'number' == typeof f && (f = { add: !0, visible: f }),
          'object' != typeof f && (f = {}),
          'number' == typeof f.visible &&
            (f.visible = { min: f.visible, max: f.visible }),
          (f = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], f)),
          f.add)
        ) {
          f.visible.min = Math.max(1, Math.min(6, f.visible.min));
          f.visible.max = Math.max(f.visible.min, Math.min(6, f.visible.max));
          this.$menu.addClass(t.columns);
          for (
            var c = this.opts.offCanvas ? this.$menu.add(u.$html) : this.$menu,
              e = [],
              s = 0;
            s <= f.visible.max;
            s++
          )
            e.push(t.columns + '-' + s);
          e = e.join(' ');
          var l = function (n) {
              h.call(this, this.$pnls.children('.' + t.current));
              f.hideNavbars && n.removeClass(t.hasnavbar);
            },
            o = function () {
              var n = this.$pnls.children('.' + t.panel).filter('.' + t.opened)
                .length;
              n = Math.min(f.visible.max, Math.max(f.visible.min, n));
              c.removeClass(e).addClass(t.columns + '-' + n);
            },
            a = function () {
              this.opts.offCanvas && u.$html.removeClass(e);
            },
            h = function (i) {
              this.$pnls
                .children('.' + t.panel)
                .removeClass(e)
                .filter('.' + t.subopened)
                .removeClass(t.hidden)
                .add(i)
                .slice(-f.visible.max)
                .each(function (i) {
                  n(this).addClass(t.columns + '-' + i);
                });
            };
          this.bind('open', o);
          this.bind('close', a);
          this.bind('init', l);
          this.bind('openPanel', h);
          this.bind('openingPanel', o);
          this.bind('openedPanel', o);
          this.opts.offCanvas || o.call(this);
        }
      },
      add: function () {
        t = n[i]._c;
        f = n[i]._d;
        e = n[i]._e;
        t.add('columns');
      },
      clickAnchor: function (i, u) {
        var e, s, f, o;
        if (!this.opts[r].add) return !1;
        if (u && ((e = i.attr('href')), e.length > 1 && '#' == e.slice(0, 1)))
          try {
            if (((s = n(e, this.$menu)), s.is('.' + t.panel)))
              for (
                f =
                  parseInt(
                    i
                      .closest('.' + t.panel)
                      .attr('class')
                      .split(t.columns + '-')[1]
                      .split(' ')[0],
                    10
                  ) + 1;
                f !== !1;

              ) {
                if (
                  ((o = this.$pnls.children('.' + t.columns + '-' + f)),
                  !o.length)
                ) {
                  f = !1;
                  break;
                }
                f++;
                o.removeClass(t.subopened)
                  .removeClass(t.opened)
                  .removeClass(t.current)
                  .removeClass(t.highest)
                  .addClass(t.hidden);
              }
          } catch (h) {}
      },
    };
    n[i].defaults[r] = {
      add: !1,
      visible: { min: 1, max: 3 },
      hideNavbars: !1,
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'counters',
      r,
      u,
      f,
      e;
    n[t].addons[i] = {
      setup: function () {
        var o = this,
          f = this.opts[i];
        this.conf[i];
        e = n[t].glbl;
        'boolean' == typeof f && (f = { add: f, update: f });
        'object' != typeof f && (f = {});
        f = this.opts[i] = n.extend(!0, {}, n[t].defaults[i], f);
        this.bind('init', function (t) {
          this.__refactorClass(
            n('em', t),
            this.conf.classNames[i].counter,
            'counter'
          );
        });
        f.add &&
          this.bind('init', function (t) {
            var i;
            switch (f.addTo) {
              case 'panels':
                i = t;
                break;
              default:
                i = t.filter(f.addTo);
            }
            i.each(function () {
              var t = n(this).data(u.parent);
              t &&
                (t.children('em.' + r.counter).length ||
                  t.prepend(n('<em class="' + r.counter + '" />')));
            });
          });
        f.update &&
          this.bind('update', function () {
            this.$pnls.children('.' + r.panel).each(function () {
              var t = n(this),
                f = t.data(u.parent),
                i;
              f &&
                ((i = f.children('em.' + r.counter)),
                i.length &&
                  ((t = t.children('.' + r.listview)),
                  t.length &&
                    i.html(o.__filterListItems(t.children()).length)));
            });
          });
      },
      add: function () {
        r = n[t]._c;
        u = n[t]._d;
        f = n[t]._e;
        r.add('counter search noresultsmsg');
      },
      clickAnchor: function () {},
    };
    n[t].defaults[i] = { add: !1, addTo: 'panels', update: !1 };
    n[t].configuration.classNames[i] = { counter: 'Counter' };
  })(jQuery),
  (function (n) {
    var r = 'mmenu',
      i = 'dividers',
      t,
      f,
      u,
      e;
    n[r].addons[i] = {
      setup: function () {
        var s = this,
          f = this.opts[i],
          o;
        this.conf[i];
        ((e = n[r].glbl),
        'boolean' == typeof f && (f = { add: f, fixed: f }),
        'object' != typeof f && (f = {}),
        (f = this.opts[i] = n.extend(!0, {}, n[r].defaults[i], f)),
        this.bind('init', function () {
          this.__refactorClass(
            n('li', this.$menu),
            this.conf.classNames[i].collapsed,
            'collapsed'
          );
        }),
        f.add &&
          this.bind('init', function (i) {
            var r;
            switch (f.addTo) {
              case 'panels':
                r = i;
                break;
              default:
                r = i.filter(f.addTo);
            }
            n('.' + t.divider, r).remove();
            r.find('.' + t.listview)
              .not('.' + t.vertical)
              .each(function () {
                var i = '';
                s.__filterListItems(n(this).children()).each(function () {
                  var r = n
                    .trim(n(this).children('a, span').text())
                    .slice(0, 1)
                    .toLowerCase();
                  r != i &&
                    r.length &&
                    ((i = r),
                    n(
                      '<li class="' + t.divider + '">' + r + '</li>'
                    ).insertBefore(this));
                });
              });
          }),
        f.collapse &&
          this.bind('init', function (i) {
            n('.' + t.divider, i).each(function () {
              var i = n(this),
                r = i.nextUntil('.' + t.divider, '.' + t.collapsed);
              r.length &&
                (i.children('.' + t.subopen).length ||
                  (i.wrapInner('<span />'),
                  i.prepend(
                    '<a href="#" class="' +
                      t.subopen +
                      ' ' +
                      t.fullsubopen +
                      '" />'
                  )));
            });
          }),
        f.fixed) &&
          ((o = function (i) {
            var f, r, u;
            i = i || this.$pnls.children('.' + t.current);
            f = i.find('.' + t.divider).not('.' + t.hidden);
            f.length
              ? (this.$menu.addClass(t.hasdividers),
                (r = i.scrollTop() || 0),
                (u = ''),
                i.is(':visible') &&
                  i
                    .find('.' + t.divider)
                    .not('.' + t.hidden)
                    .each(function () {
                      n(this).position().top + r < r + 1 &&
                        (u = n(this).text());
                    }),
                this.$fixeddivider.text(u))
              : this.$menu.removeClass(t.hasdividers);
          }),
          (this.$fixeddivider = n(
            '<ul class="' +
              t.listview +
              ' ' +
              t.fixeddivider +
              '"><li class="' +
              t.divider +
              '"></li></ul>'
          )
            .prependTo(this.$pnls)
            .children()),
          this.bind('openPanel', o),
          this.bind('update', o),
          this.bind('init', function (t) {
            t.off(u.scroll + '-dividers ' + u.touchmove + '-dividers').on(
              u.scroll + '-dividers ' + u.touchmove + '-dividers',
              function () {
                o.call(s, n(this));
              }
            );
          }));
      },
      add: function () {
        t = n[r]._c;
        f = n[r]._d;
        u = n[r]._e;
        t.add('collapsed uncollapsed fixeddivider hasdividers');
        u.add('scroll');
      },
      clickAnchor: function (n, r) {
        var u, f;
        return this.opts[i].collapse &&
          r &&
          ((u = n.parent()), u.is('.' + t.divider))
          ? ((f = u.nextUntil('.' + t.divider, '.' + t.collapsed)),
            u.toggleClass(t.opened),
            f[u.hasClass(t.opened) ? 'addClass' : 'removeClass'](t.uncollapsed),
            !0)
          : !1;
      },
    };
    n[r].defaults[i] = { add: !1, addTo: 'panels', fixed: !1, collapse: !1 };
    n[r].configuration.classNames[i] = { collapsed: 'Collapsed' };
  })(jQuery),
  (function (n) {
    function f(n, t, i) {
      return t > n && (n = t), n > i && (n = i), n;
    }
    var i = 'mmenu',
      r = 'dragOpen',
      u,
      e,
      o,
      t;
    n[i].addons[r] = {
      setup: function () {
        var a, nt;
        if (this.opts.offCanvas) {
          var v = this,
            o = this.opts[r],
            p = this.conf[r];
          if (
            ((t = n[i].glbl),
            'boolean' == typeof o && (o = { open: o }),
            'object' != typeof o && (o = {}),
            (o = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], o)),
            o.open)
          ) {
            var c,
              y,
              w,
              b,
              k,
              e = {},
              s = 0,
              d = !1,
              h = !1,
              l = 0,
              g = 0;
            switch (this.opts.offCanvas.position) {
              case 'left':
              case 'right':
                e.events = 'panleft panright';
                e.typeLower = 'x';
                e.typeUpper = 'X';
                h = 'width';
                break;
              case 'top':
              case 'bottom':
                e.events = 'panup pandown';
                e.typeLower = 'y';
                e.typeUpper = 'Y';
                h = 'height';
            }
            switch (this.opts.offCanvas.position) {
              case 'right':
              case 'bottom':
                e.negative = !0;
                b = function (n) {
                  n >= t.$wndw[h]() - o.maxStartPos && (s = 1);
                };
                break;
              default:
                e.negative = !1;
                b = function (n) {
                  n <= o.maxStartPos && (s = 1);
                };
            }
            switch (this.opts.offCanvas.position) {
              case 'left':
                e.open_dir = 'right';
                e.close_dir = 'left';
                break;
              case 'right':
                e.open_dir = 'left';
                e.close_dir = 'right';
                break;
              case 'top':
                e.open_dir = 'down';
                e.close_dir = 'up';
                break;
              case 'bottom':
                e.open_dir = 'up';
                e.close_dir = 'down';
            }
            switch (this.opts.offCanvas.zposition) {
              case 'front':
                k = function () {
                  return this.$menu;
                };
                break;
              default:
                k = function () {
                  return n('.' + u.slideout);
                };
            }
            a = this.__valueOrFn(o.pageNode, this.$menu, t.$page);
            'string' == typeof a && (a = n(a));
            nt = new Hammer(a[0], o.vendors.hammer);
            nt.on('panstart', function (n) {
              b(n.center[e.typeLower]);
              t.$slideOutNodes = k();
              d = e.open_dir;
            })
              .on(e.events + ' panend', function (n) {
                s > 0 && n.preventDefault();
              })
              .on(e.events, function (n) {
                if (
                  ((c = n['delta' + e.typeUpper]),
                  e.negative && (c = -c),
                  c != l && (d = c >= l ? e.open_dir : e.close_dir),
                  (l = c),
                  l > o.threshold && 1 == s)
                ) {
                  if (t.$html.hasClass(u.opened)) return;
                  s = 2;
                  v._openSetup();
                  v.trigger('opening');
                  t.$html.addClass(u.dragging);
                  g = f(t.$wndw[h]() * p[h].perc, p[h].min, p[h].max);
                }
                2 == s &&
                  ((y =
                    f(l, 10, g) -
                    ('front' == v.opts.offCanvas.zposition ? g : 0)),
                  e.negative && (y = -y),
                  (w = 'translate' + e.typeUpper + '(' + y + 'px )'),
                  t.$slideOutNodes.css({
                    '-webkit-transform': '-webkit-' + w,
                    transform: w,
                  }));
              })
              .on('panend', function () {
                2 == s &&
                  (t.$html.removeClass(u.dragging),
                  t.$slideOutNodes.css('transform', ''),
                  v[d == e.open_dir ? '_openFinish' : 'close']());
                s = 0;
              });
          }
        }
      },
      add: function () {
        return 'function' != typeof Hammer || Hammer.VERSION < 2
          ? void (n[i].addons[r].setup = function () {})
          : ((u = n[i]._c),
            (e = n[i]._d),
            (o = n[i]._e),
            void u.add('dragging'));
      },
      clickAnchor: function () {},
    };
    n[i].defaults[r] = {
      open: !1,
      maxStartPos: 100,
      threshold: 50,
      vendors: { hammer: {} },
    };
    n[i].configuration[r] = {
      width: { perc: 0.8, min: 140, max: 440 },
      height: { perc: 0.8, min: 140, max: 880 },
    };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'dropdown',
      t,
      e,
      f,
      u;
    n[i].addons[r] = {
      setup: function () {
        var s, h, v, a;
        if (this.opts.offCanvas) {
          var c = this,
            o = this.opts[r],
            l = this.conf[r];
          ((u = n[i].glbl),
          'boolean' == typeof o && o && (o = { drop: o }),
          'object' != typeof o && (o = {}),
          'string' == typeof o.position && (o.position = { of: o.position }),
          (o = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], o)),
          o.drop) &&
            ('string' != typeof o.position.of &&
              ((s = this.$menu.attr('id')),
              s &&
                s.length &&
                (this.conf.clone && (s = t.umm(s)),
                (o.position.of = '[href="#' + s + '"]'))),
            'string' == typeof o.position.of &&
              ((h = n(o.position.of)),
              h.length &&
                (this.$menu.addClass(t.dropdown),
                o.tip && this.$menu.addClass(t.tip),
                (o.event = o.event.split(' ')),
                1 == o.event.length && (o.event[1] = o.event[0]),
                'hover' == o.event[0] &&
                  h.on(f.mouseenter + '-dropdown', function () {
                    c.open();
                  }),
                'hover' == o.event[1] &&
                  this.$menu.on(f.mouseleave + '-dropdown', function () {
                    c.close();
                  }),
                this.bind('opening', function () {
                  this.$menu.data(e.style, this.$menu.attr('style') || '');
                  u.$html.addClass(t.dropdown);
                }),
                this.bind('closed', function () {
                  this.$menu.attr('style', this.$menu.data(e.style));
                  u.$html.removeClass(t.dropdown);
                }),
                (v = function (f, e) {
                  var s = e[0],
                    y = e[1],
                    tt = 'x' == f ? 'scrollLeft' : 'scrollTop',
                    it = 'x' == f ? 'outerWidth' : 'outerHeight',
                    p = 'x' == f ? 'left' : 'top',
                    k = 'x' == f ? 'right' : 'bottom',
                    d = 'x' == f ? 'width' : 'height',
                    rt = 'x' == f ? 'maxWidth' : 'maxHeight',
                    a = null,
                    ut = u.$wndw[tt](),
                    v = (h.offset()[p] -= ut),
                    w = v + h[it](),
                    g = u.$wndw[d](),
                    nt = l.offset.button[f] + l.offset.viewport[f],
                    c,
                    b;
                  if (o.position[f])
                    switch (o.position[f]) {
                      case 'left':
                      case 'bottom':
                        a = 'after';
                        break;
                      case 'right':
                      case 'top':
                        a = 'before';
                    }
                  return (
                    null === a &&
                      (a = g / 2 > v + (w - v) / 2 ? 'after' : 'before'),
                    'after' == a
                      ? ((c = 'x' == f ? v : w),
                        (b = g - (c + nt)),
                        (s[p] = c + l.offset.button[f]),
                        (s[k] = 'auto'),
                        y.push(t['x' == f ? 'tipleft' : 'tiptop']))
                      : ((c = 'x' == f ? w : v),
                        (b = c - nt),
                        (s[k] =
                          'calc( 100% - ' + (c - l.offset.button[f]) + 'px )'),
                        (s[p] = 'auto'),
                        y.push(t['x' == f ? 'tipright' : 'tipbottom'])),
                    (s[rt] = Math.min(n[i].configuration[r][d].max, b)),
                    [s, y]
                  );
                }),
                (a = function () {
                  if (this.vars.opened) {
                    this.$menu.attr('style', this.$menu.data(e.style));
                    var n = [{}, []];
                    n = v.call(this, 'y', n);
                    n = v.call(this, 'x', n);
                    this.$menu.css(n[0]);
                    o.tip &&
                      this.$menu
                        .removeClass(
                          t.tipleft +
                            ' ' +
                            t.tipright +
                            ' ' +
                            t.tiptop +
                            ' ' +
                            t.tipbottom
                        )
                        .addClass(n[1].join(' '));
                  }
                }),
                this.bind('opening', a),
                u.$wndw.on(f.resize + '-dropdown', function () {
                  a.call(c);
                }),
                this.opts.offCanvas.blockUI ||
                  u.$wndw.on(f.scroll + '-dropdown', function () {
                    a.call(c);
                  }))));
        }
      },
      add: function () {
        t = n[i]._c;
        e = n[i]._d;
        f = n[i]._e;
        t.add('dropdown tip tipleft tipright tiptop tipbottom');
        f.add('mouseenter mouseleave resize scroll');
      },
      clickAnchor: function () {},
    };
    n[i].defaults[r] = { drop: !1, event: 'click', position: {}, tip: !0 };
    n[i].configuration[r] = {
      offset: { button: { x: -10, y: 10 }, viewport: { x: 20, y: 20 } },
      height: { max: 880 },
      width: { max: 440 },
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'fixedElements',
      u,
      f,
      e,
      r;
    n[t].addons[i] = {
      setup: function () {
        var u, f;
        this.opts.offCanvas &&
          ((u = this.opts[i]),
          this.conf[i],
          (r = n[t].glbl),
          (u = this.opts[i] = n.extend(!0, {}, n[t].defaults[i], u)),
          (f = function (n) {
            var t = this.conf.classNames[i].fixed;
            this.__refactorClass(n.find('.' + t), t, 'slideout').appendTo(
              r.$body
            );
          }),
          f.call(this, r.$page),
          this.bind('setPage', f));
      },
      add: function () {
        u = n[t]._c;
        f = n[t]._d;
        e = n[t]._e;
        u.add('fixed');
      },
      clickAnchor: function () {},
    };
    n[t].configuration.classNames[i] = { fixed: 'Fixed' };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'iconPanels',
      t,
      u,
      f,
      e;
    n[i].addons[r] = {
      setup: function () {
        var s = this,
          u = this.opts[r],
          f,
          o,
          h;
        if (
          (this.conf[r],
          (e = n[i].glbl),
          'boolean' == typeof u && (u = { add: u }),
          'number' == typeof u && (u = { add: !0, visible: u }),
          'object' != typeof u && (u = {}),
          (u = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], u)),
          u.visible++,
          u.add)
        ) {
          for (
            this.$menu.addClass(t.iconpanel), f = [], o = 0;
            o <= u.visible;
            o++
          )
            f.push(t.iconpanel + '-' + o);
          f = f.join(' ');
          h = function (i) {
            i.hasClass(t.vertical) ||
              s.$pnls
                .children('.' + t.panel)
                .removeClass(f)
                .filter('.' + t.subopened)
                .removeClass(t.hidden)
                .add(i)
                .not('.' + t.vertical)
                .slice(-u.visible)
                .each(function (i) {
                  n(this).addClass(t.iconpanel + '-' + i);
                });
          };
          this.bind('openPanel', h);
          this.bind('init', function (i) {
            h.call(s, s.$pnls.children('.' + t.current));
            u.hideNavbars && i.removeClass(t.hasnavbar);
            i.not('.' + t.vertical).each(function () {
              n(this).children('.' + t.subblocker).length ||
                n(this).prepend(
                  '<a href="#' +
                    n(this)
                      .closest('.' + t.panel)
                      .attr('id') +
                    '" class="' +
                    t.subblocker +
                    '" />'
                );
            });
          });
        }
      },
      add: function () {
        t = n[i]._c;
        u = n[i]._d;
        f = n[i]._e;
        t.add('iconpanel subblocker');
      },
      clickAnchor: function () {},
    };
    n[i].defaults[r] = { add: !1, visible: 3, hideNavbars: !1 };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'navbars',
      t,
      u,
      f,
      e;
    n[i].addons[r] = {
      setup: function () {
        var o = this,
          f = this.opts[r],
          h = this.conf[r],
          u,
          s;
        if (((e = n[i].glbl), 'undefined' != typeof f)) {
          f instanceof Array || (f = [f]);
          u = {};
          n.each(f, function (e) {
            var s = f[e],
              c,
              l,
              a,
              v;
            'boolean' == typeof s && s && (s = {});
            'object' != typeof s && (s = {});
            'undefined' == typeof s.content && (s.content = ['prev', 'title']);
            s.content instanceof Array || (s.content = [s.content]);
            s = n.extend(!0, {}, o.opts.navbar, s);
            c = s.position;
            l = s.height;
            'number' != typeof l && (l = 1);
            l = Math.min(4, Math.max(1, l));
            'bottom' != c && (c = 'top');
            u[c] || (u[c] = 0);
            u[c]++;
            a = n('<div />').addClass(
              t.navbar +
                ' ' +
                t.navbar +
                '-' +
                c +
                ' ' +
                t.navbar +
                '-' +
                c +
                '-' +
                u[c] +
                ' ' +
                t.navbar +
                '-size-' +
                l
            );
            u[c] += l - 1;
            for (var p = 0, y = 0, w = s.content.length; w > y; y++)
              (v = n[i].addons[r][s.content[y]] || !1),
                v
                  ? (p += v.call(o, a, s, h))
                  : ((v = s.content[y]),
                    v instanceof n || (v = n(s.content[y])),
                    a.append(v));
            p += Math.ceil(
              a
                .children()
                .not('.' + t.btn)
                .not('.' + t.title + '-prev').length / l
            );
            p > 1 && a.addClass(t.navbar + '-content-' + p);
            a.children('.' + t.btn).length && a.addClass(t.hasbtns);
            a.prependTo(o.$menu);
          });
          for (s in u) o.$menu.addClass(t.hasnavbar + '-' + s + '-' + u[s]);
        }
      },
      add: function () {
        t = n[i]._c;
        u = n[i]._d;
        f = n[i]._e;
        t.add('close hasbtns');
      },
      clickAnchor: function () {},
    };
    n[i].configuration[r] = { breadcrumbSeparator: '/' };
    n[i].configuration.classNames[r] = {};
  })(jQuery),
  (function (n) {
    var t = 'mmenu';
    n[t].addons['navbars']['breadcrumbs'] = function (i, r, u) {
      var f = n[t]._c,
        s = n[t]._d,
        o,
        e;
      return (
        f.add('breadcrumbs separator'),
        (o = n('<span class="' + f.breadcrumbs + '" />').appendTo(i)),
        this.bind('init', function (t) {
          t.removeClass(f.hasnavbar).each(function () {
            for (
              var i,
                r = [],
                o = n(this),
                h = n('<span class="' + f.breadcrumbs + '"></span>'),
                t = n(this).children().first(),
                e = !0;
              t && t.length;

            )
              t.is('.' + f.panel) || (t = t.closest('.' + f.panel)),
                (i = t
                  .children('.' + f.navbar)
                  .children('.' + f.title)
                  .text()),
                r.unshift(
                  e
                    ? '<span>' + i + '</span>'
                    : '<a href="#' + t.attr('id') + '">' + i + '</a>'
                ),
                (e = !1),
                (t = t.data(s.parent));
            h.append(
              r.join(
                '<span class="' +
                  f.separator +
                  '">' +
                  u.breadcrumbSeparator +
                  '</span>'
              )
            ).appendTo(o.children('.' + f.navbar));
          });
        }),
        (e = function () {
          o.html(
            this.$pnls
              .children('.' + f.current)
              .children('.' + f.navbar)
              .children('.' + f.breadcrumbs)
              .html()
          );
        }),
        this.bind('openPanel', e),
        this.bind('init', e),
        0
      );
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu';
    n[t].addons['navbars']['close'] = function (i) {
      var r = n[t]._c,
        f = n[t].glbl,
        e = n('<a class="' + r.close + ' ' + r.btn + '" href="#" />').appendTo(
          i
        ),
        u = function (n) {
          e.attr('href', '#' + n.attr('id'));
        };
      return u.call(this, f.$page), this.bind('setPage', u), -1;
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'navbars';
    n[t].addons[i]['next'] = function (r) {
      var u,
        e,
        f = n[t]._c,
        o = n('<a class="' + f.next + ' ' + f.btn + '" href="#" />').appendTo(
          r
        ),
        s = function (n) {
          n = n || this.$pnls.children('.' + f.current);
          var t = n.find('.' + this.conf.classNames[i].panelNext);
          u = t.attr('href');
          e = t.html();
          o[u ? 'attr' : 'removeAttr']('href', u);
          o[u || e ? 'removeClass' : 'addClass'](f.hidden);
          o.html(e);
        };
      return (
        this.bind('openPanel', s),
        this.bind('init', function () {
          s.call(this);
        }),
        -1
      );
    };
    n[t].configuration.classNames[i].panelNext = 'Next';
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'navbars';
    n[t].addons[i]['prev'] = function (r) {
      var u = n[t]._c,
        e = n('<a class="' + u.prev + ' ' + u.btn + '" href="#" />').appendTo(
          r
        ),
        f,
        o,
        s;
      return (
        this.bind('init', function (n) {
          n.removeClass(u.hasnavbar)
            .children('.' + u.navbar)
            .addClass(u.hidden);
        }),
        (s = function (n) {
          if (
            ((n = n || this.$pnls.children('.' + u.current)),
            !n.hasClass(u.vertical))
          ) {
            var t = n.find('.' + this.conf.classNames[i].panelPrev);
            t.length || (t = n.children('.' + u.navbar).children('.' + u.prev));
            f = t.attr('href');
            o = t.html();
            e[f ? 'attr' : 'removeAttr']('href', f);
            e[f || o ? 'removeClass' : 'addClass'](u.hidden);
            e.html(o);
          }
        }),
        this.bind('openPanel', s),
        this.bind('init', function () {
          s.call(this);
        }),
        -1
      );
    };
    n[t].configuration.classNames[i].panelPrev = 'Prev';
  })(jQuery),
  (function (n) {
    var t = 'mmenu';
    n[t].addons['navbars']['searchfield'] = function (i) {
      var r = n[t]._c,
        u = n('<div class="' + r.search + '" />').appendTo(i);
      return (
        'object' != typeof this.opts.searchfield &&
          (this.opts.searchfield = {}),
        (this.opts.searchfield.add = !0),
        (this.opts.searchfield.addTo = u),
        0
      );
    };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'navbars';
    n[t].addons[i]['title'] = function (r, u) {
      var e,
        o,
        f = n[t]._c,
        s = n('<a class="' + f.title + '" />').appendTo(r),
        h = function (n) {
          if (
            ((n = n || this.$pnls.children('.' + f.current)),
            !n.hasClass(f.vertical))
          ) {
            var t = n.find('.' + this.conf.classNames[i].panelTitle);
            t.length ||
              (t = n.children('.' + f.navbar).children('.' + f.title));
            e = t.attr('href');
            o = t.html() || u.title;
            s[e ? 'attr' : 'removeAttr']('href', e);
            s[e || o ? 'removeClass' : 'addClass'](f.hidden);
            s.html(o);
          }
        };
      return (
        this.bind('openPanel', h),
        this.bind('init', function () {
          h.call(this);
        }),
        0
      );
    };
    n[t].configuration.classNames[i].panelTitle = 'Title';
  })(jQuery),
  (function (n) {
    function r(n, t, i) {
      n.prop('aria-' + t, i)[i ? 'attr' : 'removeAttr']('aria-' + t, 'true');
    }
    function f(n) {
      return '<span class="' + t.sronly + '">' + n + '</span>';
    }
    var i = 'mmenu',
      u = 'screenReader',
      t,
      e,
      o,
      s;
    n[i].addons[u] = {
      setup: function () {
        var e = this.opts[u],
          o = this.conf[u],
          v,
          h,
          c,
          y,
          l,
          a;
        ((s = n[i].glbl),
        'boolean' == typeof e && (e = { aria: e, text: e }),
        'object' != typeof e && (e = {}),
        (e = this.opts[u] = n.extend(!0, {}, n[i].defaults[u], e)),
        e.aria) &&
          (this.opts.offCanvas &&
            ((v = function () {
              r(this.$menu, 'hidden', !1);
            }),
            (h = function () {
              r(this.$menu, 'hidden', !0);
            }),
            this.bind('open', v),
            this.bind('close', h),
            h.call(this)),
          (c = function () {
            r(this.$menu.find('.' + t.hidden), 'hidden', !0);
            r(
              this.$menu.find('[aria-hidden="true"]').not('.' + t.hidden),
              'hidden',
              !1
            );
          }),
          (y = function (n) {
            r(
              this.$pnls
                .children('.' + t.panel)
                .not(n)
                .not('.' + t.hidden),
              'hidden',
              !0
            );
            r(n, 'hidden', !1);
          }),
          this.bind('update', c),
          this.bind('openPanel', c),
          this.bind('openPanel', y),
          (l = function (n) {
            r(n.find('.' + t.prev + ', .' + t.next), 'haspopup', !0);
          }),
          this.bind('init', l),
          l.call(this, this.$menu.children('.' + t.navbar)));
        e.text &&
          ((a = function (i) {
            i.children('.' + t.navbar)
              .children('.' + t.prev)
              .html(f(o.text.closeSubmenu))
              .end()
              .children('.' + t.next)
              .html(f(o.text.openSubmenu))
              .end()
              .children('.' + t.close)
              .html(f(o.text.closeMenu));
            i.is('.' + t.panel) &&
              i
                .find('.' + t.listview)
                .find('.' + t.next)
                .each(function () {
                  n(this).html(
                    f(
                      o.text[
                        n(this)
                          .parent()
                          .is('.' + t.vertical)
                          ? 'toggleSubmenu'
                          : 'openSubmenu'
                      ]
                    )
                  );
                });
          }),
          this.bind('init', a),
          a.call(this, this.$menu));
      },
      add: function () {
        t = n[i]._c;
        e = n[i]._d;
        o = n[i]._e;
        t.add('sronly');
      },
      clickAnchor: function () {},
    };
    n[i].defaults[u] = { aria: !1, text: !1 };
    n[i].configuration[u] = {
      text: {
        closeMenu: 'Close menu',
        closeSubmenu: 'Close submenu',
        openSubmenu: 'Open submenu',
        toggleSubmenu: 'Toggle submenu',
      },
    };
  })(jQuery),
  (function (n) {
    function e(n) {
      switch (n) {
        case 9:
        case 16:
        case 17:
        case 18:
        case 37:
        case 38:
        case 39:
        case 40:
          return !0;
      }
      return !1;
    }
    var u = 'mmenu',
      i = 'searchfield',
      t,
      f,
      r,
      o;
    n[u].addons[i] = {
      setup: function () {
        var h = this,
          s = this.opts[i],
          c = this.conf[i];
        o = n[u].glbl;
        'boolean' == typeof s && (s = { add: s });
        'object' != typeof s && (s = {});
        'boolean' == typeof s.resultsPanel &&
          (s.resultsPanel = { add: s.resultsPanel });
        s = this.opts[i] = n.extend(!0, {}, n[u].defaults[i], s);
        c = this.conf[i] = n.extend(!0, {}, n[u].configuration[i], c);
        this.bind('close', function () {
          this.$menu
            .find('.' + t.search)
            .find('input')
            .blur();
        });
        this.bind('init', function (u) {
          var l, o;
          if (s.add) {
            switch (s.addTo) {
              case 'panels':
                l = u;
                break;
              default:
                l = this.$menu.find(s.addTo);
            }
            (l.each(function () {
              var i = n(this),
                f,
                y,
                o;
              if (!i.is('.' + t.panel) || !i.is('.' + t.vertical)) {
                if (!i.children('.' + t.search).length) {
                  var v = h.__valueOrFn(c.clear, i),
                    e = h.__valueOrFn(c.form, i),
                    l = h.__valueOrFn(c.input, i),
                    p = h.__valueOrFn(c.submit, i),
                    u = n(
                      '<' +
                        (e ? 'form' : 'div') +
                        ' class="' +
                        t.search +
                        '" />'
                    ),
                    a = n(
                      '<input placeholder="' +
                        s.placeholder +
                        '" type="text" autocomplete="off" />'
                    );
                  if ((u.append(a), l)) for (f in l) a.attr(f, l[f]);
                  if (
                    (v &&
                      n('<a class="' + t.btn + ' ' + t.clear + '" href="#" />')
                        .appendTo(u)
                        .on(r.click + '-searchfield', function (n) {
                          n.preventDefault();
                          a.val('').trigger(r.keyup + '-searchfield');
                        }),
                    e)
                  ) {
                    for (f in e) u.attr(f, e[f]);
                    p &&
                      !v &&
                      n('<a class="' + t.btn + ' ' + t.next + '" href="#" />')
                        .appendTo(u)
                        .on(r.click + '-searchfield', function (n) {
                          n.preventDefault();
                          u.submit();
                        });
                  }
                  i.hasClass(t.search)
                    ? i.replaceWith(u)
                    : i.prepend(u).addClass(t.hassearch);
                }
                s.noResults &&
                  ((y = i.closest('.' + t.panel).length),
                  (y || (i = h.$pnls.children('.' + t.panel).first()),
                  i.children('.' + t.noresultsmsg).length) ||
                    ((o = i.children('.' + t.listview).first()),
                    n('<div class="' + t.noresultsmsg + ' ' + t.hidden + '" />')
                      .append(s.noResults)
                      [o.length ? 'insertAfter' : 'prependTo'](
                        o.length ? o : i
                      )));
              }
            }),
            s.search) &&
              (s.resultsPanel.add &&
                ((s.showSubPanels = !1),
                (o = this.$pnls.children('.' + t.resultspanel)),
                o.length ||
                  ((o = n(
                    '<div class="' +
                      t.panel +
                      ' ' +
                      t.resultspanel +
                      ' ' +
                      t.hidden +
                      '" />'
                  )
                    .appendTo(this.$pnls)
                    .append(
                      '<div class="' +
                        t.navbar +
                        ' ' +
                        t.hidden +
                        '"><a class="' +
                        t.title +
                        '">' +
                        s.resultsPanel.title +
                        '</a></div>'
                    )
                    .append('<ul class="' + t.listview + '" />')
                    .append(
                      this.$pnls
                        .find('.' + t.noresultsmsg)
                        .first()
                        .clone()
                    )),
                  this.init(o))),
              this.$menu.find('.' + t.search).each(function () {
                var u,
                  v,
                  l = n(this),
                  w = l.closest('.' + t.panel).length,
                  p;
                w
                  ? ((u = l.closest('.' + t.panel)), (v = u))
                  : ((u = n('.' + t.panel, h.$menu)), (v = h.$menu));
                s.resultsPanel.add && (u = u.not(o));
                var c = l.children('input'),
                  b = h.__findAddBack(u, '.' + t.listview).children('li'),
                  g = b.filter('.' + t.divider),
                  y = h.__filterListItems(b),
                  k = 'a',
                  nt = k + ', span',
                  a = '',
                  d = function () {
                    var r = c.val().toLowerCase(),
                      i;
                    r != a &&
                      (((a = r),
                      s.resultsPanel.add &&
                        o.children('.' + t.listview).empty(),
                      u.scrollTop(0),
                      y
                        .add(g)
                        .addClass(t.hidden)
                        .find('.' + t.fullsubopensearch)
                        .removeClass(t.fullsubopen + ' ' + t.fullsubopensearch),
                      y.each(function () {
                        var i = n(this),
                          r = k,
                          u;
                        (s.showTextItems ||
                          (s.showSubPanels && i.find('.' + t.next))) &&
                          (r = nt);
                        u = i.data(f.searchtext) || i.children(r).text();
                        u.toLowerCase().indexOf(a) > -1 &&
                          i
                            .add(i.prevAll('.' + t.divider).first())
                            .removeClass(t.hidden);
                      }),
                      s.showSubPanels &&
                        u.each(function () {
                          var i = n(this);
                          h.__filterListItems(
                            i.find('.' + t.listview).children()
                          ).each(function () {
                            var i = n(this),
                              r = i.data(f.sub);
                            i.removeClass(t.nosubresults);
                            r &&
                              r
                                .find('.' + t.listview)
                                .children()
                                .removeClass(t.hidden);
                          });
                        }),
                      s.resultsPanel.add)
                        ? '' === a
                          ? (this.closeAllPanels(),
                            this.openPanel(
                              this.$pnls.children('.' + t.subopened).last()
                            ))
                          : ((i = n()),
                            u.each(function () {
                              var r = h
                                .__filterListItems(
                                  n(this)
                                    .find('.' + t.listview)
                                    .children()
                                )
                                .not('.' + t.hidden)
                                .clone(!0);
                              r.length &&
                                (s.resultsPanel.dividers &&
                                  (i = i.add(
                                    '<li class="' +
                                      t.divider +
                                      '">' +
                                      n(this)
                                        .children('.' + t.navbar)
                                        .text() +
                                      '</li>'
                                  )),
                                (i = i.add(r)));
                            }),
                            i.find('.' + t.next).remove(),
                            o.children('.' + t.listview).append(i),
                            this.openPanel(o))
                        : n(u.get().reverse()).each(function (i) {
                            var u = n(this),
                              r = u.data(f.parent);
                            r &&
                              (h.__filterListItems(
                                u.find('.' + t.listview).children()
                              ).length
                                ? (r.hasClass(t.hidden) &&
                                    r
                                      .children('.' + t.next)
                                      .not('.' + t.fullsubopen)
                                      .addClass(t.fullsubopen)
                                      .addClass(t.fullsubopensearch),
                                  r
                                    .removeClass(t.hidden)
                                    .removeClass(t.nosubresults)
                                    .prevAll('.' + t.divider)
                                    .first()
                                    .removeClass(t.hidden))
                                : w ||
                                  (u.hasClass(t.opened) &&
                                    setTimeout(function () {
                                      h.openPanel(r.closest('.' + t.panel));
                                    }, (i + 1) * 1.5 * h.conf.openingInterval),
                                  r.addClass(t.nosubresults)));
                          }),
                      v
                        .find('.' + t.noresultsmsg)
                        [
                          y.not('.' + t.hidden).length
                            ? 'addClass'
                            : 'removeClass'
                        ](t.hidden),
                      this.update());
                  };
                c.off(r.keyup + '-' + i + ' ' + r.change + '-' + i)
                  .on(r.keyup + '-' + i, function (n) {
                    e(n.keyCode) || d.call(h);
                  })
                  .on(r.change + '-' + i, function () {
                    d.call(h);
                  });
                p = l.children('.' + t.btn);
                p.length &&
                  c.on(r.keyup + '-' + i, function () {
                    p[c.val().length ? 'removeClass' : 'addClass'](t.hidden);
                  });
                c.trigger(r.keyup + '-' + i);
              }));
          }
        });
      },
      add: function () {
        t = n[u]._c;
        f = n[u]._d;
        r = n[u]._e;
        t.add(
          'clear search hassearch resultspanel noresultsmsg noresults nosubresults fullsubopensearch'
        );
        f.add('searchtext');
        r.add('change keyup');
      },
      clickAnchor: function () {},
    };
    n[u].defaults[i] = {
      add: !1,
      addTo: 'panels',
      placeholder: 'Search',
      noResults: 'No results found.',
      resultsPanel: { add: !1, dividers: !0, title: 'Search results' },
      search: !0,
      showTextItems: !1,
      showSubPanels: !0,
    };
    n[u].configuration[i] = { clear: !1, form: !1, input: !1, submit: !1 };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'sectionIndexer',
      t,
      f,
      u,
      e;
    n[i].addons[r] = {
      setup: function () {
        var o = this,
          f = this.opts[r];
        this.conf[r];
        e = n[i].glbl;
        'boolean' == typeof f && (f = { add: f });
        'object' != typeof f && (f = {});
        f = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], f);
        this.bind('init', function (i) {
          var r, e;
          if (f.add) {
            switch (f.addTo) {
              case 'panels':
                r = i;
                break;
              default:
                r = n(f.addTo, this.$menu).filter('.' + t.panel);
            }
            r.find('.' + t.divider)
              .closest('.' + t.panel)
              .addClass(t.hasindexer);
          }
          !this.$indexer &&
            this.$pnls.children('.' + t.hasindexer).length &&
            ((this.$indexer = n('<div class="' + t.indexer + '" />')
              .prependTo(this.$pnls)
              .append(
                '<a href="#a">a</a><a href="#b">b</a><a href="#c">c</a><a href="#d">d</a><a href="#e">e</a><a href="#f">f</a><a href="#g">g</a><a href="#h">h</a><a href="#i">i</a><a href="#j">j</a><a href="#k">k</a><a href="#l">l</a><a href="#m">m</a><a href="#n">n</a><a href="#o">o</a><a href="#p">p</a><a href="#q">q</a><a href="#r">r</a><a href="#s">s</a><a href="#t">t</a><a href="#u">u</a><a href="#v">v</a><a href="#w">w</a><a href="#x">x</a><a href="#y">y</a><a href="#z">z</a>'
              )),
            this.$indexer
              .children()
              .on(
                u.mouseover +
                  '-sectionindexer ' +
                  t.touchstart +
                  '-sectionindexer',
                function () {
                  var u = n(this).attr('href').slice(1),
                    i = o.$pnls.children('.' + t.current),
                    f = i.find('.' + t.listview),
                    r = !1,
                    e = i.scrollTop();
                  i.scrollTop(0);
                  f.children('.' + t.divider)
                    .not('.' + t.hidden)
                    .each(function () {
                      r === !1 &&
                        u == n(this).text().slice(0, 1).toLowerCase() &&
                        (r = n(this).position().top);
                    });
                  i.scrollTop(r !== !1 ? r : e);
                }
              ),
            (e = function (n) {
              o.$menu[(n.hasClass(t.hasindexer) ? 'add' : 'remove') + 'Class'](
                t.hasindexer
              );
            }),
            this.bind('openPanel', e),
            e.call(this, this.$pnls.children('.' + t.current)));
        });
      },
      add: function () {
        t = n[i]._c;
        f = n[i]._d;
        u = n[i]._e;
        t.add('indexer hasindexer');
        u.add('mouseover touchstart');
      },
      clickAnchor: function (n) {
        if (n.parent().is('.' + t.indexer)) return !0;
      },
    };
    n[i].defaults[r] = { add: !1, addTo: 'panels' };
  })(jQuery),
  (function (n) {
    var i = 'mmenu',
      r = 'setSelected',
      t,
      u,
      f,
      e;
    n[i].addons[r] = {
      setup: function () {
        var f = this.opts[r],
          o;
        this.conf[r];
        ((e = n[i].glbl),
        'boolean' == typeof f && (f = { hover: f, parent: f }),
        'object' != typeof f && (f = {}),
        (f = this.opts[r] = n.extend(!0, {}, n[i].defaults[r], f)),
        f.current ||
          this.bind('init', function (n) {
            n.find('.' + t.listview)
              .children('.' + t.selected)
              .removeClass(t.selected);
          }),
        f.hover && this.$menu.addClass(t.hoverselected),
        f.parent) &&
          (this.$menu.addClass(t.parentselected),
          (o = function (n) {
            this.$pnls
              .find('.' + t.listview)
              .find('.' + t.next)
              .removeClass(t.selected);
            for (var i = n.data(u.parent); i && i.length; )
              i = i
                .children('.' + t.next)
                .addClass(t.selected)
                .closest('.' + t.panel)
                .data(u.parent);
          }),
          this.bind('openedPanel', o),
          this.bind('init', function () {
            o.call(this, this.$pnls.children('.' + t.current));
          }));
      },
      add: function () {
        t = n[i]._c;
        u = n[i]._d;
        f = n[i]._e;
        t.add('hoverselected parentselected');
      },
      clickAnchor: function () {},
    };
    n[i].defaults[r] = { current: !0, hover: !1, parent: !1 };
  })(jQuery),
  (function (n) {
    var t = 'mmenu',
      i = 'toggles',
      r,
      u,
      f,
      e;
    n[t].addons[i] = {
      setup: function () {
        var u = this;
        this.opts[i];
        this.conf[i];
        e = n[t].glbl;
        this.bind('init', function (t) {
          this.__refactorClass(
            n('input', t),
            this.conf.classNames[i].toggle,
            'toggle'
          );
          this.__refactorClass(
            n('input', t),
            this.conf.classNames[i].check,
            'check'
          );
          n('input.' + r.toggle + ', input.' + r.check, t).each(function () {
            var t = n(this),
              i = t.closest('li'),
              e = t.hasClass(r.toggle) ? 'toggle' : 'check',
              f = t.attr('id') || u.__getUniqueId();
            i.children('label[for="' + f + '"]').length ||
              (t.attr('id', f),
              i.prepend(t),
              n(
                '<label for="' + f + '" class="' + r[e] + '"></label>'
              ).insertBefore(i.children('a, span').last()));
          });
        });
      },
      add: function () {
        r = n[t]._c;
        u = n[t]._d;
        f = n[t]._e;
        r.add('toggle check');
      },
      clickAnchor: function () {},
    };
    n[t].configuration.classNames[i] = { toggle: 'Toggle', check: 'Check' };
  })(jQuery);
!(function (n, t) {
  function r(t, r) {
    this.element = t;
    this.$element = n(this.element);
    this.options = n.extend({}, u, r);
    this._defaults = u;
    this._name = i;
    this.init();
  }
  function f() {
    return t.innerHeight ? t.innerHeight : n(t).height();
  }
  function e(t) {
    n(t).each(function () {
      var t = new Image();
      t.src = this;
      t.width > 0 &&
        n('<img />')
          .attr('src', this)
          .addClass('litebox-preload')
          .appendTo('body')
          .hide();
    });
  }
  var i = 'liteBox',
    u = {
      revealSpeed: 400,
      background: 'rgba(0,0,0,.8)',
      overlayClose: !0,
      escKey: !0,
      navKey: !0,
      closeTip: 'tip-l-fade',
      closeTipText: 'Close',
      prevTip: 'tip-t-fade',
      prevTipText: 'Previous',
      nextTip: 'tip-t-fade',
      nextTipText: 'Next',
      callbackInit: function () {},
      callbackBeforeOpen: function () {},
      callbackAfterOpen: function () {},
      callbackBeforeClose: function () {},
      callbackAfterClose: function () {},
      callbackError: function () {},
      callbackPrev: function () {},
      callbackNext: function () {},
      errorMessage: 'Error loading content.',
    };
  r.prototype = {
    init: function () {
      var t = this;
      this.$element.on('click', function (n) {
        n.preventDefault();
        t.openLitebox();
      });
      keyEsc = 27;
      keyLeft = 37;
      keyRight = 39;
      n('body')
        .off('keyup')
        .on('keyup', function (i) {
          t.options.escKey && i.keyCode == keyEsc && t.closeLitebox();
          t.options.navKey &&
            i.keyCode == keyLeft &&
            n('.litebox-prev').trigger('click');
          t.options.navKey &&
            i.keyCode == keyRight &&
            n('.litebox-next').trigger('click');
        });
      this.options.callbackInit.call(this);
    },
    openLitebox: function () {
      var i = this,
        t;
      if (
        (this.options.callbackBeforeOpen.call(this),
        i.buildLitebox(),
        (t = this.$element),
        i.populateLitebox(t),
        i.options.overlayClose &&
          $litebox.on('click', function (t) {
            (t.target === this ||
              n(t.target).hasClass('litebox-container') ||
              n(t.target).hasClass('litebox-error')) &&
              i.closeLitebox();
          }),
        $close.on('click', function () {
          i.closeLitebox();
        }),
        this.$element.attr('data-litebox-group'))
      ) {
        var i = this,
          f = this.$element.attr('data-litebox-group'),
          r = n(
            '[data-litebox-group="' +
              this.$element.attr('data-litebox-group') +
              '"]'
          ),
          u = [];
        n('[data-litebox-group="' + f + '"]').each(function () {
          var t = n(this).attr('href');
          u.push(t);
        });
        e(u);
        n('.litebox-nav').show();
        $prevNav.off('click').on('click', function () {
          i.options.callbackPrev.call(this);
          var u = r.index(t);
          t = r.eq(u - 1);
          n(t).length || (t = r.last());
          i.populateLitebox(t);
        });
        $nextNav.off('click').on('click', function () {
          i.options.callbackNext.call(this);
          var u = r.index(t);
          t = r.eq(u + 1);
          n(t).length || (t = r.first());
          i.populateLitebox(t);
        });
      }
      this.options.callbackAfterOpen.call(this);
    },
    buildLitebox: function () {
      $litebox = n('<div>', { class: 'litebox-overlay' });
      $close = n('<div>', {
        class: 'litebox-close ' + this.options.closeTip,
        'data-tooltip': this.options.closeTipText,
      });
      $text = n('<div>', { class: 'litebox-text' });
      $error = n(
        '<div class="litebox-error"><span>' +
          this.options.errorMessage +
          '</span></div>'
      );
      $prevNav = n('<div>', {
        class: 'litebox-nav litebox-prev ' + this.options.prevTip,
        'data-tooltip': this.options.prevTipText,
      });
      $nextNav = n('<div>', {
        class: 'litebox-nav litebox-next ' + this.options.nextTip,
        'data-tooltip': this.options.nextTipText,
      });
      $container = n('<div>', { class: 'litebox-container' });
      $loader = n('<div>', { class: 'litebox-loader' });
      n('body').prepend(
        $litebox.css({ 'background-color': this.options.background })
      );
      $litebox.append($close, $text, $prevNav, $nextNav, $container);
      $litebox.fadeIn(this.options.revealSpeed);
    },
    populateLitebox: function (t) {
      var f = this,
        r = t.attr('href'),
        e = n('.litebox-content'),
        o,
        s,
        i,
        u;
      $litebox.append($loader);
      o = t.attr('data-litebox-text');
      ('undefined' == typeof o || '' == o
        ? (n('.litebox-text').removeClass('active'), n('.litebox-text').html())
        : (n('.litebox-text').html(o), n('.litebox-text').addClass('active')),
      null !== r.match(/\.(jpeg|jpg|gif|png|bmp)/i))
        ? ((s = n('<img>', { src: r, class: 'litebox-content' })),
          f.transitionContent('image', e, s),
          n('img.litebox-content').imagesLoaded(function () {
            $loader.remove();
          }),
          s.error(function () {
            f.liteboxError();
            $loader.remove();
          }))
        : (videoURL = r.match(
            /(youtube|youtu|vimeo|dailymotion|kickstarter)\.(com|be)\/((watch\?v=([-\w]+))|(video\/([-\w]+))|(projects\/([-\w]+)\/([-\w]+))|([-\w]+))/
          ))
        ? ((i = ''),
          ('youtube' == videoURL[1] &&
            (i = 'http://www.youtube.com/v/' + videoURL[5]),
          'youtu' == videoURL[1] &&
            (i = 'http://www.youtube.com/v/' + videoURL[3]),
          'vimeo' == videoURL[1] &&
            (i = 'http://player.vimeo.com/video/' + videoURL[3]),
          'dailymotion' == videoURL[1] &&
            (i = 'https://www.dailymotion.com/embed/video/' + videoURL[7]),
          'kickstarter' == videoURL[1] &&
            (i =
              'https://www.kickstarter.com/projects/' +
              videoURL[9] +
              '/' +
              videoURL[10] +
              '/widget/video.html'),
          i) &&
            ((u = n('<iframe>', {
              src: i,
              frameborder: '0',
              vspace: '0',
              hspace: '0',
              scrolling: 'no',
              allowfullscreen: '',
              class: 'litebox-content',
              style: 'background: #000',
            })),
            f.transitionContent('embed', e, u),
            u.load(function () {
              $loader.remove();
            })))
        : '#' == r.substring(0, 1)
        ? (n(r).length
            ? (($html = n('<div>', {
                class: 'litebox-content litebox-inline-html',
              })),
              $html.append(n(r).clone()),
              f.transitionContent('inline', e, $html))
            : f.liteboxError(),
          $loader.remove())
        : ((u = n('<iframe>', {
            src: r,
            frameborder: '0',
            vspace: '0',
            hspace: '0',
            scrolling: 'auto',
            class: 'litebox-content',
            allowfullscreen: '',
          })),
          f.transitionContent('iframe', e, u),
          u.load(function () {
            $loader.remove();
          }));
    },
    transitionContent: function (i, r, u) {
      var f = this;
      'inline' != i && $container.removeClass('litebox-scroll');
      r.remove();
      $container.append(u);
      'inline' == i && $container.addClass('litebox-scroll');
      f.centerContent();
      n(t).on('resize', function () {
        f.centerContent();
      });
    },
    centerContent: function () {
      $litebox.css({ height: f() });
      $container.css({ 'line-height': $container.height() + 'px' });
      'undefined' != typeof $html &&
        n('.litebox-inline-html').outerHeight() < $container.height() &&
        n('.litebox-inline-html').css({
          'margin-top':
            '-' + n('.litebox-inline-html').outerHeight() / 2 + 'px',
          top: '50%',
        });
    },
    closeLitebox: function () {
      this.options.callbackBeforeClose.call(this);
      $litebox.fadeOut(this.options.revealSpeed, function () {
        n('.litebox-nav').hide();
        $litebox.empty().remove();
        n('.litebox-preload').remove();
      });
      n('.tipsy').fadeOut(this.options.revealSpeed, function () {
        n(this).remove();
      });
      n('.litebox-prev').off('click');
      n('.litebox-next').off('click');
      this.options.callbackAfterClose.call(this);
    },
    liteboxError: function () {
      this.options.callbackError.call(this);
      $container.append($error);
    },
  };
  n.fn[i] = function (t) {
    return this.each(function () {
      n.data(this, i) || n.data(this, i, new r(this, t));
    });
  };
})(jQuery, window, document);
!(function (n, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? (module.exports = t())
    : 'function' == typeof define && define.amd
    ? define(t)
    : (n.moment = t());
})(this, function () {
  'use strict';
  function t() {
    return ae.apply(null, arguments);
  }
  function vo(n) {
    ae = n;
  }
  function pt(n) {
    return (
      n instanceof Array ||
      '[object Array]' === Object.prototype.toString.call(n)
    );
  }
  function ai(n) {
    return (
      n instanceof Date || '[object Date]' === Object.prototype.toString.call(n)
    );
  }
  function bu(n, t) {
    for (var r = [], i = 0; i < n.length; ++i) r.push(t(n[i], i));
    return r;
  }
  function w(n, t) {
    return Object.prototype.hasOwnProperty.call(n, t);
  }
  function wt(n, t) {
    for (var i in t) w(t, i) && (n[i] = t[i]);
    return (
      w(t, 'toString') && (n.toString = t.toString),
      w(t, 'valueOf') && (n.valueOf = t.valueOf),
      n
    );
  }
  function it(n, t, i, r) {
    return pf(n, t, i, r, !0).utc();
  }
  function yo() {
    return {
      empty: !1,
      unusedTokens: [],
      unusedInput: [],
      overflow: -2,
      charsLeftOver: 0,
      nullInput: !1,
      invalidMonth: null,
      invalidFormat: !1,
      userInvalidated: !1,
      iso: !1,
      parsedDateParts: [],
      meridiem: null,
    };
  }
  function f(n) {
    return null == n._pf && (n._pf = yo()), n._pf;
  }
  function sr(n) {
    if (null == n._isValid) {
      var t = f(n),
        i = ve.call(t.parsedDateParts, function (n) {
          return null != n;
        });
      n._isValid =
        !isNaN(n._d.getTime()) &&
        t.overflow < 0 &&
        !t.empty &&
        !t.invalidMonth &&
        !t.invalidWeekday &&
        !t.nullInput &&
        !t.invalidFormat &&
        !t.userInvalidated &&
        (!t.meridiem || (t.meridiem && i));
      n._strict &&
        (n._isValid =
          n._isValid &&
          0 === t.charsLeftOver &&
          0 === t.unusedTokens.length &&
          void 0 === t.bigHour);
    }
    return n._isValid;
  }
  function vi(n) {
    var t = it(NaN);
    return null != n ? wt(f(t), n) : (f(t).userInvalidated = !0), t;
  }
  function p(n) {
    return void 0 === n;
  }
  function hr(n, t) {
    var u, i, r;
    if (
      (p(t._isAMomentObject) || (n._isAMomentObject = t._isAMomentObject),
      p(t._i) || (n._i = t._i),
      p(t._f) || (n._f = t._f),
      p(t._l) || (n._l = t._l),
      p(t._strict) || (n._strict = t._strict),
      p(t._tzm) || (n._tzm = t._tzm),
      p(t._isUTC) || (n._isUTC = t._isUTC),
      p(t._offset) || (n._offset = t._offset),
      p(t._pf) || (n._pf = f(t)),
      p(t._locale) || (n._locale = t._locale),
      fu.length > 0)
    )
      for (u in fu) (i = fu[u]), (r = t[i]), p(r) || (n[i] = r);
    return n;
  }
  function ei(n) {
    hr(this, n);
    this._d = new Date(null != n._d ? n._d.getTime() : NaN);
    eu === !1 && ((eu = !0), t.updateOffset(this), (eu = !1));
  }
  function st(n) {
    return n instanceof ei || (null != n && null != n._isAMomentObject);
  }
  function b(n) {
    return 0 > n ? Math.ceil(n) : Math.floor(n);
  }
  function e(n) {
    var t = +n,
      i = 0;
    return 0 !== t && isFinite(t) && (i = b(t)), i;
  }
  function ku(n, t, i) {
    for (
      var f = Math.min(n.length, t.length),
        o = Math.abs(n.length - t.length),
        u = 0,
        r = 0;
      f > r;
      r++
    )
      ((i && n[r] !== t[r]) || (!i && e(n[r]) !== e(t[r]))) && u++;
    return u + o;
  }
  function du(n) {
    t.suppressDeprecationWarnings === !1 &&
      'undefined' != typeof console &&
      console.warn &&
      console.warn('Deprecation warning: ' + n);
  }
  function d(n, i) {
    var r = !0;
    return wt(function () {
      return (
        null != t.deprecationHandler && t.deprecationHandler(null, n),
        r &&
          (du(
            n +
              '\nArguments: ' +
              Array.prototype.slice.call(arguments).join(', ') +
              '\n' +
              new Error().stack
          ),
          (r = !1)),
        i.apply(this, arguments)
      );
    }, i);
  }
  function cr(n, i) {
    null != t.deprecationHandler && t.deprecationHandler(n, i);
    ye[n] || (du(i), (ye[n] = !0));
  }
  function ht(n) {
    return (
      n instanceof Function ||
      '[object Function]' === Object.prototype.toString.call(n)
    );
  }
  function gu(n) {
    return '[object Object]' === Object.prototype.toString.call(n);
  }
  function po(n) {
    var t, i;
    for (i in n) (t = n[i]), ht(t) ? (this[i] = t) : (this['_' + i] = t);
    this._config = n;
    this._ordinalParseLenient = new RegExp(
      this._ordinalParse.source + '|' + /\d{1,2}/.source
    );
  }
  function lr(n, t) {
    var i,
      r = wt({}, n);
    for (i in t)
      w(t, i) &&
        (gu(n[i]) && gu(t[i])
          ? ((r[i] = {}), wt(r[i], n[i]), wt(r[i], t[i]))
          : null != t[i]
          ? (r[i] = t[i])
          : delete r[i]);
    return r;
  }
  function ar(n) {
    null != n && this.set(n);
  }
  function nf(n) {
    return n ? n.toLowerCase().replace('_', '-') : n;
  }
  function wo(n) {
    for (var i, t, f, r, u = 0; u < n.length; ) {
      for (
        r = nf(n[u]).split('-'),
          i = r.length,
          t = nf(n[u + 1]),
          t = t ? t.split('-') : null;
        i > 0;

      ) {
        if ((f = tf(r.slice(0, i).join('-')))) return f;
        if (t && t.length >= i && ku(r, t, !0) >= i - 1) break;
        i--;
      }
      u++;
    }
    return null;
  }
  function tf(n) {
    var t = null;
    if (!l[n] && 'undefined' != typeof module && module && module.exports)
      try {
        t = nr._abbr;
        require('./locale/' + n);
        ti(t);
      } catch (i) {}
    return l[n];
  }
  function ti(n, t) {
    var i;
    return n && ((i = p(t) ? ct(n) : rf(n, t)), i && (nr = i)), nr._abbr;
  }
  function rf(n, t) {
    return null !== t
      ? ((t.abbr = n),
        null != l[n]
          ? (cr(
              'defineLocaleOverride',
              'use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale'
            ),
            (t = lr(l[n]._config, t)))
          : null != t.parentLocale &&
            (null != l[t.parentLocale]
              ? (t = lr(l[t.parentLocale]._config, t))
              : cr(
                  'parentLocaleUndefined',
                  'specified parentLocale is not defined yet'
                )),
        (l[n] = new ar(t)),
        ti(n),
        l[n])
      : (delete l[n], null);
  }
  function bo(n, t) {
    if (null != t) {
      var i;
      null != l[n] && (t = lr(l[n]._config, t));
      i = new ar(t);
      i.parentLocale = l[n];
      l[n] = i;
      ti(n);
    } else null != l[n] && (null != l[n].parentLocale ? (l[n] = l[n].parentLocale) : null != l[n] && delete l[n]);
    return l[n];
  }
  function ct(n) {
    var t;
    if ((n && n._locale && n._locale._abbr && (n = n._locale._abbr), !n))
      return nr;
    if (!pt(n)) {
      if ((t = tf(n))) return t;
      n = [n];
    }
    return wo(n);
  }
  function ko() {
    return pe(l);
  }
  function y(n, t) {
    var i = n.toLowerCase();
    ci[i] = ci[i + 's'] = ci[t] = n;
  }
  function g(n) {
    if ('string' == typeof n) return ci[n] || ci[n.toLowerCase()];
  }
  function uf(n) {
    var i,
      t,
      r = {};
    for (t in n) w(n, t) && ((i = g(t)), i && (r[i] = n[t]));
    return r;
  }
  function ii(n, i) {
    return function (r) {
      return null != r
        ? (ff(this, n, r), t.updateOffset(this, i), this)
        : yi(this, n);
    };
  }
  function yi(n, t) {
    return n.isValid() ? n._d['get' + (n._isUTC ? 'UTC' : '') + t]() : NaN;
  }
  function ff(n, t, i) {
    n.isValid() && n._d['set' + (n._isUTC ? 'UTC' : '') + t](i);
  }
  function ef(n, t) {
    var i;
    if ('object' == typeof n) for (i in n) this.set(i, n[i]);
    else if (((n = g(n)), ht(this[n]))) return this[n](t);
    return this;
  }
  function ut(n, t, i) {
    var r = '' + Math.abs(n),
      u = t - r.length,
      f = n >= 0;
    return (
      (f ? (i ? '+' : '') : '-') +
      Math.pow(10, Math.max(0, u)).toString().substr(1) +
      r
    );
  }
  function r(n, t, i, r) {
    var u = r;
    'string' == typeof r &&
      (u = function () {
        return this[r]();
      });
    n && (ui[n] = u);
    t &&
      (ui[t[0]] = function () {
        return ut(u.apply(this, arguments), t[1], t[2]);
      });
    i &&
      (ui[i] = function () {
        return this.localeData().ordinal(u.apply(this, arguments), n);
      });
  }
  function go(n) {
    return n.match(/\[[\s\S]/)
      ? n.replace(/^\[|\]$/g, '')
      : n.replace(/\\/g, '');
  }
  function ns(n) {
    for (var t = n.match(we), i = 0, r = t.length; r > i; i++)
      t[i] = ui[t[i]] ? ui[t[i]] : go(t[i]);
    return function (i) {
      for (var f = '', u = 0; r > u; u++)
        f += t[u] instanceof Function ? t[u].call(i, n) : t[u];
      return f;
    };
  }
  function vr(n, t) {
    return n.isValid()
      ? ((t = of(t, n.localeData())), (ou[t] = ou[t] || ns(t)), ou[t](n))
      : n.localeData().invalidDate();
  }
  function of(n, t) {
    function r(n) {
      return t.longDateFormat(n) || n;
    }
    var i = 5;
    for (tr.lastIndex = 0; i >= 0 && tr.test(n); )
      (n = n.replace(tr, r)), (tr.lastIndex = 0), (i -= 1);
    return n;
  }
  function i(n, t, i) {
    cu[n] = ht(t)
      ? t
      : function (n) {
          return n && i ? i : t;
        };
  }
  function ts(n, t) {
    return w(cu, n) ? cu[n](t._strict, t._locale) : new RegExp(is(n));
  }
  function is(n) {
    return bt(
      n
        .replace('\\', '')
        .replace(
          /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
          function (n, t, i, r, u) {
            return t || i || r || u;
          }
        )
    );
  }
  function bt(n) {
    return n.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
  function s(n, t) {
    var i,
      r = t;
    for (
      'string' == typeof n && (n = [n]),
        'number' == typeof t &&
          (r = function (n, i) {
            i[t] = e(n);
          }),
        i = 0;
      i < n.length;
      i++
    )
      lu[n[i]] = r;
  }
  function oi(n, t) {
    s(n, function (n, i, r, u) {
      r._w = r._w || {};
      t(n, r._w, r, u);
    });
  }
  function rs(n, t, i) {
    null != t && w(lu, n) && lu[n](t, i._a, i, n);
  }
  function yr(n, t) {
    return new Date(Date.UTC(n, t + 1, 0)).getUTCDate();
  }
  function us(n, t) {
    return pt(this._months)
      ? this._months[n.month()]
      : this._months[no.test(t) ? 'format' : 'standalone'][n.month()];
  }
  function fs(n, t) {
    return pt(this._monthsShort)
      ? this._monthsShort[n.month()]
      : this._monthsShort[no.test(t) ? 'format' : 'standalone'][n.month()];
  }
  function es(n, t, i) {
    var u,
      r,
      e,
      f = n.toLocaleLowerCase();
    if (!this._monthsParse)
      for (
        this._monthsParse = [],
          this._longMonthsParse = [],
          this._shortMonthsParse = [],
          u = 0;
        12 > u;
        ++u
      )
        (e = it([2e3, u])),
          (this._shortMonthsParse[u] = this.monthsShort(
            e,
            ''
          ).toLocaleLowerCase()),
          (this._longMonthsParse[u] = this.months(e, '').toLocaleLowerCase());
    return i
      ? 'MMM' === t
        ? ((r = a.call(this._shortMonthsParse, f)), -1 !== r ? r : null)
        : ((r = a.call(this._longMonthsParse, f)), -1 !== r ? r : null)
      : 'MMM' === t
      ? ((r = a.call(this._shortMonthsParse, f)),
        -1 !== r
          ? r
          : ((r = a.call(this._longMonthsParse, f)), -1 !== r ? r : null))
      : ((r = a.call(this._longMonthsParse, f)),
        -1 !== r
          ? r
          : ((r = a.call(this._shortMonthsParse, f)), -1 !== r ? r : null));
  }
  function os(n, t, i) {
    var r, u, f;
    if (this._monthsParseExact) return es.call(this, n, t, i);
    for (
      this._monthsParse ||
        ((this._monthsParse = []),
        (this._longMonthsParse = []),
        (this._shortMonthsParse = [])),
        r = 0;
      12 > r;
      r++
    )
      if (
        ((u = it([2e3, r])),
        i &&
          !this._longMonthsParse[r] &&
          ((this._longMonthsParse[r] = new RegExp(
            '^' + this.months(u, '').replace('.', '') + '$',
            'i'
          )),
          (this._shortMonthsParse[r] = new RegExp(
            '^' + this.monthsShort(u, '').replace('.', '') + '$',
            'i'
          ))),
        i ||
          this._monthsParse[r] ||
          ((f = '^' + this.months(u, '') + '|^' + this.monthsShort(u, '')),
          (this._monthsParse[r] = new RegExp(f.replace('.', ''), 'i'))),
        i && 'MMMM' === t && this._longMonthsParse[r].test(n)) ||
        (i && 'MMM' === t && this._shortMonthsParse[r].test(n)) ||
        (!i && this._monthsParse[r].test(n))
      )
        return r;
  }
  function sf(n, t) {
    var i;
    if (!n.isValid()) return n;
    if ('string' == typeof t)
      if (/^\d+$/.test(t)) t = e(t);
      else if (((t = n.localeData().monthsParse(t)), 'number' != typeof t))
        return n;
    return (
      (i = Math.min(n.date(), yr(n.year(), t))),
      n._d['set' + (n._isUTC ? 'UTC' : '') + 'Month'](t, i),
      n
    );
  }
  function hf(n) {
    return null != n
      ? (sf(this, n), t.updateOffset(this, !0), this)
      : yi(this, 'Month');
  }
  function ss() {
    return yr(this.year(), this.month());
  }
  function hs(n) {
    return this._monthsParseExact
      ? (w(this, '_monthsRegex') || cf.call(this),
        n ? this._monthsShortStrictRegex : this._monthsShortRegex)
      : this._monthsShortStrictRegex && n
      ? this._monthsShortStrictRegex
      : this._monthsShortRegex;
  }
  function cs(n) {
    return this._monthsParseExact
      ? (w(this, '_monthsRegex') || cf.call(this),
        n ? this._monthsStrictRegex : this._monthsRegex)
      : this._monthsStrictRegex && n
      ? this._monthsStrictRegex
      : this._monthsRegex;
  }
  function cf() {
    function f(n, t) {
      return t.length - n.length;
    }
    for (var i, r = [], u = [], t = [], n = 0; 12 > n; n++)
      (i = it([2e3, n])),
        r.push(this.monthsShort(i, '')),
        u.push(this.months(i, '')),
        t.push(this.months(i, '')),
        t.push(this.monthsShort(i, ''));
    for (r.sort(f), u.sort(f), t.sort(f), n = 0; 12 > n; n++)
      (r[n] = bt(r[n])), (u[n] = bt(u[n])), (t[n] = bt(t[n]));
    this._monthsRegex = new RegExp('^(' + t.join('|') + ')', 'i');
    this._monthsShortRegex = this._monthsRegex;
    this._monthsStrictRegex = new RegExp('^(' + u.join('|') + ')', 'i');
    this._monthsShortStrictRegex = new RegExp('^(' + r.join('|') + ')', 'i');
  }
  function pr(n) {
    var i,
      t = n._a;
    return (
      t &&
        -2 === f(n).overflow &&
        ((i =
          t[ft] < 0 || t[ft] > 11
            ? ft
            : t[rt] < 1 || t[rt] > yr(t[nt], t[ft])
            ? rt
            : t[v] < 0 ||
              t[v] > 24 ||
              (24 === t[v] && (0 !== t[tt] || 0 !== t[et] || 0 !== t[ni]))
            ? v
            : t[tt] < 0 || t[tt] > 59
            ? tt
            : t[et] < 0 || t[et] > 59
            ? et
            : t[ni] < 0 || t[ni] > 999
            ? ni
            : -1),
        f(n)._overflowDayOfYear && (nt > i || i > rt) && (i = rt),
        f(n)._overflowWeeks && -1 === i && (i = yv),
        f(n)._overflowWeekday && -1 === i && (i = pv),
        (f(n).overflow = i)),
      n
    );
  }
  function lf(n) {
    var t,
      r,
      o,
      e,
      u,
      s,
      h = n._i,
      i = gv.exec(h) || ny.exec(h);
    if (i) {
      for (f(n).iso = !0, t = 0, r = or.length; r > t; t++)
        if (or[t][1].exec(i[1])) {
          e = or[t][0];
          o = or[t][2] !== !1;
          break;
        }
      if (null == e) return void (n._isValid = !1);
      if (i[3]) {
        for (t = 0, r = au.length; r > t; t++)
          if (au[t][1].exec(i[3])) {
            u = (i[2] || ' ') + au[t][0];
            break;
          }
        if (null == u) return void (n._isValid = !1);
      }
      if (!o && null != u) return void (n._isValid = !1);
      if (i[4]) {
        if (!ty.exec(i[4])) return void (n._isValid = !1);
        s = 'Z';
      }
      n._f = e + (u || '') + (s || '');
      br(n);
    } else n._isValid = !1;
  }
  function ls(n) {
    var i = iy.exec(n._i);
    return null !== i
      ? void (n._d = new Date(+i[1]))
      : (lf(n),
        void (
          n._isValid === !1 && (delete n._isValid, t.createFromInputFallback(n))
        ));
  }
  function as(n, t, i, r, u, f, e) {
    var o = new Date(n, t, i, r, u, f, e);
    return (
      100 > n && n >= 0 && isFinite(o.getFullYear()) && o.setFullYear(n), o
    );
  }
  function pi(n) {
    var t = new Date(Date.UTC.apply(null, arguments));
    return (
      100 > n && n >= 0 && isFinite(t.getUTCFullYear()) && t.setUTCFullYear(n),
      t
    );
  }
  function si(n) {
    return af(n) ? 366 : 365;
  }
  function af(n) {
    return (n % 4 == 0 && n % 100 != 0) || n % 400 == 0;
  }
  function vs() {
    return af(this.year());
  }
  function wi(n, t, i) {
    var r = 7 + t - i,
      u = (7 + pi(n, 0, r).getUTCDay() - t) % 7;
    return -u + r - 1;
  }
  function vf(n, t, i, r, u) {
    var f,
      o,
      s = (7 + i - r) % 7,
      h = wi(n, r, u),
      e = 1 + 7 * (t - 1) + s + h;
    return (
      0 >= e
        ? ((f = n - 1), (o = si(f) + e))
        : e > si(n)
        ? ((f = n + 1), (o = e - si(n)))
        : ((f = n), (o = e)),
      { year: f, dayOfYear: o }
    );
  }
  function hi(n, t, i) {
    var f,
      r,
      e = wi(n.year(), t, i),
      u = Math.floor((n.dayOfYear() - e - 1) / 7) + 1;
    return (
      1 > u
        ? ((r = n.year() - 1), (f = u + kt(r, t, i)))
        : u > kt(n.year(), t, i)
        ? ((f = u - kt(n.year(), t, i)), (r = n.year() + 1))
        : ((r = n.year()), (f = u)),
      { week: f, year: r }
    );
  }
  function kt(n, t, i) {
    var r = wi(n, t, i),
      u = wi(n + 1, t, i);
    return (si(n) - r + u) / 7;
  }
  function ri(n, t, i) {
    return null != n ? n : null != t ? t : i;
  }
  function ys(n) {
    var i = new Date(t.now());
    return n._useUTC
      ? [i.getUTCFullYear(), i.getUTCMonth(), i.getUTCDate()]
      : [i.getFullYear(), i.getMonth(), i.getDate()];
  }
  function wr(n) {
    var t,
      i,
      r,
      u,
      e = [];
    if (!n._d) {
      for (
        r = ys(n),
          n._w && null == n._a[rt] && null == n._a[ft] && ps(n),
          n._dayOfYear &&
            ((u = ri(n._a[nt], r[nt])),
            n._dayOfYear > si(u) && (f(n)._overflowDayOfYear = !0),
            (i = pi(u, 0, n._dayOfYear)),
            (n._a[ft] = i.getUTCMonth()),
            (n._a[rt] = i.getUTCDate())),
          t = 0;
        3 > t && null == n._a[t];
        ++t
      )
        n._a[t] = e[t] = r[t];
      for (; 7 > t; t++)
        n._a[t] = e[t] = null == n._a[t] ? (2 === t ? 1 : 0) : n._a[t];
      24 === n._a[v] &&
        0 === n._a[tt] &&
        0 === n._a[et] &&
        0 === n._a[ni] &&
        ((n._nextDay = !0), (n._a[v] = 0));
      n._d = (n._useUTC ? pi : as).apply(null, e);
      null != n._tzm && n._d.setUTCMinutes(n._d.getUTCMinutes() - n._tzm);
      n._nextDay && (n._a[v] = 24);
    }
  }
  function ps(n) {
    var t, o, u, i, r, e, c, s;
    t = n._w;
    null != t.GG || null != t.W || null != t.E
      ? ((r = 1),
        (e = 4),
        (o = ri(t.GG, n._a[nt], hi(h(), 1, 4).year)),
        (u = ri(t.W, 1)),
        (i = ri(t.E, 1)),
        (1 > i || i > 7) && (s = !0))
      : ((r = n._locale._week.dow),
        (e = n._locale._week.doy),
        (o = ri(t.gg, n._a[nt], hi(h(), r, e).year)),
        (u = ri(t.w, 1)),
        null != t.d
          ? ((i = t.d), (0 > i || i > 6) && (s = !0))
          : null != t.e
          ? ((i = t.e + r), (t.e < 0 || t.e > 6) && (s = !0))
          : (i = r));
    1 > u || u > kt(o, r, e)
      ? (f(n)._overflowWeeks = !0)
      : null != s
      ? (f(n)._overflowWeekday = !0)
      : ((c = vf(o, u, i, r, e)),
        (n._a[nt] = c.year),
        (n._dayOfYear = c.dayOfYear));
  }
  function br(n) {
    if (n._f === t.ISO_8601) return void lf(n);
    n._a = [];
    f(n).empty = !0;
    for (
      var i,
        u,
        s,
        r = '' + n._i,
        c = r.length,
        h = 0,
        o = of(n._f, n._locale).match(we) || [],
        e = 0;
      e < o.length;
      e++
    )
      (u = o[e]),
        (i = (r.match(ts(u, n)) || [])[0]),
        i &&
          ((s = r.substr(0, r.indexOf(i))),
          s.length > 0 && f(n).unusedInput.push(s),
          (r = r.slice(r.indexOf(i) + i.length)),
          (h += i.length)),
        ui[u]
          ? (i ? (f(n).empty = !1) : f(n).unusedTokens.push(u), rs(u, i, n))
          : n._strict && !i && f(n).unusedTokens.push(u);
    f(n).charsLeftOver = c - h;
    r.length > 0 && f(n).unusedInput.push(r);
    f(n).bigHour === !0 &&
      n._a[v] <= 12 &&
      n._a[v] > 0 &&
      (f(n).bigHour = void 0);
    f(n).parsedDateParts = n._a.slice(0);
    f(n).meridiem = n._meridiem;
    n._a[v] = ws(n._locale, n._a[v], n._meridiem);
    wr(n);
    pr(n);
  }
  function ws(n, t, i) {
    var r;
    return null == i
      ? t
      : null != n.meridiemHour
      ? n.meridiemHour(t, i)
      : null != n.isPM
      ? ((r = n.isPM(i)), r && 12 > t && (t += 12), r || 12 !== t || (t = 0), t)
      : t;
  }
  function bs(n) {
    var t, e, u, r, i;
    if (0 === n._f.length)
      return (f(n).invalidFormat = !0), void (n._d = new Date(NaN));
    for (r = 0; r < n._f.length; r++)
      (i = 0),
        (t = hr({}, n)),
        null != n._useUTC && (t._useUTC = n._useUTC),
        (t._f = n._f[r]),
        br(t),
        sr(t) &&
          ((i += f(t).charsLeftOver),
          (i += 10 * f(t).unusedTokens.length),
          (f(t).score = i),
          (null == u || u > i) && ((u = i), (e = t)));
    wt(n, e || t);
  }
  function ks(n) {
    if (!n._d) {
      var t = uf(n._i);
      n._a = bu(
        [
          t.year,
          t.month,
          t.day || t.date,
          t.hour,
          t.minute,
          t.second,
          t.millisecond,
        ],
        function (n) {
          return n && parseInt(n, 10);
        }
      );
      wr(n);
    }
  }
  function ds(n) {
    var t = new ei(pr(yf(n)));
    return t._nextDay && (t.add(1, 'd'), (t._nextDay = void 0)), t;
  }
  function yf(n) {
    var t = n._i,
      i = n._f;
    return (
      (n._locale = n._locale || ct(n._l)),
      null === t || (void 0 === i && '' === t)
        ? vi({ nullInput: !0 })
        : ('string' == typeof t && (n._i = t = n._locale.preparse(t)),
          st(t)
            ? new ei(pr(t))
            : (pt(i) ? bs(n) : i ? br(n) : ai(t) ? (n._d = t) : gs(n),
              sr(n) || (n._d = null),
              n))
    );
  }
  function gs(n) {
    var i = n._i;
    void 0 === i
      ? (n._d = new Date(t.now()))
      : ai(i)
      ? (n._d = new Date(i.valueOf()))
      : 'string' == typeof i
      ? ls(n)
      : pt(i)
      ? ((n._a = bu(i.slice(0), function (n) {
          return parseInt(n, 10);
        })),
        wr(n))
      : 'object' == typeof i
      ? ks(n)
      : 'number' == typeof i
      ? (n._d = new Date(i))
      : t.createFromInputFallback(n);
  }
  function pf(n, t, i, r, u) {
    var f = {};
    return (
      'boolean' == typeof i && ((r = i), (i = void 0)),
      (f._isAMomentObject = !0),
      (f._useUTC = f._isUTC = u),
      (f._l = i),
      (f._i = n),
      (f._f = t),
      (f._strict = r),
      ds(f)
    );
  }
  function h(n, t, i, r) {
    return pf(n, t, i, r, !1);
  }
  function wf(n, t) {
    var r, i;
    if ((1 === t.length && pt(t[0]) && (t = t[0]), !t.length)) return h();
    for (r = t[0], i = 1; i < t.length; ++i)
      (!t[i].isValid() || t[i][n](r)) && (r = t[i]);
    return r;
  }
  function nh() {
    var n = [].slice.call(arguments, 0);
    return wf('isBefore', n);
  }
  function th() {
    var n = [].slice.call(arguments, 0);
    return wf('isAfter', n);
  }
  function bi(n) {
    var t = uf(n),
      i = t.year || 0,
      r = t.quarter || 0,
      u = t.month || 0,
      f = t.week || 0,
      e = t.day || 0,
      o = t.hour || 0,
      s = t.minute || 0,
      h = t.second || 0,
      c = t.millisecond || 0;
    this._milliseconds = +c + 1e3 * h + 6e4 * s + 36e5 * o;
    this._days = +e + 7 * f;
    this._months = +u + 3 * r + 12 * i;
    this._data = {};
    this._locale = ct();
    this._bubble();
  }
  function kr(n) {
    return n instanceof bi;
  }
  function bf(n, t) {
    r(n, 0, 0, function () {
      var n = this.utcOffset(),
        i = '+';
      return (
        0 > n && ((n = -n), (i = '-')),
        i + ut(~~(n / 60), 2) + t + ut(~~n % 60, 2)
      );
    });
  }
  function dr(n, t) {
    var r = (t || '').match(n) || [],
      f = r[r.length - 1] || [],
      i = (f + '').match(to) || ['-', 0, 0],
      u = +(60 * i[1]) + e(i[2]);
    return '+' === i[0] ? u : -u;
  }
  function gr(n, i) {
    var r, u;
    return i._isUTC
      ? ((r = i.clone()),
        (u = (st(n) || ai(n) ? n.valueOf() : h(n).valueOf()) - r.valueOf()),
        r._d.setTime(r._d.valueOf() + u),
        t.updateOffset(r, !1),
        r)
      : h(n).local();
  }
  function nu(n) {
    return 15 * -Math.round(n._d.getTimezoneOffset() / 15);
  }
  function ih(n, i) {
    var r,
      u = this._offset || 0;
    return this.isValid()
      ? null != n
        ? ('string' == typeof n
            ? (n = dr(er, n))
            : Math.abs(n) < 16 && (n = 60 * n),
          !this._isUTC && i && (r = nu(this)),
          (this._offset = n),
          (this._isUTC = !0),
          null != r && this.add(r, 'm'),
          u !== n &&
            (!i || this._changeInProgress
              ? te(this, lt(n - u, 'm'), 1, !1)
              : this._changeInProgress ||
                ((this._changeInProgress = !0),
                t.updateOffset(this, !0),
                (this._changeInProgress = null))),
          this)
        : this._isUTC
        ? u
        : nu(this)
      : null != n
      ? this
      : NaN;
  }
  function rh(n, t) {
    return null != n
      ? ('string' != typeof n && (n = -n), this.utcOffset(n, t), this)
      : -this.utcOffset();
  }
  function uh(n) {
    return this.utcOffset(0, n);
  }
  function fh(n) {
    return (
      this._isUTC &&
        (this.utcOffset(0, n),
        (this._isUTC = !1),
        n && this.subtract(nu(this), 'm')),
      this
    );
  }
  function eh() {
    return (
      this._tzm
        ? this.utcOffset(this._tzm)
        : 'string' == typeof this._i && this.utcOffset(dr(vv, this._i)),
      this
    );
  }
  function oh(n) {
    return this.isValid()
      ? ((n = n ? h(n).utcOffset() : 0), (this.utcOffset() - n) % 60 == 0)
      : !1;
  }
  function sh() {
    return (
      this.utcOffset() > this.clone().month(0).utcOffset() ||
      this.utcOffset() > this.clone().month(5).utcOffset()
    );
  }
  function hh() {
    var n, t;
    return p(this._isDSTShifted)
      ? ((n = {}),
        (hr(n, this), (n = yf(n)), n._a)
          ? ((t = n._isUTC ? it(n._a) : h(n._a)),
            (this._isDSTShifted = this.isValid() && ku(n._a, t.toArray()) > 0))
          : (this._isDSTShifted = !1),
        this._isDSTShifted)
      : this._isDSTShifted;
  }
  function ch() {
    return this.isValid() ? !this._isUTC : !1;
  }
  function lh() {
    return this.isValid() ? this._isUTC : !1;
  }
  function kf() {
    return this.isValid() ? this._isUTC && 0 === this._offset : !1;
  }
  function lt(n, t) {
    var u,
      f,
      o,
      i = n,
      r = null;
    return (
      kr(n)
        ? (i = { ms: n._milliseconds, d: n._days, M: n._months })
        : 'number' == typeof n
        ? ((i = {}), t ? (i[t] = n) : (i.milliseconds = n))
        : (r = io.exec(n))
        ? ((u = '-' === r[1] ? -1 : 1),
          (i = {
            y: 0,
            d: e(r[rt]) * u,
            h: e(r[v]) * u,
            m: e(r[tt]) * u,
            s: e(r[et]) * u,
            ms: e(r[ni]) * u,
          }))
        : (r = ro.exec(n))
        ? ((u = '-' === r[1] ? -1 : 1),
          (i = {
            y: dt(r[2], u),
            M: dt(r[3], u),
            w: dt(r[4], u),
            d: dt(r[5], u),
            h: dt(r[6], u),
            m: dt(r[7], u),
            s: dt(r[8], u),
          }))
        : null == i
        ? (i = {})
        : 'object' == typeof i &&
          ('from' in i || 'to' in i) &&
          ((o = ah(h(i.from), h(i.to))),
          (i = {}),
          (i.ms = o.milliseconds),
          (i.M = o.months)),
      (f = new bi(i)),
      kr(n) && w(n, '_locale') && (f._locale = n._locale),
      f
    );
  }
  function dt(n, t) {
    var i = n && parseFloat(n.replace(',', '.'));
    return (isNaN(i) ? 0 : i) * t;
  }
  function df(n, t) {
    var i = { milliseconds: 0, months: 0 };
    return (
      (i.months = t.month() - n.month() + 12 * (t.year() - n.year())),
      n.clone().add(i.months, 'M').isAfter(t) && --i.months,
      (i.milliseconds = +t - +n.clone().add(i.months, 'M')),
      i
    );
  }
  function ah(n, t) {
    var i;
    return n.isValid() && t.isValid()
      ? ((t = gr(t, n)),
        n.isBefore(t)
          ? (i = df(n, t))
          : ((i = df(t, n)),
            (i.milliseconds = -i.milliseconds),
            (i.months = -i.months)),
        i)
      : { milliseconds: 0, months: 0 };
  }
  function gf(n) {
    return 0 > n ? -1 * Math.round(-1 * n) : Math.round(n);
  }
  function ne(n, t) {
    return function (i, r) {
      var u, f;
      return (
        null === r ||
          isNaN(+r) ||
          (cr(
            t,
            'moment().' +
              t +
              '(period, number) is deprecated. Please use moment().' +
              t +
              '(number, period).'
          ),
          (f = i),
          (i = r),
          (r = f)),
        (i = 'string' == typeof i ? +i : i),
        (u = lt(i, r)),
        te(this, u, n),
        this
      );
    };
  }
  function te(n, i, r, u) {
    var o = i._milliseconds,
      f = gf(i._days),
      e = gf(i._months);
    n.isValid() &&
      ((u = null == u ? !0 : u),
      o && n._d.setTime(n._d.valueOf() + o * r),
      f && ff(n, 'Date', yi(n, 'Date') + f * r),
      e && sf(n, yi(n, 'Month') + e * r),
      u && t.updateOffset(n, f || e));
  }
  function vh(n, t) {
    var u = n || h(),
      f = gr(u, this).startOf('day'),
      i = this.diff(f, 'days', !0),
      r =
        -6 > i
          ? 'sameElse'
          : -1 > i
          ? 'lastWeek'
          : 0 > i
          ? 'lastDay'
          : 1 > i
          ? 'sameDay'
          : 2 > i
          ? 'nextDay'
          : 7 > i
          ? 'nextWeek'
          : 'sameElse',
      e = t && (ht(t[r]) ? t[r]() : t[r]);
    return this.format(e || this.localeData().calendar(r, this, h(u)));
  }
  function yh() {
    return new ei(this);
  }
  function ph(n, t) {
    var i = st(n) ? n : h(n);
    return this.isValid() && i.isValid()
      ? ((t = g(p(t) ? 'millisecond' : t)),
        'millisecond' === t
          ? this.valueOf() > i.valueOf()
          : i.valueOf() < this.clone().startOf(t).valueOf())
      : !1;
  }
  function wh(n, t) {
    var i = st(n) ? n : h(n);
    return this.isValid() && i.isValid()
      ? ((t = g(p(t) ? 'millisecond' : t)),
        'millisecond' === t
          ? this.valueOf() < i.valueOf()
          : this.clone().endOf(t).valueOf() < i.valueOf())
      : !1;
  }
  function bh(n, t, i, r) {
    return (
      (r = r || '()'),
      ('(' === r[0] ? this.isAfter(n, i) : !this.isBefore(n, i)) &&
        (')' === r[1] ? this.isBefore(t, i) : !this.isAfter(t, i))
    );
  }
  function kh(n, t) {
    var i,
      r = st(n) ? n : h(n);
    return this.isValid() && r.isValid()
      ? ((t = g(t || 'millisecond')),
        'millisecond' === t
          ? this.valueOf() === r.valueOf()
          : ((i = r.valueOf()),
            this.clone().startOf(t).valueOf() <= i &&
              i <= this.clone().endOf(t).valueOf()))
      : !1;
  }
  function dh(n, t) {
    return this.isSame(n, t) || this.isAfter(n, t);
  }
  function gh(n, t) {
    return this.isSame(n, t) || this.isBefore(n, t);
  }
  function nc(n, t, i) {
    var f, e, r, u;
    return this.isValid()
      ? ((f = gr(n, this)),
        f.isValid()
          ? ((e = 6e4 * (f.utcOffset() - this.utcOffset())),
            (t = g(t)),
            'year' === t || 'month' === t || 'quarter' === t
              ? ((u = tc(this, f)),
                'quarter' === t ? (u /= 3) : 'year' === t && (u /= 12))
              : ((r = this - f),
                (u =
                  'second' === t
                    ? r / 1e3
                    : 'minute' === t
                    ? r / 6e4
                    : 'hour' === t
                    ? r / 36e5
                    : 'day' === t
                    ? (r - e) / 864e5
                    : 'week' === t
                    ? (r - e) / 6048e5
                    : r)),
            i ? u : b(u))
          : NaN)
      : NaN;
  }
  function tc(n, t) {
    var r,
      f,
      u = 12 * (t.year() - n.year()) + (t.month() - n.month()),
      i = n.clone().add(u, 'months');
    return (
      0 > t - i
        ? ((r = n.clone().add(u - 1, 'months')), (f = (t - i) / (i - r)))
        : ((r = n.clone().add(u + 1, 'months')), (f = (t - i) / (r - i))),
      -(u + f) || 0
    );
  }
  function ic() {
    return this.clone().locale('en').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  }
  function rc() {
    var n = this.clone().utc();
    return 0 < n.year() && n.year() <= 9999
      ? ht(Date.prototype.toISOString)
        ? this.toDate().toISOString()
        : vr(n, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      : vr(n, 'YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
  }
  function uc(n) {
    n || (n = this.isUtc() ? t.defaultFormatUtc : t.defaultFormat);
    var i = vr(this, n);
    return this.localeData().postformat(i);
  }
  function fc(n, t) {
    return this.isValid() && ((st(n) && n.isValid()) || h(n).isValid())
      ? lt({ to: this, from: n }).locale(this.locale()).humanize(!t)
      : this.localeData().invalidDate();
  }
  function ec(n) {
    return this.from(h(), n);
  }
  function oc(n, t) {
    return this.isValid() && ((st(n) && n.isValid()) || h(n).isValid())
      ? lt({ from: this, to: n }).locale(this.locale()).humanize(!t)
      : this.localeData().invalidDate();
  }
  function sc(n) {
    return this.to(h(), n);
  }
  function ie(n) {
    var t;
    return void 0 === n
      ? this._locale._abbr
      : ((t = ct(n)), null != t && (this._locale = t), this);
  }
  function re() {
    return this._locale;
  }
  function hc(n) {
    switch ((n = g(n))) {
      case 'year':
        this.month(0);
      case 'quarter':
      case 'month':
        this.date(1);
      case 'week':
      case 'isoWeek':
      case 'day':
      case 'date':
        this.hours(0);
      case 'hour':
        this.minutes(0);
      case 'minute':
        this.seconds(0);
      case 'second':
        this.milliseconds(0);
    }
    return (
      'week' === n && this.weekday(0),
      'isoWeek' === n && this.isoWeekday(1),
      'quarter' === n && this.month(3 * Math.floor(this.month() / 3)),
      this
    );
  }
  function cc(n) {
    return (
      (n = g(n)),
      void 0 === n || 'millisecond' === n
        ? this
        : ('date' === n && (n = 'day'),
          this.startOf(n)
            .add(1, 'isoWeek' === n ? 'week' : n)
            .subtract(1, 'ms'))
    );
  }
  function lc() {
    return this._d.valueOf() - 6e4 * (this._offset || 0);
  }
  function ac() {
    return Math.floor(this.valueOf() / 1e3);
  }
  function vc() {
    return this._offset ? new Date(this.valueOf()) : this._d;
  }
  function yc() {
    var n = this;
    return [
      n.year(),
      n.month(),
      n.date(),
      n.hour(),
      n.minute(),
      n.second(),
      n.millisecond(),
    ];
  }
  function pc() {
    var n = this;
    return {
      years: n.year(),
      months: n.month(),
      date: n.date(),
      hours: n.hours(),
      minutes: n.minutes(),
      seconds: n.seconds(),
      milliseconds: n.milliseconds(),
    };
  }
  function wc() {
    return this.isValid() ? this.toISOString() : null;
  }
  function bc() {
    return sr(this);
  }
  function kc() {
    return wt({}, f(this));
  }
  function dc() {
    return f(this).overflow;
  }
  function gc() {
    return {
      input: this._i,
      format: this._f,
      locale: this._locale,
      isUTC: this._isUTC,
      strict: this._strict,
    };
  }
  function ki(n, t) {
    r(0, [n, n.length], 0, t);
  }
  function nl(n) {
    return ue.call(
      this,
      n,
      this.week(),
      this.weekday(),
      this.localeData()._week.dow,
      this.localeData()._week.doy
    );
  }
  function tl(n) {
    return ue.call(this, n, this.isoWeek(), this.isoWeekday(), 1, 4);
  }
  function il() {
    return kt(this.year(), 1, 4);
  }
  function rl() {
    var n = this.localeData()._week;
    return kt(this.year(), n.dow, n.doy);
  }
  function ue(n, t, i, r, u) {
    var f;
    return null == n
      ? hi(this, r, u).year
      : ((f = kt(n, r, u)), t > f && (t = f), ul.call(this, n, t, i, r, u));
  }
  function ul(n, t, i, r, u) {
    var e = vf(n, t, i, r, u),
      f = pi(e.year, 0, e.dayOfYear);
    return (
      this.year(f.getUTCFullYear()),
      this.month(f.getUTCMonth()),
      this.date(f.getUTCDate()),
      this
    );
  }
  function fl(n) {
    return null == n
      ? Math.ceil((this.month() + 1) / 3)
      : this.month(3 * (n - 1) + (this.month() % 3));
  }
  function el(n) {
    return hi(n, this._week.dow, this._week.doy).week;
  }
  function ol() {
    return this._week.dow;
  }
  function sl() {
    return this._week.doy;
  }
  function hl(n) {
    var t = this.localeData().week(this);
    return null == n ? t : this.add(7 * (n - t), 'd');
  }
  function cl(n) {
    var t = hi(this, 1, 4).week;
    return null == n ? t : this.add(7 * (n - t), 'd');
  }
  function ll(n, t) {
    return 'string' != typeof n
      ? n
      : isNaN(n)
      ? ((n = t.weekdaysParse(n)), 'number' == typeof n ? n : null)
      : parseInt(n, 10);
  }
  function al(n, t) {
    return pt(this._weekdays)
      ? this._weekdays[n.day()]
      : this._weekdays[
          this._weekdays.isFormat.test(t) ? 'format' : 'standalone'
        ][n.day()];
  }
  function vl(n) {
    return this._weekdaysShort[n.day()];
  }
  function yl(n) {
    return this._weekdaysMin[n.day()];
  }
  function pl(n, t, i) {
    var f,
      r,
      e,
      u = n.toLocaleLowerCase();
    if (!this._weekdaysParse)
      for (
        this._weekdaysParse = [],
          this._shortWeekdaysParse = [],
          this._minWeekdaysParse = [],
          f = 0;
        7 > f;
        ++f
      )
        (e = it([2e3, 1]).day(f)),
          (this._minWeekdaysParse[f] = this.weekdaysMin(
            e,
            ''
          ).toLocaleLowerCase()),
          (this._shortWeekdaysParse[f] = this.weekdaysShort(
            e,
            ''
          ).toLocaleLowerCase()),
          (this._weekdaysParse[f] = this.weekdays(e, '').toLocaleLowerCase());
    return i
      ? 'dddd' === t
        ? ((r = a.call(this._weekdaysParse, u)), -1 !== r ? r : null)
        : 'ddd' === t
        ? ((r = a.call(this._shortWeekdaysParse, u)), -1 !== r ? r : null)
        : ((r = a.call(this._minWeekdaysParse, u)), -1 !== r ? r : null)
      : 'dddd' === t
      ? ((r = a.call(this._weekdaysParse, u)),
        -1 !== r
          ? r
          : ((r = a.call(this._shortWeekdaysParse, u)),
            -1 !== r
              ? r
              : ((r = a.call(this._minWeekdaysParse, u)), -1 !== r ? r : null)))
      : 'ddd' === t
      ? ((r = a.call(this._shortWeekdaysParse, u)),
        -1 !== r
          ? r
          : ((r = a.call(this._weekdaysParse, u)),
            -1 !== r
              ? r
              : ((r = a.call(this._minWeekdaysParse, u)), -1 !== r ? r : null)))
      : ((r = a.call(this._minWeekdaysParse, u)),
        -1 !== r
          ? r
          : ((r = a.call(this._weekdaysParse, u)),
            -1 !== r
              ? r
              : ((r = a.call(this._shortWeekdaysParse, u)),
                -1 !== r ? r : null)));
  }
  function wl(n, t, i) {
    var r, u, f;
    if (this._weekdaysParseExact) return pl.call(this, n, t, i);
    for (
      this._weekdaysParse ||
        ((this._weekdaysParse = []),
        (this._minWeekdaysParse = []),
        (this._shortWeekdaysParse = []),
        (this._fullWeekdaysParse = [])),
        r = 0;
      7 > r;
      r++
    )
      if (
        ((u = it([2e3, 1]).day(r)),
        i &&
          !this._fullWeekdaysParse[r] &&
          ((this._fullWeekdaysParse[r] = new RegExp(
            '^' + this.weekdays(u, '').replace('.', '.?') + '$',
            'i'
          )),
          (this._shortWeekdaysParse[r] = new RegExp(
            '^' + this.weekdaysShort(u, '').replace('.', '.?') + '$',
            'i'
          )),
          (this._minWeekdaysParse[r] = new RegExp(
            '^' + this.weekdaysMin(u, '').replace('.', '.?') + '$',
            'i'
          ))),
        this._weekdaysParse[r] ||
          ((f =
            '^' +
            this.weekdays(u, '') +
            '|^' +
            this.weekdaysShort(u, '') +
            '|^' +
            this.weekdaysMin(u, '')),
          (this._weekdaysParse[r] = new RegExp(f.replace('.', ''), 'i'))),
        i && 'dddd' === t && this._fullWeekdaysParse[r].test(n)) ||
        (i && 'ddd' === t && this._shortWeekdaysParse[r].test(n)) ||
        (i && 'dd' === t && this._minWeekdaysParse[r].test(n)) ||
        (!i && this._weekdaysParse[r].test(n))
      )
        return r;
  }
  function bl(n) {
    if (!this.isValid()) return null != n ? this : NaN;
    var t = this._isUTC ? this._d.getUTCDay() : this._d.getDay();
    return null != n
      ? ((n = ll(n, this.localeData())), this.add(n - t, 'd'))
      : t;
  }
  function kl(n) {
    if (!this.isValid()) return null != n ? this : NaN;
    var t = (this.day() + 7 - this.localeData()._week.dow) % 7;
    return null == n ? t : this.add(n - t, 'd');
  }
  function dl(n) {
    return this.isValid()
      ? null == n
        ? this.day() || 7
        : this.day(this.day() % 7 ? n : n - 7)
      : null != n
      ? this
      : NaN;
  }
  function gl(n) {
    return this._weekdaysParseExact
      ? (w(this, '_weekdaysRegex') || tu.call(this),
        n ? this._weekdaysStrictRegex : this._weekdaysRegex)
      : this._weekdaysStrictRegex && n
      ? this._weekdaysStrictRegex
      : this._weekdaysRegex;
  }
  function na(n) {
    return this._weekdaysParseExact
      ? (w(this, '_weekdaysRegex') || tu.call(this),
        n ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex)
      : this._weekdaysShortStrictRegex && n
      ? this._weekdaysShortStrictRegex
      : this._weekdaysShortRegex;
  }
  function ta(n) {
    return this._weekdaysParseExact
      ? (w(this, '_weekdaysRegex') || tu.call(this),
        n ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex)
      : this._weekdaysMinStrictRegex && n
      ? this._weekdaysMinStrictRegex
      : this._weekdaysMinRegex;
  }
  function tu() {
    function u(n, t) {
      return t.length - n.length;
    }
    for (var f, e, o, s, h = [], i = [], r = [], t = [], n = 0; 7 > n; n++)
      (f = it([2e3, 1]).day(n)),
        (e = this.weekdaysMin(f, '')),
        (o = this.weekdaysShort(f, '')),
        (s = this.weekdays(f, '')),
        h.push(e),
        i.push(o),
        r.push(s),
        t.push(e),
        t.push(o),
        t.push(s);
    for (h.sort(u), i.sort(u), r.sort(u), t.sort(u), n = 0; 7 > n; n++)
      (i[n] = bt(i[n])), (r[n] = bt(r[n])), (t[n] = bt(t[n]));
    this._weekdaysRegex = new RegExp('^(' + t.join('|') + ')', 'i');
    this._weekdaysShortRegex = this._weekdaysRegex;
    this._weekdaysMinRegex = this._weekdaysRegex;
    this._weekdaysStrictRegex = new RegExp('^(' + r.join('|') + ')', 'i');
    this._weekdaysShortStrictRegex = new RegExp('^(' + i.join('|') + ')', 'i');
    this._weekdaysMinStrictRegex = new RegExp('^(' + h.join('|') + ')', 'i');
  }
  function ia(n) {
    var t =
      Math.round(
        (this.clone().startOf('day') - this.clone().startOf('year')) / 864e5
      ) + 1;
    return null == n ? t : this.add(n - t, 'd');
  }
  function iu() {
    return this.hours() % 12 || 12;
  }
  function ra() {
    return this.hours() || 24;
  }
  function fe(n, t) {
    r(n, 0, 0, function () {
      return this.localeData().meridiem(this.hours(), this.minutes(), t);
    });
  }
  function ee(n, t) {
    return t._meridiemParse;
  }
  function ua(n) {
    return 'p' === (n + '').toLowerCase().charAt(0);
  }
  function fa(n, t, i) {
    return n > 11 ? (i ? 'pm' : 'PM') : i ? 'am' : 'AM';
  }
  function ea(n, t) {
    t[ni] = e(1e3 * ('0.' + n));
  }
  function oa() {
    return this._isUTC ? 'UTC' : '';
  }
  function sa() {
    return this._isUTC ? 'Coordinated Universal Time' : '';
  }
  function ha(n) {
    return h(1e3 * n);
  }
  function ca() {
    return h.apply(null, arguments).parseZone();
  }
  function la(n, t, i) {
    var r = this._calendar[n];
    return ht(r) ? r.call(t, i) : r;
  }
  function aa(n) {
    var t = this._longDateFormat[n],
      i = this._longDateFormat[n.toUpperCase()];
    return t || !i
      ? t
      : ((this._longDateFormat[n] = i.replace(/MMMM|MM|DD|dddd/g, function (n) {
          return n.slice(1);
        })),
        this._longDateFormat[n]);
  }
  function va() {
    return this._invalidDate;
  }
  function ya(n) {
    return this._ordinal.replace('%d', n);
  }
  function oe(n) {
    return n;
  }
  function pa(n, t, i, r) {
    var u = this._relativeTime[i];
    return ht(u) ? u(n, t, i, r) : u.replace(/%d/i, n);
  }
  function wa(n, t) {
    var i = this._relativeTime[n > 0 ? 'future' : 'past'];
    return ht(i) ? i(t) : i.replace(/%s/i, t);
  }
  function di(n, t, i, r) {
    var u = ct(),
      f = it().set(r, t);
    return u[i](f, n);
  }
  function se(n, t, i) {
    if (
      ('number' == typeof n && ((t = n), (n = void 0)),
      (n = n || ''),
      null != t)
    )
      return di(n, t, i, 'month');
    for (var u = [], r = 0; 12 > r; r++) u[r] = di(n, r, i, 'month');
    return u;
  }
  function ru(n, t, i, r) {
    var o, f, u, e;
    if (
      ('boolean' == typeof n
        ? ('number' == typeof t && ((i = t), (t = void 0)), (t = t || ''))
        : ((t = n),
          (i = t),
          (n = !1),
          'number' == typeof t && ((i = t), (t = void 0)),
          (t = t || '')),
      (o = ct()),
      (f = n ? o._week.dow : 0),
      null != i)
    )
      return di(t, (i + f) % 7, r, 'day');
    for (e = [], u = 0; 7 > u; u++) e[u] = di(t, (u + f) % 7, r, 'day');
    return e;
  }
  function ba(n, t) {
    return se(n, t, 'months');
  }
  function ka(n, t) {
    return se(n, t, 'monthsShort');
  }
  function da(n, t, i) {
    return ru(n, t, i, 'weekdays');
  }
  function ga(n, t, i) {
    return ru(n, t, i, 'weekdaysShort');
  }
  function nv(n, t, i) {
    return ru(n, t, i, 'weekdaysMin');
  }
  function tv() {
    var n = this._data;
    return (
      (this._milliseconds = ot(this._milliseconds)),
      (this._days = ot(this._days)),
      (this._months = ot(this._months)),
      (n.milliseconds = ot(n.milliseconds)),
      (n.seconds = ot(n.seconds)),
      (n.minutes = ot(n.minutes)),
      (n.hours = ot(n.hours)),
      (n.months = ot(n.months)),
      (n.years = ot(n.years)),
      this
    );
  }
  function he(n, t, i, r) {
    var u = lt(t, i);
    return (
      (n._milliseconds += r * u._milliseconds),
      (n._days += r * u._days),
      (n._months += r * u._months),
      n._bubble()
    );
  }
  function iv(n, t) {
    return he(this, n, t, 1);
  }
  function rv(n, t) {
    return he(this, n, t, -1);
  }
  function ce(n) {
    return 0 > n ? Math.floor(n) : Math.ceil(n);
  }
  function uv() {
    var u,
      f,
      e,
      s,
      o,
      r = this._milliseconds,
      n = this._days,
      t = this._months,
      i = this._data;
    return (
      (r >= 0 && n >= 0 && t >= 0) ||
        (0 >= r && 0 >= n && 0 >= t) ||
        ((r += 864e5 * ce(uu(t) + n)), (n = 0), (t = 0)),
      (i.milliseconds = r % 1e3),
      (u = b(r / 1e3)),
      (i.seconds = u % 60),
      (f = b(u / 60)),
      (i.minutes = f % 60),
      (e = b(f / 60)),
      (i.hours = e % 24),
      (n += b(e / 24)),
      (o = b(le(n))),
      (t += o),
      (n -= ce(uu(o))),
      (s = b(t / 12)),
      (t %= 12),
      (i.days = n),
      (i.months = t),
      (i.years = s),
      this
    );
  }
  function le(n) {
    return (4800 * n) / 146097;
  }
  function uu(n) {
    return (146097 * n) / 4800;
  }
  function fv(n) {
    var t,
      r,
      i = this._milliseconds;
    if (((n = g(n)), 'month' === n || 'year' === n))
      return (
        (t = this._days + i / 864e5),
        (r = this._months + le(t)),
        'month' === n ? r : r / 12
      );
    switch (((t = this._days + Math.round(uu(this._months))), n)) {
      case 'week':
        return t / 7 + i / 6048e5;
      case 'day':
        return t + i / 864e5;
      case 'hour':
        return 24 * t + i / 36e5;
      case 'minute':
        return 1440 * t + i / 6e4;
      case 'second':
        return 86400 * t + i / 1e3;
      case 'millisecond':
        return Math.floor(864e5 * t) + i;
      default:
        throw new Error('Unknown unit ' + n);
    }
  }
  function ev() {
    return (
      this._milliseconds +
      864e5 * this._days +
      (this._months % 12) * 2592e6 +
      31536e6 * e(this._months / 12)
    );
  }
  function at(n) {
    return function () {
      return this.as(n);
    };
  }
  function ov(n) {
    return (n = g(n)), this[n + 's']();
  }
  function gt(n) {
    return function () {
      return this._data[n];
    };
  }
  function sv() {
    return b(this.days() / 7);
  }
  function hv(n, t, i, r, u) {
    return u.relativeTime(t || 1, !!i, n, r);
  }
  function cv(n, t, i) {
    var r = lt(n).abs(),
      h = fi(r.as('s')),
      f = fi(r.as('m')),
      e = fi(r.as('h')),
      o = fi(r.as('d')),
      s = fi(r.as('M')),
      c = fi(r.as('y')),
      u = (h < yt.s && ['s', h]) ||
        (1 >= f && ['m']) ||
        (f < yt.m && ['mm', f]) ||
        (1 >= e && ['h']) ||
        (e < yt.h && ['hh', e]) ||
        (1 >= o && ['d']) ||
        (o < yt.d && ['dd', o]) ||
        (1 >= s && ['M']) ||
        (s < yt.M && ['MM', s]) ||
        (1 >= c && ['y']) || ['yy', c];
    return (u[2] = t), (u[3] = +n > 0), (u[4] = i), hv.apply(null, u);
  }
  function lv(n, t) {
    return void 0 === yt[n] ? !1 : void 0 === t ? yt[n] : ((yt[n] = t), !0);
  }
  function av(n) {
    var t = this.localeData(),
      i = cv(this, !n, t);
    return n && (i = t.pastFuture(+this, i)), t.postformat(i);
  }
  function gi() {
    var n,
      e,
      o,
      t = wu(this._milliseconds) / 1e3,
      a = wu(this._days),
      i = wu(this._months);
    n = b(t / 60);
    e = b(n / 60);
    t %= 60;
    n %= 60;
    o = b(i / 12);
    i %= 12;
    var s = o,
      h = i,
      c = a,
      r = e,
      u = n,
      f = t,
      l = this.asSeconds();
    return l
      ? (0 > l ? '-' : '') +
          'P' +
          (s ? s + 'Y' : '') +
          (h ? h + 'M' : '') +
          (c ? c + 'D' : '') +
          (r || u || f ? 'T' : '') +
          (r ? r + 'H' : '') +
          (u ? u + 'M' : '') +
          (f ? f + 'S' : '')
      : 'P0D';
  }
  var ae, ve, pe, vu, to, io, ro, uo, fo, yu, eo, pu, oo, so, ho, co, vt, lo, n;
  ve = Array.prototype.some
    ? Array.prototype.some
    : function (n) {
        for (var i = Object(this), r = i.length >>> 0, t = 0; r > t; t++)
          if (t in i && n.call(this, i[t], t, i)) return !0;
        return !1;
      };
  var fu = (t.momentProperties = []),
    eu = !1,
    ye = {};
  t.suppressDeprecationWarnings = !1;
  t.deprecationHandler = null;
  pe = Object.keys
    ? Object.keys
    : function (n) {
        var t,
          i = [];
        for (t in n) w(n, t) && i.push(t);
        return i;
      };
  var nr,
    a,
    l = {},
    ci = {},
    we = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|YYYYYY|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g,
    tr = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g,
    ou = {},
    ui = {},
    be = /\d/,
    k = /\d\d/,
    ke = /\d{3}/,
    su = /\d{4}/,
    ir = /[+-]?\d{6}/,
    c = /\d\d?/,
    de = /\d\d\d\d?/,
    ge = /\d\d\d\d\d\d?/,
    rr = /\d{1,3}/,
    hu = /\d{1,4}/,
    ur = /[+-]?\d{1,6}/,
    fr = /[+-]?\d+/,
    vv = /Z|[+-]\d\d:?\d\d/gi,
    er = /Z|[+-]\d\d(?::?\d\d)?/gi,
    li = /[0-9]*['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+|[\u0600-\u06FF\/]+(\s*?[\u0600-\u06FF]+){1,2}/i,
    cu = {},
    lu = {},
    nt = 0,
    ft = 1,
    rt = 2,
    v = 3,
    tt = 4,
    et = 5,
    ni = 6,
    yv = 7,
    pv = 8;
  a = Array.prototype.indexOf
    ? Array.prototype.indexOf
    : function (n) {
        for (var t = 0; t < this.length; ++t) if (this[t] === n) return t;
        return -1;
      };
  r('M', ['MM', 2], 'Mo', function () {
    return this.month() + 1;
  });
  r('MMM', 0, 0, function (n) {
    return this.localeData().monthsShort(this, n);
  });
  r('MMMM', 0, 0, function (n) {
    return this.localeData().months(this, n);
  });
  y('month', 'M');
  i('M', c);
  i('MM', c, k);
  i('MMM', function (n, t) {
    return t.monthsShortRegex(n);
  });
  i('MMMM', function (n, t) {
    return t.monthsRegex(n);
  });
  s(['M', 'MM'], function (n, t) {
    t[ft] = e(n) - 1;
  });
  s(['MMM', 'MMMM'], function (n, t, i, r) {
    var u = i._locale.monthsParse(n, r, i._strict);
    null != u ? (t[ft] = u) : (f(i).invalidMonth = n);
  });
  var no = /D[oD]?(\[[^\[\]]*\]|\s+)+MMMM?/,
    wv = 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
      '_'
    ),
    bv = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    kv = li,
    dv = li,
    gv = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([\+\-]\d\d(?::?\d\d)?|\s*Z)?)?/,
    ny = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([\+\-]\d\d(?::?\d\d)?|\s*Z)?)?/,
    ty = /Z|[+-]\d\d(?::?\d\d)?/,
    or = [
      ['YYYYYY-MM-DD', /[+-]\d{6}-\d\d-\d\d/],
      ['YYYY-MM-DD', /\d{4}-\d\d-\d\d/],
      ['GGGG-[W]WW-E', /\d{4}-W\d\d-\d/],
      ['GGGG-[W]WW', /\d{4}-W\d\d/, !1],
      ['YYYY-DDD', /\d{4}-\d{3}/],
      ['YYYY-MM', /\d{4}-\d\d/, !1],
      ['YYYYYYMMDD', /[+-]\d{10}/],
      ['YYYYMMDD', /\d{8}/],
      ['GGGG[W]WWE', /\d{4}W\d{3}/],
      ['GGGG[W]WW', /\d{4}W\d{2}/, !1],
      ['YYYYDDD', /\d{7}/],
    ],
    au = [
      ['HH:mm:ss.SSSS', /\d\d:\d\d:\d\d\.\d+/],
      ['HH:mm:ss,SSSS', /\d\d:\d\d:\d\d,\d+/],
      ['HH:mm:ss', /\d\d:\d\d:\d\d/],
      ['HH:mm', /\d\d:\d\d/],
      ['HHmmss.SSSS', /\d\d\d\d\d\d\.\d+/],
      ['HHmmss,SSSS', /\d\d\d\d\d\d,\d+/],
      ['HHmmss', /\d\d\d\d\d\d/],
      ['HHmm', /\d\d\d\d/],
      ['HH', /\d\d/],
    ],
    iy = /^\/?Date\((\-?\d+)/i;
  t.createFromInputFallback = d(
    'moment construction falls back to js Date. This is discouraged and will be removed in upcoming major release. Please refer to https://github.com/moment/moment/issues/1407 for more info.',
    function (n) {
      n._d = new Date(n._i + (n._useUTC ? ' UTC' : ''));
    }
  );
  r('Y', 0, 0, function () {
    var n = this.year();
    return 9999 >= n ? '' + n : '+' + n;
  });
  r(0, ['YY', 2], 0, function () {
    return this.year() % 100;
  });
  r(0, ['YYYY', 4], 0, 'year');
  r(0, ['YYYYY', 5], 0, 'year');
  r(0, ['YYYYYY', 6, !0], 0, 'year');
  y('year', 'y');
  i('Y', fr);
  i('YY', c, k);
  i('YYYY', hu, su);
  i('YYYYY', ur, ir);
  i('YYYYYY', ur, ir);
  s(['YYYYY', 'YYYYYY'], nt);
  s('YYYY', function (n, i) {
    i[nt] = 2 === n.length ? t.parseTwoDigitYear(n) : e(n);
  });
  s('YY', function (n, i) {
    i[nt] = t.parseTwoDigitYear(n);
  });
  s('Y', function (n, t) {
    t[nt] = parseInt(n, 10);
  });
  t.parseTwoDigitYear = function (n) {
    return e(n) + (e(n) > 68 ? 1900 : 2e3);
  };
  vu = ii('FullYear', !0);
  t.ISO_8601 = function () {};
  var ry = d(
      'moment().min is deprecated, use moment.max instead. https://github.com/moment/moment/issues/1548',
      function () {
        var n = h.apply(null, arguments);
        return this.isValid() && n.isValid() ? (this > n ? this : n) : vi();
      }
    ),
    uy = d(
      'moment().max is deprecated, use moment.min instead. https://github.com/moment/moment/issues/1548',
      function () {
        var n = h.apply(null, arguments);
        return this.isValid() && n.isValid() ? (n > this ? this : n) : vi();
      }
    ),
    fy = function () {
      return Date.now ? Date.now() : +new Date();
    };
  bf('Z', ':');
  bf('ZZ', '');
  i('Z', er);
  i('ZZ', er);
  s(['Z', 'ZZ'], function (n, t, i) {
    i._useUTC = !0;
    i._tzm = dr(er, n);
  });
  to = /([\+\-]|\d\d)/gi;
  t.updateOffset = function () {};
  io = /^(\-)?(?:(\d*)[. ])?(\d+)\:(\d+)(?:\:(\d+)\.?(\d{3})?\d*)?$/;
  ro = /^(-)?P(?:(-?[0-9,.]*)Y)?(?:(-?[0-9,.]*)M)?(?:(-?[0-9,.]*)W)?(?:(-?[0-9,.]*)D)?(?:T(?:(-?[0-9,.]*)H)?(?:(-?[0-9,.]*)M)?(?:(-?[0-9,.]*)S)?)?$/;
  lt.fn = bi.prototype;
  uo = ne(1, 'add');
  fo = ne(-1, 'subtract');
  t.defaultFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  t.defaultFormatUtc = 'YYYY-MM-DDTHH:mm:ss[Z]';
  yu = d(
    'moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.',
    function (n) {
      return void 0 === n ? this.localeData() : this.locale(n);
    }
  );
  r(0, ['gg', 2], 0, function () {
    return this.weekYear() % 100;
  });
  r(0, ['GG', 2], 0, function () {
    return this.isoWeekYear() % 100;
  });
  ki('gggg', 'weekYear');
  ki('ggggg', 'weekYear');
  ki('GGGG', 'isoWeekYear');
  ki('GGGGG', 'isoWeekYear');
  y('weekYear', 'gg');
  y('isoWeekYear', 'GG');
  i('G', fr);
  i('g', fr);
  i('GG', c, k);
  i('gg', c, k);
  i('GGGG', hu, su);
  i('gggg', hu, su);
  i('GGGGG', ur, ir);
  i('ggggg', ur, ir);
  oi(['gggg', 'ggggg', 'GGGG', 'GGGGG'], function (n, t, i, r) {
    t[r.substr(0, 2)] = e(n);
  });
  oi(['gg', 'GG'], function (n, i, r, u) {
    i[u] = t.parseTwoDigitYear(n);
  });
  r('Q', 0, 'Qo', 'quarter');
  y('quarter', 'Q');
  i('Q', be);
  s('Q', function (n, t) {
    t[ft] = 3 * (e(n) - 1);
  });
  r('w', ['ww', 2], 'wo', 'week');
  r('W', ['WW', 2], 'Wo', 'isoWeek');
  y('week', 'w');
  y('isoWeek', 'W');
  i('w', c);
  i('ww', c, k);
  i('W', c);
  i('WW', c, k);
  oi(['w', 'ww', 'W', 'WW'], function (n, t, i, r) {
    t[r.substr(0, 1)] = e(n);
  });
  eo = { dow: 0, doy: 6 };
  r('D', ['DD', 2], 'Do', 'date');
  y('date', 'D');
  i('D', c);
  i('DD', c, k);
  i('Do', function (n, t) {
    return n ? t._ordinalParse : t._ordinalParseLenient;
  });
  s(['D', 'DD'], rt);
  s('Do', function (n, t) {
    t[rt] = e(n.match(c)[0], 10);
  });
  pu = ii('Date', !0);
  r('d', 0, 'do', 'day');
  r('dd', 0, 0, function (n) {
    return this.localeData().weekdaysMin(this, n);
  });
  r('ddd', 0, 0, function (n) {
    return this.localeData().weekdaysShort(this, n);
  });
  r('dddd', 0, 0, function (n) {
    return this.localeData().weekdays(this, n);
  });
  r('e', 0, 0, 'weekday');
  r('E', 0, 0, 'isoWeekday');
  y('day', 'd');
  y('weekday', 'e');
  y('isoWeekday', 'E');
  i('d', c);
  i('e', c);
  i('E', c);
  i('dd', function (n, t) {
    return t.weekdaysMinRegex(n);
  });
  i('ddd', function (n, t) {
    return t.weekdaysShortRegex(n);
  });
  i('dddd', function (n, t) {
    return t.weekdaysRegex(n);
  });
  oi(['dd', 'ddd', 'dddd'], function (n, t, i, r) {
    var u = i._locale.weekdaysParse(n, r, i._strict);
    null != u ? (t.d = u) : (f(i).invalidWeekday = n);
  });
  oi(['d', 'e', 'E'], function (n, t, i, r) {
    t[r] = e(n);
  });
  var ey = 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
      '_'
    ),
    oy = 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    sy = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    hy = li,
    cy = li,
    ly = li;
  for (
    r('DDD', ['DDDD', 3], 'DDDo', 'dayOfYear'),
      y('dayOfYear', 'DDD'),
      i('DDD', rr),
      i('DDDD', ke),
      s(['DDD', 'DDDD'], function (n, t, i) {
        i._dayOfYear = e(n);
      }),
      r('H', ['HH', 2], 0, 'hour'),
      r('h', ['hh', 2], 0, iu),
      r('k', ['kk', 2], 0, ra),
      r('hmm', 0, 0, function () {
        return '' + iu.apply(this) + ut(this.minutes(), 2);
      }),
      r('hmmss', 0, 0, function () {
        return (
          '' + iu.apply(this) + ut(this.minutes(), 2) + ut(this.seconds(), 2)
        );
      }),
      r('Hmm', 0, 0, function () {
        return '' + this.hours() + ut(this.minutes(), 2);
      }),
      r('Hmmss', 0, 0, function () {
        return (
          '' + this.hours() + ut(this.minutes(), 2) + ut(this.seconds(), 2)
        );
      }),
      fe('a', !0),
      fe('A', !1),
      y('hour', 'h'),
      i('a', ee),
      i('A', ee),
      i('H', c),
      i('h', c),
      i('HH', c, k),
      i('hh', c, k),
      i('hmm', de),
      i('hmmss', ge),
      i('Hmm', de),
      i('Hmmss', ge),
      s(['H', 'HH'], v),
      s(['a', 'A'], function (n, t, i) {
        i._isPm = i._locale.isPM(n);
        i._meridiem = n;
      }),
      s(['h', 'hh'], function (n, t, i) {
        t[v] = e(n);
        f(i).bigHour = !0;
      }),
      s('hmm', function (n, t, i) {
        var r = n.length - 2;
        t[v] = e(n.substr(0, r));
        t[tt] = e(n.substr(r));
        f(i).bigHour = !0;
      }),
      s('hmmss', function (n, t, i) {
        var r = n.length - 4,
          u = n.length - 2;
        t[v] = e(n.substr(0, r));
        t[tt] = e(n.substr(r, 2));
        t[et] = e(n.substr(u));
        f(i).bigHour = !0;
      }),
      s('Hmm', function (n, t) {
        var i = n.length - 2;
        t[v] = e(n.substr(0, i));
        t[tt] = e(n.substr(i));
      }),
      s('Hmmss', function (n, t) {
        var i = n.length - 4,
          r = n.length - 2;
        t[v] = e(n.substr(0, i));
        t[tt] = e(n.substr(i, 2));
        t[et] = e(n.substr(r));
      }),
      oo = /[ap]\.?m?\.?/i,
      so = ii('Hours', !0),
      r('m', ['mm', 2], 0, 'minute'),
      y('minute', 'm'),
      i('m', c),
      i('mm', c, k),
      s(['m', 'mm'], tt),
      ho = ii('Minutes', !1),
      r('s', ['ss', 2], 0, 'second'),
      y('second', 's'),
      i('s', c),
      i('ss', c, k),
      s(['s', 'ss'], et),
      co = ii('Seconds', !1),
      r('S', 0, 0, function () {
        return ~~(this.millisecond() / 100);
      }),
      r(0, ['SS', 2], 0, function () {
        return ~~(this.millisecond() / 10);
      }),
      r(0, ['SSS', 3], 0, 'millisecond'),
      r(0, ['SSSS', 4], 0, function () {
        return 10 * this.millisecond();
      }),
      r(0, ['SSSSS', 5], 0, function () {
        return 100 * this.millisecond();
      }),
      r(0, ['SSSSSS', 6], 0, function () {
        return 1e3 * this.millisecond();
      }),
      r(0, ['SSSSSSS', 7], 0, function () {
        return 1e4 * this.millisecond();
      }),
      r(0, ['SSSSSSSS', 8], 0, function () {
        return 1e5 * this.millisecond();
      }),
      r(0, ['SSSSSSSSS', 9], 0, function () {
        return 1e6 * this.millisecond();
      }),
      y('millisecond', 'ms'),
      i('S', rr, be),
      i('SS', rr, k),
      i('SSS', rr, ke),
      vt = 'SSSS';
    vt.length <= 9;
    vt += 'S'
  )
    i(vt, /\d+/);
  for (vt = 'S'; vt.length <= 9; vt += 'S') s(vt, ea);
  lo = ii('Milliseconds', !1);
  r('z', 0, 0, 'zoneAbbr');
  r('zz', 0, 0, 'zoneName');
  n = ei.prototype;
  n.add = uo;
  n.calendar = vh;
  n.clone = yh;
  n.diff = nc;
  n.endOf = cc;
  n.format = uc;
  n.from = fc;
  n.fromNow = ec;
  n.to = oc;
  n.toNow = sc;
  n.get = ef;
  n.invalidAt = dc;
  n.isAfter = ph;
  n.isBefore = wh;
  n.isBetween = bh;
  n.isSame = kh;
  n.isSameOrAfter = dh;
  n.isSameOrBefore = gh;
  n.isValid = bc;
  n.lang = yu;
  n.locale = ie;
  n.localeData = re;
  n.max = uy;
  n.min = ry;
  n.parsingFlags = kc;
  n.set = ef;
  n.startOf = hc;
  n.subtract = fo;
  n.toArray = yc;
  n.toObject = pc;
  n.toDate = vc;
  n.toISOString = rc;
  n.toJSON = wc;
  n.toString = ic;
  n.unix = ac;
  n.valueOf = lc;
  n.creationData = gc;
  n.year = vu;
  n.isLeapYear = vs;
  n.weekYear = nl;
  n.isoWeekYear = tl;
  n.quarter = n.quarters = fl;
  n.month = hf;
  n.daysInMonth = ss;
  n.week = n.weeks = hl;
  n.isoWeek = n.isoWeeks = cl;
  n.weeksInYear = rl;
  n.isoWeeksInYear = il;
  n.date = pu;
  n.day = n.days = bl;
  n.weekday = kl;
  n.isoWeekday = dl;
  n.dayOfYear = ia;
  n.hour = n.hours = so;
  n.minute = n.minutes = ho;
  n.second = n.seconds = co;
  n.millisecond = n.milliseconds = lo;
  n.utcOffset = ih;
  n.utc = uh;
  n.local = fh;
  n.parseZone = eh;
  n.hasAlignedHourOffset = oh;
  n.isDST = sh;
  n.isDSTShifted = hh;
  n.isLocal = ch;
  n.isUtcOffset = lh;
  n.isUtc = kf;
  n.isUTC = kf;
  n.zoneAbbr = oa;
  n.zoneName = sa;
  n.dates = d('dates accessor is deprecated. Use date instead.', pu);
  n.months = d('months accessor is deprecated. Use month instead', hf);
  n.years = d('years accessor is deprecated. Use year instead', vu);
  n.zone = d(
    'moment().zone is deprecated, use moment().utcOffset instead. https://github.com/moment/moment/issues/1779',
    rh
  );
  var ao = n,
    u = ar.prototype;
  u._calendar = {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'dddd [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] dddd [at] LT',
    sameElse: 'L',
  };
  u.calendar = la;
  u._longDateFormat = {
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
  };
  u.longDateFormat = aa;
  u._invalidDate = 'Invalid date';
  u.invalidDate = va;
  u._ordinal = '%d';
  u.ordinal = ya;
  u._ordinalParse = /\d{1,2}/;
  u.preparse = oe;
  u.postformat = oe;
  u._relativeTime = {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  };
  u.relativeTime = pa;
  u.pastFuture = wa;
  u.set = po;
  u.months = us;
  u._months = wv;
  u.monthsShort = fs;
  u._monthsShort = bv;
  u.monthsParse = os;
  u._monthsRegex = dv;
  u.monthsRegex = cs;
  u._monthsShortRegex = kv;
  u.monthsShortRegex = hs;
  u.week = el;
  u._week = eo;
  u.firstDayOfYear = sl;
  u.firstDayOfWeek = ol;
  u.weekdays = al;
  u._weekdays = ey;
  u.weekdaysMin = yl;
  u._weekdaysMin = sy;
  u.weekdaysShort = vl;
  u._weekdaysShort = oy;
  u.weekdaysParse = wl;
  u._weekdaysRegex = hy;
  u.weekdaysRegex = gl;
  u._weekdaysShortRegex = cy;
  u.weekdaysShortRegex = na;
  u._weekdaysMinRegex = ly;
  u.weekdaysMinRegex = ta;
  u.isPM = ua;
  u._meridiemParse = oo;
  u.meridiem = fa;
  ti('en', {
    ordinalParse: /\d{1,2}(th|st|nd|rd)/,
    ordinal: function (n) {
      var t = n % 10,
        i =
          1 === e((n % 100) / 10)
            ? 'th'
            : 1 === t
            ? 'st'
            : 2 === t
            ? 'nd'
            : 3 === t
            ? 'rd'
            : 'th';
      return n + i;
    },
  });
  t.lang = d('moment.lang is deprecated. Use moment.locale instead.', ti);
  t.langData = d(
    'moment.langData is deprecated. Use moment.localeData instead.',
    ct
  );
  var ot = Math.abs,
    ay = at('ms'),
    vy = at('s'),
    yy = at('m'),
    py = at('h'),
    wy = at('d'),
    by = at('w'),
    ky = at('M'),
    dy = at('y'),
    gy = gt('milliseconds'),
    np = gt('seconds'),
    tp = gt('minutes'),
    ip = gt('hours'),
    rp = gt('days'),
    up = gt('months'),
    fp = gt('years'),
    fi = Math.round,
    yt = { s: 45, m: 45, h: 22, d: 26, M: 11 },
    wu = Math.abs,
    o = bi.prototype;
  return (
    (o.abs = tv),
    (o.add = iv),
    (o.subtract = rv),
    (o.as = fv),
    (o.asMilliseconds = ay),
    (o.asSeconds = vy),
    (o.asMinutes = yy),
    (o.asHours = py),
    (o.asDays = wy),
    (o.asWeeks = by),
    (o.asMonths = ky),
    (o.asYears = dy),
    (o.valueOf = ev),
    (o._bubble = uv),
    (o.get = ov),
    (o.milliseconds = gy),
    (o.seconds = np),
    (o.minutes = tp),
    (o.hours = ip),
    (o.days = rp),
    (o.weeks = sv),
    (o.months = up),
    (o.years = fp),
    (o.humanize = av),
    (o.toISOString = gi),
    (o.toString = gi),
    (o.toJSON = gi),
    (o.locale = ie),
    (o.localeData = re),
    (o.toIsoString = d(
      'toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)',
      gi
    )),
    (o.lang = yu),
    r('X', 0, 0, 'unix'),
    r('x', 0, 0, 'valueOf'),
    i('x', fr),
    i('X', /[+-]?\d+(\.\d{1,3})?/),
    s('X', function (n, t, i) {
      i._d = new Date(1e3 * parseFloat(n, 10));
    }),
    s('x', function (n, t, i) {
      i._d = new Date(e(n));
    }),
    (t.version = '2.13.0'),
    vo(h),
    (t.fn = ao),
    (t.min = nh),
    (t.max = th),
    (t.now = fy),
    (t.utc = it),
    (t.unix = ha),
    (t.months = ba),
    (t.isDate = ai),
    (t.locale = ti),
    (t.invalid = vi),
    (t.duration = lt),
    (t.isMoment = st),
    (t.weekdays = da),
    (t.parseZone = ca),
    (t.localeData = ct),
    (t.isDuration = kr),
    (t.monthsShort = ka),
    (t.weekdaysMin = nv),
    (t.defineLocale = rf),
    (t.updateLocale = bo),
    (t.locales = ko),
    (t.weekdaysShort = ga),
    (t.normalizeUnits = g),
    (t.relativeTimeThreshold = lv),
    (t.prototype = ao),
    t
  );
}),
  (function () {
    window.hasQueryString = function (n) {
      return (
        jQuery.grep(this.getQuerystrings(), function (t) {
          return t.key.toString().toLowerCase() === n.toLowerCase();
        }).length > 0
      );
    };
    window.getQueryString = function (n) {
      var t = jQuery.grep(this.getQuerystrings(), function (t) {
        return t.key.toLowerCase() === n.toLowerCase();
      });
      return t.length > 0 ? t[0] : null;
    };
    window.getQuerystrings = function () {
      var n, i, t;
      if (
        !window.queryStrings &&
        ((window.queryStrings = []), (n = window.location.search), n.length > 0)
      )
        for (n = n.replace('?', ''), i = n.split('&'), t = 0; t < i.length; t++)
          window.queryStrings.push({
            key: i[t].split('=')[0],
            value: i[t].split('=')[1],
          });
      return window.queryStrings;
    };
    StringBuilder.prototype.append = function (n) {
      n && this.strings.push(n);
    };
    StringBuilder.prototype.appendWith = function (n, t, i) {
      n &&
        this.strings.push(
          (this.strings.length > 0 && t ? t : '') +
            n +
            (this.strings.length > 0 && i ? i : '')
        );
    };
    StringBuilder.prototype.length = function () {
      return this.strings.length;
    };
    StringBuilder.prototype.clear = function () {
      this.string = [];
    };
    StringBuilder.prototype.toString = function () {
      return this.strings.join('');
    };
    var n = function (n) {
      return n !== undefined && n !== null;
    };
    Array.prototype.each = function (n) {
      for (var i = this, t = 0; t < i.length; t++) n(i[t], t);
    };
    Array.prototype.select = function (t) {
      var i = [];
      return (
        this.each(function (r) {
          try {
            n(r[t]) && i.push(r[t]);
          } catch (u) {
            console.log(u);
          }
        }),
        i
      );
    };
    Array.prototype.where = function (t, i) {
      var r = [];
      return (
        this.each(function (u) {
          try {
            n(u[t]) &&
              i &&
              u[t].toString().toLowerCase() === i.toString().toLowerCase() &&
              r.push(u);
          } catch (f) {
            console.log(f);
          }
        }),
        r
      );
    };
    Array.prototype.whereNot = function (t, i) {
      var r = [];
      return (
        this.each(function (u) {
          try {
            n(u[t]) &&
              i &&
              u[t].toString().toLowerCase() !== i.toString().toLowerCase() &&
              r.push(u);
          } catch (f) {
            console.log(f);
          }
        }),
        r
      );
    };
    Array.prototype.firstOrDefault = function (n, t) {
      var i = this.where(n, t);
      if (i.length === 1) return i[0];
      if (i.length > 1)
        throw (
          'Property:[' +
          n +
          '] on Value:[' +
          t +
          '] returned ' +
          i.length +
          ' items, not a single record as request with firstOrDefault on array'
        );
      return null;
    };
    Array.prototype.distinct = function (n) {
      var t = [],
        i = [];
      return (
        this.each(function (r) {
          i.indexOf(r[n]) < 0 && (t.push(r), i.push(r[n]));
        }),
        t
      );
    };
    Array.prototype.update = function (n, t) {
      var i = [],
        r = !1;
      return (
        this.each(function (u) {
          u[t] !== n[t] ? i.push(u) : (i.push(n), (r = !0));
        }),
        r || i.push(n),
        i
      );
    };
    Array.prototype.remove = function (n, t) {
      var i = [];
      return (
        this.each(function (r) {
          r[n] !== t && i.push(r);
        }),
        i
      );
    };
    Array.prototype.sortBy = function (n, t) {
      t = t ? t.toString().toLowerCase() : undefined;
      var r = function (n, t) {
          return n - t;
        },
        u = function (n, t) {
          return t - n;
        },
        i = t !== undefined && (t === 'desc' || t === '-');
      return n ? this.sort(i ? u() : r()) : i ? this.reverse() : this.sort();
    };
    Array.prototype.sum = function (n, t, i) {
      return this.reduce(function (r, u) {
        return (
          t !== undefined && u[t] === (i !== undefined ? i : !0),
          parseFloat(r) + (u[n] !== null ? parseFloat(u[n]) : 0)
        );
      }, 0);
    };
    Array.prototype.minValue = function (n) {
      return Math.min.apply(null, this.select(n));
    };
    Array.prototype.maxValue = function (n) {
      return Math.max.apply(null, this.select(n));
    };
  })(),
  (function (n) {
    'use strict';
    function l(n) {
      return function () {
        for (
          var i = arguments[0],
            u,
            t,
            r =
              '[' +
              (n ? n + ':' : '') +
              i +
              '] http://errors.angularjs.org/1.5.6/' +
              (n ? n + '/' : '') +
              i,
            i = 1;
          i < arguments.length;
          i++
        )
          (r = r + (1 == i ? '?' : '&') + 'p' + (i - 1) + '='),
            (u = encodeURIComponent),
            (t = arguments[i]),
            (t =
              'function' == typeof t
                ? t.toString().replace(/ \{[\s\S]*$/, '')
                : 'undefined' == typeof t
                ? 'undefined'
                : 'string' != typeof t
                ? JSON.stringify(t)
                : t),
            (r += u(t));
        return Error(r);
      };
    }
    function ri(n) {
      if (null == n || ur(n)) return !1;
      if (c(n) || h(n) || (e && n instanceof e)) return !0;
      var t = 'length' in Object(n) && n.length;
      return (
        w(t) &&
        ((0 <= t && (t - 1 in n || n instanceof Array)) ||
          'function' == typeof n.item)
      );
    }
    function t(n, i, r) {
      var u, e, o;
      if (n)
        if (f(n))
          for (u in n)
            'prototype' == u ||
              'length' == u ||
              'name' == u ||
              (n.hasOwnProperty && !n.hasOwnProperty(u)) ||
              i.call(r, n[u], u, n);
        else if (c(n) || ri(n))
          for (o = 'object' != typeof n, u = 0, e = n.length; u < e; u++)
            (o || u in n) && i.call(r, n[u], u, n);
        else if (n.forEach && n.forEach !== t) n.forEach(i, r, n);
        else if (pe(n)) for (u in n) i.call(r, n[u], u, n);
        else if ('function' == typeof n.hasOwnProperty)
          for (u in n) n.hasOwnProperty(u) && i.call(r, n[u], u, n);
        else for (u in n) wt.call(n, u) && i.call(r, n[u], u, n);
      return n;
    }
    function ae(n, t, i) {
      for (var r = Object.keys(n).sort(), u = 0; u < r.length; u++)
        t.call(i, n[r[u]], r[u]);
      return r;
    }
    function ve(n) {
      return function (t, i) {
        n(i, t);
      };
    }
    function wc() {
      return ++ku;
    }
    function of(n, t, i) {
      for (var e, u, r, l = n.$$hashKey, o = 0, v = t.length; o < v; ++o)
        if (((e = t[o]), s(e) || f(e)))
          for (var a = Object.keys(e), h = 0, y = a.length; h < y; h++)
            (u = a[h]),
              (r = e[u]),
              i && s(r)
                ? et(r)
                  ? (n[u] = new Date(r.valueOf()))
                  : rr(r)
                  ? (n[u] = new RegExp(r))
                  : r.nodeName
                  ? (n[u] = r.cloneNode(!0))
                  : hf(r)
                  ? (n[u] = r.clone())
                  : (s(n[u]) || (n[u] = c(r) ? [] : {}), of(n[u], [r], !0))
                : (n[u] = r);
      return l ? (n.$$hashKey = l) : delete n.$$hashKey, n;
    }
    function a(n) {
      return of(n, bt.call(arguments, 1), !1);
    }
    function bc(n) {
      return of(n, bt.call(arguments, 1), !0);
    }
    function tt(n) {
      return parseInt(n, 10);
    }
    function sf(n, t) {
      return a(Object.create(n), t);
    }
    function o() {}
    function ir(n) {
      return n;
    }
    function ft(n) {
      return function () {
        return n;
      };
    }
    function ye(n) {
      return f(n.toString) && n.toString !== rt;
    }
    function r(n) {
      return 'undefined' == typeof n;
    }
    function u(n) {
      return 'undefined' != typeof n;
    }
    function s(n) {
      return null !== n && 'object' == typeof n;
    }
    function pe(n) {
      return null !== n && 'object' == typeof n && !eh(n);
    }
    function h(n) {
      return 'string' == typeof n;
    }
    function w(n) {
      return 'number' == typeof n;
    }
    function et(n) {
      return '[object Date]' === rt.call(n);
    }
    function f(n) {
      return 'function' == typeof n;
    }
    function rr(n) {
      return '[object RegExp]' === rt.call(n);
    }
    function ur(n) {
      return n && n.window === n;
    }
    function fr(n) {
      return n && n.$evalAsync && n.$watch;
    }
    function ui(n) {
      return 'boolean' == typeof n;
    }
    function kc(n) {
      return n && w(n.length) && wv.test(rt.call(n));
    }
    function hf(n) {
      return !(!n || !(n.nodeName || (n.prop && n.attr && n.find)));
    }
    function dc(n) {
      var i = {},
        t;
      for (n = n.split(','), t = 0; t < n.length; t++) i[n[t]] = !0;
      return i;
    }
    function at(n) {
      return v(n.nodeName || (n[0] && n[0].nodeName));
    }
    function er(n, t) {
      var i = n.indexOf(t);
      return 0 <= i && n.splice(i, 1), i;
    }
    function kt(n, i) {
      function o(n, t) {
        var u = t.$$hashKey,
          i,
          f;
        if (c(n)) for (i = 0, f = n.length; i < f; i++) t.push(r(n[i]));
        else if (pe(n)) for (i in n) t[i] = r(n[i]);
        else if (n && 'function' == typeof n.hasOwnProperty)
          for (i in n) n.hasOwnProperty(i) && (t[i] = r(n[i]));
        else for (i in n) wt.call(n, i) && (t[i] = r(n[i]));
        return u ? (t.$$hashKey = u) : delete t.$$hashKey, t;
      }
      function r(n) {
        var t, i;
        if (!s(n)) return n;
        if (((t = u.indexOf(n)), -1 !== t)) return e[t];
        if (ur(n) || fr(n)) throw ci('cpws');
        return (
          (t = !1),
          (i = h(n)),
          void 0 === i && ((i = c(n) ? [] : Object.create(eh(n))), (t = !0)),
          u.push(n),
          e.push(i),
          t ? o(n, i) : i
        );
      }
      function h(n) {
        switch (rt.call(n)) {
          case '[object Int8Array]':
          case '[object Int16Array]':
          case '[object Int32Array]':
          case '[object Float32Array]':
          case '[object Float64Array]':
          case '[object Uint8Array]':
          case '[object Uint8ClampedArray]':
          case '[object Uint16Array]':
          case '[object Uint32Array]':
            return new n.constructor(r(n.buffer));
          case '[object ArrayBuffer]':
            if (!n.slice) {
              var t = new ArrayBuffer(n.byteLength);
              return new Uint8Array(t).set(new Uint8Array(n)), t;
            }
            return n.slice(0);
          case '[object Boolean]':
          case '[object Number]':
          case '[object String]':
          case '[object Date]':
            return new n.constructor(n.valueOf());
          case '[object RegExp]':
            return (
              (t = new RegExp(n.source, n.toString().match(/[^\/]*$/)[0])),
              (t.lastIndex = n.lastIndex),
              t
            );
          case '[object Blob]':
            return new n.constructor([n], { type: n.type });
        }
        if (f(n.cloneNode)) return n.cloneNode(!0);
      }
      var u = [],
        e = [];
      if (i) {
        if (kc(i) || '[object ArrayBuffer]' === rt.call(i)) throw ci('cpta');
        if (n === i) throw ci('cpi');
        return (
          c(i)
            ? (i.length = 0)
            : t(i, function (n, t) {
                '$$hashKey' !== t && delete i[t];
              }),
          u.push(n),
          e.push(i),
          o(n, i)
        );
      }
      return r(n);
    }
    function ot(n, t) {
      if (c(n)) {
        t = t || [];
        for (var i = 0, r = n.length; i < r; i++) t[i] = n[i];
      } else if (s(n))
        for (i in ((t = t || {}), n))
          ('$' !== i.charAt(0) || '$' !== i.charAt(1)) && (t[i] = n[i]);
      return t || n;
    }
    function st(n, t) {
      if (n === t) return !0;
      if (null === n || null === t) return !1;
      if (n !== n && t !== t) return !0;
      var r = typeof n,
        i;
      if (r == typeof t && 'object' == r)
        if (c(n)) {
          if (!c(t)) return !1;
          if ((r = n.length) == t.length) {
            for (i = 0; i < r; i++) if (!st(n[i], t[i])) return !1;
            return !0;
          }
        } else {
          if (et(n)) return et(t) ? st(n.getTime(), t.getTime()) : !1;
          if (rr(n)) return rr(t) ? n.toString() == t.toString() : !1;
          if (fr(n) || fr(t) || ur(n) || ur(t) || c(t) || et(t) || rr(t))
            return !1;
          r = y();
          for (i in n)
            if ('$' !== i.charAt(0) && !f(n[i])) {
              if (!st(n[i], t[i])) return !1;
              r[i] = !0;
            }
          for (i in t)
            if (!(i in r) && '$' !== i.charAt(0) && u(t[i]) && !f(t[i]))
              return !1;
          return !0;
        }
      return !1;
    }
    function or(n, t, i) {
      return n.concat(bt.call(t, i));
    }
    function sr(n, t) {
      var i = 2 < arguments.length ? bt.call(arguments, 2) : [];
      return !f(t) || t instanceof RegExp
        ? t
        : i.length
        ? function () {
            return arguments.length
              ? t.apply(n, or(i, arguments, 0))
              : t.apply(n, i);
          }
        : function () {
            return arguments.length ? t.apply(n, arguments) : t.call(n);
          };
    }
    function gc(t, i) {
      var r = i;
      return (
        'string' == typeof t && '$' === t.charAt(0) && '$' === t.charAt(1)
          ? (r = void 0)
          : ur(i)
          ? (r = '$WINDOW')
          : i && n.document === i
          ? (r = '$DOCUMENT')
          : fr(i) && (r = '$SCOPE'),
        r
      );
    }
    function hr(n, t) {
      if (!r(n)) return w(t) || (t = t ? 2 : null), JSON.stringify(n, gc, t);
    }
    function we(n) {
      return h(n) ? JSON.parse(n) : n;
    }
    function be(n, t) {
      n = n.replace(bv, '');
      var i = Date.parse('Jan 01, 1970 00:00:00 ' + n) / 6e4;
      return isNaN(i) ? t : i;
    }
    function cf(n, t, i) {
      i = i ? -1 : 1;
      var r = n.getTimezoneOffset();
      return (
        (t = be(t, r)),
        (i *= t - r),
        (n = new Date(n.getTime())),
        n.setMinutes(n.getMinutes() + i),
        n
      );
    }
    function vt(n) {
      n = e(n).clone();
      try {
        n.empty();
      } catch (i) {}
      var t = e('<div>').append(n).html();
      try {
        return n[0].nodeType === di
          ? v(t)
          : t.match(/^(<[^>]+>)/)[1].replace(/^<([\w\-]+)/, function (n, t) {
              return '<' + v(t);
            });
      } catch (r) {
        return v(t);
      }
    }
    function ke(n) {
      try {
        return decodeURIComponent(n);
      } catch (t) {}
    }
    function de(n) {
      var i = {};
      return (
        t((n || '').split('&'), function (n) {
          var f, t, r;
          n &&
            ((t = n = n.replace(/\+/g, '%20')),
            (f = n.indexOf('=')),
            -1 !== f && ((t = n.substring(0, f)), (r = n.substring(f + 1))),
            (t = ke(t)),
            u(t) &&
              ((r = u(r) ? ke(r) : !0),
              wt.call(i, t)
                ? c(i[t])
                  ? i[t].push(r)
                  : (i[t] = [i[t], r])
                : (i[t] = r)));
        }),
        i
      );
    }
    function lf(n) {
      var i = [];
      return (
        t(n, function (n, r) {
          c(n)
            ? t(n, function (n) {
                i.push(ht(r, !0) + (!0 === n ? '' : '=' + ht(n, !0)));
              })
            : i.push(ht(r, !0) + (!0 === n ? '' : '=' + ht(n, !0)));
        }),
        i.length ? i.join('&') : ''
      );
    }
    function tu(n) {
      return ht(n, !0)
        .replace(/%26/gi, '&')
        .replace(/%3D/gi, '=')
        .replace(/%2B/gi, '+');
    }
    function ht(n, t) {
      return encodeURIComponent(n)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        .replace(/%20/g, t ? '%20' : '+');
    }
    function nl(n, t) {
      for (var i, u = ki.length, r = 0; r < u; ++r)
        if (((i = ki[r] + t), h((i = n.getAttribute(i))))) return i;
      return null;
    }
    function tl(n, i) {
      var r,
        u,
        f = {};
      t(ki, function (t) {
        t += 'app';
        !r &&
          n.hasAttribute &&
          n.hasAttribute(t) &&
          ((r = n), (u = n.getAttribute(t)));
      });
      t(ki, function (t) {
        t += 'app';
        var i;
        !r &&
          (i = n.querySelector('[' + t.replace(':', '\\:') + ']')) &&
          ((r = i), (u = i.getAttribute(t)));
      });
      r && ((f.strictDi = null !== nl(r, 'strict-di')), i(r, u ? [u] : [], f));
    }
    function ge(i, r, u) {
      s(u) || (u = {});
      u = a({ strictDi: !1 }, u);
      var o = function () {
          if (((i = e(i)), i.injector())) {
            var t = i[0] === n.document ? 'document' : vt(i);
            throw ci('btstrpd', t.replace(/</, '&lt;').replace(/>/, '&gt;'));
          }
          return (
            (r = r || []),
            r.unshift([
              '$provide',
              function (n) {
                n.value('$rootElement', i);
              },
            ]),
            u.debugInfoEnabled &&
              r.push([
                '$compileProvider',
                function (n) {
                  n.debugInfoEnabled(!0);
                },
              ]),
            r.unshift('ng'),
            (t = ar(r, u.strictDi)),
            t.invoke([
              '$rootScope',
              '$rootElement',
              '$compile',
              '$injector',
              function (n, t, i, r) {
                n.$apply(function () {
                  t.data('$injector', r);
                  i(t)(n);
                });
              },
            ]),
            t
          );
        },
        h = /^NG_ENABLE_DEBUG_INFO!/,
        c = /^NG_DEFER_BOOTSTRAP!/;
      if (
        (n &&
          h.test(n.name) &&
          ((u.debugInfoEnabled = !0), (n.name = n.name.replace(h, ''))),
        n && !c.test(n.name))
      )
        return o();
      n.name = n.name.replace(c, '');
      ut.resumeBootstrap = function (n) {
        return (
          t(n, function (n) {
            r.push(n);
          }),
          o()
        );
      };
      f(ut.resumeDeferredBootstrap) && ut.resumeDeferredBootstrap();
    }
    function il() {
      n.name = 'NG_ENABLE_DEBUG_INFO!' + n.name;
      n.location.reload();
    }
    function rl(n) {
      if (((n = ut.element(n).injector()), !n)) throw ci('test');
      return n.get('$$testability');
    }
    function no(n, t) {
      return (
        (t = t || '_'),
        n.replace(kv, function (n, i) {
          return (i ? t : '') + n.toLowerCase();
        })
      );
    }
    function ul() {
      var i, t;
      sh ||
        ((t = du()),
        (ti = r(t) ? n.jQuery : t ? n[t] : void 0) && ti.fn.on
          ? ((e = ti),
            a(ti.fn, {
              scope: nr.scope,
              isolateScope: nr.isolateScope,
              controller: nr.controller,
              injector: nr.injector,
              inheritedData: nr.inheritedData,
            }),
            (i = ti.cleanData),
            (ti.cleanData = function (n) {
              for (var r, u = 0, t; null != (t = n[u]); u++)
                (r = ti._data(t, 'events')) &&
                  r.$destroy &&
                  ti(t).triggerHandler('$destroy');
              i(n);
            }))
          : (e = b),
        (ut.element = e),
        (sh = !0));
    }
    function iu(n, t, i) {
      if (!n) throw ci('areq', t || '?', i || 'required');
      return n;
    }
    function yi(n, t, i) {
      return (
        i && c(n) && (n = n[n.length - 1]),
        iu(
          f(n),
          t,
          'not a function, got ' +
            (n && 'object' == typeof n
              ? n.constructor.name || 'Object'
              : typeof n)
        ),
        n
      );
    }
    function pi(n, t) {
      if ('hasOwnProperty' === n) throw ci('badname', t);
    }
    function to(n, t, i) {
      if (!t) return n;
      t = t.split('.');
      for (var u, e = n, o = t.length, r = 0; r < o; r++)
        (u = t[r]), n && (n = (e = n)[u]);
      return !i && f(n) ? sr(e, n) : n;
    }
    function ru(n) {
      for (
        var t = n[0], u = n[n.length - 1], i, r = 1;
        t !== u && (t = t.nextSibling);
        r++
      )
        (i || n[r] !== t) && (i || (i = e(bt.call(n, 0, r))), i.push(t));
      return i || n;
    }
    function y() {
      return Object.create(null);
    }
    function fl(n) {
      function t(n, t, i) {
        return n[t] || (n[t] = i());
      }
      var i = l('$injector'),
        r = l('ng');
      return (
        (n = t(n, 'angular', Object)),
        (n.$$minErr = n.$$minErr || l),
        t(n, 'module', function () {
          var n = {};
          return function (u, e, o) {
            if ('hasOwnProperty' === u) throw r('badname', 'module');
            return (
              e && n.hasOwnProperty(u) && (n[u] = null),
              t(n, u, function () {
                function t(n, t, i, u) {
                  return (
                    u || (u = r),
                    function () {
                      return u[i || 'push']([n, t, arguments]), s;
                    }
                  );
                }
                function n(n, t) {
                  return function (i, e) {
                    return (
                      e && f(e) && (e.$$moduleName = u),
                      r.push([n, t, arguments]),
                      s
                    );
                  };
                }
                if (!e) throw i('nomod', u);
                var r = [],
                  h = [],
                  c = [],
                  l = t('$injector', 'invoke', 'push', h),
                  s = {
                    _invokeQueue: r,
                    _configBlocks: h,
                    _runBlocks: c,
                    requires: e,
                    name: u,
                    provider: n('$provide', 'provider'),
                    factory: n('$provide', 'factory'),
                    service: n('$provide', 'service'),
                    value: t('$provide', 'value'),
                    constant: t('$provide', 'constant', 'unshift'),
                    decorator: n('$provide', 'decorator'),
                    animation: n('$animateProvider', 'register'),
                    filter: n('$filterProvider', 'register'),
                    controller: n('$controllerProvider', 'register'),
                    directive: n('$compileProvider', 'directive'),
                    component: n('$compileProvider', 'component'),
                    config: l,
                    run: function (n) {
                      return c.push(n), this;
                    },
                  };
                return o && l(o), s;
              })
            );
          };
        })
      );
    }
    function el(i) {
      a(i, {
        bootstrap: ge,
        copy: kt,
        extend: a,
        merge: bc,
        equals: st,
        element: e,
        forEach: t,
        injector: ar,
        noop: o,
        bind: sr,
        toJson: hr,
        fromJson: we,
        identity: ir,
        isUndefined: r,
        isDefined: u,
        isString: h,
        isFunction: f,
        isObject: s,
        isNumber: w,
        isElement: hf,
        isArray: c,
        version: dv,
        isDate: et,
        lowercase: v,
        uppercase: bu,
        callbacks: { counter: 0 },
        getTestability: rl,
        $$minErr: l,
        $$csp: li,
        reloadWithDebugInfo: il,
      });
      re = fl(n);
      re(
        'ng',
        ['ngLocale'],
        [
          '$provide',
          function (n) {
            n.provider({ $$sanitizeUri: ka });
            n.provider('$compile', lo)
              .directive({
                a: nc,
                input: sc,
                textarea: sc,
                form: vp,
                script: ab,
                select: pb,
                style: bb,
                option: wb,
                ngBind: nw,
                ngBindHtml: iw,
                ngBindTemplate: tw,
                ngClass: uw,
                ngClassEven: ew,
                ngClassOdd: fw,
                ngCloak: ow,
                ngController: sw,
                ngForm: yp,
                ngHide: fb,
                ngIf: cw,
                ngInclude: lw,
                ngInit: vw,
                ngNonBindable: dw,
                ngPluralize: ib,
                ngRepeat: rb,
                ngShow: ub,
                ngStyle: eb,
                ngSwitch: ob,
                ngSwitchWhen: sb,
                ngSwitchDefault: hb,
                ngOptions: tb,
                ngTransclude: lb,
                ngModel: ww,
                ngList: yw,
                ngChange: rw,
                pattern: vc,
                ngPattern: vc,
                required: ac,
                ngRequired: ac,
                minlength: pc,
                ngMinlength: pc,
                maxlength: yc,
                ngMaxlength: yc,
                ngValue: gp,
                ngModelOptions: kw,
              })
              .directive({ ngInclude: aw })
              .directive(kr)
              .directive(hc);
            n.provider({
              $anchorScroll: vl,
              $animate: vy,
              $animateCss: wy,
              $$animateJs: ly,
              $$animateQueue: ay,
              $$AnimateRunner: py,
              $$animateAsyncRun: yy,
              $browser: wl,
              $cacheFactory: bl,
              $controller: dl,
              $document: gl,
              $exceptionHandler: na,
              $filter: ys,
              $$forceReflow: ky,
              $interpolate: oa,
              $interval: sa,
              $http: ra,
              $httpParamSerializer: ta,
              $httpParamSerializerJQLike: ia,
              $httpBackend: fa,
              $xhrFactory: ua,
              $location: ha,
              $log: ca,
              $parse: va,
              $rootScope: ba,
              $q: ya,
              $$q: pa,
              $sce: nv,
              $sceDelegate: ga,
              $sniffer: tv,
              $templateCache: kl,
              $templateRequest: iv,
              $$testability: rv,
              $timeout: uv,
              $window: fv,
              $$rAF: wa,
              $$jqLite: ll,
              $$HashMap: fy,
              $$cookieReader: ev,
            });
          },
        ]
      );
    }
    function cr(n) {
      return n
        .replace(gv, function (n, t, i, r) {
          return r ? i.toUpperCase() : i;
        })
        .replace(ny, 'Moz$1');
    }
    function io(n) {
      return (n = n.nodeType), 1 === n || !n || 9 === n;
    }
    function ro(n, i) {
      var r,
        u,
        f = i.createDocumentFragment(),
        e = [];
      if (fe.test(n)) {
        for (
          r = r || f.appendChild(i.createElement('div')),
            u = (iy.exec(n) || ['', ''])[1].toLowerCase(),
            u = ct[u] || ct._default,
            r.innerHTML = u[1] + n.replace(ry, '<$1></$2>') + u[2],
            u = u[0];
          u--;

        )
          r = r.lastChild;
        e = or(e, r.childNodes);
        r = f.firstChild;
        r.textContent = '';
      } else e.push(i.createTextNode(n));
      return (
        (f.textContent = ''),
        (f.innerHTML = ''),
        t(e, function (n) {
          f.appendChild(n);
        }),
        f
      );
    }
    function uo(n, t) {
      var i = n.parentNode;
      i && i.replaceChild(t, n);
      t.appendChild(n);
    }
    function b(t) {
      var i, r;
      if (t instanceof b) return t;
      if ((h(t) && ((t = p(t)), (i = !0)), !(this instanceof b))) {
        if (i && '<' != t.charAt(0)) throw ue('nosel');
        return new b(t);
      }
      i &&
        ((i = n.document),
        (t = (r = ty.exec(t))
          ? [i.createElement(r[1])]
          : (r = ro(t, i))
          ? r.childNodes
          : []));
      eo(this, t);
    }
    function af(n) {
      return n.cloneNode(!0);
    }
    function uu(n, t) {
      if ((t || lr(n), n.querySelectorAll))
        for (var r = n.querySelectorAll('*'), i = 0, u = r.length; i < u; i++)
          lr(r[i]);
    }
    function fo(n, i, r, f) {
      var e, o, s;
      if (u(f)) throw ue('offargs');
      if (((e = (f = fu(n)) && f.events), (o = f && f.handle), o))
        if (i)
          (s = function (t) {
            var i = e[t];
            u(r) && er(i || [], r);
            (u(r) && i && 0 < i.length) ||
              (n.removeEventListener(t, o, !1), delete e[t]);
          }),
            t(i.split(' '), function (n) {
              s(n);
              gu[n] && s(gu[n]);
            });
        else
          for (i in e)
            '$destroy' !== i && n.removeEventListener(i, o, !1), delete e[i];
    }
    function lr(n, t) {
      var r = n.ng339,
        i = r && gi[r];
      i &&
        (t
          ? delete i.data[t]
          : (i.handle && (i.events.$destroy && i.handle({}, '$destroy'), fo(n)),
            delete gi[r],
            (n.ng339 = void 0)));
    }
    function fu(n, t) {
      var i = n.ng339,
        i = i && gi[i];
      return (
        t &&
          !i &&
          ((n.ng339 = i = ++hh),
          (i = gi[i] = { events: {}, data: {}, handle: void 0 })),
        i
      );
    }
    function vf(n, t, i) {
      if (io(n)) {
        var r = u(i),
          f = !r && t && !s(t),
          e = !t;
        if (((n = (n = fu(n, !f)) && n.data), r)) n[t] = i;
        else {
          if (e) return n;
          if (f) return n && n[t];
          a(n, t);
        }
      }
    }
    function eu(n, t) {
      return n.getAttribute
        ? -1 <
            (' ' + (n.getAttribute('class') || '') + ' ')
              .replace(/[\n\t]/g, ' ')
              .indexOf(' ' + t + ' ')
        : !1;
    }
    function ou(n, i) {
      i &&
        n.setAttribute &&
        t(i.split(' '), function (t) {
          n.setAttribute(
            'class',
            p(
              (' ' + (n.getAttribute('class') || '') + ' ')
                .replace(/[\n\t]/g, ' ')
                .replace(' ' + p(t) + ' ', ' ')
            )
          );
        });
    }
    function su(n, i) {
      if (i && n.setAttribute) {
        var r = (' ' + (n.getAttribute('class') || '') + ' ').replace(
          /[\n\t]/g,
          ' '
        );
        t(i.split(' '), function (n) {
          n = p(n);
          -1 === r.indexOf(' ' + n + ' ') && (r += n + ' ');
        });
        n.setAttribute('class', p(r));
      }
    }
    function eo(n, t) {
      var i, r;
      if (t)
        if (t.nodeType) n[n.length++] = t;
        else if (((i = t.length), 'number' == typeof i && t.window !== t)) {
          if (i) for (r = 0; r < i; r++) n[n.length++] = t[r];
        } else n[n.length++] = t;
    }
    function oo(n, t) {
      return hu(n, '$' + (t || 'ngController') + 'Controller');
    }
    function hu(n, t, i) {
      for (
        9 == n.nodeType && (n = n.documentElement), t = c(t) ? t : [t];
        n;

      ) {
        for (var r = 0, f = t.length; r < f; r++)
          if (u((i = e.data(n, t[r])))) return i;
        n = n.parentNode || (11 === n.nodeType && n.host);
      }
    }
    function so(n) {
      for (uu(n, !0); n.firstChild; ) n.removeChild(n.firstChild);
    }
    function cu(n, t) {
      t || uu(n);
      var i = n.parentNode;
      i && i.removeChild(n);
    }
    function ol(t, i) {
      if (((i = i || n), 'complete' === i.document.readyState)) i.setTimeout(t);
      else e(i).on('load', t);
    }
    function ho(n, t) {
      var i = nf[t.toLowerCase()];
      return i && ee[at(n)] && i;
    }
    function sl(n, t) {
      var i = function (i, u) {
        var f, e, s, h, o;
        if (
          ((i.isDefaultPrevented = function () {
            return i.defaultPrevented;
          }),
          (f = t[u || i.type]),
          (e = f ? f.length : 0),
          e)
        )
          for (
            r(i.immediatePropagationStopped) &&
              ((s = i.stopImmediatePropagation),
              (i.stopImmediatePropagation = function () {
                i.immediatePropagationStopped = !0;
                i.stopPropagation && i.stopPropagation();
                s && s.call(i);
              })),
              i.isImmediatePropagationStopped = function () {
                return !0 === i.immediatePropagationStopped;
              },
              h = f.specialHandlerWrapper || hl,
              1 < e && (f = ot(f)),
              o = 0;
            o < e;
            o++
          )
            i.isImmediatePropagationStopped() || h(n, i, f[o]);
      };
      return (i.elem = n), i;
    }
    function hl(n, t, i) {
      i.call(n, t);
    }
    function cl(n, t, i) {
      var r = t.relatedTarget;
      (r && (r === n || uy.call(n, r))) || i.call(n, t);
    }
    function ll() {
      this.$get = function () {
        return a(b, {
          hasClass: function (n, t) {
            return n.attr && (n = n[0]), eu(n, t);
          },
          addClass: function (n, t) {
            return n.attr && (n = n[0]), su(n, t);
          },
          removeClass: function (n, t) {
            return n.attr && (n = n[0]), ou(n, t);
          },
        });
      };
    }
    function fi(n, t) {
      var i = n && n.$$hashKey;
      return i
        ? ('function' == typeof i && (i = n.$$hashKey()), i)
        : ((i = typeof n),
          'function' == i || ('object' == i && null !== n)
            ? (n.$$hashKey = i + ':' + (t || wc)())
            : i + ':' + n);
    }
    function wi(n, i) {
      if (i) {
        var r = 0;
        this.nextUid = function () {
          return ++r;
        };
      }
      t(n, this.put, this);
    }
    function co(n) {
      return (
        (n = (Function.prototype.toString.call(n) + ' ').replace(cy, '')),
        n.match(ey) || n.match(oy)
      );
    }
    function al(n) {
      return (n = co(n))
        ? 'function(' + (n[1] || '').replace(/[\s\r\n]+/, ' ') + ')'
        : 'fn';
    }
    function ar(n, i) {
      function l(n) {
        return function (i, r) {
          if (s(i)) t(i, ve(n));
          else return n(i, r);
        };
      }
      function w(n, t) {
        if (
          (pi(n, 'service'), (f(t) || c(t)) && (t = o.instantiate(t)), !t.$get)
        )
          throw ai('pget', n);
        return (e[n + 'Provider'] = t);
      }
      function tt(n, t) {
        return function () {
          var i = u.invoke(t, this);
          if (r(i)) throw ai('undef', n);
          return i;
        };
      }
      function v(n, t, i) {
        return w(n, { $get: !1 !== i ? tt(n, t) : t });
      }
      function b(n) {
        iu(r(n) || c(n), 'modulesToLoad', 'not an array');
        var i = [],
          u;
        return (
          t(n, function (n) {
            function r(n) {
              for (var i, r, t = 0, u = n.length; t < u; t++)
                (i = n[t]), (r = o.get(i[0])), r[i[1]].apply(r, i[2]);
            }
            if (!d.get(n)) {
              d.put(n, !0);
              try {
                h(n)
                  ? ((u = re(n)),
                    (i = i.concat(b(u.requires)).concat(u._runBlocks)),
                    r(u._invokeQueue),
                    r(u._configBlocks))
                  : f(n)
                  ? i.push(o.invoke(n))
                  : c(n)
                  ? i.push(o.invoke(n))
                  : yi(n, 'module');
              } catch (t) {
                throw (
                  (c(n) && (n = n[n.length - 1]),
                  t.message &&
                    t.stack &&
                    -1 == t.stack.indexOf(t.message) &&
                    (t = t.message + '\n' + t.stack),
                  ai('modulerr', n, t.stack || t.message || t))
                );
              }
            }
          }),
          i
        );
      }
      function k(n, t) {
        function r(i, r) {
          if (n.hasOwnProperty(i)) {
            if (n[i] === y) throw ai('cdep', i + ' <- ' + a.join(' <- '));
            return n[i];
          }
          try {
            return a.unshift(i), (n[i] = y), (n[i] = t(i, r));
          } catch (u) {
            throw (n[i] === y && delete n[i], u);
          } finally {
            a.shift();
          }
        }
        function u(n, t, u) {
          var o = [],
            e,
            s,
            f;
          for (n = ar.$$annotate(n, i, u), e = 0, s = n.length; e < s; e++) {
            if (((f = n[e]), 'string' != typeof f)) throw ai('itkn', f);
            o.push(t && t.hasOwnProperty(f) ? t[f] : r(f, u));
          }
          return o;
        }
        return {
          invoke: function (n, t, i, r) {
            return (
              'string' == typeof i && ((r = i), (i = null)),
              (i = u(n, i, r)),
              c(n) && (n = n[n.length - 1]),
              (r =
                11 >= ni
                  ? !1
                  : 'function' == typeof n &&
                    /^(?:class\s|constructor\()/.test(
                      Function.prototype.toString.call(n) + ' '
                    )),
              r
                ? (i.unshift(null), new (Function.prototype.bind.apply(n, i))())
                : n.apply(t, i)
            );
          },
          instantiate: function (n, t, i) {
            var r = c(n) ? n[n.length - 1] : n;
            return (
              (n = u(n, t, i)),
              n.unshift(null),
              new (Function.prototype.bind.apply(r, n))()
            );
          },
          get: r,
          annotate: ar.$$annotate,
          has: function (t) {
            return e.hasOwnProperty(t + 'Provider') || n.hasOwnProperty(t);
          },
        };
      }
      var nt, u;
      i = !0 === i;
      var y = {},
        a = [],
        d = new wi([], !0),
        e = {
          $provide: {
            provider: l(w),
            factory: l(v),
            service: l(function (n, t) {
              return v(n, [
                '$injector',
                function (n) {
                  return n.instantiate(t);
                },
              ]);
            }),
            value: l(function (n, t) {
              return v(n, ft(t), !1);
            }),
            constant: l(function (n, t) {
              pi(n, 'constant');
              e[n] = t;
              g[n] = t;
            }),
            decorator: function (n, t) {
              var i = o.get(n + 'Provider'),
                r = i.$get;
              i.$get = function () {
                var n = u.invoke(r, i);
                return u.invoke(t, null, { $delegate: n });
              };
            },
          },
        },
        o = (e.$injector = k(e, function (n, t) {
          ut.isString(t) && a.push(t);
          throw ai('unpr', a.join(' <- '));
        })),
        g = {},
        p = k(g, function (n, t) {
          var i = o.get(n + 'Provider', t);
          return u.invoke(i.$get, i, void 0, n);
        }),
        u = p;
      return (
        (e.$injectorProvider = { $get: ft(p) }),
        (nt = b(n)),
        (u = p.get('$injector')),
        (u.strictDi = i),
        t(nt, function (n) {
          n && u.invoke(n);
        }),
        u
      );
    }
    function vl() {
      var n = !0;
      this.disableAutoScrolling = function () {
        n = !1;
      };
      this.$get = [
        '$window',
        '$location',
        '$rootScope',
        function (t, i, r) {
          function s(n) {
            var t = null;
            return (
              Array.prototype.some.call(n, function (n) {
                if ('a' === at(n)) return (t = n), !0;
              }),
              t
            );
          }
          function u(n) {
            if (n) {
              n.scrollIntoView();
              var i;
              i = e.yOffset;
              f(i)
                ? (i = i())
                : hf(i)
                ? ((i = i[0]),
                  (i =
                    'fixed' !== t.getComputedStyle(i).position
                      ? 0
                      : i.getBoundingClientRect().bottom))
                : w(i) || (i = 0);
              i && ((n = n.getBoundingClientRect().top), t.scrollBy(0, n - i));
            } else t.scrollTo(0, 0);
          }
          function e(n) {
            n = h(n) ? n : i.hash();
            var t;
            n
              ? (t = o.getElementById(n))
                ? u(t)
                : (t = s(o.getElementsByName(n)))
                ? u(t)
                : 'top' === n && u(null)
              : u(null);
          }
          var o = t.document;
          return (
            n &&
              r.$watch(
                function () {
                  return i.hash();
                },
                function (n, t) {
                  (n === t && '' === n) ||
                    ol(function () {
                      r.$evalAsync(e);
                    });
                }
              ),
            e
          );
        },
      ];
    }
    function vr(n, t) {
      return !n && !t
        ? ''
        : n
        ? t
          ? (c(n) && (n = n.join(' ')), c(t) && (t = t.join(' ')), n + ' ' + t)
          : n
        : t;
    }
    function yl(n) {
      h(n) && (n = n.split(' '));
      var i = y();
      return (
        t(n, function (n) {
          n.length && (i[n] = !0);
        }),
        i
      );
    }
    function ei(n) {
      return s(n) ? n : {};
    }
    function pl(n, i, u, f) {
      function k(n) {
        try {
          n.apply(null, bt.call(arguments, 1));
        } finally {
          if ((l--, 0 === l))
            for (; b.length; )
              try {
                b.pop()();
              } catch (t) {
                u.error(t);
              }
        }
      }
      function d() {
        y = null;
        g();
        tt();
      }
      function g() {
        h = ot();
        h = r(h) ? null : h;
        st(h, nt) && (h = nt);
        nt = h;
      }
      function tt() {
        (v !== s.url() || a !== h) &&
          ((v = s.url()),
          (a = h),
          t(it, function (n) {
            n(s.url(), h);
          }));
      }
      var s = this,
        c = n.location,
        p = n.history,
        ut = n.setTimeout,
        ft = n.clearTimeout,
        w = {},
        l,
        b;
      s.isMock = !1;
      l = 0;
      b = [];
      s.$$completeOutstandingRequest = k;
      s.$$incOutstandingRequestCount = function () {
        l++;
      };
      s.notifyWhenNoOutstandingRequests = function (n) {
        0 === l ? n() : b.push(n);
      };
      var h,
        a,
        v = c.href,
        et = i.find('base'),
        y = null,
        ot = f.history
          ? function () {
              try {
                return p.state;
              } catch (n) {}
            }
          : o;
      g();
      a = h;
      s.url = function (t, i, u) {
        var o, e;
        return (r(u) && (u = null),
        c !== n.location && (c = n.location),
        p !== n.history && (p = n.history),
        t)
          ? ((o = a === u), v === t && (!f.history || o))
            ? s
            : ((e = v && oi(v) === oi(t)),
              (v = t),
              (a = u),
              !f.history || (e && o)
                ? (e || (y = t),
                  i
                    ? c.replace(t)
                    : e
                    ? ((i = c),
                      (u = t.indexOf('#')),
                      (u = -1 === u ? '' : t.substr(u)),
                      (i.hash = u))
                    : (c.href = t),
                  c.href !== t && (y = t))
                : (p[i ? 'replaceState' : 'pushState'](u, '', t), g(), (a = h)),
              y && (y = t),
              s)
          : y || c.href.replace(/%27/g, "'");
      };
      s.state = function () {
        return h;
      };
      var it = [],
        rt = !1,
        nt = null;
      s.onUrlChange = function (t) {
        if (!rt) {
          if (f.history) e(n).on('popstate', d);
          e(n).on('hashchange', d);
          rt = !0;
        }
        return it.push(t), t;
      };
      s.$$applicationDestroyed = function () {
        e(n).off('hashchange popstate', d);
      };
      s.$$checkUrlChange = tt;
      s.baseHref = function () {
        var n = et.attr('href');
        return n ? n.replace(/^(https?\:)?\/\/[^\/]*/, '') : '';
      };
      s.defer = function (n, t) {
        var i;
        return (
          l++,
          (i = ut(function () {
            delete w[i];
            k(n);
          }, t || 0)),
          (w[i] = !0),
          i
        );
      };
      s.defer.cancel = function (n) {
        return w[n] ? (delete w[n], ft(n), k(o), !0) : !1;
      };
    }
    function wl() {
      this.$get = [
        '$window',
        '$log',
        '$sniffer',
        '$document',
        function (n, t, i, r) {
          return new pl(n, r, t, i);
        },
      ];
    }
    function bl() {
      this.$get = function () {
        function i(t, i) {
          function v(n) {
            n != o &&
              (u ? u == n && (u = n.n) : (u = n),
              c(n.n, n.p),
              c(n, o),
              (o = n),
              (o.n = null));
          }
          function c(n, t) {
            n != t && (n && (n.p = t), t && (t.n = n));
          }
          if (t in n) throw l('$cacheFactory')('iid', t);
          var s = 0,
            p = a({}, i, { id: t }),
            f = y(),
            h = (i && i.capacity) || Number.MAX_VALUE,
            e = y(),
            o = null,
            u = null;
          return (n[t] = {
            put: function (n, t) {
              if (!r(t)) {
                if (h < Number.MAX_VALUE) {
                  var i = e[n] || (e[n] = { key: n });
                  v(i);
                }
                return (
                  n in f || s++, (f[n] = t), s > h && this.remove(u.key), t
                );
              }
            },
            get: function (n) {
              if (h < Number.MAX_VALUE) {
                var t = e[n];
                if (!t) return;
                v(t);
              }
              return f[n];
            },
            remove: function (n) {
              if (h < Number.MAX_VALUE) {
                var t = e[n];
                if (!t) return;
                t == o && (o = t.p);
                t == u && (u = t.n);
                c(t.n, t.p);
                delete e[n];
              }
              n in f && (delete f[n], s--);
            },
            removeAll: function () {
              f = y();
              s = 0;
              e = y();
              o = u = null;
            },
            destroy: function () {
              e = p = f = null;
              delete n[t];
            },
            info: function () {
              return a({}, p, { size: s });
            },
          });
        }
        var n = {};
        return (
          (i.info = function () {
            var i = {};
            return (
              t(n, function (n, t) {
                i[t] = n.info();
              }),
              i
            );
          }),
          (i.get = function (t) {
            return n[t];
          }),
          i
        );
      };
    }
    function kl() {
      this.$get = [
        '$cacheFactory',
        function (n) {
          return n('templates');
        },
      ];
    }
    function lo(i, l) {
      function d(n, i, r) {
        var f = /^\s*([@&<]|=(\*?))(\??)\s*(\w*)\s*$/,
          u = y();
        return (
          t(n, function (n, t) {
            if (n in nt) u[t] = nt[n];
            else {
              var e = n.match(f);
              if (!e)
                throw g(
                  'iscp',
                  i,
                  t,
                  n,
                  r
                    ? 'controller bindings definition'
                    : 'isolate scope definition'
                );
              u[t] = {
                mode: e[1][0],
                collection: '*' === e[2],
                optional: '?' === e[3],
                attrName: e[4] || t,
              };
              e[4] && (nt[n] = u[t]);
            }
          }),
          u
        );
      }
      function it(n) {
        var t = n.charAt(0);
        if (!t || t !== v(t)) throw g('baddir', n);
        if (n !== n.trim()) throw g('baddir', n);
      }
      function ut(n) {
        var i = n.require || (n.controller && n.name);
        return (
          !c(i) &&
            s(i) &&
            t(i, function (n, t) {
              var r = n.match(tt);
              n.substring(r[0].length) || (i[t] = r[0] + t);
            }),
          i
        );
      }
      var b = {},
        et = /^\s*directive\:\s*([\w\-]+)\s+(.*)$/,
        ot = /(([\w\-]+)(?:\:([^;]+))?;?)/,
        ht = dc('ngSrc,ngSrcset,src,srcset'),
        tt = /^(?:(\^\^?)?(\?)?(\^\^?)?)?/,
        ct = /^(on[a-z]+|formaction)$/,
        nt = y(),
        w,
        k;
      this.directive = function lt(n, r) {
        return (
          pi(n, 'directive'),
          h(n)
            ? (it(n),
              iu(r, 'directiveFactory'),
              b.hasOwnProperty(n) ||
                ((b[n] = []),
                i.factory(n + 'Directive', [
                  '$injector',
                  '$exceptionHandler',
                  function (i, r) {
                    var u = [];
                    return (
                      t(b[n], function (t, e) {
                        try {
                          var o = i.invoke(t);
                          f(o)
                            ? (o = { compile: ft(o) })
                            : !o.compile && o.link && (o.compile = ft(o.link));
                          o.priority = o.priority || 0;
                          o.index = e;
                          o.name = o.name || n;
                          o.require = ut(o);
                          o.restrict = o.restrict || 'EA';
                          o.$$moduleName = t.$$moduleName;
                          u.push(o);
                        } catch (s) {
                          r(s);
                        }
                      }),
                      u
                    );
                  },
                ])),
              b[n].push(r))
            : t(n, ve(lt)),
          this
        );
      };
      this.component = function (n, i) {
        function r(n) {
          function r(t) {
            return f(t) || c(t)
              ? function (i, r) {
                  return n.invoke(t, this, { $element: i, $attrs: r });
                }
              : t;
          }
          var o = i.template || i.templateUrl ? i.template : '',
            e = {
              controller: u,
              controllerAs: yo(i.controller) || i.controllerAs || '$ctrl',
              template: r(o),
              templateUrl: r(i.templateUrl),
              transclude: i.transclude,
              scope: {},
              bindToController: i.bindings || {},
              restrict: 'E',
              require: i.require,
            };
          return (
            t(i, function (n, t) {
              '$' === t.charAt(0) && (e[t] = n);
            }),
            e
          );
        }
        var u = i.controller || function () {};
        return (
          t(i, function (n, t) {
            '$' === t.charAt(0) && ((r[t] = n), f(u) && (u[t] = n));
          }),
          (r.$inject = ['$injector']),
          this.directive(n, r)
        );
      };
      this.aHrefSanitizationWhitelist = function (n) {
        return u(n)
          ? (l.aHrefSanitizationWhitelist(n), this)
          : l.aHrefSanitizationWhitelist();
      };
      this.imgSrcSanitizationWhitelist = function (n) {
        return u(n)
          ? (l.imgSrcSanitizationWhitelist(n), this)
          : l.imgSrcSanitizationWhitelist();
      };
      w = !0;
      this.debugInfoEnabled = function (n) {
        return u(n) ? ((w = n), this) : w;
      };
      k = 10;
      this.onChangesTtl = function (n) {
        return arguments.length ? ((k = n), this) : k;
      };
      this.$get = [
        '$injector',
        '$interpolate',
        '$exceptionHandler',
        '$templateRequest',
        '$parse',
        '$controller',
        '$rootScope',
        '$sce',
        '$animate',
        '$$sanitizeUri',
        function (i, l, nt, it, ut, ft, lt, pt, kt, dt) {
          function ar() {
            try {
              if (!--or) throw ((ti = void 0), g('infchng', k));
              lt.$apply(function () {
                for (var n = 0, t = ti.length; n < t; ++n) ti[n]();
                ti = void 0;
              });
            } finally {
              or++;
            }
          }
          function hi(n, t) {
            if (t)
              for (var u = Object.keys(t), r, i = 0, f = u.length; i < f; i++)
                (r = u[i]), (this[r] = t[r]);
            else this.$attr = {};
            this.$$element = n;
          }
          function vr(n, t, i) {
            ur.innerHTML = '<span ' + t + '>';
            t = ur.firstChild.attributes;
            var r = t[0];
            t.removeNamedItem(r.name);
            r.value = i;
            n.attributes.setNamedItem(r);
          }
          function ei(n, t) {
            try {
              n.addClass(t);
            } catch (i) {}
          }
          function gt(t, i, r, u, f) {
            var h, c, o;
            t instanceof e || (t = e(t));
            for (var s = 0, l = t.length; s < l; s++)
              (h = t[s]),
                h.nodeType === di &&
                  h.nodeValue.match(/\S+/) &&
                  uo(h, (t[s] = n.document.createElement('span')));
            return (
              (c = ci(t, i, t, r, u, f)),
              gt.$$addScopeClass(t),
              (o = null),
              function (n, i, r) {
                var u, s, h;
                if (
                  (iu(n, 'scope'),
                  f && f.needsNewScope && (n = n.$parent.$new()),
                  (r = r || {}),
                  (u = r.parentBoundTranscludeFn),
                  (s = r.transcludeControllers),
                  (r = r.futureParentElement),
                  u && u.$$boundTransclude && (u = u.$$boundTransclude),
                  o ||
                    (o = (r = r && r[0])
                      ? 'foreignobject' !== at(r) && rt.call(r).match(/SVG/)
                        ? 'svg'
                        : 'html'
                      : 'html'),
                  (r =
                    'html' !== o
                      ? e(vi(o, e('<div>').append(t).html()))
                      : i
                      ? nr.clone.call(t)
                      : t),
                  s)
                )
                  for (h in s) r.data('$' + h + 'Controller', s[h].instance);
                return (
                  gt.$$addScopeInfo(r, n), i && i(r, n), c && c(n, r, r, u), r
                );
              }
            );
          }
          function ci(n, t, i, r, u, f) {
            function y(n, i, r, u) {
              var s, h, c, f, v, y, l;
              if (a)
                for (l = Array(i.length), f = 0; f < o.length; f += 3)
                  (s = o[f]), (l[s] = i[s]);
              else l = i;
              for (f = 0, v = o.length; f < v; )
                (h = l[o[f++]]),
                  (i = o[f++]),
                  (s = o[f++]),
                  i
                    ? (i.scope
                        ? ((c = n.$new()), gt.$$addScopeInfo(e(h), c))
                        : (c = n),
                      (y = i.transcludeOnThisElement
                        ? ri(n, i.transclude, u)
                        : !i.templateOnThisElement && u
                        ? u
                        : !u && t
                        ? ri(n, t)
                        : null),
                      i(s, c, h, r, y))
                    : s && s(n, h.childNodes, void 0, u);
            }
            for (var o = [], s, c, l, v, a, h = 0; h < n.length; h++)
              (s = new hi()),
                (c = li(n[h], [], s, 0 === h ? r : void 0, u)),
                (f = c.length ? bi(c, n[h], s, t, i, null, [], [], f) : null) &&
                  f.scope &&
                  gt.$$addScopeClass(s.$$element),
                (s =
                  (f && f.terminal) || !(l = n[h].childNodes) || !l.length
                    ? null
                    : ci(
                        l,
                        f
                          ? (f.transcludeOnThisElement ||
                              !f.templateOnThisElement) &&
                              f.transclude
                          : t
                      )),
                (f || s) && (o.push(h, f, s), (v = !0), (a = a || f)),
                (f = null);
            return v ? y : null;
          }
          function ri(n, t, i) {
            function u(r, u, f, e, o) {
              return (
                r || ((r = n.$new(!1, o)), (r.$$transcluded = !0)),
                t(r, u, {
                  parentBoundTranscludeFn: i,
                  transcludeControllers: f,
                  futureParentElement: e,
                })
              );
            }
            var f = (u.$$slots = y()),
              r;
            for (r in t.$$slots)
              f[r] = t.$$slots[r] ? ri(n, t.$$slots[r], i) : null;
            return u;
          }
          function li(n, t, i, r, u) {
            var w = i.$attr,
              e,
              v,
              y;
            switch (n.nodeType) {
              case 1:
                oi(t, yt(at(n)), 'E', r, u);
                for (
                  var f, o, c, l = n.attributes, a = 0, b = l && l.length;
                  a < b;
                  a++
                )
                  (v = !1),
                    (y = !1),
                    (f = l[a]),
                    (e = f.name),
                    (o = p(f.value)),
                    (f = yt(e)),
                    (c = tu.test(f)) &&
                      (e = e
                        .replace(lh, '')
                        .substr(8)
                        .replace(/_(.)/g, function (n, t) {
                          return t.toUpperCase();
                        })),
                    (f = f.match(ru)) &&
                      pr(f[1]) &&
                      ((v = e),
                      (y = e.substr(0, e.length - 5) + 'end'),
                      (e = e.substr(0, e.length - 6))),
                    (f = yt(e.toLowerCase())),
                    (w[f] = e),
                    (c || !i.hasOwnProperty(f)) &&
                      ((i[f] = o), ho(n, f) && (i[f] = !0)),
                    gr(n, t, o, f, c),
                    oi(t, f, 'A', r, u, v, y);
                if (
                  ((n = n.className), s(n) && (n = n.animVal), h(n) && '' !== n)
                )
                  for (; (e = ot.exec(n)); )
                    (f = yt(e[2])),
                      oi(t, f, 'C', r, u) && (i[f] = p(e[3])),
                      (n = n.substr(e.index + e[0].length));
                break;
              case di:
                if (11 === ni)
                  for (
                    ;
                    n.parentNode &&
                    n.nextSibling &&
                    n.nextSibling.nodeType === di;

                  )
                    (n.nodeValue += n.nextSibling.nodeValue),
                      n.parentNode.removeChild(n.nextSibling);
                kr(t, n.nodeValue);
                break;
              case 8:
                try {
                  (e = et.exec(n.nodeValue)) &&
                    ((f = yt(e[1])), oi(t, f, 'M', r, u) && (i[f] = p(e[2])));
                } catch (k) {}
            }
            return t.sort(br), t;
          }
          function pi(n, t, i) {
            var r = [],
              u = 0;
            if (t && n.hasAttribute && n.hasAttribute(t)) {
              do {
                if (!n) throw g('uterdir', t, i);
                1 == n.nodeType &&
                  (n.hasAttribute(t) && u++, n.hasAttribute(i) && u--);
                r.push(n);
                n = n.nextSibling;
              } while (0 < u);
            } else r.push(n);
            return e(r);
          }
          function wi(n, t, i) {
            return function (r, u, f, e, o) {
              return (u = pi(u[0], t, i)), n(r, u, f, e, o);
            };
          }
          function ai(n, t, i, r, u, f) {
            var e;
            return n
              ? gt(t, i, r, u, f)
              : function () {
                  return (
                    e || ((e = gt(t, i, r, u, f)), (t = i = f = null)),
                    e.apply(this, arguments)
                  );
                };
          }
          function bi(n, i, u, o, h, l, v, w, b) {
            function ur(n, t, i, r) {
              n &&
                (i && (n = wi(n, i, r)),
                (n.require = k.require),
                (n.directiveName = ot),
                (d === k || k.$$isolateScope) &&
                  (n = tr(n, { isolateScope: !0 })),
                v.push(n));
              t &&
                (i && (t = wi(t, i, r)),
                (t.require = k.require),
                (t.directiveName = ot),
                (d === k || k.$$isolateScope) &&
                  (t = tr(t, { isolateScope: !0 })),
                w.push(t));
            }
            function lt(n, o, h, l, y) {
              function lt(n, t, i, u) {
                var e, f;
                if (
                  (fr(n) || ((u = i), (i = t), (t = n), (n = void 0)),
                  ri && (e = it),
                  i || (i = ri ? b.parent() : b),
                  u)
                ) {
                  if (((f = y.$$slots[u]), f)) return f(n, t, e, i, ot);
                  if (r(f)) throw g('noslot', u, vt(b));
                } else return y(n, t, e, i, ot);
              }
              var k, nt, p, tt, ft, it, rt, b, ht, ct, ot;
              i === h
                ? ((l = u), (b = u.$$element))
                : ((b = e(h)), (l = new hi(b, u)));
              ft = o;
              d ? (tt = o.$new(!0)) : ut && (ft = o.$parent);
              y &&
                ((rt = lt),
                (rt.$$boundTransclude = y),
                (rt.isSlotFilled = function (n) {
                  return !!y.$$slots[n];
                }));
              et && (it = yr(b, l, rt, et, tt, o, d));
              d &&
                (gt.$$addScopeInfo(
                  b,
                  tt,
                  !0,
                  !(st && (st === d || st === d.$$originalDirective))
                ),
                gt.$$addScopeClass(b, !0),
                (tt.$$isolateBindings = d.$$isolateBindings),
                (nt = yi(o, l, tt, tt.$$isolateBindings, d)),
                nt.removeWatches && tt.$on('$destroy', nt.removeWatches));
              for (k in it)
                (nt = et[k]),
                  (p = it[k]),
                  (ht = nt.$$bindings.bindToController),
                  (p.bindingInfo =
                    p.identifier && ht ? yi(ft, l, p.instance, ht, nt) : {}),
                  (ct = p()),
                  ct !== p.instance &&
                    ((p.instance = ct),
                    b.data('$' + nt.name + 'Controller', ct),
                    p.bindingInfo.removeWatches &&
                      p.bindingInfo.removeWatches(),
                    (p.bindingInfo = yi(ft, l, p.instance, ht, nt)));
              for (
                t(et, function (n, t) {
                  var i = n.require;
                  n.bindToController &&
                    !c(i) &&
                    s(i) &&
                    a(it[t].instance, fi(t, i, b, it));
                }),
                  t(it, function (n) {
                    var t = n.instance;
                    f(t.$onChanges) &&
                      t.$onChanges(n.bindingInfo.initialChanges);
                    f(t.$onInit) && t.$onInit();
                    f(t.$onDestroy) &&
                      ft.$on('$destroy', function () {
                        t.$onDestroy();
                      });
                  }),
                  k = 0,
                  nt = v.length;
                k < nt;
                k++
              )
                (p = v[k]),
                  rr(
                    p,
                    p.isolateScope ? tt : o,
                    b,
                    l,
                    p.require && fi(p.directiveName, p.require, b, it),
                    rt
                  );
              for (
                ot = o,
                  d && (d.template || null === d.templateUrl) && (ot = tt),
                  n && n(ot, h.childNodes, void 0, y),
                  k = w.length - 1;
                0 <= k;
                k--
              )
                (p = w[k]),
                  rr(
                    p,
                    p.isolateScope ? tt : o,
                    b,
                    l,
                    p.require && fi(p.directiveName, p.require, b, it),
                    rt
                  );
              t(it, function (n) {
                n = n.instance;
                f(n.$postLink) && n.$postLink();
              });
            }
            var ni, ei, ft, ir, oi, ci, er, bi;
            b = b || {};
            for (
              var dt = -Number.MAX_VALUE,
                ut = b.newScopeDirective,
                et = b.controllerDirectives,
                d = b.newIsolateScopeDirective,
                st = b.templateDirective,
                ti = b.nonTlbTranscludeDirective,
                di = !1,
                nr = !1,
                ri = b.hasElementTranscludeDirective,
                tt = (u.$$element = e(i)),
                k,
                ot,
                rt,
                pt = o,
                wt,
                ht = !1,
                ui = !1,
                it,
                ct = 0,
                kt = n.length;
              ct < kt;
              ct++
            ) {
              if (
                ((k = n[ct]),
                (ni = k.$$start),
                (ei = k.$$end),
                ni && (tt = pi(i, ni, ei)),
                (rt = void 0),
                dt > k.priority)
              )
                break;
              if (
                ((it = k.scope) &&
                  (k.templateUrl ||
                    (s(it)
                      ? (ii('new/isolated scope', d || ut, k, tt), (d = k))
                      : ii('new/isolated scope', d, k, tt)),
                  (ut = ut || k)),
                (ot = k.name),
                !ht &&
                  ((k.replace && (k.templateUrl || k.template)) ||
                    (k.transclude && !k.$$tlb)))
              ) {
                for (it = ct + 1; (ht = n[it++]); )
                  if (
                    (ht.transclude && !ht.$$tlb) ||
                    (ht.replace && (ht.templateUrl || ht.template))
                  ) {
                    ui = !0;
                    break;
                  }
                ht = !0;
              }
              if (
                (!k.templateUrl &&
                  k.controller &&
                  ((it = k.controller),
                  (et = et || y()),
                  ii("'" + ot + "' controller", et[ot], k, tt),
                  (et[ot] = k)),
                (it = k.transclude))
              )
                if (
                  ((di = !0),
                  k.$$tlb || (ii('transclusion', ti, k, tt), (ti = k)),
                  'element' == it)
                )
                  (ri = !0),
                    (dt = k.priority),
                    (rt = tt),
                    (tt = u.$$element = e(gt.$$createComment(ot, u[ot]))),
                    (i = tt[0]),
                    si(h, bt.call(rt, 0), i),
                    (rt[0].$$parentNode = rt[0].parentNode),
                    (pt = ai(ui, rt, o, dt, l && l.name, {
                      nonTlbTranscludeDirective: ti,
                    }));
                else {
                  if (((ft = y()), (rt = e(af(i)).contents()), s(it))) {
                    rt = [];
                    ir = y();
                    oi = y();
                    t(it, function (n, t) {
                      var i = '?' === n.charAt(0);
                      n = i ? n.substring(1) : n;
                      ir[n] = t;
                      ft[t] = null;
                      oi[t] = i;
                    });
                    t(tt.contents(), function (n) {
                      var t = ir[yt(at(n))];
                      t
                        ? ((oi[t] = !0), (ft[t] = ft[t] || []), ft[t].push(n))
                        : rt.push(n);
                    });
                    t(oi, function (n, t) {
                      if (!n) throw g('reqslot', t);
                    });
                    for (ci in ft) ft[ci] && (ft[ci] = ai(ui, ft[ci], o));
                  }
                  tt.empty();
                  pt = ai(ui, rt, o, void 0, void 0, {
                    needsNewScope: k.$$isolateScope || k.$$newScope,
                  });
                  pt.$$slots = ft;
                }
              if (k.template)
                if (
                  ((nr = !0),
                  ii('template', st, k, tt),
                  (st = k),
                  (it = f(k.template) ? k.template(tt, u) : k.template),
                  (it = lr(it)),
                  k.replace)
                ) {
                  if (
                    ((l = k),
                    (rt = fe.test(it)
                      ? vo(vi(k.templateNamespace, p(it)))
                      : []),
                    (i = rt[0]),
                    1 != rt.length || 1 !== i.nodeType)
                  )
                    throw g('tplrt', ot, '');
                  si(h, tt, i);
                  kt = { $attr: {} };
                  it = li(i, [], kt);
                  er = n.splice(ct + 1, n.length - (ct + 1));
                  (d || ut) && ki(it, d, ut);
                  n = n.concat(it).concat(er);
                  gi(u, kt);
                  kt = n.length;
                } else tt.html(it);
              if (k.templateUrl)
                (nr = !0),
                  ii('template', st, k, tt),
                  (st = k),
                  k.replace && (l = k),
                  (lt = wr(
                    n.splice(ct, n.length - ct),
                    tt,
                    u,
                    h,
                    di && pt,
                    v,
                    w,
                    {
                      controllerDirectives: et,
                      newScopeDirective: ut !== k && ut,
                      newIsolateScopeDirective: d,
                      templateDirective: st,
                      nonTlbTranscludeDirective: ti,
                    }
                  )),
                  (kt = n.length);
              else if (k.compile)
                try {
                  wt = k.compile(tt, u, pt);
                  bi = k.$$originalDirective || k;
                  f(wt)
                    ? ur(null, sr(bi, wt), ni, ei)
                    : wt && ur(sr(bi, wt.pre), sr(bi, wt.post), ni, ei);
                } catch (or) {
                  nt(or, vt(tt));
                }
              k.terminal &&
                ((lt.terminal = !0), (dt = Math.max(dt, k.priority)));
            }
            return (
              (lt.scope = ut && !0 === ut.scope),
              (lt.transcludeOnThisElement = di),
              (lt.templateOnThisElement = nr),
              (lt.transclude = pt),
              (b.hasElementTranscludeDirective = ri),
              lt
            );
          }
          function fi(n, i, r, u) {
            var f, o, e, l;
            if (h(i)) {
              if (
                ((e = i.match(tt)),
                (i = i.substring(e[0].length)),
                (o = e[1] || e[3]),
                (e = '?' === e[2]),
                '^^' === o
                  ? (r = r.parent())
                  : (f = (f = u && u[i]) && f.instance),
                f ||
                  ((l = '$' + i + 'Controller'),
                  (f = o ? r.inheritedData(l) : r.data(l))),
                !f && !e)
              )
                throw g('ctreq', i, n);
            } else if (c(i))
              for (f = [], o = 0, e = i.length; o < e; o++)
                f[o] = fi(n, i[o], r, u);
            else
              s(i) &&
                ((f = {}),
                t(i, function (t, i) {
                  f[i] = fi(n, t, r, u);
                }));
            return f || null;
          }
          function yr(n, t, i, r, u, f, e) {
            var c = y(),
              l;
            for (l in r) {
              var o = r[l],
                s = {
                  $scope: o === e || o.$$isolateScope ? u : f,
                  $element: n,
                  $attrs: t,
                  $transclude: i,
                },
                h = o.controller;
              '@' == h && (h = t[o.name]);
              s = ft(h, s, !0, o.controllerAs);
              c[o.name] = s;
              n.data('$' + o.name + 'Controller', s.instance);
            }
            return c;
          }
          function ki(n, t, i) {
            for (var r = 0, u = n.length; r < u; r++)
              n[r] = sf(n[r], { $$isolateScope: t, $$newScope: i });
          }
          function oi(n, t, u, f, e, o, h) {
            var c, y, k, p, tt, w;
            if (t === e) return null;
            if (((e = null), b.hasOwnProperty(t)))
              for (t = i.get(t + 'Directive'), y = 0, k = t.length; y < k; y++)
                try {
                  if (
                    ((c = t[y]),
                    (r(f) || f > c.priority) && -1 != c.restrict.indexOf(u))
                  ) {
                    if (
                      (o && (c = sf(c, { $$start: o, $$end: h })),
                      !c.$$bindings)
                    ) {
                      var it = c,
                        l = c,
                        v = c.name,
                        a = { isolateScope: null, bindToController: null };
                      if (
                        (s(l.scope) &&
                          (!0 === l.bindToController
                            ? ((a.bindToController = d(l.scope, v, !0)),
                              (a.isolateScope = {}))
                            : (a.isolateScope = d(l.scope, v, !1))),
                        s(l.bindToController) &&
                          (a.bindToController = d(l.bindToController, v, !0)),
                        s(a.bindToController))
                      ) {
                        if (((p = l.controller), (tt = l.controllerAs), !p))
                          throw g('noctrl', v);
                        if (!yo(p, tt)) throw g('noident', v);
                      }
                      w = it.$$bindings = a;
                      s(w.isolateScope) &&
                        (c.$$isolateBindings = w.isolateScope);
                    }
                    n.push(c);
                    e = c;
                  }
                } catch (rt) {
                  nt(rt);
                }
            return e;
          }
          function pr(n) {
            if (b.hasOwnProperty(n))
              for (
                var r = i.get(n + 'Directive'), t = 0, u = r.length;
                t < u;
                t++
              )
                if (((n = r[t]), n.multiElement)) return !0;
            return !1;
          }
          function gi(n, i) {
            var u = i.$attr,
              f = n.$attr,
              r = n.$$element;
            t(n, function (t, r) {
              '$' != r.charAt(0) &&
                (i[r] &&
                  i[r] !== t &&
                  (t += ('style' === r ? ';' : ' ') + i[r]),
                n.$set(r, t, !0, u[r]));
            });
            t(i, function (t, i) {
              'class' == i
                ? (ei(r, t),
                  (n['class'] = (n['class'] ? n['class'] + ' ' : '') + t))
                : 'style' == i
                ? (r.attr('style', r.attr('style') + ';' + t),
                  (n.style = (n.style ? n.style + ';' : '') + t))
                : '$' == i.charAt(0) ||
                  n.hasOwnProperty(i) ||
                  ((n[i] = t), (f[i] = u[i]));
            });
          }
          function wr(n, i, r, u, o, h, c, l) {
            var v = [],
              y,
              w,
              b = i[0],
              a = n.shift(),
              d = sf(a, {
                templateUrl: null,
                transclude: null,
                replace: null,
                $$originalDirective: a,
              }),
              k = f(a.templateUrl) ? a.templateUrl(i, r) : a.templateUrl,
              nt = a.templateNamespace;
            return (
              i.empty(),
              it(k).then(function (f) {
                var tt, rt, it, ft;
                if (((f = lr(f)), a.replace)) {
                  if (
                    ((f = fe.test(f) ? vo(vi(nt, p(f))) : []),
                    (tt = f[0]),
                    1 != f.length || 1 !== tt.nodeType)
                  )
                    throw g('tplrt', a.name, k);
                  f = { $attr: {} };
                  si(u, i, tt);
                  it = li(tt, [], f);
                  s(a.scope) && ki(it, !0);
                  n = it.concat(n);
                  gi(r, f);
                } else (tt = b), i.html(f);
                for (
                  n.unshift(d),
                    y = bi(n, tt, r, o, i, a, h, c, l),
                    t(u, function (n, t) {
                      n == tt && (u[t] = i[0]);
                    }),
                    w = ci(i[0].childNodes, o);
                  v.length;

                ) {
                  f = v.shift();
                  rt = v.shift();
                  var et = v.shift(),
                    ut = v.shift(),
                    it = i[0];
                  f.$$destroyed ||
                    (rt !== b &&
                      ((ft = rt.className),
                      (l.hasElementTranscludeDirective && a.replace) ||
                        (it = af(tt)),
                      si(et, e(rt), it),
                      ei(e(it), ft)),
                    (rt = y.transcludeOnThisElement
                      ? ri(f, y.transclude, ut)
                      : ut),
                    y(w, f, it, u, rt));
                }
                v = null;
              }),
              function (n, t, i, r, u) {
                n = u;
                t.$$destroyed ||
                  (v
                    ? v.push(t, i, r, n)
                    : (y.transcludeOnThisElement &&
                        (n = ri(t, y.transclude, u)),
                      y(w, t, i, r, n)));
              }
            );
          }
          function br(n, t) {
            var i = t.priority - n.priority;
            return 0 !== i
              ? i
              : n.name !== t.name
              ? n.name < t.name
                ? -1
                : 1
              : n.index - t.index;
          }
          function ii(n, t, i, r) {
            function u(n) {
              return n ? ' (module: ' + n + ')' : '';
            }
            if (t)
              throw g(
                'multidir',
                t.name,
                u(t.$$moduleName),
                i.name,
                u(i.$$moduleName),
                n,
                vt(r)
              );
          }
          function kr(n, t) {
            var i = l(t, !0);
            i &&
              n.push({
                priority: 0,
                compile: function (n) {
                  n = n.parent();
                  var t = !!n.length;
                  return (
                    t && gt.$$addBindingClass(n),
                    function (n, r) {
                      var u = r.parent();
                      t || gt.$$addBindingClass(u);
                      gt.$$addBindingInfo(u, i.expressions);
                      n.$watch(i, function (n) {
                        r[0].nodeValue = n;
                      });
                    }
                  );
                },
              });
          }
          function vi(t, i) {
            t = v(t || 'html');
            switch (t) {
              case 'svg':
              case 'math':
                var r = n.document.createElement('div');
                return (
                  (r.innerHTML = '<' + t + '>' + i + '</' + t + '>'),
                  r.childNodes[0].childNodes
                );
              default:
                return i;
            }
          }
          function dr(n, t) {
            if ('srcdoc' == t) return pt.HTML;
            var i = at(n);
            if (
              'xlinkHref' == t ||
              ('form' == i && 'action' == t) ||
              ('img' != i && ('src' == t || 'ngSrc' == t))
            )
              return pt.RESOURCE_URL;
          }
          function gr(n, t, i, r, u) {
            var e = dr(n, r),
              f;
            if (((u = ht[r] || u), (f = l(i, !0, e, u)), f)) {
              if ('multiple' === r && 'select' === at(n))
                throw g('selmulti', vt(n));
              t.push({
                priority: 100,
                compile: function () {
                  return {
                    pre: function (n, t, o) {
                      if (
                        ((t = o.$$observers || (o.$$observers = y())),
                        ct.test(r))
                      )
                        throw g('nodomevents');
                      var s = o[r];
                      s !== i && ((f = s && l(s, !0, e, u)), (i = s));
                      f &&
                        ((o[r] = f(n)),
                        ((t[r] || (t[r] = [])).$$inter = !0),
                        (
                          (o.$$observers && o.$$observers[r].$$scope) ||
                          n
                        ).$watch(f, function (n, t) {
                          'class' === r && n != t
                            ? o.$updateClass(n, t)
                            : o.$set(r, n);
                        }));
                    },
                  };
                },
              });
            }
          }
          function si(t, i, r) {
            var f = i[0],
              s = i.length,
              c = f.parentNode,
              u,
              o,
              h;
            if (t)
              for (u = 0, o = t.length; u < o; u++)
                if (t[u] == f) {
                  for (t[u++] = r, o = u + s - 1, h = t.length; u < h; u++, o++)
                    o < h ? (t[u] = t[o]) : delete t[u];
                  t.length -= s - 1;
                  t.context === f && (t.context = r);
                  break;
                }
            for (
              c && c.replaceChild(r, f),
                t = n.document.createDocumentFragment(),
                u = 0;
              u < s;
              u++
            )
              t.appendChild(i[u]);
            for (
              e.hasData(f) && (e.data(r, e.data(f)), e(f).off('$destroy')),
                e.cleanData(t.querySelectorAll('*')),
                u = 1;
              u < s;
              u++
            )
              delete i[u];
            i[0] = r;
            i.length = 1;
          }
          function tr(n, t) {
            return a(
              function () {
                return n.apply(null, arguments);
              },
              n,
              t
            );
          }
          function rr(n, t, i, r, u, f) {
            try {
              n(t, i, r, u, f);
            } catch (e) {
              nt(e, vt(i));
            }
          }
          function yi(n, i, r, u, e) {
            function v(t, i, u) {
              f(r.$onChanges) &&
                i !== u &&
                (ti || (n.$$postDigest(ar), (ti = [])),
                s || ((s = {}), ti.push(y)),
                s[t] && (u = s[t].previousValue),
                (s[t] = new lu(u, i)));
            }
            function y() {
              r.$onChanges(s);
              s = void 0;
            }
            var c = [],
              a = {},
              s;
            return (
              t(u, function (t, u) {
                var f = t.attrName,
                  s = t.optional,
                  p,
                  y,
                  k,
                  w,
                  b;
                switch (t.mode) {
                  case '@':
                    s || wt.call(i, f) || (r[u] = i[f] = void 0);
                    i.$observe(f, function (n) {
                      (h(n) || ui(n)) && (v(u, n, r[u]), (r[u] = n));
                    });
                    i.$$observers[f].$$scope = n;
                    p = i[f];
                    h(p) ? (r[u] = l(p)(n)) : ui(p) && (r[u] = p);
                    a[u] = new lu(se, r[u]);
                    break;
                  case '=':
                    if (!wt.call(i, f)) {
                      if (s) break;
                      i[f] = void 0;
                    }
                    if (s && !i[f]) break;
                    y = ut(i[f]);
                    w = y.literal
                      ? st
                      : function (n, t) {
                          return n === t || (n !== n && t !== t);
                        };
                    k =
                      y.assign ||
                      function () {
                        p = r[u] = y(n);
                        throw g('nonassign', i[f], f, e.name);
                      };
                    p = r[u] = y(n);
                    s = function (t) {
                      return (
                        w(t, r[u]) || (w(t, p) ? k(n, (t = r[u])) : (r[u] = t)),
                        (p = t)
                      );
                    };
                    s.$stateful = !0;
                    s = t.collection
                      ? n.$watchCollection(i[f], s)
                      : n.$watch(ut(i[f], s), null, y.literal);
                    c.push(s);
                    break;
                  case '<':
                    if (!wt.call(i, f)) {
                      if (s) break;
                      i[f] = void 0;
                    }
                    if (s && !i[f]) break;
                    y = ut(i[f]);
                    b = r[u] = y(n);
                    a[u] = new lu(se, r[u]);
                    s = n.$watch(
                      y,
                      function (n, t) {
                        if (t === n) {
                          if (t === b) return;
                          t = b;
                        }
                        v(u, n, t);
                        r[u] = n;
                      },
                      y.literal
                    );
                    c.push(s);
                    break;
                  case '&':
                    if (
                      ((y = i.hasOwnProperty(f) ? ut(i[f]) : o), y === o && s)
                    )
                      break;
                    r[u] = function (t) {
                      return y(n, t);
                    };
                }
              }),
              {
                initialChanges: a,
                removeWatches:
                  c.length &&
                  function () {
                    for (var n = 0, t = c.length; n < t; ++n) c[n]();
                  },
              }
            );
          }
          var nu = /^\w/,
            ur = n.document.createElement('div'),
            or = k,
            ti;
          hi.prototype = {
            $normalize: yt,
            $addClass: function (n) {
              n && 0 < n.length && kt.addClass(this.$$element, n);
            },
            $removeClass: function (n) {
              n && 0 < n.length && kt.removeClass(this.$$element, n);
            },
            $updateClass: function (n, t) {
              var i = ao(n, t);
              i && i.length && kt.addClass(this.$$element, i);
              (i = ao(t, n)) && i.length && kt.removeClass(this.$$element, i);
            },
            $set: function (n, i, f, e) {
              var s = ho(this.$$element[0], n),
                o = oe[n],
                l = n;
              if (
                (s
                  ? (this.$$element.prop(n, i), (e = s))
                  : o && ((this[o] = i), (l = o)),
                (this[n] = i),
                e
                  ? (this.$attr[n] = e)
                  : (e = this.$attr[n]) || (this.$attr[n] = e = no(n, '-')),
                (s = at(this.$$element)),
                ('a' === s && ('href' === n || 'xlinkHref' === n)) ||
                  ('img' === s && 'src' === n))
              )
                this[n] = i = dt(i, 'src' === n);
              else if ('img' === s && 'srcset' === n && u(i)) {
                for (
                  var s = '',
                    o = p(i),
                    h = /(\s+\d+x\s*,|\s+\d+w\s*,|\s+,|,\s+)/,
                    h = /\s/.test(o) ? h : /(,)/,
                    o = o.split(h),
                    h = Math.floor(o.length / 2),
                    c = 0;
                  c < h;
                  c++
                )
                  var a = 2 * c,
                    s = s + dt(p(o[a]), !0),
                    s = s + (' ' + p(o[a + 1]));
                o = p(o[2 * c]).split(/\s/);
                s += dt(p(o[0]), !0);
                2 === o.length && (s += ' ' + p(o[1]));
                this[n] = i = s;
              }
              !1 !== f &&
                (null === i || r(i)
                  ? this.$$element.removeAttr(e)
                  : nu.test(e)
                  ? this.$$element.attr(e, i)
                  : vr(this.$$element[0], e, i));
              (n = this.$$observers) &&
                t(n[l], function (n) {
                  try {
                    n(i);
                  } catch (t) {
                    nt(t);
                  }
                });
            },
            $observe: function (n, t) {
              var i = this,
                f = i.$$observers || (i.$$observers = y()),
                u = f[n] || (f[n] = []);
              return (
                u.push(t),
                lt.$evalAsync(function () {
                  u.$$inter || !i.hasOwnProperty(n) || r(i[n]) || t(i[n]);
                }),
                function () {
                  er(u, t);
                }
              );
            },
          };
          var hr = l.startSymbol(),
            cr = l.endSymbol(),
            lr =
              '{{' == hr && '}}' == cr
                ? ir
                : function (n) {
                    return n.replace(/\{\{/g, hr).replace(/}}/g, cr);
                  },
            tu = /^ngAttr[A-Z]/,
            ru = /^(.+)Start$/;
          return (
            (gt.$$addBindingInfo = w
              ? function (n, t) {
                  var i = n.data('$binding') || [];
                  c(t) ? (i = i.concat(t)) : i.push(t);
                  n.data('$binding', i);
                }
              : o),
            (gt.$$addBindingClass = w
              ? function (n) {
                  ei(n, 'ng-binding');
                }
              : o),
            (gt.$$addScopeInfo = w
              ? function (n, t, i, r) {
                  n.data(
                    i
                      ? r
                        ? '$isolateScopeNoTemplate'
                        : '$isolateScope'
                      : '$scope',
                    t
                  );
                }
              : o),
            (gt.$$addScopeClass = w
              ? function (n, t) {
                  ei(n, t ? 'ng-isolate-scope' : 'ng-scope');
                }
              : o),
            (gt.$$createComment = function (t, i) {
              var r = '';
              return (
                w && ((r = ' ' + (t || '') + ': '), i && (r += i + ' ')),
                n.document.createComment(r)
              );
            }),
            gt
          );
        },
      ];
    }
    function lu(n, t) {
      this.previousValue = n;
      this.currentValue = t;
    }
    function yt(n) {
      return cr(n.replace(lh, ''));
    }
    function ao(n, t) {
      var r = '',
        e = n.split(/\s+/),
        o = t.split(/\s+/),
        u = 0,
        f,
        i;
      n: for (; u < e.length; u++) {
        for (f = e[u], i = 0; i < o.length; i++) if (f == o[i]) continue n;
        r += (0 < r.length ? ' ' : '') + f;
      }
      return r;
    }
    function vo(n) {
      n = e(n);
      var t = n.length;
      if (1 >= t) return n;
      for (; t--; ) 8 === n[t].nodeType && yv.call(n, t, 1);
      return n;
    }
    function yo(n, t) {
      if (t && h(t)) return t;
      if (h(n)) {
        var i = ah.exec(n);
        if (i) return i[3];
      }
    }
    function dl() {
      var n = {},
        t = !1;
      this.has = function (t) {
        return n.hasOwnProperty(t);
      };
      this.register = function (t, i) {
        pi(t, 'controller');
        s(t) ? a(n, t) : (n[t] = i);
      };
      this.allowGlobals = function () {
        t = !0;
      };
      this.$get = [
        '$injector',
        '$window',
        function (i, r) {
          function u(n, t, i, r) {
            if (!n || !s(n.$scope)) throw l('$controller')('noscp', r, t);
            n.$scope[t] = i;
          }
          return function (e, o, l, v) {
            var y, p, w;
            if (((l = !0 === l), v && h(v) && (w = v), h(e))) {
              if (((v = e.match(ah)), !v)) throw by('ctrlfmt', e);
              p = v[1];
              w = w || v[3];
              e = n.hasOwnProperty(p)
                ? n[p]
                : to(o.$scope, p, !0) || (t ? to(r, p, !0) : void 0);
              yi(e, p, !0);
            }
            return l
              ? ((l = (c(e) ? e[e.length - 1] : e).prototype),
                (y = Object.create(l || null)),
                w && u(o, w, y, p || e.name),
                a(
                  function () {
                    var n = i.invoke(e, y, o, p);
                    return (
                      n !== y &&
                        (s(n) || f(n)) &&
                        ((y = n), w && u(o, w, y, p || e.name)),
                      y
                    );
                  },
                  { instance: y, identifier: w }
                ))
              : ((y = i.instantiate(e, o, p)), w && u(o, w, y, p || e.name), y);
          };
        },
      ];
    }
    function gl() {
      this.$get = [
        '$window',
        function (n) {
          return e(n.document);
        },
      ];
    }
    function na() {
      this.$get = [
        '$log',
        function (n) {
          return function () {
            n.error.apply(n, arguments);
          };
        },
      ];
    }
    function yf(n) {
      return s(n) ? (et(n) ? n.toISOString() : hr(n)) : n;
    }
    function ta() {
      this.$get = function () {
        return function (n) {
          if (!n) return '';
          var i = [];
          return (
            ae(n, function (n, u) {
              null === n ||
                r(n) ||
                (c(n)
                  ? t(n, function (n) {
                      i.push(ht(u) + '=' + ht(yf(n)));
                    })
                  : i.push(ht(u) + '=' + ht(yf(n))));
            }),
            i.join('&')
          );
        };
      };
    }
    function ia() {
      this.$get = function () {
        return function (n) {
          function i(n, f, e) {
            null === n ||
              r(n) ||
              (c(n)
                ? t(n, function (n, t) {
                    i(n, f + '[' + (s(n) ? t : '') + ']');
                  })
                : s(n) && !et(n)
                ? ae(n, function (n, t) {
                    i(n, f + (e ? '' : '[') + t + (e ? '' : ']'));
                  })
                : u.push(ht(f) + '=' + ht(yf(n))));
          }
          if (!n) return '';
          var u = [];
          return i(n, '', !0), u.join('&');
        };
      };
    }
    function pf(n, t) {
      var r, i;
      return (
        h(n) &&
          ((r = n.replace(np, '').trim()),
          r &&
            ((i = t('Content-Type')),
            (i = i && 0 === i.indexOf(vh)) ||
              (i = (i = r.match(dy)) && gy[i[0]].test(r)),
            i && (n = we(r)))),
        n
      );
    }
    function po(n) {
      var i = y(),
        r;
      return (
        h(n)
          ? t(n.split('\n'), function (n) {
              r = n.indexOf(':');
              var t = v(p(n.substr(0, r)));
              n = p(n.substr(r + 1));
              t && (i[t] = i[t] ? i[t] + ', ' + n : n);
            })
          : s(n) &&
            t(n, function (n, t) {
              var r = v(t),
                u = p(n);
              r && (i[r] = i[r] ? i[r] + ', ' + u : u);
            }),
        i
      );
    }
    function wo(n) {
      var t;
      return function (i) {
        return (
          t || (t = po(n)),
          i ? ((i = t[v(i)]), void 0 === i && (i = null), i) : t
        );
      };
    }
    function bo(n, i, r, u) {
      return f(u)
        ? u(n, i, r)
        : (t(u, function (t) {
            n = t(n, i, r);
          }),
          n);
    }
    function ra() {
      var n = (this.defaults = {
          transformResponse: [pf],
          transformRequest: [
            function (n) {
              return s(n) &&
                '[object File]' !== rt.call(n) &&
                '[object Blob]' !== rt.call(n) &&
                '[object FormData]' !== rt.call(n)
                ? hr(n)
                : n;
            },
          ],
          headers: {
            common: { Accept: 'application/json, text/plain, */*' },
            post: ot(he),
            put: ot(he),
            patch: ot(he),
          },
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          paramSerializer: '$httpParamSerializer',
        }),
        i = !1,
        e,
        o;
      this.useApplyAsync = function (n) {
        return u(n) ? ((i = !!n), this) : i;
      };
      e = !0;
      this.useLegacyPromiseExtensions = function (n) {
        return u(n) ? ((e = !!n), this) : e;
      };
      o = this.interceptors = [];
      this.$get = [
        '$httpBackend',
        '$$cookieReader',
        '$cacheFactory',
        '$rootScope',
        '$q',
        '$injector',
        function (y, p, w, b, k, d) {
          function g(i) {
            function y(n) {
              var t = a({}, n);
              return (
                (t.data = bo(n.data, n.headers, n.status, o.transformResponse)),
                (n = n.status),
                200 <= n && 300 > n ? t : k.reject(t)
              );
            }
            function w(n, i) {
              var r,
                u = {};
              return (
                t(n, function (n, t) {
                  f(n) ? ((r = n(i)), null != r && (u[t] = r)) : (u[t] = n);
                }),
                u
              );
            }
            var o, c, p, u;
            if (!s(i)) throw l('$http')('badreq', i);
            if (!h(i.url)) throw l('$http')('badreq', i.url);
            for (
              o = a(
                {
                  method: 'get',
                  transformRequest: n.transformRequest,
                  transformResponse: n.transformResponse,
                  paramSerializer: n.paramSerializer,
                },
                i
              ),
                o.headers = (function (t) {
                  var i = n.headers,
                    u = a({}, t.headers),
                    r,
                    f,
                    e,
                    i = a({}, i.common, i[v(t.method)]);
                  n: for (r in i) {
                    f = v(r);
                    for (e in u) if (v(e) === f) continue n;
                    u[r] = i[r];
                  }
                  return w(u, ot(t));
                })(i),
                o.method = bu(o.method),
                o.paramSerializer = h(o.paramSerializer)
                  ? d.get(o.paramSerializer)
                  : o.paramSerializer,
                c = [
                  function (i) {
                    var u = i.headers,
                      f = bo(i.data, wo(u), void 0, i.transformRequest);
                    return (
                      r(f) &&
                        t(u, function (n, t) {
                          'content-type' === v(t) && delete u[t];
                        }),
                      r(i.withCredentials) &&
                        !r(n.withCredentials) &&
                        (i.withCredentials = n.withCredentials),
                      tt(i, f).then(y, y)
                    );
                  },
                  void 0,
                ],
                u = k.when(o),
                t(nt, function (n) {
                  (n.request || n.requestError) &&
                    c.unshift(n.request, n.requestError);
                  (n.response || n.responseError) &&
                    c.push(n.response, n.responseError);
                });
              c.length;

            )
              (i = c.shift()), (p = c.shift()), (u = u.then(i, p));
            return (
              e
                ? ((u.success = function (n) {
                    return (
                      yi(n, 'fn'),
                      u.then(function (t) {
                        n(t.data, t.status, t.headers, o);
                      }),
                      u
                    );
                  }),
                  (u.error = function (n) {
                    return (
                      yi(n, 'fn'),
                      u.then(null, function (t) {
                        n(t.data, t.status, t.headers, o);
                      }),
                      u
                    );
                  }))
                : ((u.success = yh('success')), (u.error = yh('error'))),
              u
            );
          }
          function tt(e, o) {
            function nt(n) {
              if (n) {
                var r = {};
                return (
                  t(n, function (n, t) {
                    r[t] = function (t) {
                      function r() {
                        n(t);
                      }
                      i ? b.$applyAsync(r) : b.$$phase ? r() : b.$apply(r);
                    };
                  }),
                  r
                );
              }
            }
            function et(n, t, r, u) {
              function f() {
                v(t, n, r, u);
              }
              l &&
                (200 <= n && 300 > n
                  ? l.put(a, [n, t, po(r), u])
                  : l.remove(a));
              i ? b.$applyAsync(f) : (f(), b.$$phase || b.$apply());
            }
            function v(n, t, i, r) {
              t = -1 <= t ? t : 0;
              (200 <= t && 300 > t ? w.resolve : w.reject)({
                data: n,
                status: t,
                headers: wo(i),
                config: e,
                statusText: r,
              });
            }
            function tt(n) {
              v(n.data, n.status, ot(n.headers()), n.statusText);
            }
            function ut() {
              var n = g.pendingRequests.indexOf(e);
              -1 !== n && g.pendingRequests.splice(n, 1);
            }
            var w = k.defer(),
              d = w.promise,
              l,
              h,
              ft = e.headers,
              a = it(e.url, e.paramSerializer(e.params));
            return (
              g.pendingRequests.push(e),
              d.then(ut, ut),
              (e.cache || n.cache) &&
                !1 !== e.cache &&
                ('GET' === e.method || 'JSONP' === e.method) &&
                (l = s(e.cache) ? e.cache : s(n.cache) ? n.cache : rt),
              l &&
                ((h = l.get(a)),
                u(h)
                  ? h && f(h.then)
                    ? h.then(tt, tt)
                    : c(h)
                    ? v(h[1], h[0], ot(h[2]), h[3])
                    : v(h, 200, {}, 'OK')
                  : l.put(a, d)),
              r(h) &&
                ((h = as(e.url)
                  ? p()[e.xsrfCookieName || n.xsrfCookieName]
                  : void 0) && (ft[e.xsrfHeaderName || n.xsrfHeaderName] = h),
                y(
                  e.method,
                  a,
                  o,
                  et,
                  ft,
                  e.timeout,
                  e.withCredentials,
                  e.responseType,
                  nt(e.eventHandlers),
                  nt(e.uploadEventHandlers)
                )),
              d
            );
          }
          function it(n, t) {
            return (
              0 < t.length && (n += (-1 == n.indexOf('?') ? '?' : '&') + t), n
            );
          }
          var rt = w('$http'),
            nt;
          return (
            (n.paramSerializer = h(n.paramSerializer)
              ? d.get(n.paramSerializer)
              : n.paramSerializer),
            (nt = []),
            t(o, function (n) {
              nt.unshift(h(n) ? d.get(n) : d.invoke(n));
            }),
            (g.pendingRequests = []),
            (function () {
              t(arguments, function (n) {
                g[n] = function (t, i) {
                  return g(a({}, i || {}, { method: n, url: t }));
                };
              });
            })('get', 'delete', 'head', 'jsonp'),
            (function () {
              t(arguments, function (n) {
                g[n] = function (t, i, r) {
                  return g(a({}, r || {}, { method: n, url: t, data: i }));
                };
              });
            })('post', 'put', 'patch'),
            (g.defaults = n),
            g
          );
        },
      ];
    }
    function ua() {
      this.$get = function () {
        return function () {
          return new n.XMLHttpRequest();
        };
      };
    }
    function fa() {
      this.$get = [
        '$browser',
        '$window',
        '$document',
        '$xhrFactory',
        function (n, t, i, r) {
          return ea(n, r, n.defer, t.angular.callbacks, i[0]);
        },
      ];
    }
    function ea(n, i, e, s, h) {
      function c(n, t, i) {
        var r = h.createElement('script'),
          u = null;
        return (
          (r.type = 'text/javascript'),
          (r.src = n),
          (r.async = !0),
          (u = function (n) {
            r.removeEventListener('load', u, !1);
            r.removeEventListener('error', u, !1);
            h.body.removeChild(r);
            r = null;
            var f = -1,
              e = 'unknown';
            n &&
              ('load' !== n.type || s[t].called || (n = { type: 'error' }),
              (e = n.type),
              (f = 'error' === n.type ? 404 : 200));
            i && i(f, e);
          }),
          r.addEventListener('load', u, !1),
          r.addEventListener('error', u, !1),
          h.body.appendChild(r),
          u
        );
      }
      return function (h, l, a, y, p, w, b, k, d, g) {
        function ft() {
          it && it();
          nt && nt.abort();
        }
        function rt(t, i, r, f, s) {
          u(ut) && e.cancel(ut);
          it = nt = null;
          t(i, r, f, s);
          n.$$completeOutstandingRequest(o);
        }
        var tt, it, nt, ut;
        if (
          (n.$$incOutstandingRequestCount(),
          (l = l || n.url()),
          'jsonp' == v(h))
        )
          (tt = '_' + (s.counter++).toString(36)),
            (s[tt] = function (n) {
              s[tt].data = n;
              s[tt].called = !0;
            }),
            (it = c(
              l.replace('JSON_CALLBACK', 'angular.callbacks.' + tt),
              tt,
              function (n, t) {
                rt(y, n, s[tt].data, '', t);
                s[tt] = o;
              }
            ));
        else {
          if (
            ((nt = i(h, l)),
            nt.open(h, l, !0),
            t(p, function (n, t) {
              u(n) && nt.setRequestHeader(t, n);
            }),
            (nt.onload = function () {
              var i = nt.statusText || '',
                t = 'response' in nt ? nt.response : nt.responseText,
                n = 1223 === nt.status ? 204 : nt.status;
              0 === n && (n = t ? 200 : 'file' == gt(l).protocol ? 404 : 0);
              rt(y, n, t, nt.getAllResponseHeaders(), i);
            }),
            (h = function () {
              rt(y, -1, null, null, '');
            }),
            (nt.onerror = h),
            (nt.onabort = h),
            t(d, function (n, t) {
              nt.addEventListener(t, n);
            }),
            t(g, function (n, t) {
              nt.upload.addEventListener(t, n);
            }),
            b && (nt.withCredentials = !0),
            k)
          )
            try {
              nt.responseType = k;
            } catch (et) {
              if ('json' !== k) throw et;
            }
          nt.send(r(a) ? null : a);
        }
        0 < w ? (ut = e(ft, w)) : w && f(w.then) && w.then(ft);
      };
    }
    function oa() {
      var n = '{{',
        t = '}}';
      this.startSymbol = function (t) {
        return t ? ((n = t), this) : n;
      };
      this.endSymbol = function (n) {
        return n ? ((t = n), this) : t;
      };
      this.$get = [
        '$parse',
        '$exceptionHandler',
        '$sce',
        function (i, e, o) {
          function c(n) {
            return '\\\\\\' + n;
          }
          function s(i) {
            return i.replace(p, n).replace(w, t);
          }
          function v(n, t, i, r) {
            var u;
            return (u = n.$watch(
              function (n) {
                return u(), r(n);
              },
              t,
              i
            ));
          }
          function h(h, c, p, w) {
            function et(n) {
              var i, t;
              try {
                if (
                  ((i = n),
                  (n = p ? o.getTrusted(p, i) : o.valueOf(i)),
                  w && !u(n))
                )
                  t = n;
                else if (null == n) t = '';
                else {
                  switch (typeof n) {
                    case 'string':
                      break;
                    case 'number':
                      n = '' + n;
                      break;
                    default:
                      n = hr(n);
                  }
                  t = n;
                }
                return t;
              } catch (r) {
                e(vi.interr(h, r));
              }
            }
            var k, d, rt, ut;
            if (!h.length || -1 === h.indexOf(n))
              return (
                c ||
                  ((c = s(h)),
                  (k = ft(c)),
                  (k.exp = h),
                  (k.expressions = []),
                  (k.$$watchDelegate = v)),
                k
              );
            w = !!w;
            var g,
              tt,
              b = 0,
              nt = [],
              it = [];
            for (k = h.length, d = [], rt = []; b < k; )
              if (
                -1 != (g = h.indexOf(n, b)) &&
                -1 != (tt = h.indexOf(t, g + l))
              )
                b !== g && d.push(s(h.substring(b, g))),
                  (b = h.substring(g + l, tt)),
                  nt.push(b),
                  it.push(i(b, et)),
                  (b = tt + y),
                  rt.push(d.length),
                  d.push('');
              else {
                b !== k && d.push(s(h.substring(b)));
                break;
              }
            return (
              p && 1 < d.length && vi.throwNoconcat(h),
              !c || nt.length
                ? ((ut = function (n) {
                    for (var t = 0, i = nt.length; t < i; t++) {
                      if (w && r(n[t])) return;
                      d[rt[t]] = n[t];
                    }
                    return d.join('');
                  }),
                  a(
                    function (n) {
                      var t = 0,
                        i = nt.length,
                        r = Array(i);
                      try {
                        for (; t < i; t++) r[t] = it[t](n);
                        return ut(r);
                      } catch (u) {
                        e(vi.interr(h, u));
                      }
                    },
                    {
                      exp: h,
                      expressions: nt,
                      $$watchDelegate: function (n, t) {
                        var i;
                        return n.$watchGroup(it, function (r, u) {
                          var e = ut(r);
                          f(t) && t.call(this, e, r !== u ? i : e, n);
                          i = e;
                        });
                      },
                    }
                  ))
                : void 0
            );
          }
          var l = n.length,
            y = t.length,
            p = new RegExp(n.replace(/./g, c), 'g'),
            w = new RegExp(t.replace(/./g, c), 'g');
          return (
            (h.startSymbol = function () {
              return n;
            }),
            (h.endSymbol = function () {
              return t;
            }),
            h
          );
        },
      ];
    }
    function sa() {
      this.$get = [
        '$rootScope',
        '$window',
        '$q',
        '$$q',
        '$browser',
        function (n, t, i, r, f) {
          function o(o, s, h, c) {
            function p() {
              w ? o.apply(null, b) : o(a);
            }
            var w = 4 < arguments.length,
              b = w ? bt.call(arguments, 4) : [],
              k = t.setInterval,
              d = t.clearInterval,
              a = 0,
              y = u(c) && !c,
              v = (y ? r : i).defer(),
              l = v.promise;
            return (
              (h = u(h) ? h : 0),
              (l.$$intervalId = k(function () {
                y ? f.defer(p) : n.$evalAsync(p);
                v.notify(a++);
                0 < h &&
                  a >= h &&
                  (v.resolve(a), d(l.$$intervalId), delete e[l.$$intervalId]);
                y || n.$apply();
              }, s)),
              (e[l.$$intervalId] = v),
              l
            );
          }
          var e = {};
          return (
            (o.cancel = function (n) {
              return n && n.$$intervalId in e
                ? (e[n.$$intervalId].reject('canceled'),
                  t.clearInterval(n.$$intervalId),
                  delete e[n.$$intervalId],
                  !0)
                : !1;
            }),
            o
          );
        },
      ];
    }
    function wf(n) {
      n = n.split('/');
      for (var t = n.length; t--; ) n[t] = tu(n[t]);
      return n.join('/');
    }
    function ko(n, t) {
      var i = gt(n);
      t.$$protocol = i.protocol;
      t.$$host = i.hostname;
      t.$$port = tt(i.port) || rp[i.protocol] || null;
    }
    function go(n, t) {
      var r = '/' !== n.charAt(0),
        i;
      r && (n = '/' + n);
      i = gt(n);
      t.$$path = decodeURIComponent(
        r && '/' === i.pathname.charAt(0) ? i.pathname.substring(1) : i.pathname
      );
      t.$$search = de(i.search);
      t.$$hash = decodeURIComponent(i.hash);
      t.$$path && '/' != t.$$path.charAt(0) && (t.$$path = '/' + t.$$path);
    }
    function pt(n, t) {
      if (0 === t.lastIndexOf(n, 0)) return t.substr(n.length);
    }
    function oi(n) {
      var t = n.indexOf('#');
      return -1 == t ? n : n.substr(0, t);
    }
    function yr(n) {
      return n.replace(/(#.+)|#$/, '$1');
    }
    function bf(n, t, i) {
      this.$$html5 = !0;
      i = i || '';
      ko(n, this);
      this.$$parse = function (n) {
        var i = pt(t, n);
        if (!h(i)) throw tf('ipthprfx', n, t);
        go(i, this);
        this.$$path || (this.$$path = '/');
        this.$$compose();
      };
      this.$$compose = function () {
        var n = lf(this.$$search),
          i = this.$$hash ? '#' + tu(this.$$hash) : '';
        this.$$url = wf(this.$$path) + (n ? '?' + n : '') + i;
        this.$$absUrl = t + this.$$url.substr(1);
      };
      this.$$parseLinkUrl = function (r, f) {
        if (f && '#' === f[0]) return this.hash(f.slice(1)), !0;
        var e, o;
        return (
          u((e = pt(n, r)))
            ? ((o = e), (o = u((e = pt(i, e))) ? t + (pt('/', e) || e) : n + o))
            : u((e = pt(t, r)))
            ? (o = t + e)
            : t == r + '/' && (o = t),
          o && this.$$parse(o),
          !!o
        );
      };
    }
    function kf(n, t, i) {
      ko(n, this);
      this.$$parse = function (u) {
        var e = pt(n, u) || pt(t, u),
          f,
          o;
        r(e) || '#' !== e.charAt(0)
          ? this.$$html5
            ? (f = e)
            : ((f = ''), r(e) && ((n = u), this.replace()))
          : ((f = pt(i, e)), r(f) && (f = e));
        go(f, this);
        u = this.$$path;
        e = n;
        o = /^\/[A-Z]:(\/.*)/;
        0 === f.lastIndexOf(e, 0) && (f = f.replace(e, ''));
        o.exec(f) || (u = (f = o.exec(u)) ? f[1] : u);
        this.$$path = u;
        this.$$compose();
      };
      this.$$compose = function () {
        var t = lf(this.$$search),
          r = this.$$hash ? '#' + tu(this.$$hash) : '';
        this.$$url = wf(this.$$path) + (t ? '?' + t : '') + r;
        this.$$absUrl = n + (this.$$url ? i + this.$$url : '');
      };
      this.$$parseLinkUrl = function (t) {
        return oi(n) == oi(t) ? (this.$$parse(t), !0) : !1;
      };
    }
    function ns(n, t, i) {
      this.$$html5 = !0;
      kf.apply(this, arguments);
      this.$$parseLinkUrl = function (r, u) {
        if (u && '#' === u[0]) return this.hash(u.slice(1)), !0;
        var f, e;
        return (
          n == oi(r)
            ? (f = r)
            : (e = pt(t, r))
            ? (f = n + i + e)
            : t === r + '/' && (f = t),
          f && this.$$parse(f),
          !!f
        );
      };
      this.$$compose = function () {
        var t = lf(this.$$search),
          r = this.$$hash ? '#' + tu(this.$$hash) : '';
        this.$$url = wf(this.$$path) + (t ? '?' + t : '') + r;
        this.$$absUrl = n + i + this.$$url;
      };
    }
    function au(n) {
      return function () {
        return this[n];
      };
    }
    function ts(n, t) {
      return function (i) {
        return r(i) ? this[n] : ((this[n] = t(i)), this.$$compose(), this);
      };
    }
    function ha() {
      var t = '',
        n = { enabled: !1, requireBase: !0, rewriteLinks: !0 };
      this.hashPrefix = function (n) {
        return u(n) ? ((t = n), this) : t;
      };
      this.html5Mode = function (t) {
        return ui(t)
          ? ((n.enabled = t), this)
          : s(t)
          ? (ui(t.enabled) && (n.enabled = t.enabled),
            ui(t.requireBase) && (n.requireBase = t.requireBase),
            ui(t.rewriteLinks) && (n.rewriteLinks = t.rewriteLinks),
            this)
          : n;
      };
      this.$get = [
        '$rootScope',
        '$browser',
        '$sniffer',
        '$rootElement',
        '$window',
        function (i, u, f, o, h) {
          function w(n, t, i) {
            var r = c.url(),
              f = c.$$state;
            try {
              u.url(n, t, i);
              c.$$state = u.state();
            } catch (e) {
              throw (c.url(r), (c.$$state = f), e);
            }
          }
          function b(n, t) {
            i.$broadcast('$locationChangeSuccess', c.absUrl(), n, c.$$state, t);
          }
          var c, a, l, v, p, k, y;
          if (((a = u.baseHref()), (l = u.url()), n.enabled)) {
            if (!a && n.requireBase) throw tf('nobase');
            v =
              l.substring(0, l.indexOf('/', l.indexOf('//') + 2)) + (a || '/');
            a = f.history ? bf : ns;
          } else (v = oi(l)), (a = kf);
          p = v.substr(0, oi(v).lastIndexOf('/') + 1);
          c = new a(v, p, '#' + t);
          c.$$parseLinkUrl(l, l);
          c.$$state = u.state();
          k = /^\s*(javascript|mailto):/i;
          o.on('click', function (t) {
            var r, f, l;
            if (
              n.rewriteLinks &&
              !t.ctrlKey &&
              !t.metaKey &&
              !t.shiftKey &&
              2 != t.which &&
              2 != t.button
            ) {
              for (r = e(t.target); 'a' !== at(r[0]); )
                if (r[0] === o[0] || !(r = r.parent())[0]) return;
              f = r.prop('href');
              l = r.attr('href') || r.attr('xlink:href');
              s(f) &&
                '[object SVGAnimatedString]' === f.toString() &&
                (f = gt(f.animVal).href);
              k.test(f) ||
                !f ||
                r.attr('target') ||
                t.isDefaultPrevented() ||
                !c.$$parseLinkUrl(f, l) ||
                (t.preventDefault(),
                c.absUrl() != u.url() &&
                  (i.$apply(), (h.angular['ff-684208-preventDefault'] = !0)));
            }
          });
          yr(c.absUrl()) != yr(l) && u.url(c.absUrl(), !0);
          y = !0;
          u.onUrlChange(function (n, t) {
            r(pt(p, n))
              ? (h.location.href = n)
              : (i.$evalAsync(function () {
                  var r = c.absUrl(),
                    u = c.$$state,
                    f;
                  n = yr(n);
                  c.$$parse(n);
                  c.$$state = t;
                  f = i.$broadcast('$locationChangeStart', n, r, t, u)
                    .defaultPrevented;
                  c.absUrl() === n &&
                    (f
                      ? (c.$$parse(r), (c.$$state = u), w(r, !1, u))
                      : ((y = !1), b(r, u)));
                }),
                i.$$phase || i.$digest());
          });
          return (
            i.$watch(function () {
              var t = yr(u.url()),
                e = yr(c.absUrl()),
                n = u.state(),
                o = c.$$replace,
                r = t !== e || (c.$$html5 && f.history && n !== c.$$state);
              (y || r) &&
                ((y = !1),
                i.$evalAsync(function () {
                  var u = c.absUrl(),
                    f = i.$broadcast('$locationChangeStart', u, t, c.$$state, n)
                      .defaultPrevented;
                  c.absUrl() === u &&
                    (f
                      ? (c.$$parse(t), (c.$$state = n))
                      : (r && w(u, o, n === c.$$state ? null : c.$$state),
                        b(t, n)));
                }));
              c.$$replace = !1;
            }),
            c
          );
        },
      ];
    }
    function ca() {
      var n = !0,
        i = this;
      this.debugEnabled = function (t) {
        return u(t) ? ((n = t), this) : n;
      };
      this.$get = [
        '$window',
        function (r) {
          function f(n) {
            return (
              n instanceof Error &&
                (n.stack
                  ? (n =
                      n.message && -1 === n.stack.indexOf(n.message)
                        ? 'Error: ' + n.message + '\n' + n.stack
                        : n.stack)
                  : n.sourceURL &&
                    (n = n.message + '\n' + n.sourceURL + ':' + n.line)),
              n
            );
          }
          function u(n) {
            var i = r.console || {},
              u = i[n] || i.log || o;
            n = !1;
            try {
              n = !!u.apply;
            } catch (e) {}
            return n
              ? function () {
                  var n = [];
                  return (
                    t(arguments, function (t) {
                      n.push(f(t));
                    }),
                    u.apply(i, n)
                  );
                }
              : function (n, t) {
                  u(n, null == t ? '' : t);
                };
          }
          return {
            log: u('log'),
            info: u('info'),
            warn: u('warn'),
            error: u('error'),
            debug: (function () {
              var t = u('debug');
              return function () {
                n && t.apply(i, arguments);
              };
            })(),
          };
        },
      ];
    }
    function bi(n, t) {
      if (
        '__defineGetter__' === n ||
        '__defineSetter__' === n ||
        '__lookupGetter__' === n ||
        '__lookupSetter__' === n ||
        '__proto__' === n
      )
        throw it('isecfld', t);
      return n;
    }
    function la(n) {
      return n + '';
    }
    function dt(n, t) {
      if (n) {
        if (n.constructor === n) throw it('isecfn', t);
        if (n.window === n) throw it('isecwindow', t);
        if (n.children && (n.nodeName || (n.prop && n.attr && n.find)))
          throw it('isecdom', t);
        if (n === Object) throw it('isecobj', t);
      }
      return n;
    }
    function is(n, t) {
      if (n) {
        if (n.constructor === n) throw it('isecfn', t);
        if (n === fp || n === ep || n === op) throw it('isecff', t);
      }
    }
    function vu(n, t) {
      if (
        n &&
        (n === (0).constructor ||
          n === (!1).constructor ||
          n === ''.constructor ||
          n === {}.constructor ||
          n === [].constructor ||
          n === Function.constructor)
      )
        throw it('isecaf', t);
    }
    function aa(n, t) {
      return 'undefined' != typeof n ? n : t;
    }
    function rs(n, t) {
      return 'undefined' == typeof n ? t : 'undefined' == typeof t ? n : n + t;
    }
    function k(n, r) {
      var u, f;
      switch (n.type) {
        case i.Program:
          u = !0;
          t(n.body, function (n) {
            k(n.expression, r);
            u = u && n.expression.constant;
          });
          n.constant = u;
          break;
        case i.Literal:
          n.constant = !0;
          n.toWatch = [];
          break;
        case i.UnaryExpression:
          k(n.argument, r);
          n.constant = n.argument.constant;
          n.toWatch = n.argument.toWatch;
          break;
        case i.BinaryExpression:
          k(n.left, r);
          k(n.right, r);
          n.constant = n.left.constant && n.right.constant;
          n.toWatch = n.left.toWatch.concat(n.right.toWatch);
          break;
        case i.LogicalExpression:
          k(n.left, r);
          k(n.right, r);
          n.constant = n.left.constant && n.right.constant;
          n.toWatch = n.constant ? [] : [n];
          break;
        case i.ConditionalExpression:
          k(n.test, r);
          k(n.alternate, r);
          k(n.consequent, r);
          n.constant =
            n.test.constant && n.alternate.constant && n.consequent.constant;
          n.toWatch = n.constant ? [] : [n];
          break;
        case i.Identifier:
          n.constant = !1;
          n.toWatch = [n];
          break;
        case i.MemberExpression:
          k(n.object, r);
          n.computed && k(n.property, r);
          n.constant =
            n.object.constant && (!n.computed || n.property.constant);
          n.toWatch = [n];
          break;
        case i.CallExpression:
          u = n.filter ? !r(n.callee.name).$stateful : !1;
          f = [];
          t(n.arguments, function (n) {
            k(n, r);
            u = u && n.constant;
            n.constant || f.push.apply(f, n.toWatch);
          });
          n.constant = u;
          n.toWatch = n.filter && !r(n.callee.name).$stateful ? f : [n];
          break;
        case i.AssignmentExpression:
          k(n.left, r);
          k(n.right, r);
          n.constant = n.left.constant && n.right.constant;
          n.toWatch = [n];
          break;
        case i.ArrayExpression:
          u = !0;
          f = [];
          t(n.elements, function (n) {
            k(n, r);
            u = u && n.constant;
            n.constant || f.push.apply(f, n.toWatch);
          });
          n.constant = u;
          n.toWatch = f;
          break;
        case i.ObjectExpression:
          u = !0;
          f = [];
          t(n.properties, function (n) {
            k(n.value, r);
            u = u && n.value.constant && !n.computed;
            n.value.constant || f.push.apply(f, n.value.toWatch);
          });
          n.constant = u;
          n.toWatch = f;
          break;
        case i.ThisExpression:
          n.constant = !1;
          n.toWatch = [];
          break;
        case i.LocalsExpression:
          n.constant = !1;
          n.toWatch = [];
      }
    }
    function us(n) {
      if (1 == n.length) {
        n = n[0].expression;
        var t = n.toWatch;
        return 1 !== t.length ? t : t[0] !== n ? t : void 0;
      }
    }
    function fs(n) {
      return n.type === i.Identifier || n.type === i.MemberExpression;
    }
    function es(n) {
      if (1 === n.body.length && fs(n.body[0].expression))
        return {
          type: i.AssignmentExpression,
          left: n.body[0].expression,
          right: { type: i.NGValueParameter },
          operator: '=',
        };
    }
    function os(n) {
      return (
        0 === n.body.length ||
        (1 === n.body.length &&
          (n.body[0].expression.type === i.Literal ||
            n.body[0].expression.type === i.ArrayExpression ||
            n.body[0].expression.type === i.ObjectExpression))
      );
    }
    function ss(n, t) {
      this.astBuilder = n;
      this.$filter = t;
    }
    function hs(n, t) {
      this.astBuilder = n;
      this.$filter = t;
    }
    function yu(n) {
      return 'constructor' == n;
    }
    function df(n) {
      return f(n.valueOf) ? n.valueOf() : sp.call(n);
    }
    function va() {
      var e = y(),
        s = y(),
        r = { true: !0, false: !1, null: null, undefined: void 0 },
        n,
        i;
      this.addLiteral = function (n, t) {
        r[n] = t;
      };
      this.setIdentifierFns = function (t, r) {
        return (n = t), (i = r), this;
      };
      this.$get = [
        '$filter',
        function (h) {
          function p(n, t, i) {
            var r, l, u, f, p;
            i = i || c;
            switch (typeof n) {
              case 'string':
                return (
                  (u = n = n.trim()),
                  (f = i ? s : e),
                  (r = f[u]),
                  r ||
                    (':' === n.charAt(0) &&
                      ':' === n.charAt(1) &&
                      ((l = !0), (n = n.substring(2))),
                    (r = i ? nt : g),
                    (p = new uf(r)),
                    (r = new ff(p, h, r).parse(n)),
                    r.constant
                      ? (r.$$watchDelegate = d)
                      : l
                      ? (r.$$watchDelegate = r.literal ? b : w)
                      : r.inputs && (r.$$watchDelegate = v),
                    i && (r = a(r)),
                    (f[u] = r)),
                  y(r, t)
                );
              case 'function':
                return y(n, t);
              default:
                return y(o, t);
            }
          }
          function a(n) {
            function t(t, i, r, u) {
              var f = c;
              c = !0;
              try {
                return n(t, i, r, u);
              } finally {
                c = f;
              }
            }
            if (!n) return n;
            t.$$watchDelegate = n.$$watchDelegate;
            t.assign = a(n.assign);
            t.constant = n.constant;
            t.literal = n.literal;
            for (var i = 0; n.inputs && i < n.inputs.length; ++i)
              n.inputs[i] = a(n.inputs[i]);
            return (t.inputs = n.inputs), t;
          }
          function l(n, t) {
            return null == n || null == t
              ? n === t
              : 'object' == typeof n && ((n = df(n)), 'object' == typeof n)
              ? !1
              : n === t || (n !== n && t !== t);
          }
          function v(n, t, i, r, u) {
            var f = r.inputs,
              e,
              s;
            if (1 === f.length)
              return (
                (s = l),
                (f = f[0]),
                n.$watch(
                  function (n) {
                    var t = f(n);
                    return (
                      l(t, s) ||
                        ((e = r(n, void 0, void 0, [t])), (s = t && df(t))),
                      e
                    );
                  },
                  t,
                  i,
                  u
                )
              );
            for (var h = [], c = [], o = 0, a = f.length; o < a; o++)
              (h[o] = l), (c[o] = null);
            return n.$watch(
              function (n) {
                for (var i, u = !1, t = 0, o = f.length; t < o; t++)
                  (i = f[t](n)),
                    (u || (u = !l(i, h[t]))) &&
                      ((c[t] = i), (h[t] = i && df(i)));
                return u && (e = r(n, void 0, void 0, c)), e;
              },
              t,
              i,
              u
            );
          }
          function w(n, t, i, r) {
            var e, o;
            return (e = n.$watch(
              function (n) {
                return r(n);
              },
              function (n, i, r) {
                o = n;
                f(t) && t.apply(this, arguments);
                u(n) &&
                  r.$$postDigest(function () {
                    u(o) && e();
                  });
              },
              i
            ));
          }
          function b(n, i, r, e) {
            function o(n) {
              var i = !0;
              return (
                t(n, function (n) {
                  u(n) || (i = !1);
                }),
                i
              );
            }
            var s, h;
            return (s = n.$watch(
              function (n) {
                return e(n);
              },
              function (n, t, r) {
                h = n;
                f(i) && i.call(this, n, t, r);
                o(n) &&
                  r.$$postDigest(function () {
                    o(h) && s();
                  });
              },
              r
            ));
          }
          function d(n, t, i, r) {
            var u;
            return (u = n.$watch(
              function (n) {
                return u(), r(n);
              },
              t,
              i
            ));
          }
          function y(n, t) {
            if (!t) return n;
            var i = n.$$watchDelegate,
              r = !1,
              i =
                i !== b && i !== w
                  ? function (i, u, f, e) {
                      return (f = r && e ? e[0] : n(i, u, f, e)), t(f, i, u);
                    }
                  : function (i, r, f, e) {
                      return (
                        (f = n(i, r, f, e)), (i = t(f, i, r)), u(f) ? i : f
                      );
                    };
            return (
              n.$$watchDelegate && n.$$watchDelegate !== v
                ? (i.$$watchDelegate = n.$$watchDelegate)
                : t.$stateful ||
                  ((i.$$watchDelegate = v),
                  (r = !n.inputs),
                  (i.inputs = n.inputs ? n.inputs : [n])),
              i
            );
          }
          var k = li().noUnsafeEval,
            g = {
              csp: k,
              expensiveChecks: !1,
              literals: kt(r),
              isIdentifierStart: f(n) && n,
              isIdentifierContinue: f(i) && i,
            },
            nt = {
              csp: k,
              expensiveChecks: !0,
              literals: kt(r),
              isIdentifierStart: f(n) && n,
              isIdentifierContinue: f(i) && i,
            },
            c = !1;
          return (
            (p.$$runningExpensiveChecks = function () {
              return c;
            }),
            p
          );
        },
      ];
    }
    function ya() {
      this.$get = [
        '$rootScope',
        '$exceptionHandler',
        function (n, t) {
          return cs(function (t) {
            n.$evalAsync(t);
          }, t);
        },
      ];
    }
    function pa() {
      this.$get = [
        '$browser',
        '$exceptionHandler',
        function (n, t) {
          return cs(function (t) {
            n.defer(t);
          }, t);
        },
      ];
    }
    function cs(n, i) {
      function v() {
        this.$$state = { status: 0 };
      }
      function o(n, t) {
        return function (i) {
          t.call(n, i);
        };
      }
      function y(t) {
        !t.processScheduled &&
          t.pending &&
          ((t.processScheduled = !0),
          n(function () {
            var e, n, u, r, o;
            for (
              u = t.pending,
                t.processScheduled = !1,
                t.pending = void 0,
                r = 0,
                o = u.length;
              r < o;
              ++r
            ) {
              n = u[r][0];
              e = u[r][t.status];
              try {
                f(e)
                  ? n.resolve(e(t.value))
                  : 1 === t.status
                  ? n.resolve(t.value)
                  : n.reject(t.value);
              } catch (s) {
                n.reject(s);
                i(s);
              }
            }
          }));
      }
      function u() {
        this.promise = new v();
      }
      var w = l('$q', TypeError);
      a(v.prototype, {
        then: function (n, t, i) {
          if (r(n) && r(t) && r(i)) return this;
          var f = new u();
          return (
            (this.$$state.pending = this.$$state.pending || []),
            this.$$state.pending.push([f, n, t, i]),
            0 < this.$$state.status && y(this.$$state),
            f.promise
          );
        },
        catch: function (n) {
          return this.then(null, n);
        },
        finally: function (n, t) {
          return this.then(
            function (t) {
              return b(t, !0, n);
            },
            function (t) {
              return b(t, !1, n);
            },
            t
          );
        },
      });
      a(u.prototype, {
        resolve: function (n) {
          this.promise.$$state.status ||
            (n === this.promise
              ? this.$$reject(w('qcycle', n))
              : this.$$resolve(n));
        },
        $$resolve: function (n) {
          function c(n) {
            t || ((t = !0), e.$$resolve(n));
          }
          function u(n) {
            t || ((t = !0), e.$$reject(n));
          }
          var r,
            e = this,
            t = !1;
          try {
            (s(n) || f(n)) && (r = n && n.then);
            f(r)
              ? ((this.promise.$$state.status = -1),
                r.call(n, c, u, o(this, this.notify)))
              : ((this.promise.$$state.value = n),
                (this.promise.$$state.status = 1),
                y(this.promise.$$state));
          } catch (h) {
            u(h);
            i(h);
          }
        },
        reject: function (n) {
          this.promise.$$state.status || this.$$reject(n);
        },
        $$reject: function (n) {
          this.promise.$$state.value = n;
          this.promise.$$state.status = 2;
          y(this.promise.$$state);
        },
        notify: function (t) {
          var r = this.promise.$$state.pending;
          0 >= this.promise.$$state.status &&
            r &&
            r.length &&
            n(function () {
              for (var u, e, n = 0, o = r.length; n < o; n++) {
                e = r[n][0];
                u = r[n][3];
                try {
                  e.notify(f(u) ? u(t) : t);
                } catch (s) {
                  i(s);
                }
              }
            });
        },
      });
      var h = function (n, t) {
          var i = new u();
          return t ? i.resolve(n) : i.reject(n), i.promise;
        },
        b = function (n, t, i) {
          var r = null;
          try {
            f(i) && (r = i());
          } catch (u) {
            return h(u, !1);
          }
          return r && f(r.then)
            ? r.then(
                function () {
                  return h(n, t);
                },
                function (n) {
                  return h(n, !1);
                }
              )
            : h(n, t);
        },
        p = function (n, t, i, r) {
          var f = new u();
          return f.resolve(n), f.promise.then(t, i, r);
        },
        e = function (n) {
          if (!f(n)) throw w('norslvr', n);
          var t = new u();
          return (
            n(
              function (n) {
                t.resolve(n);
              },
              function (n) {
                t.reject(n);
              }
            ),
            t.promise
          );
        };
      return (
        (e.prototype = v.prototype),
        (e.defer = function () {
          var n = new u();
          return (
            (n.resolve = o(n, n.resolve)),
            (n.reject = o(n, n.reject)),
            (n.notify = o(n, n.notify)),
            n
          );
        }),
        (e.reject = function (n) {
          var t = new u();
          return t.reject(n), t.promise;
        }),
        (e.when = p),
        (e.resolve = p),
        (e.all = function (n) {
          var r = new u(),
            f = 0,
            i = c(n) ? [] : {};
          return (
            t(n, function (n, t) {
              f++;
              p(n).then(
                function (n) {
                  i.hasOwnProperty(t) || ((i[t] = n), --f || r.resolve(i));
                },
                function (n) {
                  i.hasOwnProperty(t) || r.reject(n);
                }
              );
            }),
            0 === f && r.resolve(i),
            r.promise
          );
        }),
        e
      );
    }
    function wa() {
      this.$get = [
        '$window',
        '$timeout',
        function (n, t) {
          var i = n.requestAnimationFrame || n.webkitRequestAnimationFrame,
            f =
              n.cancelAnimationFrame ||
              n.webkitCancelAnimationFrame ||
              n.webkitCancelRequestAnimationFrame,
            r = !!i,
            u = r
              ? function (n) {
                  var t = i(n);
                  return function () {
                    f(t);
                  };
                }
              : function (n) {
                  var i = t(n, 16.66, !1);
                  return function () {
                    t.cancel(i);
                  };
                };
          return (u.supported = r), u;
        },
      ];
    }
    function ba() {
      function h(n) {
        function t() {
          this.$$watchers = this.$$nextSibling = this.$$childHead = this.$$childTail = null;
          this.$$listeners = {};
          this.$$listenerCount = {};
          this.$$watchersCount = 0;
          this.$id = ++ku;
          this.$$ChildScope = null;
        }
        return (t.prototype = n), t;
      }
      var u = 10,
        e = l('$rootScope'),
        n = null,
        i = null;
      this.digestTtl = function (n) {
        return arguments.length && (u = n), u;
      };
      this.$get = [
        '$exceptionHandler',
        '$parse',
        '$browser',
        function (c, l, a) {
          function ut(n) {
            n.currentScope.$$destroyed = !0;
          }
          function b(n) {
            9 === ni &&
              (n.$$childHead && b(n.$$childHead),
              n.$$nextSibling && b(n.$$nextSibling));
            n.$parent = n.$$nextSibling = n.$$prevSibling = n.$$childHead = n.$$childTail = n.$root = n.$$watchers = null;
          }
          function p() {
            this.$id = ++ku;
            this.$$phase = this.$parent = this.$$watchers = this.$$nextSibling = this.$$prevSibling = this.$$childHead = this.$$childTail = null;
            this.$root = this;
            this.$$destroyed = !1;
            this.$$listeners = {};
            this.$$listenerCount = {};
            this.$$watchersCount = 0;
            this.$$isolateBindings = null;
          }
          function nt(n) {
            if (v.$$phase) throw e('inprog', v.$$phase);
            v.$$phase = n;
          }
          function k(n, t) {
            do n.$$watchersCount += t;
            while ((n = n.$parent));
          }
          function tt(n, t, i) {
            do
              (n.$$listenerCount[i] -= t),
                0 === n.$$listenerCount[i] && delete n.$$listenerCount[i];
            while ((n = n.$parent));
          }
          function it() {}
          function rt() {
            for (; d.length; )
              try {
                d.shift()();
              } catch (n) {
                c(n);
              }
            i = null;
          }
          function ft() {
            null === i &&
              (i = a.defer(function () {
                v.$apply(rt);
              }));
          }
          p.prototype = {
            constructor: p,
            $new: function (n, t) {
              var i;
              return (
                (t = t || this),
                n
                  ? ((i = new p()), (i.$root = this.$root))
                  : (this.$$ChildScope || (this.$$ChildScope = h(this)),
                    (i = new this.$$ChildScope())),
                (i.$parent = t),
                (i.$$prevSibling = t.$$childTail),
                t.$$childHead
                  ? ((t.$$childTail.$$nextSibling = i), (t.$$childTail = i))
                  : (t.$$childHead = t.$$childTail = i),
                (n || t != this) && i.$on('$destroy', ut),
                i
              );
            },
            $watch: function (t, i, r, u) {
              var e = l(t);
              if (e.$$watchDelegate) return e.$$watchDelegate(this, i, r, e, t);
              var h = this,
                s = h.$$watchers,
                c = { fn: i, last: it, get: e, exp: u || t, eq: !!r };
              return (
                (n = null),
                f(i) || (c.fn = o),
                s || (s = h.$$watchers = []),
                s.unshift(c),
                k(this, 1),
                function () {
                  0 <= er(s, c) && k(h, -1);
                  n = null;
                }
              );
            },
            $watchGroup: function (n, i) {
              function c() {
                o = !1;
                h ? ((h = !1), i(r, r, u)) : i(r, f, u);
              }
              var f = Array(n.length),
                r = Array(n.length),
                e = [],
                u = this,
                o = !1,
                h = !0,
                s;
              return n.length
                ? 1 === n.length
                  ? this.$watch(n[0], function (n, t, u) {
                      r[0] = n;
                      f[0] = t;
                      i(r, n === t ? r : f, u);
                    })
                  : (t(n, function (n, t) {
                      var i = u.$watch(n, function (n, i) {
                        r[t] = n;
                        f[t] = i;
                        o || ((o = !0), u.$evalAsync(c));
                      });
                      e.push(i);
                    }),
                    function () {
                      for (; e.length; ) e.shift()();
                    })
                : ((s = !0),
                  u.$evalAsync(function () {
                    s && i(r, r, u);
                  }),
                  function () {
                    s = !1;
                  });
            },
            $watchCollection: function (n, t) {
              function h(n) {
                i = n;
                var t, c, o, h;
                if (!r(i)) {
                  if (s(i))
                    if (ri(i))
                      for (
                        u !== a && ((u = a), (e = u.length = 0), f++),
                          n = i.length,
                          e !== n && (f++, (u.length = e = n)),
                          t = 0;
                        t < n;
                        t++
                      )
                        (h = u[t]),
                          (o = i[t]),
                          (c = h !== h && o !== o),
                          c || h === o || (f++, (u[t] = o));
                    else {
                      u !== v && ((u = v = {}), (e = 0), f++);
                      n = 0;
                      for (t in i)
                        wt.call(i, t) &&
                          (n++,
                          (o = i[t]),
                          (h = u[t]),
                          t in u
                            ? ((c = h !== h && o !== o),
                              c || h === o || (f++, (u[t] = o)))
                            : (e++, (u[t] = o), f++));
                      if (e > n)
                        for (t in (f++, u)) wt.call(i, t) || (e--, delete u[t]);
                    }
                  else u !== i && ((u = i), f++);
                  return f;
                }
              }
              h.$stateful = !0;
              var c = this,
                i,
                u,
                o,
                p = 1 < t.length,
                f = 0,
                w = l(n, h),
                a = [],
                v = {},
                y = !0,
                e = 0;
              return this.$watch(w, function () {
                if ((y ? ((y = !1), t(i, i, c)) : t(i, o, c), p))
                  if (s(i))
                    if (ri(i)) {
                      o = Array(i.length);
                      for (var n = 0; n < i.length; n++) o[n] = i[n];
                    } else
                      for (n in ((o = {}), i)) wt.call(i, n) && (o[n] = i[n]);
                  else o = i;
              });
            },
            $digest: function () {
              var t,
                s,
                h,
                ut,
                ft,
                o,
                d,
                l,
                tt = u,
                r,
                p = [],
                b,
                k;
              nt('$digest');
              a.$$checkUrlChange();
              this === v && null !== i && (a.defer.cancel(i), rt());
              n = null;
              do {
                for (l = !1, r = this, o = 0; o < y.length; o++) {
                  try {
                    k = y[o];
                    k.scope.$eval(k.expression, k.locals);
                  } catch (et) {
                    c(et);
                  }
                  n = null;
                }
                y.length = 0;
                n: do {
                  if ((o = r.$$watchers))
                    for (d = o.length; d--; )
                      try {
                        if ((t = o[d]))
                          if (
                            ((ft = t.get),
                            (s = ft(r)) === (h = t.last) ||
                              (t.eq
                                ? st(s, h)
                                : 'number' == typeof s &&
                                  'number' == typeof h &&
                                  isNaN(s) &&
                                  isNaN(h)))
                          ) {
                            if (t === n) {
                              l = !1;
                              break n;
                            }
                          } else
                            (l = !0),
                              (n = t),
                              (t.last = t.eq ? kt(s, null) : s),
                              (ut = t.fn),
                              ut(s, h === it ? s : h, r),
                              5 > tt &&
                                ((b = 4 - tt),
                                p[b] || (p[b] = []),
                                p[b].push({
                                  msg: f(t.exp)
                                    ? 'fn: ' + (t.exp.name || t.exp.toString())
                                    : t.exp,
                                  newVal: s,
                                  oldVal: h,
                                }));
                      } catch (ot) {
                        c(ot);
                      }
                  if (
                    !(o =
                      (r.$$watchersCount && r.$$childHead) ||
                      (r !== this && r.$$nextSibling))
                  )
                    for (; r !== this && !(o = r.$$nextSibling); )
                      r = r.$parent;
                } while ((r = o));
                if ((l || y.length) && !tt--)
                  throw ((v.$$phase = null), e('infdig', u, p));
              } while (l || y.length);
              for (v.$$phase = null; g < w.length; )
                try {
                  w[g++]();
                } catch (ht) {
                  c(ht);
                }
              w.length = g = 0;
            },
            $destroy: function () {
              var n, t;
              if (!this.$$destroyed) {
                n = this.$parent;
                this.$broadcast('$destroy');
                this.$$destroyed = !0;
                this === v && a.$$applicationDestroyed();
                k(this, -this.$$watchersCount);
                for (t in this.$$listenerCount)
                  tt(this, this.$$listenerCount[t], t);
                n &&
                  n.$$childHead == this &&
                  (n.$$childHead = this.$$nextSibling);
                n &&
                  n.$$childTail == this &&
                  (n.$$childTail = this.$$prevSibling);
                this.$$prevSibling &&
                  (this.$$prevSibling.$$nextSibling = this.$$nextSibling);
                this.$$nextSibling &&
                  (this.$$nextSibling.$$prevSibling = this.$$prevSibling);
                this.$destroy = this.$digest = this.$apply = this.$evalAsync = this.$applyAsync = o;
                this.$on = this.$watch = this.$watchGroup = function () {
                  return o;
                };
                this.$$listeners = {};
                this.$$nextSibling = null;
                b(this);
              }
            },
            $eval: function (n, t) {
              return l(n)(this, t);
            },
            $evalAsync: function (n, t) {
              v.$$phase ||
                y.length ||
                a.defer(function () {
                  y.length && v.$digest();
                });
              y.push({ scope: this, expression: l(n), locals: t });
            },
            $$postDigest: function (n) {
              w.push(n);
            },
            $apply: function (n) {
              try {
                nt('$apply');
                try {
                  return this.$eval(n);
                } finally {
                  v.$$phase = null;
                }
              } catch (i) {
                c(i);
              } finally {
                try {
                  v.$digest();
                } catch (t) {
                  throw (c(t), t);
                }
              }
            },
            $applyAsync: function (n) {
              function t() {
                i.$eval(n);
              }
              var i = this;
              n && d.push(t);
              n = l(n);
              ft();
            },
            $on: function (n, t) {
              var r = this.$$listeners[n],
                i,
                u;
              r || (this.$$listeners[n] = r = []);
              r.push(t);
              i = this;
              do
                i.$$listenerCount[n] || (i.$$listenerCount[n] = 0),
                  i.$$listenerCount[n]++;
              while ((i = i.$parent));
              return (
                (u = this),
                function () {
                  var i = r.indexOf(t);
                  -1 !== i && ((r[i] = null), tt(u, 1, n));
                }
              );
            },
            $emit: function (n) {
              var o = [],
                u,
                r = this,
                e = !1,
                t = {
                  name: n,
                  targetScope: r,
                  stopPropagation: function () {
                    e = !0;
                  },
                  preventDefault: function () {
                    t.defaultPrevented = !0;
                  },
                  defaultPrevented: !1,
                },
                s = or([t], arguments, 1),
                i,
                f;
              do {
                for (
                  u = r.$$listeners[n] || o,
                    t.currentScope = r,
                    i = 0,
                    f = u.length;
                  i < f;
                  i++
                )
                  if (u[i])
                    try {
                      u[i].apply(null, s);
                    } catch (h) {
                      c(h);
                    }
                  else u.splice(i, 1), i--, f--;
                if (e) return (t.currentScope = null), t;
                r = r.$parent;
              } while (r);
              return (t.currentScope = null), t;
            },
            $broadcast: function (n) {
              var t = this,
                i = this,
                u = {
                  name: n,
                  targetScope: this,
                  preventDefault: function () {
                    u.defaultPrevented = !0;
                  },
                  defaultPrevented: !1,
                },
                e,
                r,
                f;
              if (!this.$$listenerCount[n]) return u;
              for (e = or([u], arguments, 1); (t = i); ) {
                for (
                  u.currentScope = t,
                    i = t.$$listeners[n] || [],
                    r = 0,
                    f = i.length;
                  r < f;
                  r++
                )
                  if (i[r])
                    try {
                      i[r].apply(null, e);
                    } catch (o) {
                      c(o);
                    }
                  else i.splice(r, 1), r--, f--;
                if (
                  !(i =
                    (t.$$listenerCount[n] && t.$$childHead) ||
                    (t !== this && t.$$nextSibling))
                )
                  for (; t !== this && !(i = t.$$nextSibling); ) t = t.$parent;
              }
              return (u.currentScope = null), u;
            },
          };
          var v = new p(),
            y = (v.$$asyncQueue = []),
            w = (v.$$postDigestQueue = []),
            d = (v.$$applyAsyncQueue = []),
            g = 0;
          return v;
        },
      ];
    }
    function ka() {
      var n = /^\s*(https?|ftp|mailto|tel|file):/,
        t = /^\s*((https?|ftp|file|blob):|data:image\/)/;
      this.aHrefSanitizationWhitelist = function (t) {
        return u(t) ? ((n = t), this) : n;
      };
      this.imgSrcSanitizationWhitelist = function (n) {
        return u(n) ? ((t = n), this) : t;
      };
      this.$get = function () {
        return function (i, r) {
          var f = r ? t : n,
            u;
          return (u = gt(i).href), '' === u || u.match(f) ? i : 'unsafe:' + u;
        };
      };
    }
    function da(n) {
      if ('self' === n) return n;
      if (h(n)) {
        if (-1 < n.indexOf('***')) throw ii('iwcard', n);
        return (
          (n = oh(n).replace('\\*\\*', '.*').replace('\\*', '[^:/.?&;]*')),
          new RegExp('^' + n + '$')
        );
      }
      if (rr(n)) return new RegExp('^' + n.source + '$');
      throw ii('imatcher');
    }
    function ls(n) {
      var i = [];
      return (
        u(n) &&
          t(n, function (n) {
            i.push(da(n));
          }),
        i
      );
    }
    function ga() {
      this.SCE_CONTEXTS = lt;
      var n = ['self'],
        t = [];
      this.resourceUrlWhitelist = function (t) {
        return arguments.length && (n = ls(t)), n;
      };
      this.resourceUrlBlacklist = function (n) {
        return arguments.length && (t = ls(n)), t;
      };
      this.$get = [
        '$injector',
        function (i) {
          function o(n, t) {
            return 'self' === n ? as(t) : !!n.exec(t.href);
          }
          function f(n) {
            var t = function (n) {
              this.$$unwrapTrustedValue = function () {
                return n;
              };
            };
            return (
              n && (t.prototype = new n()),
              (t.prototype.valueOf = function () {
                return this.$$unwrapTrustedValue();
              }),
              (t.prototype.toString = function () {
                return this.$$unwrapTrustedValue().toString();
              }),
              t
            );
          }
          var s = function () {
              throw ii('unsafe');
            },
            e,
            u;
          return (
            i.has('$sanitize') && (s = i.get('$sanitize')),
            (e = f()),
            (u = {}),
            (u[lt.HTML] = f(e)),
            (u[lt.CSS] = f(e)),
            (u[lt.URL] = f(e)),
            (u[lt.JS] = f(e)),
            (u[lt.RESOURCE_URL] = f(u[lt.URL])),
            {
              trustAs: function (n, t) {
                var i = u.hasOwnProperty(n) ? u[n] : null;
                if (!i) throw ii('icontext', n, t);
                if (null === t || r(t) || '' === t) return t;
                if ('string' != typeof t) throw ii('itype', n);
                return new i(t);
              },
              getTrusted: function (i, f) {
                var h, e, l, c;
                if (null === f || r(f) || '' === f) return f;
                if (
                  ((h = u.hasOwnProperty(i) ? u[i] : null), h && f instanceof h)
                )
                  return f.$$unwrapTrustedValue();
                if (i === lt.RESOURCE_URL) {
                  for (
                    h = gt(f.toString()), c = !1, e = 0, l = n.length;
                    e < l;
                    e++
                  )
                    if (o(n[e], h)) {
                      c = !0;
                      break;
                    }
                  if (c)
                    for (e = 0, l = t.length; e < l; e++)
                      if (o(t[e], h)) {
                        c = !1;
                        break;
                      }
                  if (c) return f;
                  throw ii('insecurl', f.toString());
                }
                if (i === lt.HTML) return s(f);
                throw ii('unsafe');
              },
              valueOf: function (n) {
                return n instanceof e ? n.$$unwrapTrustedValue() : n;
              },
            }
          );
        },
      ];
    }
    function nv() {
      var n = !0;
      this.enabled = function (t) {
        return arguments.length && (n = !!t), n;
      };
      this.$get = [
        '$parse',
        '$sceDelegate',
        function (i, r) {
          var u;
          if (n && 8 > ni) throw ii('iequirks');
          u = ot(lt);
          u.isEnabled = function () {
            return n;
          };
          u.trustAs = r.trustAs;
          u.getTrusted = r.getTrusted;
          u.valueOf = r.valueOf;
          n ||
            ((u.trustAs = u.getTrusted = function (n, t) {
              return t;
            }),
            (u.valueOf = ir));
          u.parseAs = function (n, t) {
            var r = i(t);
            return r.literal && r.constant
              ? r
              : i(t, function (t) {
                  return u.getTrusted(n, t);
                });
          };
          var f = u.parseAs,
            e = u.getTrusted,
            o = u.trustAs;
          return (
            t(lt, function (n, t) {
              var i = v(t);
              u[cr('parse_as_' + i)] = function (t) {
                return f(n, t);
              };
              u[cr('get_trusted_' + i)] = function (t) {
                return e(n, t);
              };
              u[cr('trust_as_' + i)] = function (t) {
                return o(n, t);
              };
            }),
            u
          );
        },
      ];
    }
    function tv() {
      this.$get = [
        '$window',
        '$document',
        function (n, t) {
          var o = {},
            a =
              !(n.chrome && n.chrome.app && n.chrome.app.runtime) &&
              n.history &&
              n.history.pushState,
            s = tt(
              (/android (\d+)/.exec(v((n.navigator || {}).userAgent)) || [])[1]
            ),
            y = /Boxee/i.test((n.navigator || {}).userAgent),
            c = t[0] || {},
            i,
            u = c.body && c.body.style,
            f = !1,
            e = !1,
            l;
          if (u) {
            for (l in u)
              if ((f = /^(Moz|webkit|ms)(?=[A-Z])/.exec(l))) {
                i = f[0];
                i = i[0].toUpperCase() + i.substr(1);
                break;
              }
            i || (i = 'WebkitOpacity' in u && 'webkit');
            f = !!('transition' in u || i + 'Transition' in u);
            e = !!('animation' in u || i + 'Animation' in u);
            !s ||
              (f && e) ||
              ((f = h(u.webkitTransition)), (e = h(u.webkitAnimation)));
          }
          return {
            history: !(!a || 4 > s || y),
            hasEvent: function (n) {
              if ('input' === n && 11 >= ni) return !1;
              if (r(o[n])) {
                var t = c.createElement('div');
                o[n] = 'on' + n in t;
              }
              return o[n];
            },
            csp: li(),
            vendorPrefix: i,
            transitions: f,
            animations: e,
            android: s,
          };
        },
      ];
    }
    function iv() {
      var n;
      this.httpOptions = function (t) {
        return t ? ((n = t), this) : n;
      };
      this.$get = [
        '$templateCache',
        '$http',
        '$q',
        '$sce',
        function (t, i, u, f) {
          function e(o, s) {
            e.totalPendingRequests++;
            (!h(o) || r(t.get(o))) && (o = f.getTrustedResourceUrl(o));
            var l = i.defaults && i.defaults.transformResponse;
            return (
              c(l)
                ? (l = l.filter(function (n) {
                    return n !== pf;
                  }))
                : l === pf && (l = null),
              i
                .get(o, a({ cache: t, transformResponse: l }, n))
                ['finally'](function () {
                  e.totalPendingRequests--;
                })
                .then(
                  function (n) {
                    return t.put(o, n.data), n.data;
                  },
                  function (n) {
                    if (!s) throw hp('tpload', o, n.status, n.statusText);
                    return u.reject(n);
                  }
                )
            );
          }
          return (e.totalPendingRequests = 0), e;
        },
      ];
    }
    function rv() {
      this.$get = [
        '$rootScope',
        '$browser',
        '$location',
        function (n, i, r) {
          return {
            findBindings: function (n, i, r) {
              n = n.getElementsByClassName('ng-binding');
              var u = [];
              return (
                t(n, function (n) {
                  var f = ut.element(n).data('$binding');
                  f &&
                    t(f, function (t) {
                      r
                        ? new RegExp('(^|\\s)' + oh(i) + '(\\s|\\||$)').test(
                            t
                          ) && u.push(n)
                        : -1 != t.indexOf(i) && u.push(n);
                    });
                }),
                u
              );
            },
            findModels: function (n, t, i) {
              for (
                var f, u = ['ng-', 'data-ng-', 'ng\\:'], r = 0;
                r < u.length;
                ++r
              )
                if (
                  ((f = n.querySelectorAll(
                    '[' + u[r] + 'model' + (i ? '=' : '*=') + '"' + t + '"]'
                  )),
                  f.length)
                )
                  return f;
            },
            getLocation: function () {
              return r.url();
            },
            setLocation: function (t) {
              t !== r.url() && (r.url(t), n.$digest());
            },
            whenStable: function (n) {
              i.notifyWhenNoOutstandingRequests(n);
            },
          };
        },
      ];
    }
    function uv() {
      this.$get = [
        '$rootScope',
        '$browser',
        '$q',
        '$$q',
        '$exceptionHandler',
        function (n, t, i, r, e) {
          function h(h, c, l) {
            f(h) || ((l = c), (c = h), (h = o));
            var w = bt.call(arguments, 3),
              p = u(l) && !l,
              a = (p ? r : i).defer(),
              v = a.promise,
              y;
            return (
              (y = t.defer(function () {
                try {
                  a.resolve(h.apply(null, w));
                } catch (t) {
                  a.reject(t);
                  e(t);
                } finally {
                  delete s[v.$$timeoutId];
                }
                p || n.$apply();
              }, c)),
              (v.$$timeoutId = y),
              (s[y] = a),
              v
            );
          }
          var s = {};
          return (
            (h.cancel = function (n) {
              return n && n.$$timeoutId in s
                ? (s[n.$$timeoutId].reject('canceled'),
                  delete s[n.$$timeoutId],
                  t.defer.cancel(n.$$timeoutId))
                : !1;
            }),
            h
          );
        },
      ];
    }
    function gt(n) {
      return (
        ni && (nt.setAttribute('href', n), (n = nt.href)),
        nt.setAttribute('href', n),
        {
          href: nt.href,
          protocol: nt.protocol ? nt.protocol.replace(/:$/, '') : '',
          host: nt.host,
          search: nt.search ? nt.search.replace(/^\?/, '') : '',
          hash: nt.hash ? nt.hash.replace(/^#/, '') : '',
          hostname: nt.hostname,
          port: nt.port,
          pathname:
            '/' === nt.pathname.charAt(0) ? nt.pathname : '/' + nt.pathname,
        }
      );
    }
    function as(n) {
      return (
        (n = h(n) ? gt(n) : n), n.protocol === wh.protocol && n.host === wh.host
      );
    }
    function fv() {
      this.$get = ft(n);
    }
    function vs(n) {
      function u(n) {
        try {
          return decodeURIComponent(n);
        } catch (t) {
          return n;
        }
      }
      var f = n[0] || {},
        t = {},
        i = '';
      return function () {
        var n, e, o, s, h;
        if (((n = f.cookie || ''), n !== i))
          for (i = n, n = i.split('; '), t = {}, o = 0; o < n.length; o++)
            (e = n[o]),
              (s = e.indexOf('=')),
              0 < s &&
                ((h = u(e.substring(0, s))),
                r(t[h]) && (t[h] = u(e.substring(s + 1))));
        return t;
      };
    }
    function ev() {
      this.$get = vs;
    }
    function ys(n) {
      function i(r, u) {
        if (s(r)) {
          var f = {};
          return (
            t(r, function (n, t) {
              f[t] = i(t, n);
            }),
            f
          );
        }
        return n.factory(r + 'Filter', u);
      }
      this.register = i;
      this.$get = [
        '$injector',
        function (n) {
          return function (t) {
            return n.get(t + 'Filter');
          };
        },
      ];
      i('currency', ps);
      i('date', gs);
      i('filter', ov);
      i('json', lv);
      i('limitTo', av);
      i('lowercase', dh);
      i('number', ws);
      i('orderBy', nh);
      i('uppercase', gh);
    }
    function ov() {
      return function (n, t, i) {
        if (!ri(n)) {
          if (null == n) return n;
          throw l('filter')('notarray', n);
        }
        var r;
        switch (gf(t)) {
          case 'function':
            break;
          case 'boolean':
          case 'null':
          case 'number':
          case 'string':
            r = !0;
          case 'object':
            t = sv(t, i, r);
            break;
          default:
            return n;
        }
        return Array.prototype.filter.call(n, t);
      };
    }
    function sv(n, t, i) {
      var u = s(n) && '$' in n;
      return (
        !0 === t
          ? (t = st)
          : f(t) ||
            (t = function (n, t) {
              return r(n)
                ? !1
                : null === n || null === t
                ? n === t
                : s(t) || (s(n) && !ye(n))
                ? !1
                : ((n = v('' + n)), (t = v('' + t)), -1 !== n.indexOf(t));
            }),
        function (r) {
          return u && !s(r) ? si(r, n.$, t, !1) : si(r, n, t, i);
        }
      );
    }
    function si(n, t, i, u, e) {
      var s = gf(n),
        h = gf(t),
        o;
      if ('string' === h && '!' === t.charAt(0))
        return !si(n, t.substring(1), i, u);
      if (c(n))
        return n.some(function (n) {
          return si(n, t, i, u);
        });
      switch (s) {
        case 'object':
          if (u) {
            for (o in n)
              if ('$' !== o.charAt(0) && si(n[o], t, i, !0)) return !0;
            return e ? !1 : si(n, t, i, !1);
          }
          if ('object' === h) {
            for (o in t)
              if (
                ((e = t[o]),
                !f(e) &&
                  !r(e) &&
                  ((s = '$' === o), !si(s ? n : n[o], e, i, s, s)))
              )
                return !1;
            return !0;
          }
          return i(n, t);
        case 'function':
          return !1;
        default:
          return i(n, t);
      }
    }
    function gf(n) {
      return null === n ? 'null' : typeof n;
    }
    function ps(n) {
      var t = n.NUMBER_FORMATS;
      return function (n, i, u) {
        return (
          r(i) && (i = t.CURRENCY_SYM),
          r(u) && (u = t.PATTERNS[1].maxFrac),
          null == n
            ? n
            : bs(n, t.PATTERNS[1], t.GROUP_SEP, t.DECIMAL_SEP, u).replace(
                /\u00A4/g,
                i
              )
        );
      };
    }
    function ws(n) {
      var t = n.NUMBER_FORMATS;
      return function (n, i) {
        return null == n
          ? n
          : bs(n, t.PATTERNS[0], t.GROUP_SEP, t.DECIMAL_SEP, i);
      };
    }
    function hv(n) {
      var e = 0,
        r,
        t,
        i,
        f,
        u;
      for (
        -1 < (t = n.indexOf(kh)) && (n = n.replace(kh, '')),
          0 < (i = n.search(/e/i))
            ? (0 > t && (t = i),
              (t += +n.slice(i + 1)),
              (n = n.substring(0, i)))
            : 0 > t && (t = n.length),
          i = 0;
        n.charAt(i) == ce;
        i++
      );
      if (i == (u = n.length)) (r = [0]), (t = 1);
      else {
        for (u--; n.charAt(u) == ce; ) u--;
        for (t -= i, r = [], f = 0; i <= u; i++, f++) r[f] = +n.charAt(i);
      }
      return (
        t > bh && ((r = r.splice(0, bh - 1)), (e = t - 1), (t = 1)),
        { d: r, e: e, i: t }
      );
    }
    function cv(n, t, i, u) {
      var f = n.d,
        o = f.length - n.i,
        e;
      if (
        ((t = r(t) ? Math.min(Math.max(i, o), u) : +t),
        (i = t + n.i),
        (u = f[i]),
        0 < i)
      )
        for (f.splice(Math.max(n.i, i)), e = i; e < f.length; e++) f[e] = 0;
      else
        for (
          o = Math.max(0, o),
            n.i = 1,
            f.length = Math.max(1, (i = t + 1)),
            f[0] = 0,
            e = 1;
          e < i;
          e++
        )
          f[e] = 0;
      if (5 <= u)
        if (0 > i - 1) {
          for (u = 0; u > i; u--) f.unshift(0), n.i++;
          f.unshift(1);
          n.i++;
        } else f[i - 1]++;
      for (; o < Math.max(0, t); o++) f.push(0);
      (t = f.reduceRight(function (n, t, i, r) {
        return (t += n), (r[i] = t % 10), Math.floor(t / 10);
      }, 0)) && (f.unshift(t), n.i++);
    }
    function bs(n, t, i, r, u) {
      if ((!h(n) && !w(n)) || isNaN(n)) return '';
      var s = !isFinite(n),
        o = !1,
        e = Math.abs(n) + '',
        f = '';
      if (s) f = '∞';
      else {
        for (
          o = hv(e),
            cv(o, u, t.minFrac, t.maxFrac),
            f = o.d,
            e = o.i,
            u = o.e,
            s = [],
            o = f.reduce(function (n, t) {
              return n && !t;
            }, !0);
          0 > e;

        )
          f.unshift(0), e++;
        for (
          0 < e ? (s = f.splice(e, f.length)) : ((s = f), (f = [0])),
            e = [],
            f.length >= t.lgSize &&
              e.unshift(f.splice(-t.lgSize, f.length).join(''));
          f.length > t.gSize;

        )
          e.unshift(f.splice(-t.gSize, f.length).join(''));
        f.length && e.unshift(f.join(''));
        f = e.join(i);
        s.length && (f += r + s.join(''));
        u && (f += 'e+' + u);
      }
      return 0 > n && !o ? t.negPre + f + t.negSuf : t.posPre + f + t.posSuf;
    }
    function pu(n, t, i, r) {
      var u = '';
      for (
        (0 > n || (r && 0 >= n)) && (r ? (n = -n + 1) : ((n = -n), (u = '-'))),
          n = '' + n;
        n.length < t;

      )
        n = ce + n;
      return i && (n = n.substr(n.length - t)), u + n;
    }
    function d(n, t, i, r, u) {
      return (
        (i = i || 0),
        function (f) {
          return (
            (f = f['get' + n]()),
            (0 < i || f > -i) && (f += i),
            0 === f && -12 == i && (f = 12),
            pu(f, t, r, u)
          );
        }
      );
    }
    function pr(n, t, i) {
      return function (r, u) {
        var f = r['get' + n](),
          e = bu((i ? 'STANDALONE' : '') + (t ? 'SHORT' : '') + n);
        return u[e][f];
      };
    }
    function ks(n) {
      var t = new Date(n, 0, 1).getDay();
      return new Date(n, 0, (4 >= t ? 5 : 12) - t);
    }
    function ds(n) {
      return function (t) {
        var i = ks(t.getFullYear());
        return (
          (t =
            +new Date(
              t.getFullYear(),
              t.getMonth(),
              t.getDate() + (4 - t.getDay())
            ) - +i),
          (t = 1 + Math.round(t / 6048e5)),
          pu(t, n)
        );
      };
    }
    function ne(n, t) {
      return 0 >= n.getFullYear() ? t.ERAS[0] : t.ERAS[1];
    }
    function gs(n) {
      function i(n) {
        var t;
        if ((t = n.match(r))) {
          n = new Date(0);
          var i = 0,
            u = 0,
            f = t[8] ? n.setUTCFullYear : n.setFullYear,
            e = t[8] ? n.setUTCHours : n.setHours;
          t[9] && ((i = tt(t[9] + t[10])), (u = tt(t[9] + t[11])));
          f.call(n, tt(t[1]), tt(t[2]) - 1, tt(t[3]));
          i = tt(t[4] || 0) - i;
          u = tt(t[5] || 0) - u;
          f = tt(t[6] || 0);
          t = Math.round(1e3 * parseFloat('0.' + (t[7] || 0)));
          e.call(n, i, u, f, t);
        }
        return n;
      }
      var r = /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;
      return function (r, u, f) {
        var c = '',
          e = [],
          s,
          l,
          o;
        if (
          ((u = u || 'mediumDate'),
          (u = n.DATETIME_FORMATS[u] || u),
          h(r) && (r = ap.test(r) ? tt(r) : i(r)),
          w(r) && (r = new Date(r)),
          !et(r) || !isFinite(r.getTime()))
        )
          return r;
        for (; u; )
          (l = lp.exec(u))
            ? ((e = or(e, l, 1)), (u = e.pop()))
            : (e.push(u), (u = null));
        return (
          (o = r.getTimezoneOffset()),
          f && ((o = be(f, o)), (r = cf(r, f, !0))),
          t(e, function (t) {
            s = cp[t];
            c += s
              ? s(r, n.DATETIME_FORMATS, o)
              : "''" === t
              ? "'"
              : t.replace(/(^'|'$)/g, '').replace(/''/g, "'");
          }),
          c
        );
      };
    }
    function lv() {
      return function (n, t) {
        return r(t) && (t = 2), hr(n, t);
      };
    }
    function av() {
      return function (n, t, i) {
        return ((t = Infinity === Math.abs(Number(t)) ? Number(t) : tt(t)),
        isNaN(t))
          ? n
          : (w(n) && (n = n.toString()), !c(n) && !h(n))
          ? n
          : ((i = !i || isNaN(i) ? 0 : tt(i)),
            (i = 0 > i ? Math.max(0, n.length + i) : i),
            0 <= t
              ? n.slice(i, i + t)
              : 0 === i
              ? n.slice(t, n.length)
              : n.slice(Math.max(0, i + t), i));
      };
    }
    function nh(n) {
      function i(t, i) {
        return (
          (i = i ? -1 : 1),
          t.map(function (t) {
            var u = 1,
              r = ir,
              e;
            return (
              f(t)
                ? (r = t)
                : h(t) &&
                  (('+' == t.charAt(0) || '-' == t.charAt(0)) &&
                    ((u = '-' == t.charAt(0) ? -1 : 1), (t = t.substring(1))),
                  '' !== t &&
                    ((r = n(t)), r.constant) &&
                    ((e = r()),
                    (r = function (n) {
                      return n[e];
                    }))),
              { get: r, descending: u * i }
            );
          })
        );
      }
      function t(n) {
        switch (typeof n) {
          case 'number':
          case 'boolean':
          case 'string':
            return !0;
          default:
            return !1;
        }
      }
      return function (n, r, u) {
        if (null == n) return n;
        if (!ri(n)) throw l('orderBy')('notarray', n);
        c(r) || (r = [r]);
        0 === r.length && (r = ['+']);
        var f = i(r, u);
        return (
          f.push({
            get: function () {
              return {};
            },
            descending: u ? -1 : 1,
          }),
          (n = Array.prototype.map.call(n, function (n, i) {
            return {
              value: n,
              predicateValues: f.map(function (r) {
                var u = r.get(n);
                if (((r = typeof u), null === u)) (r = 'string'), (u = 'null');
                else if ('string' === r) u = u.toLowerCase();
                else if ('object' === r)
                  n: {
                    if (
                      'function' == typeof u.valueOf &&
                      ((u = u.valueOf()), t(u))
                    )
                      break n;
                    if (ye(u) && ((u = u.toString()), t(u))) break n;
                    u = i;
                  }
                return { value: u, type: r };
              }),
            };
          })),
          n.sort(function (n, t) {
            for (var i = 0, r = 0, o = f.length; r < o; ++r) {
              var i = n.predicateValues[r],
                u = t.predicateValues[r],
                e = 0;
              if (
                (i.type === u.type
                  ? i.value !== u.value && (e = i.value < u.value ? -1 : 1)
                  : (e = i.type < u.type ? -1 : 1),
                (i = e * f[r].descending))
              )
                break;
            }
            return i;
          }),
          n.map(function (n) {
            return n.value;
          })
        );
      };
    }
    function hi(n) {
      return (
        f(n) && (n = { link: n }), (n.restrict = n.restrict || 'AC'), ft(n)
      );
    }
    function th(n, i, r, u, f) {
      var e = this,
        o = [];
      e.$error = {};
      e.$$success = {};
      e.$pending = void 0;
      e.$name = f(i.name || i.ngForm || '')(r);
      e.$dirty = !1;
      e.$pristine = !0;
      e.$valid = !0;
      e.$invalid = !1;
      e.$submitted = !1;
      e.$$parentForm = dr;
      e.$rollbackViewValue = function () {
        t(o, function (n) {
          n.$rollbackViewValue();
        });
      };
      e.$commitViewValue = function () {
        t(o, function (n) {
          n.$commitViewValue();
        });
      };
      e.$addControl = function (n) {
        pi(n.$name, 'input');
        o.push(n);
        n.$name && (e[n.$name] = n);
        n.$$parentForm = e;
      };
      e.$$renameControl = function (n, t) {
        var i = n.$name;
        e[i] === n && delete e[i];
        e[t] = n;
        n.$name = t;
      };
      e.$removeControl = function (n) {
        n.$name && e[n.$name] === n && delete e[n.$name];
        t(e.$pending, function (t, i) {
          e.$setValidity(i, null, n);
        });
        t(e.$error, function (t, i) {
          e.$setValidity(i, null, n);
        });
        t(e.$$success, function (t, i) {
          e.$setValidity(i, null, n);
        });
        er(o, n);
        n.$$parentForm = dr;
      };
      uh({
        ctrl: this,
        $element: n,
        set: function (n, t, i) {
          var r = n[t];
          r ? -1 === r.indexOf(i) && r.push(i) : (n[t] = [i]);
        },
        unset: function (n, t, i) {
          var r = n[t];
          r && (er(r, i), 0 === r.length && delete n[t]);
        },
        $animate: u,
      });
      e.$setDirty = function () {
        u.removeClass(n, tr);
        u.addClass(n, ef);
        e.$dirty = !0;
        e.$pristine = !1;
        e.$$parentForm.$setDirty();
      };
      e.$setPristine = function () {
        u.setClass(n, tr, ef + ' ng-submitted');
        e.$dirty = !1;
        e.$pristine = !0;
        e.$submitted = !1;
        t(o, function (n) {
          n.$setPristine();
        });
      };
      e.$setUntouched = function () {
        t(o, function (n) {
          n.$setUntouched();
        });
      };
      e.$setSubmitted = function () {
        u.addClass(n, 'ng-submitted');
        e.$submitted = !0;
        e.$$parentForm.$setSubmitted();
      };
    }
    function te(n) {
      n.$formatters.push(function (t) {
        return n.$isEmpty(t) ? t : t.toString();
      });
    }
    function wr(n, t, i, r, u, f) {
      var h = v(t[0].type),
        s,
        e,
        o,
        c;
      if (!u.android) {
        s = !1;
        t.on('compositionstart', function () {
          s = !0;
        });
        t.on('compositionend', function () {
          s = !1;
          o();
        });
      }
      if (
        ((o = function (n) {
          if ((e && (f.defer.cancel(e), (e = null)), !s)) {
            var u = t.val();
            n = n && n.type;
            'password' === h ||
              (i.ngTrim && 'false' === i.ngTrim) ||
              (u = p(u));
            (r.$viewValue !== u || ('' === u && r.$$hasNativeValidators)) &&
              r.$setViewValue(u, n);
          }
        }),
        u.hasEvent('input'))
      )
        t.on('input', o);
      else {
        c = function (n, t, i) {
          e ||
            (e = f.defer(function () {
              e = null;
              (t && t.value === i) || o(n);
            }));
        };
        t.on('keydown', function (n) {
          var t = n.keyCode;
          91 === t ||
            (15 < t && 19 > t) ||
            (37 <= t && 40 >= t) ||
            c(n, this, this.value);
        });
        if (u.hasEvent('paste')) t.on('paste cut', c);
      }
      t.on('change', o);
      if (ec[h] && r.$$hasNativeValidators && h === i.type)
        t.on('keydown wheel mousedown', function (n) {
          if (!e) {
            var t = this.validity,
              i = t.badInput,
              r = t.typeMismatch;
            e = f.defer(function () {
              e = null;
              (t.badInput === i && t.typeMismatch === r) || o(n);
            });
          }
        });
      r.$render = function () {
        var n = r.$isEmpty(r.$viewValue) ? '' : r.$viewValue;
        t.val() !== n && t.val(n);
      };
    }
    function wu(n, i) {
      return function (r, u) {
        var e, f;
        if (et(r)) return r;
        if (h(r)) {
          if (
            ('"' == r.charAt(0) &&
              '"' == r.charAt(r.length - 1) &&
              (r = r.substring(1, r.length - 1)),
            pp.test(r))
          )
            return new Date(r);
          if (((n.lastIndex = 0), (e = n.exec(r))))
            return (
              e.shift(),
              (f = u
                ? {
                    yyyy: u.getFullYear(),
                    MM: u.getMonth() + 1,
                    dd: u.getDate(),
                    HH: u.getHours(),
                    mm: u.getMinutes(),
                    ss: u.getSeconds(),
                    sss: u.getMilliseconds() / 1e3,
                  }
                : { yyyy: 1970, MM: 1, dd: 1, HH: 0, mm: 0, ss: 0, sss: 0 }),
              t(e, function (n, t) {
                t < i.length && (f[i[t]] = +n);
              }),
              new Date(
                f.yyyy,
                f.MM - 1,
                f.dd,
                f.HH,
                f.mm,
                f.ss || 0,
                1e3 * f.sss || 0
              )
            );
        }
        return NaN;
      };
    }
    function br(n, t, i, f) {
      return function (e, o, s, h, c, l, a) {
        function p(n) {
          return n && !(n.getTime && n.getTime() !== n.getTime());
        }
        function k(n) {
          return u(n) && !et(n) ? i(n) || void 0 : n;
        }
        var v, y, w, b;
        ih(e, o, s, h);
        wr(e, o, s, h, c, l);
        v = h && h.$options && h.$options.timezone;
        h.$$parserName = n;
        h.$parsers.push(function (n) {
          return h.$isEmpty(n)
            ? null
            : t.test(n)
            ? ((n = i(n, y)), v && (n = cf(n, v)), n)
            : void 0;
        });
        h.$formatters.push(function (n) {
          if (n && !et(n)) throw nu('datefmt', n);
          return p(n)
            ? ((y = n) && v && (y = cf(y, v, !0)), a('date')(n, f, v))
            : ((y = null), '');
        });
        (u(s.min) || s.ngMin) &&
          ((h.$validators.min = function (n) {
            return !p(n) || r(w) || i(n) >= w;
          }),
          s.$observe('min', function (n) {
            w = k(n);
            h.$validate();
          }));
        (u(s.max) || s.ngMax) &&
          ((h.$validators.max = function (n) {
            return !p(n) || r(b) || i(n) <= b;
          }),
          s.$observe('max', function (n) {
            b = k(n);
            h.$validate();
          }));
      };
    }
    function ih(n, t, i, r) {
      (r.$$hasNativeValidators = s(t[0].validity)) &&
        r.$parsers.push(function (n) {
          var i = t.prop('validity') || {};
          if (!i.badInput && !i.typeMismatch) return n;
        });
    }
    function rh(n, t, i, r, f) {
      if (u(r)) {
        if (((n = n(r)), !n.constant)) throw nu('constexpr', i, r);
        return n(t);
      }
      return f;
    }
    function ie(n, i) {
      return (
        (n = 'ngClass' + n),
        [
          '$animate',
          function (r) {
            function f(n, t) {
              var f = [],
                r = 0,
                u,
                i;
              n: for (; r < n.length; r++) {
                for (u = n[r], i = 0; i < t.length; i++)
                  if (u == t[i]) continue n;
                f.push(u);
              }
              return f;
            }
            function u(n) {
              var i = [];
              return c(n)
                ? (t(n, function (n) {
                    i = i.concat(u(n));
                  }),
                  i)
                : h(n)
                ? n.split(' ')
                : s(n)
                ? (t(n, function (n, t) {
                    n && (i = i.concat(t.split(' ')));
                  }),
                  i)
                : n;
            }
            return {
              restrict: 'AC',
              link: function (e, o, s) {
                function a(n) {
                  n = h(n, 1);
                  s.$addClass(n);
                }
                function h(n, i) {
                  var r = o.data('$classCounts') || y(),
                    u = [];
                  return (
                    t(n, function (n) {
                      (0 < i || r[n]) &&
                        ((r[n] = (r[n] || 0) + i),
                        r[n] === +(0 < i) && u.push(n));
                    }),
                    o.data('$classCounts', r),
                    u.join(' ')
                  );
                }
                function p(n, t) {
                  var i = f(t, n),
                    u = f(n, t),
                    i = h(i, 1),
                    u = h(u, -1);
                  i && i.length && r.addClass(o, i);
                  u && u.length && r.removeClass(o, u);
                }
                function v(n) {
                  var t, r;
                  (!0 === i || (e.$index & 1) === i) &&
                    ((t = u(n || [])),
                    l ? st(n, l) || ((r = u(l)), p(r, t)) : a(t));
                  l = c(n)
                    ? n.map(function (n) {
                        return ot(n);
                      })
                    : ot(n);
                }
                var l;
                e.$watch(s[n], v, !0);
                s.$observe('class', function () {
                  v(e.$eval(s[n]));
                });
                'ngClass' !== n &&
                  e.$watch('$index', function (t, r) {
                    var f = t & 1,
                      o;
                    f !== (r & 1) &&
                      ((o = u(e.$eval(s[n]))),
                      f === i ? a(o) : ((f = h(o, -1)), s.$removeClass(f)));
                  });
              },
            };
          },
        ]
      );
    }
    function uh(n) {
      function f(n, t) {
        t && !i[n]
          ? (h.addClass(o, n), (i[n] = !0))
          : !t && i[n] && (h.removeClass(o, n), (i[n] = !1));
      }
      function e(n, t) {
        n = n ? '-' + no(n, '-') : '';
        f(gr + n, !0 === t);
        f(cc + n, !1 === t);
      }
      var t = n.ctrl,
        o = n.$element,
        i = {},
        s = n.set,
        u = n.unset,
        h = n.$animate;
      i[cc] = !(i[gr] = o.hasClass(gr));
      t.$setValidity = function (n, i, o) {
        r(i)
          ? (t.$pending || (t.$pending = {}), s(t.$pending, n, o))
          : (t.$pending && u(t.$pending, n, o),
            fh(t.$pending) && (t.$pending = void 0));
        ui(i)
          ? i
            ? (u(t.$error, n, o), s(t.$$success, n, o))
            : (s(t.$error, n, o), u(t.$$success, n, o))
          : (u(t.$error, n, o), u(t.$$success, n, o));
        t.$pending
          ? (f(lc, !0), (t.$valid = t.$invalid = void 0), e('', null))
          : (f(lc, !1),
            (t.$valid = fh(t.$error)),
            (t.$invalid = !t.$valid),
            e('', t.$valid));
        i =
          t.$pending && t.$pending[n]
            ? void 0
            : t.$error[n]
            ? !1
            : t.$$success[n]
            ? !0
            : null;
        e(n, i);
        t.$$parentForm.$setValidity(n, i, t);
      };
    }
    function fh(n) {
      if (n) for (var t in n) if (n.hasOwnProperty(t)) return !1;
      return !0;
    }
    var vv = /^\/(.+)\/([a-z]*)$/,
      wt = Object.prototype.hasOwnProperty,
      v = function (n) {
        return h(n) ? n.toLowerCase() : n;
      },
      bu = function (n) {
        return h(n) ? n.toUpperCase() : n;
      },
      ni,
      e,
      ti,
      bt = [].slice,
      yv = [].splice,
      pv = [].push,
      rt = Object.prototype.toString,
      eh = Object.getPrototypeOf,
      ci = l('ng'),
      ut = n.angular || (n.angular = {}),
      re,
      ku = 0,
      gi,
      hh,
      ee,
      oe,
      ph,
      uf,
      i,
      ff,
      dh,
      gh,
      nc,
      kr,
      dr;
    ni = n.document.documentMode;
    o.$inject = [];
    ir.$inject = [];
    var c = Array.isArray,
      wv = /^\[object (?:Uint8|Uint8Clamped|Uint16|Uint32|Int8|Int16|Int32|Float32|Float64)Array\]$/,
      p = function (n) {
        return h(n) ? n.trim() : n;
      },
      oh = function (n) {
        return n
          .replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, '\\$1')
          .replace(/\x08/g, '\\x08');
      },
      li = function () {
        var i, t;
        if (!u(li.rules))
          if (
            ((i =
              n.document.querySelector('[ng-csp]') ||
              n.document.querySelector('[data-ng-csp]')),
            i)
          )
            (t = i.getAttribute('ng-csp') || i.getAttribute('data-ng-csp')),
              (li.rules = {
                noUnsafeEval: !t || -1 !== t.indexOf('no-unsafe-eval'),
                noInlineStyle: !t || -1 !== t.indexOf('no-inline-style'),
              });
          else {
            i = li;
            try {
              new Function('');
              t = !1;
            } catch (r) {
              t = !0;
            }
            i.rules = { noUnsafeEval: t, noInlineStyle: !1 };
          }
        return li.rules;
      },
      du = function () {
        if (u(du.name_)) return du.name_;
        for (var r, e = ki.length, i, f, t = 0; t < e; ++t)
          if (
            ((i = ki[t]),
            (r = n.document.querySelector('[' + i.replace(':', '\\:') + 'jq]')))
          ) {
            f = r.getAttribute(i + 'jq');
            break;
          }
        return (du.name_ = f);
      },
      bv = /:/g,
      ki = ['ng-', 'data-ng-', 'ng:', 'x-ng-'],
      kv = /[A-Z]/g,
      sh = !1,
      di = 3,
      dv = {
        full: '1.5.6',
        major: 1,
        minor: 5,
        dot: 6,
        codeName: 'arrow-stringification',
      };
    b.expando = 'ng339';
    gi = b.cache = {};
    hh = 1;
    b._data = function (n) {
      return this.cache[n[this.expando]] || {};
    };
    var gv = /([\:\-\_]+(.))/g,
      ny = /^moz([A-Z])/,
      gu = { mouseleave: 'mouseout', mouseenter: 'mouseover' },
      ue = l('jqLite'),
      ty = /^<([\w-]+)\s*\/?>(?:<\/\1>|)$/,
      fe = /<|&#?\w+;/,
      iy = /<([\w:-]+)/,
      ry = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([\w:-]+)[^>]*)\/>/gi,
      ct = {
        option: [1, '<select multiple="multiple">', '</select>'],
        thead: [1, '<table>', '</table>'],
        col: [2, '<table><colgroup>', '</colgroup></table>'],
        tr: [2, '<table><tbody>', '</tbody></table>'],
        td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
        _default: [0, '', ''],
      };
    ct.optgroup = ct.option;
    ct.tbody = ct.tfoot = ct.colgroup = ct.caption = ct.thead;
    ct.th = ct.td;
    var uy =
        n.Node.prototype.contains ||
        function (n) {
          return !!(this.compareDocumentPosition(n) & 16);
        },
      nr = (b.prototype = {
        ready: function (t) {
          function i() {
            r || ((r = !0), t());
          }
          var r = !1;
          'complete' === n.document.readyState
            ? n.setTimeout(i)
            : (this.on('DOMContentLoaded', i), b(n).on('load', i));
        },
        toString: function () {
          var n = [];
          return (
            t(this, function (t) {
              n.push('' + t);
            }),
            '[' + n.join(', ') + ']'
          );
        },
        eq: function (n) {
          return 0 <= n ? e(this[n]) : e(this[this.length + n]);
        },
        length: 0,
        push: pv,
        sort: [].sort,
        splice: [].splice,
      }),
      nf = {};
    t(
      'multiple selected checked disabled readOnly required open'.split(' '),
      function (n) {
        nf[v(n)] = n;
      }
    );
    ee = {};
    t(
      'input select option textarea button form details'.split(' '),
      function (n) {
        ee[n] = !0;
      }
    );
    oe = {
      ngMinlength: 'minlength',
      ngMaxlength: 'maxlength',
      ngMin: 'min',
      ngMax: 'max',
      ngPattern: 'pattern',
    };
    t(
      {
        data: vf,
        removeData: lr,
        hasData: function (n) {
          for (var t in gi[n.ng339]) return !0;
          return !1;
        },
        cleanData: function (n) {
          for (var t = 0, i = n.length; t < i; t++) lr(n[t]);
        },
      },
      function (n, t) {
        b[t] = n;
      }
    );
    t(
      {
        data: vf,
        inheritedData: hu,
        scope: function (n) {
          return (
            e.data(n, '$scope') ||
            hu(n.parentNode || n, ['$isolateScope', '$scope'])
          );
        },
        isolateScope: function (n) {
          return (
            e.data(n, '$isolateScope') || e.data(n, '$isolateScopeNoTemplate')
          );
        },
        controller: oo,
        injector: function (n) {
          return hu(n, '$injector');
        },
        removeAttr: function (n, t) {
          n.removeAttribute(t);
        },
        hasClass: eu,
        css: function (n, t, i) {
          if (((t = cr(t)), u(i))) n.style[t] = i;
          else return n.style[t];
        },
        attr: function (n, t, i) {
          var r = n.nodeType;
          if (r !== di && 2 !== r && 8 !== r)
            if (((r = v(t)), nf[r]))
              if (u(i))
                i
                  ? ((n[t] = !0), n.setAttribute(t, r))
                  : ((n[t] = !1), n.removeAttribute(r));
              else
                return n[t] || (n.attributes.getNamedItem(t) || o).specified
                  ? r
                  : void 0;
            else if (u(i)) n.setAttribute(t, i);
            else if (n.getAttribute)
              return (n = n.getAttribute(t, 2)), null === n ? void 0 : n;
        },
        prop: function (n, t, i) {
          if (u(i)) n[t] = i;
          else return n[t];
        },
        text: (function () {
          function n(n, t) {
            if (r(t)) {
              var i = n.nodeType;
              return 1 === i || i === di ? n.textContent : '';
            }
            n.textContent = t;
          }
          return (n.$dv = ''), n;
        })(),
        val: function (n, i) {
          if (r(i)) {
            if (n.multiple && 'select' === at(n)) {
              var u = [];
              return (
                t(n.options, function (n) {
                  n.selected && u.push(n.value || n.text);
                }),
                0 === u.length ? null : u
              );
            }
            return n.value;
          }
          n.value = i;
        },
        html: function (n, t) {
          if (r(t)) return n.innerHTML;
          uu(n, !0);
          n.innerHTML = t;
        },
        empty: so,
      },
      function (n, t) {
        b.prototype[t] = function (t, i) {
          var u,
            f,
            e = this.length,
            o;
          if (n !== so && r(2 == n.length && n !== eu && n !== oo ? t : i)) {
            if (s(t)) {
              for (u = 0; u < e; u++)
                if (n === vf) n(this[u], t);
                else for (f in t) n(this[u], f, t[f]);
              return this;
            }
            for (u = n.$dv, e = r(u) ? Math.min(e, 1) : e, f = 0; f < e; f++)
              (o = n(this[f], t, i)), (u = u ? u + o : o);
            return u;
          }
          for (u = 0; u < e; u++) n(this[u], t, i);
          return this;
        };
      }
    );
    t(
      {
        removeData: lr,
        on: function (n, t, i, r) {
          var f, e, s, o;
          if (u(r)) throw ue('onargs');
          if (io(n))
            for (
              r = fu(n, !0),
                f = r.events,
                e = r.handle,
                e || (e = r.handle = sl(n, f)),
                r = 0 <= t.indexOf(' ') ? t.split(' ') : [t],
                s = r.length,
                o = function (t, r, u) {
                  var o = f[t];
                  o ||
                    ((o = f[t] = []),
                    (o.specialHandlerWrapper = r),
                    '$destroy' === t || u || n.addEventListener(t, e, !1));
                  o.push(i);
                };
              s--;

            )
              (t = r[s]), gu[t] ? (o(gu[t], cl), o(t, void 0, !0)) : o(t);
        },
        off: fo,
        one: function (n, t, i) {
          n = e(n);
          n.on(t, function r() {
            n.off(t, i);
            n.off(t, r);
          });
          n.on(t, i);
        },
        replaceWith: function (n, i) {
          var r,
            u = n.parentNode;
          uu(n);
          t(new b(i), function (t) {
            r ? u.insertBefore(t, r.nextSibling) : u.replaceChild(t, n);
            r = t;
          });
        },
        children: function (n) {
          var i = [];
          return (
            t(n.childNodes, function (n) {
              1 === n.nodeType && i.push(n);
            }),
            i
          );
        },
        contents: function (n) {
          return n.contentDocument || n.childNodes || [];
        },
        append: function (n, t) {
          var i = n.nodeType,
            r;
          if (1 === i || 11 === i)
            for (t = new b(t), i = 0, r = t.length; i < r; i++)
              n.appendChild(t[i]);
        },
        prepend: function (n, i) {
          if (1 === n.nodeType) {
            var r = n.firstChild;
            t(new b(i), function (t) {
              n.insertBefore(t, r);
            });
          }
        },
        wrap: function (n, t) {
          uo(n, e(t).eq(0).clone()[0]);
        },
        remove: cu,
        detach: function (n) {
          cu(n, !0);
        },
        after: function (n, t) {
          var u = n,
            e = n.parentNode,
            i,
            f,
            r;
          for (t = new b(t), i = 0, f = t.length; i < f; i++)
            (r = t[i]), e.insertBefore(r, u.nextSibling), (u = r);
        },
        addClass: su,
        removeClass: ou,
        toggleClass: function (n, i, u) {
          i &&
            t(i.split(' '), function (t) {
              var i = u;
              r(i) && (i = !eu(n, t));
              (i ? su : ou)(n, t);
            });
        },
        parent: function (n) {
          return (n = n.parentNode) && 11 !== n.nodeType ? n : null;
        },
        next: function (n) {
          return n.nextElementSibling;
        },
        find: function (n, t) {
          return n.getElementsByTagName ? n.getElementsByTagName(t) : [];
        },
        clone: af,
        triggerHandler: function (n, i, r) {
          var u,
            e,
            s = i.type || i,
            f = fu(n);
          (f = (f = f && f.events) && f[s]) &&
            ((u = {
              preventDefault: function () {
                this.defaultPrevented = !0;
              },
              isDefaultPrevented: function () {
                return !0 === this.defaultPrevented;
              },
              stopImmediatePropagation: function () {
                this.immediatePropagationStopped = !0;
              },
              isImmediatePropagationStopped: function () {
                return !0 === this.immediatePropagationStopped;
              },
              stopPropagation: o,
              type: s,
              target: n,
            }),
            i.type && (u = a(u, i)),
            (i = ot(f)),
            (e = r ? [u].concat(r) : [u]),
            t(i, function (t) {
              u.isImmediatePropagationStopped() || t.apply(n, e);
            }));
        },
      },
      function (n, t) {
        b.prototype[t] = function (t, i, f) {
          for (var o, s = 0, h = this.length; s < h; s++)
            r(o)
              ? ((o = n(this[s], t, i, f)), u(o) && (o = e(o)))
              : eo(o, n(this[s], t, i, f));
          return u(o) ? o : this;
        };
        b.prototype.bind = b.prototype.on;
        b.prototype.unbind = b.prototype.off;
      }
    );
    wi.prototype = {
      put: function (n, t) {
        this[fi(n, this.nextUid)] = t;
      },
      get: function (n) {
        return this[fi(n, this.nextUid)];
      },
      remove: function (n) {
        var t = this[(n = fi(n, this.nextUid))];
        return delete this[n], t;
      },
    };
    var fy = [
        function () {
          this.$get = [
            function () {
              return wi;
            },
          ];
        },
      ],
      ey = /^([^\(]+?)=>/,
      oy = /^[^\(]*\(\s*([^\)]*)\)/m,
      sy = /,/,
      hy = /^\s*(_?)(\S+?)\1\s*$/,
      cy = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm,
      ai = l('$injector');
    ar.$$annotate = function (n, i, r) {
      var u;
      if ('function' == typeof n) {
        if (!(u = n.$inject)) {
          if (((u = []), n.length)) {
            if (i)
              throw ((h(r) && r) || (r = n.name || al(n)), ai('strictdi', r));
            i = co(n);
            t(i[1].split(sy), function (n) {
              n.replace(hy, function (n, t, i) {
                u.push(i);
              });
            });
          }
          n.$inject = u;
        }
      } else
        c(n)
          ? ((i = n.length - 1), yi(n[i], 'fn'), (u = n.slice(0, i)))
          : yi(n, 'fn', !0);
      return u;
    };
    var ch = l('$animate'),
      ly = function () {
        this.$get = o;
      },
      ay = function () {
        var n = new wi(),
          i = [];
        this.$get = [
          '$$AnimateRunner',
          '$rootScope',
          function (r, u) {
            function f(n, i, r) {
              var u = !1;
              return (
                i &&
                  ((i = h(i) ? i.split(' ') : c(i) ? i : []),
                  t(i, function (t) {
                    t && ((u = !0), (n[t] = r));
                  })),
                u
              );
            }
            function e() {
              t(i, function (i) {
                var f = n.get(i);
                if (f) {
                  var e = yl(i.attr('class')),
                    r = '',
                    u = '';
                  t(f, function (n, t) {
                    n !== !!e[t] &&
                      (n
                        ? (r += (r.length ? ' ' : '') + t)
                        : (u += (u.length ? ' ' : '') + t));
                  });
                  t(i, function (n) {
                    r && su(n, r);
                    u && ou(n, u);
                  });
                  n.remove(i);
                }
              });
              i.length = 0;
            }
            return {
              enabled: o,
              on: o,
              off: o,
              pin: o,
              push: function (t, o, s, h) {
                return (
                  h && h(),
                  (s = s || {}),
                  s.from && t.css(s.from),
                  s.to && t.css(s.to),
                  (s.addClass || s.removeClass) &&
                    ((o = s.addClass),
                    (h = s.removeClass),
                    (s = n.get(t) || {}),
                    (o = f(s, o, !0)),
                    (h = f(s, h, !1)),
                    o || h) &&
                    (n.put(t, s),
                    i.push(t),
                    1 === i.length && u.$$postDigest(e)),
                  (t = new r()),
                  t.complete(),
                  t
                );
              },
            };
          },
        ];
      },
      vy = [
        '$provide',
        function (n) {
          var t = this;
          this.$$registeredAnimations = Object.create(null);
          this.register = function (i, r) {
            if (i && '.' !== i.charAt(0)) throw ch('notcsel', i);
            var u = i + '-animation';
            t.$$registeredAnimations[i.substr(1)] = u;
            n.factory(u, r);
          };
          this.classNameFilter = function (n) {
            if (
              1 === arguments.length &&
              (this.$$classNameFilter = n instanceof RegExp ? n : null) &&
              /(\s+|\/)ng-animate(\s+|\/)/.test(
                this.$$classNameFilter.toString()
              )
            )
              throw ch('nongcls', 'ng-animate');
            return this.$$classNameFilter;
          };
          this.$get = [
            '$$animateQueue',
            function (n) {
              function t(n, t, i) {
                var r, u;
                if (i) {
                  n: {
                    for (r = 0; r < i.length; r++)
                      if (((u = i[r]), 1 === u.nodeType)) {
                        r = u;
                        break n;
                      }
                    r = void 0;
                  }
                  !r || r.parentNode || r.previousElementSibling || (i = null);
                }
                i ? i.after(n) : t.prepend(n);
              }
              return {
                on: n.on,
                off: n.off,
                pin: n.pin,
                enabled: n.enabled,
                cancel: function (n) {
                  n.end && n.end();
                },
                enter: function (i, r, u, f) {
                  return (
                    (r = r && e(r)),
                    (u = u && e(u)),
                    (r = r || u.parent()),
                    t(i, r, u),
                    n.push(i, 'enter', ei(f))
                  );
                },
                move: function (i, r, u, f) {
                  return (
                    (r = r && e(r)),
                    (u = u && e(u)),
                    (r = r || u.parent()),
                    t(i, r, u),
                    n.push(i, 'move', ei(f))
                  );
                },
                leave: function (t, i) {
                  return n.push(t, 'leave', ei(i), function () {
                    t.remove();
                  });
                },
                addClass: function (t, i, r) {
                  return (
                    (r = ei(r)),
                    (r.addClass = vr(r.addclass, i)),
                    n.push(t, 'addClass', r)
                  );
                },
                removeClass: function (t, i, r) {
                  return (
                    (r = ei(r)),
                    (r.removeClass = vr(r.removeClass, i)),
                    n.push(t, 'removeClass', r)
                  );
                },
                setClass: function (t, i, r, u) {
                  return (
                    (u = ei(u)),
                    (u.addClass = vr(u.addClass, i)),
                    (u.removeClass = vr(u.removeClass, r)),
                    n.push(t, 'setClass', u)
                  );
                },
                animate: function (t, i, r, u, f) {
                  return (
                    (f = ei(f)),
                    (f.from = f.from ? a(f.from, i) : i),
                    (f.to = f.to ? a(f.to, r) : r),
                    (f.tempClasses = vr(
                      f.tempClasses,
                      u || 'ng-inline-animate'
                    )),
                    n.push(t, 'animate', f)
                  );
                },
              };
            },
          ];
        },
      ],
      yy = function () {
        this.$get = [
          '$$rAF',
          function (n) {
            function i(i) {
              t.push(i);
              1 < t.length ||
                n(function () {
                  for (var n = 0; n < t.length; n++) t[n]();
                  t = [];
                });
            }
            var t = [];
            return function () {
              var n = !1;
              return (
                i(function () {
                  n = !0;
                }),
                function (t) {
                  n ? t() : i(t);
                }
              );
            };
          },
        ];
      },
      py = function () {
        this.$get = [
          '$q',
          '$sniffer',
          '$$animateAsyncRun',
          '$document',
          '$timeout',
          function (n, i, r, u, f) {
            function e(n) {
              this.setHost(n);
              var t = r();
              this._doneCallbacks = [];
              this._tick = function (n) {
                var i = u[0];
                i && i.hidden ? f(n, 0, !1) : t(n);
              };
              this._state = 0;
            }
            return (
              (e.chain = function (n, t) {
                function r() {
                  i === n.length
                    ? t(!0)
                    : n[i](function (n) {
                        !1 === n ? t(!1) : (i++, r());
                      });
                }
                var i = 0;
                r();
              }),
              (e.all = function (n, i) {
                function u(t) {
                  r = r && t;
                  ++f === n.length && i(r);
                }
                var f = 0,
                  r = !0;
                t(n, function (n) {
                  n.done(u);
                });
              }),
              (e.prototype = {
                setHost: function (n) {
                  this.host = n || {};
                },
                done: function (n) {
                  2 === this._state ? n() : this._doneCallbacks.push(n);
                },
                progress: o,
                getPromise: function () {
                  if (!this.promise) {
                    var t = this;
                    this.promise = n(function (n, i) {
                      t.done(function (t) {
                        !1 === t ? i() : n();
                      });
                    });
                  }
                  return this.promise;
                },
                then: function (n, t) {
                  return this.getPromise().then(n, t);
                },
                catch: function (n) {
                  return this.getPromise()['catch'](n);
                },
                finally: function (n) {
                  return this.getPromise()['finally'](n);
                },
                pause: function () {
                  this.host.pause && this.host.pause();
                },
                resume: function () {
                  this.host.resume && this.host.resume();
                },
                end: function () {
                  this.host.end && this.host.end();
                  this._resolve(!0);
                },
                cancel: function () {
                  this.host.cancel && this.host.cancel();
                  this._resolve(!1);
                },
                complete: function (n) {
                  var t = this;
                  0 === t._state &&
                    ((t._state = 1),
                    t._tick(function () {
                      t._resolve(n);
                    }));
                },
                _resolve: function (n) {
                  2 !== this._state &&
                    (t(this._doneCallbacks, function (t) {
                      t(n);
                    }),
                    (this._doneCallbacks.length = 0),
                    (this._state = 2));
                },
              }),
              e
            );
          },
        ];
      },
      wy = function () {
        this.$get = [
          '$$rAF',
          '$q',
          '$$AnimateRunner',
          function (n, t, i) {
            return function (t, r) {
              function e() {
                return (
                  n(function () {
                    u.addClass && (t.addClass(u.addClass), (u.addClass = null));
                    u.removeClass &&
                      (t.removeClass(u.removeClass), (u.removeClass = null));
                    u.to && (t.css(u.to), (u.to = null));
                    o || f.complete();
                    o = !0;
                  }),
                  f
                );
              }
              var u = r || {},
                o,
                f;
              return (
                u.$$prepared || (u = kt(u)),
                u.cleanupStyles && (u.from = u.to = null),
                u.from && (t.css(u.from), (u.from = null)),
                (f = new i()),
                { start: e, end: e }
              );
            };
          },
        ];
      },
      g = l('$compile'),
      se = new (function () {})();
    lo.$inject = ['$provide', '$$sanitizeUriProvider'];
    lu.prototype.isFirstChange = function () {
      return this.previousValue === se;
    };
    var lh = /^((?:x|data)[\:\-_])/i,
      by = l('$controller'),
      ah = /^(\S+)(\s+as\s+([\w$]+))?$/,
      ky = function () {
        this.$get = [
          '$document',
          function (n) {
            return function (t) {
              return (
                t
                  ? !t.nodeType && t instanceof e && (t = t[0])
                  : (t = n[0].body),
                t.offsetWidth + 1
              );
            };
          },
        ];
      },
      vh = 'application/json',
      he = { 'Content-Type': vh + ';charset=utf-8' },
      dy = /^\[|^\{(?!\{)/,
      gy = { '[': /]$/, '{': /}$/ },
      np = /^\)\]\}',?\n/,
      tp = l('$http'),
      yh = function (n) {
        return function () {
          throw tp('legacy', n);
        };
      },
      vi = (ut.$interpolateMinErr = l('$interpolate'));
    vi.throwNoconcat = function (n) {
      throw vi('noconcat', n);
    };
    vi.interr = function (n, t) {
      return vi('interr', n, t.toString());
    };
    var ip = /^([^\?#]*)(\?([^#]*))?(#(.*))?$/,
      rp = { http: 80, https: 443, ftp: 21 },
      tf = l('$location'),
      up = {
        $$html5: !1,
        $$replace: !1,
        absUrl: au('$$absUrl'),
        url: function (n) {
          if (r(n)) return this.$$url;
          var t = ip.exec(n);
          return (
            (t[1] || '' === n) && this.path(decodeURIComponent(t[1])),
            (t[2] || t[1] || '' === n) && this.search(t[3] || ''),
            this.hash(t[5] || ''),
            this
          );
        },
        protocol: au('$$protocol'),
        host: au('$$host'),
        port: au('$$port'),
        path: ts('$$path', function (n) {
          return (
            (n = null !== n ? n.toString() : ''),
            '/' == n.charAt(0) ? n : '/' + n
          );
        }),
        search: function (n, i) {
          switch (arguments.length) {
            case 0:
              return this.$$search;
            case 1:
              if (h(n) || w(n)) (n = n.toString()), (this.$$search = de(n));
              else if (s(n))
                (n = kt(n, {})),
                  t(n, function (t, i) {
                    null == t && delete n[i];
                  }),
                  (this.$$search = n);
              else throw tf('isrcharg');
              break;
            default:
              r(i) || null === i
                ? delete this.$$search[n]
                : (this.$$search[n] = i);
          }
          return this.$$compose(), this;
        },
        hash: ts('$$hash', function (n) {
          return null !== n ? n.toString() : '';
        }),
        replace: function () {
          return (this.$$replace = !0), this;
        },
      };
    t([ns, kf, bf], function (n) {
      n.prototype = Object.create(up);
      n.prototype.state = function (t) {
        if (!arguments.length) return this.$$state;
        if (n !== bf || !this.$$html5) throw tf('nostate');
        return (this.$$state = r(t) ? null : t), this;
      };
    });
    var it = l('$parse'),
      fp = Function.prototype.call,
      ep = Function.prototype.apply,
      op = Function.prototype.bind,
      rf = y();
    t('+ - * / % === !== == != < > <= >= && || ! = |'.split(' '), function (n) {
      rf[n] = !0;
    });
    ph = { n: '\n', f: '\f', r: '\r', t: '\t', v: '\v', "'": "'", '"': '"' };
    uf = function (n) {
      this.options = n;
    };
    uf.prototype = {
      constructor: uf,
      lex: function (n) {
        for (
          this.text = n, this.index = 0, this.tokens = [];
          this.index < this.text.length;

        )
          if (((n = this.text.charAt(this.index)), '"' === n || "'" === n))
            this.readString(n);
          else if (
            this.isNumber(n) ||
            ('.' === n && this.isNumber(this.peek()))
          )
            this.readNumber();
          else if (this.isIdentifierStart(this.peekMultichar()))
            this.readIdent();
          else if (this.is(n, '(){}[].,;:?'))
            this.tokens.push({ index: this.index, text: n }), this.index++;
          else if (this.isWhitespace(n)) this.index++;
          else {
            var t = n + this.peek(),
              i = t + this.peek(2),
              r = rf[t],
              u = rf[i];
            rf[n] || r || u
              ? ((n = u ? i : r ? t : n),
                this.tokens.push({ index: this.index, text: n, operator: !0 }),
                (this.index += n.length))
              : this.throwError(
                  'Unexpected next character ',
                  this.index,
                  this.index + 1
                );
          }
        return this.tokens;
      },
      is: function (n, t) {
        return -1 !== t.indexOf(n);
      },
      peek: function (n) {
        return (
          (n = n || 1),
          this.index + n < this.text.length
            ? this.text.charAt(this.index + n)
            : !1
        );
      },
      isNumber: function (n) {
        return '0' <= n && '9' >= n && 'string' == typeof n;
      },
      isWhitespace: function (n) {
        return (
          ' ' === n ||
          '\r' === n ||
          '\t' === n ||
          '\n' === n ||
          '\v' === n ||
          ' ' === n
        );
      },
      isIdentifierStart: function (n) {
        return this.options.isIdentifierStart
          ? this.options.isIdentifierStart(n, this.codePointAt(n))
          : this.isValidIdentifierStart(n);
      },
      isValidIdentifierStart: function (n) {
        return (
          ('a' <= n && 'z' >= n) ||
          ('A' <= n && 'Z' >= n) ||
          '_' === n ||
          '$' === n
        );
      },
      isIdentifierContinue: function (n) {
        return this.options.isIdentifierContinue
          ? this.options.isIdentifierContinue(n, this.codePointAt(n))
          : this.isValidIdentifierContinue(n);
      },
      isValidIdentifierContinue: function (n, t) {
        return this.isValidIdentifierStart(n, t) || this.isNumber(n);
      },
      codePointAt: function (n) {
        return 1 === n.length
          ? n.charCodeAt(0)
          : (n.charCodeAt(0) << 10) + n.charCodeAt(1) - 56613888;
      },
      peekMultichar: function () {
        var n = this.text.charAt(this.index),
          t = this.peek(),
          i,
          r;
        return t
          ? ((i = n.charCodeAt(0)),
            (r = t.charCodeAt(0)),
            55296 <= i && 56319 >= i && 56320 <= r && 57343 >= r ? n + t : n)
          : n;
      },
      isExpOperator: function (n) {
        return '-' === n || '+' === n || this.isNumber(n);
      },
      throwError: function (n, t, i) {
        i = i || this.index;
        t = u(t)
          ? 's ' + t + '-' + this.index + ' [' + this.text.substring(t, i) + ']'
          : ' ' + i;
        throw it('lexerr', n, t, this.text);
      },
      readNumber: function () {
        for (
          var t, i, n = '', r = this.index;
          this.index < this.text.length;

        ) {
          if (
            ((t = v(this.text.charAt(this.index))),
            '.' == t || this.isNumber(t))
          )
            n += t;
          else if (((i = this.peek()), 'e' == t && this.isExpOperator(i)))
            n += t;
          else if (
            this.isExpOperator(t) &&
            i &&
            this.isNumber(i) &&
            'e' == n.charAt(n.length - 1)
          )
            n += t;
          else if (
            !this.isExpOperator(t) ||
            (i && this.isNumber(i)) ||
            'e' != n.charAt(n.length - 1)
          )
            break;
          else this.throwError('Invalid exponent');
          this.index++;
        }
        this.tokens.push({ index: r, text: n, constant: !0, value: Number(n) });
      },
      readIdent: function () {
        var t = this.index,
          n;
        for (
          this.index += this.peekMultichar().length;
          this.index < this.text.length;

        ) {
          if (((n = this.peekMultichar()), !this.isIdentifierContinue(n)))
            break;
          this.index += n.length;
        }
        this.tokens.push({
          index: t,
          text: this.text.slice(t, this.index),
          identifier: !0,
        });
      },
      readString: function (n) {
        var f = this.index,
          t,
          u;
        this.index++;
        for (var r = '', u = n, i = !1; this.index < this.text.length; ) {
          if (((t = this.text.charAt(this.index)), (u = u + t), i))
            'u' === t
              ? ((i = this.text.substring(this.index + 1, this.index + 5)),
                i.match(/[\da-f]{4}/i) ||
                  this.throwError('Invalid unicode escape [\\u' + i + ']'),
                (this.index += 4),
                (r += String.fromCharCode(parseInt(i, 16))))
              : (r += ph[t] || t),
              (i = !1);
          else if ('\\' === t) i = !0;
          else {
            if (t === n) {
              this.index++;
              this.tokens.push({ index: f, text: u, constant: !0, value: r });
              return;
            }
            r += t;
          }
          this.index++;
        }
        this.throwError('Unterminated quote', f);
      },
    };
    i = function (n, t) {
      this.lexer = n;
      this.options = t;
    };
    i.Program = 'Program';
    i.ExpressionStatement = 'ExpressionStatement';
    i.AssignmentExpression = 'AssignmentExpression';
    i.ConditionalExpression = 'ConditionalExpression';
    i.LogicalExpression = 'LogicalExpression';
    i.BinaryExpression = 'BinaryExpression';
    i.UnaryExpression = 'UnaryExpression';
    i.CallExpression = 'CallExpression';
    i.MemberExpression = 'MemberExpression';
    i.Identifier = 'Identifier';
    i.Literal = 'Literal';
    i.ArrayExpression = 'ArrayExpression';
    i.Property = 'Property';
    i.ObjectExpression = 'ObjectExpression';
    i.ThisExpression = 'ThisExpression';
    i.LocalsExpression = 'LocalsExpression';
    i.NGValueParameter = 'NGValueParameter';
    i.prototype = {
      ast: function (n) {
        return (
          (this.text = n),
          (this.tokens = this.lexer.lex(n)),
          (n = this.program()),
          0 !== this.tokens.length &&
            this.throwError('is an unexpected token', this.tokens[0]),
          n
        );
      },
      program: function () {
        for (var n = []; ; )
          if (
            (0 < this.tokens.length &&
              !this.peek('}', ')', ';', ']') &&
              n.push(this.expressionStatement()),
            !this.expect(';'))
          )
            return { type: i.Program, body: n };
      },
      expressionStatement: function () {
        return { type: i.ExpressionStatement, expression: this.filterChain() };
      },
      filterChain: function () {
        for (var n = this.expression(); this.expect('|'); ) n = this.filter(n);
        return n;
      },
      expression: function () {
        return this.assignment();
      },
      assignment: function () {
        var n = this.ternary();
        return (
          this.expect('=') &&
            (n = {
              type: i.AssignmentExpression,
              left: n,
              right: this.assignment(),
              operator: '=',
            }),
          n
        );
      },
      ternary: function () {
        var n = this.logicalOR(),
          t,
          r;
        return this.expect('?') && ((t = this.expression()), this.consume(':'))
          ? ((r = this.expression()),
            {
              type: i.ConditionalExpression,
              test: n,
              alternate: t,
              consequent: r,
            })
          : n;
      },
      logicalOR: function () {
        for (var n = this.logicalAND(); this.expect('||'); )
          n = {
            type: i.LogicalExpression,
            operator: '||',
            left: n,
            right: this.logicalAND(),
          };
        return n;
      },
      logicalAND: function () {
        for (var n = this.equality(); this.expect('&&'); )
          n = {
            type: i.LogicalExpression,
            operator: '&&',
            left: n,
            right: this.equality(),
          };
        return n;
      },
      equality: function () {
        for (
          var n = this.relational(), t;
          (t = this.expect('==', '!=', '===', '!=='));

        )
          n = {
            type: i.BinaryExpression,
            operator: t.text,
            left: n,
            right: this.relational(),
          };
        return n;
      },
      relational: function () {
        for (
          var n = this.additive(), t;
          (t = this.expect('<', '>', '<=', '>='));

        )
          n = {
            type: i.BinaryExpression,
            operator: t.text,
            left: n,
            right: this.additive(),
          };
        return n;
      },
      additive: function () {
        for (var n = this.multiplicative(), t; (t = this.expect('+', '-')); )
          n = {
            type: i.BinaryExpression,
            operator: t.text,
            left: n,
            right: this.multiplicative(),
          };
        return n;
      },
      multiplicative: function () {
        for (var n = this.unary(), t; (t = this.expect('*', '/', '%')); )
          n = {
            type: i.BinaryExpression,
            operator: t.text,
            left: n,
            right: this.unary(),
          };
        return n;
      },
      unary: function () {
        var n;
        return (n = this.expect('+', '-', '!'))
          ? {
              type: i.UnaryExpression,
              operator: n.text,
              prefix: !0,
              argument: this.unary(),
            }
          : this.primary();
      },
      primary: function () {
        var n, t;
        for (
          this.expect('(')
            ? ((n = this.filterChain()), this.consume(')'))
            : this.expect('[')
            ? (n = this.arrayDeclaration())
            : this.expect('{')
            ? (n = this.object())
            : this.selfReferential.hasOwnProperty(this.peek().text)
            ? (n = kt(this.selfReferential[this.consume().text]))
            : this.options.literals.hasOwnProperty(this.peek().text)
            ? (n = {
                type: i.Literal,
                value: this.options.literals[this.consume().text],
              })
            : this.peek().identifier
            ? (n = this.identifier())
            : this.peek().constant
            ? (n = this.constant())
            : this.throwError('not a primary expression', this.peek());
          (t = this.expect('(', '[', '.'));

        )
          '(' === t.text
            ? ((n = {
                type: i.CallExpression,
                callee: n,
                arguments: this.parseArguments(),
              }),
              this.consume(')'))
            : '[' === t.text
            ? ((n = {
                type: i.MemberExpression,
                object: n,
                property: this.expression(),
                computed: !0,
              }),
              this.consume(']'))
            : '.' === t.text
            ? (n = {
                type: i.MemberExpression,
                object: n,
                property: this.identifier(),
                computed: !1,
              })
            : this.throwError('IMPOSSIBLE');
        return n;
      },
      filter: function (n) {
        n = [n];
        for (
          var t = {
            type: i.CallExpression,
            callee: this.identifier(),
            arguments: n,
            filter: !0,
          };
          this.expect(':');

        )
          n.push(this.expression());
        return t;
      },
      parseArguments: function () {
        var n = [];
        if (')' !== this.peekToken().text)
          do n.push(this.expression());
          while (this.expect(','));
        return n;
      },
      identifier: function () {
        var n = this.consume();
        return (
          n.identifier || this.throwError('is not a valid identifier', n),
          { type: i.Identifier, name: n.text }
        );
      },
      constant: function () {
        return { type: i.Literal, value: this.consume().value };
      },
      arrayDeclaration: function () {
        var n = [];
        if (']' !== this.peekToken().text)
          do {
            if (this.peek(']')) break;
            n.push(this.expression());
          } while (this.expect(','));
        return this.consume(']'), { type: i.ArrayExpression, elements: n };
      },
      object: function () {
        var t = [],
          n;
        if ('}' !== this.peekToken().text)
          do {
            if (this.peek('}')) break;
            n = { type: i.Property, kind: 'init' };
            this.peek().constant
              ? ((n.key = this.constant()),
                (n.computed = !1),
                this.consume(':'),
                (n.value = this.expression()))
              : this.peek().identifier
              ? ((n.key = this.identifier()),
                (n.computed = !1),
                this.peek(':')
                  ? (this.consume(':'), (n.value = this.expression()))
                  : (n.value = n.key))
              : this.peek('[')
              ? (this.consume('['),
                (n.key = this.expression()),
                this.consume(']'),
                (n.computed = !0),
                this.consume(':'),
                (n.value = this.expression()))
              : this.throwError('invalid key', this.peek());
            t.push(n);
          } while (this.expect(','));
        return this.consume('}'), { type: i.ObjectExpression, properties: t };
      },
      throwError: function (n, t) {
        throw it(
          'syntax',
          t.text,
          n,
          t.index + 1,
          this.text,
          this.text.substring(t.index)
        );
      },
      consume: function (n) {
        if (0 === this.tokens.length) throw it('ueoe', this.text);
        var t = this.expect(n);
        return (
          t ||
            this.throwError(
              'is unexpected, expecting [' + n + ']',
              this.peek()
            ),
          t
        );
      },
      peekToken: function () {
        if (0 === this.tokens.length) throw it('ueoe', this.text);
        return this.tokens[0];
      },
      peek: function (n, t, i, r) {
        return this.peekAhead(0, n, t, i, r);
      },
      peekAhead: function (n, t, i, r, u) {
        if (this.tokens.length > n) {
          n = this.tokens[n];
          var f = n.text;
          if (f === t || f === i || f === r || f === u || !(t || i || r || u))
            return n;
        }
        return !1;
      },
      expect: function (n, t, i, r) {
        return (n = this.peek(n, t, i, r)) ? (this.tokens.shift(), n) : !1;
      },
      selfReferential: {
        this: { type: i.ThisExpression },
        $locals: { type: i.LocalsExpression },
      },
    };
    ss.prototype = {
      compile: function (n, i) {
        var u = this,
          f = this.astBuilder.ast(n),
          r,
          e;
        return (
          (this.state = {
            nextId: 0,
            filters: {},
            expensiveChecks: i,
            fn: { vars: [], body: [], own: {} },
            assign: { vars: [], body: [], own: {} },
            inputs: [],
          }),
          k(f, u.$filter),
          (r = ''),
          (this.stage = 'assign'),
          (e = es(f)) &&
            ((this.state.computing = 'assign'),
            (r = this.nextId()),
            this.recurse(e, r),
            this.return_(r),
            (r = 'fn.assign=' + this.generateFunction('assign', 's,v,l'))),
          (e = us(f.body)),
          (u.stage = 'inputs'),
          t(e, function (n, t) {
            var i = 'fn' + t,
              r;
            u.state[i] = { vars: [], body: [], own: {} };
            u.state.computing = i;
            r = u.nextId();
            u.recurse(n, r);
            u.return_(r);
            u.state.inputs.push(i);
            n.watchId = t;
          }),
          (this.state.computing = 'fn'),
          (this.stage = 'main'),
          this.recurse(f),
          (r =
            '"' +
            this.USE +
            ' ' +
            this.STRICT +
            '";\n' +
            this.filterPrefix() +
            'var fn=' +
            this.generateFunction('fn', 's,l,a,i') +
            r +
            this.watchFns() +
            'return fn;'),
          (r = new Function(
            '$filter',
            'ensureSafeMemberName',
            'ensureSafeObject',
            'ensureSafeFunction',
            'getStringValue',
            'ensureSafeAssignContext',
            'ifDefined',
            'plus',
            'text',
            r
          )(this.$filter, bi, dt, is, la, vu, aa, rs, n)),
          (this.state = this.stage = void 0),
          (r.literal = os(f)),
          (r.constant = f.constant),
          r
        );
      },
      USE: 'use',
      STRICT: 'strict',
      watchFns: function () {
        var n = [],
          i = this.state.inputs,
          r = this;
        return (
          t(i, function (t) {
            n.push('var ' + t + '=' + r.generateFunction(t, 's'));
          }),
          i.length && n.push('fn.inputs=[' + i.join(',') + '];'),
          n.join('')
        );
      },
      generateFunction: function (n, t) {
        return (
          'function(' + t + '){' + this.varsPrefix(n) + this.body(n) + '};'
        );
      },
      filterPrefix: function () {
        var n = [],
          i = this;
        return (
          t(this.state.filters, function (t, r) {
            n.push(t + '=$filter(' + i.escape(r) + ')');
          }),
          n.length ? 'var ' + n.join(',') + ';' : ''
        );
      },
      varsPrefix: function (n) {
        return this.state[n].vars.length
          ? 'var ' + this.state[n].vars.join(',') + ';'
          : '';
      },
      body: function (n) {
        return this.state[n].body.join('');
      },
      recurse: function (n, r, f, e, s, h) {
        var l,
          v,
          c = this,
          y,
          a,
          p;
        if (((e = e || o), !h && u(n.watchId)))
          (r = r || this.nextId()),
            this.if_(
              'i',
              this.lazyAssign(r, this.computedMember('i', n.watchId)),
              this.lazyRecurse(n, r, f, e, s, !0)
            );
        else
          switch (n.type) {
            case i.Program:
              t(n.body, function (t, i) {
                c.recurse(t.expression, void 0, void 0, function (n) {
                  v = n;
                });
                i !== n.body.length - 1
                  ? c.current().body.push(v, ';')
                  : c.return_(v);
              });
              break;
            case i.Literal:
              a = this.escape(n.value);
              this.assign(r, a);
              e(a);
              break;
            case i.UnaryExpression:
              this.recurse(n.argument, void 0, void 0, function (n) {
                v = n;
              });
              a = n.operator + '(' + this.ifDefined(v, 0) + ')';
              this.assign(r, a);
              e(a);
              break;
            case i.BinaryExpression:
              this.recurse(n.left, void 0, void 0, function (n) {
                l = n;
              });
              this.recurse(n.right, void 0, void 0, function (n) {
                v = n;
              });
              a =
                '+' === n.operator
                  ? this.plus(l, v)
                  : '-' === n.operator
                  ? this.ifDefined(l, 0) + n.operator + this.ifDefined(v, 0)
                  : '(' + l + ')' + n.operator + '(' + v + ')';
              this.assign(r, a);
              e(a);
              break;
            case i.LogicalExpression:
              r = r || this.nextId();
              c.recurse(n.left, r);
              c.if_(
                '&&' === n.operator ? r : c.not(r),
                c.lazyRecurse(n.right, r)
              );
              e(r);
              break;
            case i.ConditionalExpression:
              r = r || this.nextId();
              c.recurse(n.test, r);
              c.if_(
                r,
                c.lazyRecurse(n.alternate, r),
                c.lazyRecurse(n.consequent, r)
              );
              e(r);
              break;
            case i.Identifier:
              r = r || this.nextId();
              f &&
                ((f.context =
                  'inputs' === c.stage
                    ? 's'
                    : this.assign(
                        this.nextId(),
                        this.getHasOwnProperty('l', n.name) + '?l:s'
                      )),
                (f.computed = !1),
                (f.name = n.name));
              bi(n.name);
              c.if_(
                'inputs' === c.stage || c.not(c.getHasOwnProperty('l', n.name)),
                function () {
                  c.if_('inputs' === c.stage || 's', function () {
                    s &&
                      1 !== s &&
                      c.if_(
                        c.not(c.nonComputedMember('s', n.name)),
                        c.lazyAssign(c.nonComputedMember('s', n.name), '{}')
                      );
                    c.assign(r, c.nonComputedMember('s', n.name));
                  });
                },
                r && c.lazyAssign(r, c.nonComputedMember('l', n.name))
              );
              (c.state.expensiveChecks || yu(n.name)) &&
                c.addEnsureSafeObject(r);
              e(r);
              break;
            case i.MemberExpression:
              l = (f && (f.context = this.nextId())) || this.nextId();
              r = r || this.nextId();
              c.recurse(
                n.object,
                l,
                void 0,
                function () {
                  c.if_(
                    c.notNull(l),
                    function () {
                      s && 1 !== s && c.addEnsureSafeAssignContext(l);
                      n.computed
                        ? ((v = c.nextId()),
                          c.recurse(n.property, v),
                          c.getStringValue(v),
                          c.addEnsureSafeMemberName(v),
                          s &&
                            1 !== s &&
                            c.if_(
                              c.not(c.computedMember(l, v)),
                              c.lazyAssign(c.computedMember(l, v), '{}')
                            ),
                          (a = c.ensureSafeObject(c.computedMember(l, v))),
                          c.assign(r, a),
                          f && ((f.computed = !0), (f.name = v)))
                        : (bi(n.property.name),
                          s &&
                            1 !== s &&
                            c.if_(
                              c.not(c.nonComputedMember(l, n.property.name)),
                              c.lazyAssign(
                                c.nonComputedMember(l, n.property.name),
                                '{}'
                              )
                            ),
                          (a = c.nonComputedMember(l, n.property.name)),
                          (c.state.expensiveChecks || yu(n.property.name)) &&
                            (a = c.ensureSafeObject(a)),
                          c.assign(r, a),
                          f && ((f.computed = !1), (f.name = n.property.name)));
                    },
                    function () {
                      c.assign(r, 'undefined');
                    }
                  );
                  e(r);
                },
                !!s
              );
              break;
            case i.CallExpression:
              r = r || this.nextId();
              n.filter
                ? ((v = c.filter(n.callee.name)),
                  (y = []),
                  t(n.arguments, function (n) {
                    var t = c.nextId();
                    c.recurse(n, t);
                    y.push(t);
                  }),
                  (a = v + '(' + y.join(',') + ')'),
                  c.assign(r, a),
                  e(r))
                : ((v = c.nextId()),
                  (l = {}),
                  (y = []),
                  c.recurse(n.callee, v, l, function () {
                    c.if_(
                      c.notNull(v),
                      function () {
                        c.addEnsureSafeFunction(v);
                        t(n.arguments, function (n) {
                          c.recurse(n, c.nextId(), void 0, function (n) {
                            y.push(c.ensureSafeObject(n));
                          });
                        });
                        l.name
                          ? (c.state.expensiveChecks ||
                              c.addEnsureSafeObject(l.context),
                            (a =
                              c.member(l.context, l.name, l.computed) +
                              '(' +
                              y.join(',') +
                              ')'))
                          : (a = v + '(' + y.join(',') + ')');
                        a = c.ensureSafeObject(a);
                        c.assign(r, a);
                      },
                      function () {
                        c.assign(r, 'undefined');
                      }
                    );
                    e(r);
                  }));
              break;
            case i.AssignmentExpression:
              if (((v = this.nextId()), (l = {}), !fs(n.left)))
                throw it('lval');
              this.recurse(
                n.left,
                void 0,
                l,
                function () {
                  c.if_(c.notNull(l.context), function () {
                    c.recurse(n.right, v);
                    c.addEnsureSafeObject(
                      c.member(l.context, l.name, l.computed)
                    );
                    c.addEnsureSafeAssignContext(l.context);
                    a =
                      c.member(l.context, l.name, l.computed) + n.operator + v;
                    c.assign(r, a);
                    e(r || a);
                  });
                },
                1
              );
              break;
            case i.ArrayExpression:
              y = [];
              t(n.elements, function (n) {
                c.recurse(n, c.nextId(), void 0, function (n) {
                  y.push(n);
                });
              });
              a = '[' + y.join(',') + ']';
              this.assign(r, a);
              e(a);
              break;
            case i.ObjectExpression:
              y = [];
              p = !1;
              t(n.properties, function (n) {
                n.computed && (p = !0);
              });
              p
                ? ((r = r || this.nextId()),
                  this.assign(r, '{}'),
                  t(n.properties, function (n) {
                    n.computed
                      ? ((l = c.nextId()), c.recurse(n.key, l))
                      : (l =
                          n.key.type === i.Identifier
                            ? n.key.name
                            : '' + n.key.value);
                    v = c.nextId();
                    c.recurse(n.value, v);
                    c.assign(c.member(r, l, n.computed), v);
                  }))
                : (t(n.properties, function (t) {
                    c.recurse(
                      t.value,
                      n.constant ? void 0 : c.nextId(),
                      void 0,
                      function (n) {
                        y.push(
                          c.escape(
                            t.key.type === i.Identifier
                              ? t.key.name
                              : '' + t.key.value
                          ) +
                            ':' +
                            n
                        );
                      }
                    );
                  }),
                  (a = '{' + y.join(',') + '}'),
                  this.assign(r, a));
              e(r || a);
              break;
            case i.ThisExpression:
              this.assign(r, 's');
              e('s');
              break;
            case i.LocalsExpression:
              this.assign(r, 'l');
              e('l');
              break;
            case i.NGValueParameter:
              this.assign(r, 'v');
              e('v');
          }
      },
      getHasOwnProperty: function (n, t) {
        var i = n + '.' + t,
          r = this.current().own;
        return (
          r.hasOwnProperty(i) ||
            (r[i] = this.nextId(
              !1,
              n + '&&(' + this.escape(t) + ' in ' + n + ')'
            )),
          r[i]
        );
      },
      assign: function (n, t) {
        if (n) return this.current().body.push(n, '=', t, ';'), n;
      },
      filter: function (n) {
        return (
          this.state.filters.hasOwnProperty(n) ||
            (this.state.filters[n] = this.nextId(!0)),
          this.state.filters[n]
        );
      },
      ifDefined: function (n, t) {
        return 'ifDefined(' + n + ',' + this.escape(t) + ')';
      },
      plus: function (n, t) {
        return 'plus(' + n + ',' + t + ')';
      },
      return_: function (n) {
        this.current().body.push('return ', n, ';');
      },
      if_: function (n, t, i) {
        if (!0 === n) t();
        else {
          var r = this.current().body;
          r.push('if(', n, '){');
          t();
          r.push('}');
          i && (r.push('else{'), i(), r.push('}'));
        }
      },
      not: function (n) {
        return '!(' + n + ')';
      },
      notNull: function (n) {
        return n + '!=null';
      },
      nonComputedMember: function (n, t) {
        return /[$_a-zA-Z][$_a-zA-Z0-9]*/.test(t)
          ? n + '.' + t
          : n + '["' + t.replace(/[^$_a-zA-Z0-9]/g, this.stringEscapeFn) + '"]';
      },
      computedMember: function (n, t) {
        return n + '[' + t + ']';
      },
      member: function (n, t, i) {
        return i ? this.computedMember(n, t) : this.nonComputedMember(n, t);
      },
      addEnsureSafeObject: function (n) {
        this.current().body.push(this.ensureSafeObject(n), ';');
      },
      addEnsureSafeMemberName: function (n) {
        this.current().body.push(this.ensureSafeMemberName(n), ';');
      },
      addEnsureSafeFunction: function (n) {
        this.current().body.push(this.ensureSafeFunction(n), ';');
      },
      addEnsureSafeAssignContext: function (n) {
        this.current().body.push(this.ensureSafeAssignContext(n), ';');
      },
      ensureSafeObject: function (n) {
        return 'ensureSafeObject(' + n + ',text)';
      },
      ensureSafeMemberName: function (n) {
        return 'ensureSafeMemberName(' + n + ',text)';
      },
      ensureSafeFunction: function (n) {
        return 'ensureSafeFunction(' + n + ',text)';
      },
      getStringValue: function (n) {
        this.assign(n, 'getStringValue(' + n + ')');
      },
      ensureSafeAssignContext: function (n) {
        return 'ensureSafeAssignContext(' + n + ',text)';
      },
      lazyRecurse: function (n, t, i, r, u, f) {
        var e = this;
        return function () {
          e.recurse(n, t, i, r, u, f);
        };
      },
      lazyAssign: function (n, t) {
        var i = this;
        return function () {
          i.assign(n, t);
        };
      },
      stringEscapeRegex: /[^ a-zA-Z0-9]/g,
      stringEscapeFn: function (n) {
        return '\\u' + ('0000' + n.charCodeAt(0).toString(16)).slice(-4);
      },
      escape: function (n) {
        if (h(n))
          return (
            "'" + n.replace(this.stringEscapeRegex, this.stringEscapeFn) + "'"
          );
        if (w(n)) return n.toString();
        if (!0 === n) return 'true';
        if (!1 === n) return 'false';
        if (null === n) return 'null';
        if ('undefined' == typeof n) return 'undefined';
        throw it('esc');
      },
      nextId: function (n, t) {
        var i = 'v' + this.state.nextId++;
        return n || this.current().vars.push(i + (t ? '=' + t : '')), i;
      },
      current: function () {
        return this.state[this.state.computing];
      },
    };
    hs.prototype = {
      compile: function (n, i) {
        var s = this,
          u = this.astBuilder.ast(n),
          r,
          h,
          f,
          e;
        return (
          (this.expression = n),
          (this.expensiveChecks = i),
          k(u, s.$filter),
          (r = es(u)) && (h = this.recurse(r)),
          (r = us(u.body)),
          r &&
            ((f = []),
            t(r, function (n, t) {
              var i = s.recurse(n);
              n.input = i;
              f.push(i);
              n.watchId = t;
            })),
          (e = []),
          t(u.body, function (n) {
            e.push(s.recurse(n.expression));
          }),
          (r =
            0 === u.body.length
              ? o
              : 1 === u.body.length
              ? e[0]
              : function (n, i) {
                  var r;
                  return (
                    t(e, function (t) {
                      r = t(n, i);
                    }),
                    r
                  );
                }),
          h &&
            (r.assign = function (n, t, i) {
              return h(n, i, t);
            }),
          f && (r.inputs = f),
          (r.literal = os(u)),
          (r.constant = u.constant),
          r
        );
      },
      recurse: function (n, r, u) {
        var s,
          o,
          e = this,
          f;
        if (n.input) return this.inputs(n.input, n.watchId);
        switch (n.type) {
          case i.Literal:
            return this.value(n.value, r);
          case i.UnaryExpression:
            return (
              (o = this.recurse(n.argument)), this['unary' + n.operator](o, r)
            );
          case i.BinaryExpression:
            return (
              (s = this.recurse(n.left)),
              (o = this.recurse(n.right)),
              this['binary' + n.operator](s, o, r)
            );
          case i.LogicalExpression:
            return (
              (s = this.recurse(n.left)),
              (o = this.recurse(n.right)),
              this['binary' + n.operator](s, o, r)
            );
          case i.ConditionalExpression:
            return this['ternary?:'](
              this.recurse(n.test),
              this.recurse(n.alternate),
              this.recurse(n.consequent),
              r
            );
          case i.Identifier:
            return (
              bi(n.name, e.expression),
              e.identifier(
                n.name,
                e.expensiveChecks || yu(n.name),
                r,
                u,
                e.expression
              )
            );
          case i.MemberExpression:
            return (
              (s = this.recurse(n.object, !1, !!u)),
              n.computed ||
                (bi(n.property.name, e.expression), (o = n.property.name)),
              n.computed && (o = this.recurse(n.property)),
              n.computed
                ? this.computedMember(s, o, r, u, e.expression)
                : this.nonComputedMember(
                    s,
                    o,
                    e.expensiveChecks,
                    r,
                    u,
                    e.expression
                  )
            );
          case i.CallExpression:
            return (
              (f = []),
              t(n.arguments, function (n) {
                f.push(e.recurse(n));
              }),
              n.filter && (o = this.$filter(n.callee.name)),
              n.filter || (o = this.recurse(n.callee, !0)),
              n.filter
                ? function (n, t, i, u) {
                    for (var s = [], e = 0; e < f.length; ++e)
                      s.push(f[e](n, t, i, u));
                    return (
                      (n = o.apply(void 0, s, u)),
                      r ? { context: void 0, name: void 0, value: n } : n
                    );
                  }
                : function (n, t, i, u) {
                    var h = o(n, t, i, u),
                      s,
                      c;
                    if (null != h.value) {
                      for (
                        dt(h.context, e.expression),
                          is(h.value, e.expression),
                          s = [],
                          c = 0;
                        c < f.length;
                        ++c
                      )
                        s.push(dt(f[c](n, t, i, u), e.expression));
                      s = dt(h.value.apply(h.context, s), e.expression);
                    }
                    return r ? { value: s } : s;
                  }
            );
          case i.AssignmentExpression:
            return (
              (s = this.recurse(n.left, !0, 1)),
              (o = this.recurse(n.right)),
              function (n, t, i, u) {
                var f = s(n, t, i, u);
                return (
                  (n = o(n, t, i, u)),
                  dt(f.value, e.expression),
                  vu(f.context),
                  (f.context[f.name] = n),
                  r ? { value: n } : n
                );
              }
            );
          case i.ArrayExpression:
            return (
              (f = []),
              t(n.elements, function (n) {
                f.push(e.recurse(n));
              }),
              function (n, t, i, u) {
                for (var e = [], o = 0; o < f.length; ++o)
                  e.push(f[o](n, t, i, u));
                return r ? { value: e } : e;
              }
            );
          case i.ObjectExpression:
            return (
              (f = []),
              t(n.properties, function (n) {
                n.computed
                  ? f.push({
                      key: e.recurse(n.key),
                      computed: !0,
                      value: e.recurse(n.value),
                    })
                  : f.push({
                      key:
                        n.key.type === i.Identifier
                          ? n.key.name
                          : '' + n.key.value,
                      computed: !1,
                      value: e.recurse(n.value),
                    });
              }),
              function (n, t, i, u) {
                for (var o = {}, e = 0; e < f.length; ++e)
                  f[e].computed
                    ? (o[f[e].key(n, t, i, u)] = f[e].value(n, t, i, u))
                    : (o[f[e].key] = f[e].value(n, t, i, u));
                return r ? { value: o } : o;
              }
            );
          case i.ThisExpression:
            return function (n) {
              return r ? { value: n } : n;
            };
          case i.LocalsExpression:
            return function (n, t) {
              return r ? { value: t } : t;
            };
          case i.NGValueParameter:
            return function (n, t, i) {
              return r ? { value: i } : i;
            };
        }
      },
      'unary+': function (n, t) {
        return function (i, r, f, e) {
          return (i = n(i, r, f, e)), (i = u(i) ? +i : 0), t ? { value: i } : i;
        };
      },
      'unary-': function (n, t) {
        return function (i, r, f, e) {
          return (i = n(i, r, f, e)), (i = u(i) ? -i : 0), t ? { value: i } : i;
        };
      },
      'unary!': function (n, t) {
        return function (i, r, u, f) {
          return (i = !n(i, r, u, f)), t ? { value: i } : i;
        };
      },
      'binary+': function (n, t, i) {
        return function (r, u, f, e) {
          var o = n(r, u, f, e);
          return (r = t(r, u, f, e)), (o = rs(o, r)), i ? { value: o } : o;
        };
      },
      'binary-': function (n, t, i) {
        return function (r, f, e, o) {
          var s = n(r, f, e, o);
          return (
            (r = t(r, f, e, o)),
            (s = (u(s) ? s : 0) - (u(r) ? r : 0)),
            i ? { value: s } : s
          );
        };
      },
      'binary*': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) * t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary/': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) / t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary%': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) % t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary===': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) === t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary!==': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) !== t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary==': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) == t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary!=': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) != t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary<': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) < t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary>': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) > t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary<=': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) <= t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary>=': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) >= t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary&&': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) && t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'binary||': function (n, t, i) {
        return function (r, u, f, e) {
          return (r = n(r, u, f, e) || t(r, u, f, e)), i ? { value: r } : r;
        };
      },
      'ternary?:': function (n, t, i, r) {
        return function (u, f, e, o) {
          return (
            (u = n(u, f, e, o) ? t(u, f, e, o) : i(u, f, e, o)),
            r ? { value: u } : u
          );
        };
      },
      value: function (n, t) {
        return function () {
          return t ? { context: void 0, name: void 0, value: n } : n;
        };
      },
      identifier: function (n, t, i, r, u) {
        return function (f, e) {
          return (
            (f = e && n in e ? e : f),
            r && 1 !== r && f && !f[n] && (f[n] = {}),
            (e = f ? f[n] : void 0),
            t && dt(e, u),
            i ? { context: f, name: n, value: e } : e
          );
        };
      },
      computedMember: function (n, t, i, r, u) {
        return function (f, e, o, s) {
          var h = n(f, e, o, s),
            c,
            l;
          return (
            null != h &&
              ((c = t(f, e, o, s)),
              (c += ''),
              bi(c, u),
              r && 1 !== r && (vu(h), h && !h[c] && (h[c] = {})),
              (l = h[c]),
              dt(l, u)),
            i ? { context: h, name: c, value: l } : l
          );
        };
      },
      nonComputedMember: function (n, t, i, r, u, f) {
        return function (e, o, s, h) {
          return (
            (e = n(e, o, s, h)),
            u && 1 !== u && (vu(e), e && !e[t] && (e[t] = {})),
            (o = null != e ? e[t] : void 0),
            (i || yu(t)) && dt(o, f),
            r ? { context: e, name: t, value: o } : o
          );
        };
      },
      inputs: function (n, t) {
        return function (i, r, u, f) {
          return f ? f[t] : n(i, r, u);
        };
      },
    };
    ff = function (n, t, r) {
      this.lexer = n;
      this.$filter = t;
      this.options = r;
      this.ast = new i(n, r);
      this.astCompiler = r.csp ? new hs(this.ast, t) : new ss(this.ast, t);
    };
    ff.prototype = {
      constructor: ff,
      parse: function (n) {
        return this.astCompiler.compile(n, this.options.expensiveChecks);
      },
    };
    var sp = Object.prototype.valueOf,
      ii = l('$sce'),
      lt = {
        HTML: 'html',
        CSS: 'css',
        URL: 'url',
        RESOURCE_URL: 'resourceUrl',
        JS: 'js',
      },
      hp = l('$compile'),
      nt = n.document.createElement('a'),
      wh = gt(n.location.href);
    vs.$inject = ['$document'];
    ys.$inject = ['$provide'];
    var bh = 22,
      kh = '.',
      ce = '0';
    ps.$inject = ['$locale'];
    ws.$inject = ['$locale'];
    var cp = {
        yyyy: d('FullYear', 4, 0, !1, !0),
        yy: d('FullYear', 2, 0, !0, !0),
        y: d('FullYear', 1, 0, !1, !0),
        MMMM: pr('Month'),
        MMM: pr('Month', !0),
        MM: d('Month', 2, 1),
        M: d('Month', 1, 1),
        LLLL: pr('Month', !1, !0),
        dd: d('Date', 2),
        d: d('Date', 1),
        HH: d('Hours', 2),
        H: d('Hours', 1),
        hh: d('Hours', 2, -12),
        h: d('Hours', 1, -12),
        mm: d('Minutes', 2),
        m: d('Minutes', 1),
        ss: d('Seconds', 2),
        s: d('Seconds', 1),
        sss: d('Milliseconds', 3),
        EEEE: pr('Day'),
        EEE: pr('Day', !0),
        a: function (n, t) {
          return 12 > n.getHours() ? t.AMPMS[0] : t.AMPMS[1];
        },
        Z: function (n, t, i) {
          return (
            (n = -1 * i),
            (0 <= n ? '+' : '') +
              (pu(Math[0 < n ? 'floor' : 'ceil'](n / 60), 2) +
                pu(Math.abs(n % 60), 2))
          );
        },
        ww: ds(2),
        w: ds(1),
        G: ne,
        GG: ne,
        GGG: ne,
        GGGG: function (n, t) {
          return 0 >= n.getFullYear() ? t.ERANAMES[0] : t.ERANAMES[1];
        },
      },
      lp = /((?:[^yMLdHhmsaZEwG']+)|(?:'(?:[^']|'')*')|(?:E+|y+|M+|L+|d+|H+|h+|m+|s+|a|Z|G+|w+))(.*)/,
      ap = /^\-?\d+$/;
    gs.$inject = ['$locale'];
    dh = ft(v);
    gh = ft(bu);
    nh.$inject = ['$parse'];
    nc = ft({
      restrict: 'E',
      compile: function (n, t) {
        if (!t.href && !t.xlinkHref)
          return function (n, t) {
            if ('a' === t[0].nodeName.toLowerCase()) {
              var i =
                '[object SVGAnimatedString]' === rt.call(t.prop('href'))
                  ? 'xlink:href'
                  : 'href';
              t.on('click', function (n) {
                t.attr(i) || n.preventDefault();
              });
            }
          };
      },
    });
    kr = {};
    t(nf, function (n, t) {
      function r(n, r, u) {
        n.$watch(u[i], function (n) {
          u.$set(t, !!n);
        });
      }
      if ('multiple' != n) {
        var i = yt('ng-' + t),
          u = r;
        'checked' === n &&
          (u = function (n, t, u) {
            u.ngModel !== u[i] && r(n, t, u);
          });
        kr[i] = function () {
          return { restrict: 'A', priority: 100, link: u };
        };
      }
    });
    t(oe, function (n, t) {
      kr[t] = function () {
        return {
          priority: 100,
          link: function (n, i, r) {
            if (
              'ngPattern' === t &&
              '/' == r.ngPattern.charAt(0) &&
              (i = r.ngPattern.match(vv))
            ) {
              r.$set('ngPattern', new RegExp(i[1], i[2]));
              return;
            }
            n.$watch(r[t], function (n) {
              r.$set(t, n);
            });
          },
        };
      };
    });
    t(['src', 'srcset', 'href'], function (n) {
      var t = yt('ng-' + n);
      kr[t] = function () {
        return {
          priority: 99,
          link: function (i, r, u) {
            var e = n,
              f = n;
            'href' === n &&
              '[object SVGAnimatedString]' === rt.call(r.prop('href')) &&
              ((f = 'xlinkHref'), (u.$attr[f] = 'xlink:href'), (e = null));
            u.$observe(t, function (t) {
              t
                ? (u.$set(f, t), ni && e && r.prop(e, u[f]))
                : 'href' === n && u.$set(f, null);
            });
          },
        };
      };
    });
    dr = {
      $addControl: o,
      $$renameControl: function (n, t) {
        n.$name = t;
      },
      $removeControl: o,
      $setValidity: o,
      $setDirty: o,
      $setPristine: o,
      $setSubmitted: o,
    };
    th.$inject = ['$element', '$attrs', '$scope', '$animate', '$interpolate'];
    var tc = function (n) {
        return [
          '$timeout',
          '$parse',
          function (t, i) {
            function r(n) {
              return '' === n ? i('this[""]').assign : i(n).assign || o;
            }
            return {
              name: 'form',
              restrict: n ? 'EAC' : 'E',
              require: ['form', '^^?form'],
              controller: th,
              compile: function (i, u) {
                i.addClass(tr).addClass(gr);
                var f = u.name ? 'name' : n && u.ngForm ? 'ngForm' : !1;
                return {
                  pre: function (n, i, u, e) {
                    var s = e[0],
                      c,
                      h;
                    if (!('action' in u)) {
                      c = function (t) {
                        n.$apply(function () {
                          s.$commitViewValue();
                          s.$setSubmitted();
                        });
                        t.preventDefault();
                      };
                      i[0].addEventListener('submit', c, !1);
                      i.on('$destroy', function () {
                        t(
                          function () {
                            i[0].removeEventListener('submit', c, !1);
                          },
                          0,
                          !1
                        );
                      });
                    }
                    (e[1] || s.$$parentForm).$addControl(s);
                    h = f ? r(s.$name) : o;
                    f &&
                      (h(n, s),
                      u.$observe(f, function (t) {
                        s.$name !== t &&
                          (h(n, void 0),
                          s.$$parentForm.$$renameControl(s, t),
                          (h = r(s.$name)),
                          h(n, s));
                      }));
                    i.on('$destroy', function () {
                      s.$$parentForm.$removeControl(s);
                      h(n, void 0);
                      a(s, dr);
                    });
                  },
                };
              },
            };
          },
        ];
      },
      vp = tc(),
      yp = tc(!0),
      pp = /^\d{4,}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+(?:[+-][0-2]\d:[0-5]\d|Z)$/,
      wp = /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i,
      bp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
      kp = /^\s*(\-|\+)?(\d+|(\d*(\.\d*)))([eE][+-]?\d+)?\s*$/,
      ic = /^(\d{4,})-(\d{2})-(\d{2})$/,
      rc = /^(\d{4,})-(\d\d)-(\d\d)T(\d\d):(\d\d)(?::(\d\d)(\.\d{1,3})?)?$/,
      le = /^(\d{4,})-W(\d\d)$/,
      uc = /^(\d{4,})-(\d\d)$/,
      fc = /^(\d\d):(\d\d)(?::(\d\d)(\.\d{1,3})?)?$/,
      ec = y();
    t(['date', 'datetime-local', 'month', 'time', 'week'], function (n) {
      ec[n] = !0;
    });
    var oc = {
        text: function (n, t, i, r, u, f) {
          wr(n, t, i, r, u, f);
          te(r);
        },
        date: br('date', ic, wu(ic, ['yyyy', 'MM', 'dd']), 'yyyy-MM-dd'),
        'datetime-local': br(
          'datetimelocal',
          rc,
          wu(rc, 'yyyy MM dd HH mm ss sss'.split(' ')),
          'yyyy-MM-ddTHH:mm:ss.sss'
        ),
        time: br('time', fc, wu(fc, ['HH', 'mm', 'ss', 'sss']), 'HH:mm:ss.sss'),
        week: br(
          'week',
          le,
          function (n, t) {
            var i;
            if (et(n)) return n;
            if (h(n) && ((le.lastIndex = 0), (i = le.exec(n)), i)) {
              var u = +i[1],
                r = +i[2],
                f = (i = 0),
                e = 0,
                o = 0,
                s = ks(u),
                r = 7 * (r - 1);
              return (
                t &&
                  ((i = t.getHours()),
                  (f = t.getMinutes()),
                  (e = t.getSeconds()),
                  (o = t.getMilliseconds())),
                new Date(u, 0, s.getDate() + r, i, f, e, o)
              );
            }
            return NaN;
          },
          'yyyy-Www'
        ),
        month: br('month', uc, wu(uc, ['yyyy', 'MM']), 'yyyy-MM'),
        number: function (n, t, i, f, e, o) {
          var s, h;
          ih(n, t, i, f);
          wr(n, t, i, f, e, o);
          f.$$parserName = 'number';
          f.$parsers.push(function (n) {
            return f.$isEmpty(n) ? null : kp.test(n) ? parseFloat(n) : void 0;
          });
          f.$formatters.push(function (n) {
            if (!f.$isEmpty(n)) {
              if (!w(n)) throw nu('numfmt', n);
              n = n.toString();
            }
            return n;
          });
          (u(i.min) || i.ngMin) &&
            ((f.$validators.min = function (n) {
              return f.$isEmpty(n) || r(s) || n >= s;
            }),
            i.$observe('min', function (n) {
              u(n) && !w(n) && (n = parseFloat(n, 10));
              s = w(n) && !isNaN(n) ? n : void 0;
              f.$validate();
            }));
          (u(i.max) || i.ngMax) &&
            ((f.$validators.max = function (n) {
              return f.$isEmpty(n) || r(h) || n <= h;
            }),
            i.$observe('max', function (n) {
              u(n) && !w(n) && (n = parseFloat(n, 10));
              h = w(n) && !isNaN(n) ? n : void 0;
              f.$validate();
            }));
        },
        url: function (n, t, i, r, u, f) {
          wr(n, t, i, r, u, f);
          te(r);
          r.$$parserName = 'url';
          r.$validators.url = function (n, t) {
            var i = n || t;
            return r.$isEmpty(i) || wp.test(i);
          };
        },
        email: function (n, t, i, r, u, f) {
          wr(n, t, i, r, u, f);
          te(r);
          r.$$parserName = 'email';
          r.$validators.email = function (n, t) {
            var i = n || t;
            return r.$isEmpty(i) || bp.test(i);
          };
        },
        radio: function (n, t, i, u) {
          r(i.name) && t.attr('name', ++ku);
          t.on('click', function (n) {
            t[0].checked && u.$setViewValue(i.value, n && n.type);
          });
          u.$render = function () {
            t[0].checked = i.value == u.$viewValue;
          };
          i.$observe('value', u.$render);
        },
        checkbox: function (n, t, i, r, u, f, e, o) {
          var s = rh(o, n, 'ngTrueValue', i.ngTrueValue, !0),
            h = rh(o, n, 'ngFalseValue', i.ngFalseValue, !1);
          t.on('click', function (n) {
            r.$setViewValue(t[0].checked, n && n.type);
          });
          r.$render = function () {
            t[0].checked = r.$viewValue;
          };
          r.$isEmpty = function (n) {
            return !1 === n;
          };
          r.$formatters.push(function (n) {
            return st(n, s);
          });
          r.$parsers.push(function (n) {
            return n ? s : h;
          });
        },
        hidden: o,
        button: o,
        submit: o,
        reset: o,
        file: o,
      },
      sc = [
        '$browser',
        '$sniffer',
        '$filter',
        '$parse',
        function (n, t, i, r) {
          return {
            restrict: 'E',
            require: ['?ngModel'],
            link: {
              pre: function (u, f, e, o) {
                o[0] && (oc[v(e.type)] || oc.text)(u, f, e, o[0], t, n, i, r);
              },
            },
          };
        },
      ],
      dp = /^(true|false|\d+)$/,
      gp = function () {
        return {
          restrict: 'A',
          priority: 100,
          compile: function (n, t) {
            return dp.test(t.ngValue)
              ? function (n, t, i) {
                  i.$set('value', n.$eval(i.ngValue));
                }
              : function (n, t, i) {
                  n.$watch(i.ngValue, function (n) {
                    i.$set('value', n);
                  });
                };
          },
        };
      },
      nw = [
        '$compile',
        function (n) {
          return {
            restrict: 'AC',
            compile: function (t) {
              return (
                n.$$addBindingClass(t),
                function (t, i, u) {
                  n.$$addBindingInfo(i, u.ngBind);
                  i = i[0];
                  t.$watch(u.ngBind, function (n) {
                    i.textContent = r(n) ? '' : n;
                  });
                }
              );
            },
          };
        },
      ],
      tw = [
        '$interpolate',
        '$compile',
        function (n, t) {
          return {
            compile: function (i) {
              return (
                t.$$addBindingClass(i),
                function (i, u, f) {
                  i = n(u.attr(f.$attr.ngBindTemplate));
                  t.$$addBindingInfo(u, i.expressions);
                  u = u[0];
                  f.$observe('ngBindTemplate', function (n) {
                    u.textContent = r(n) ? '' : n;
                  });
                }
              );
            },
          };
        },
      ],
      iw = [
        '$sce',
        '$parse',
        '$compile',
        function (n, t, i) {
          return {
            restrict: 'A',
            compile: function (r, u) {
              var f = t(u.ngBindHtml),
                e = t(u.ngBindHtml, function (t) {
                  return n.valueOf(t);
                });
              return (
                i.$$addBindingClass(r),
                function (t, r, u) {
                  i.$$addBindingInfo(r, u.ngBindHtml);
                  t.$watch(e, function () {
                    var i = f(t);
                    r.html(n.getTrustedHtml(i) || '');
                  });
                }
              );
            },
          };
        },
      ],
      rw = ft({
        restrict: 'A',
        require: 'ngModel',
        link: function (n, t, i, r) {
          r.$viewChangeListeners.push(function () {
            n.$eval(i.ngChange);
          });
        },
      }),
      uw = ie('', !0),
      fw = ie('Odd', 0),
      ew = ie('Even', 1),
      ow = hi({
        compile: function (n, t) {
          t.$set('ngCloak', void 0);
          n.removeClass('ng-cloak');
        },
      }),
      sw = [
        function () {
          return { restrict: 'A', scope: !0, controller: '@', priority: 500 };
        },
      ],
      hc = {},
      hw = { blur: !0, focus: !0 };
    t(
      'click dblclick mousedown mouseup mouseover mouseout mousemove mouseenter mouseleave keydown keyup keypress submit focus blur copy cut paste'.split(
        ' '
      ),
      function (n) {
        var t = yt('ng-' + n);
        hc[t] = [
          '$parse',
          '$rootScope',
          function (i, r) {
            return {
              restrict: 'A',
              compile: function (u, f) {
                var e = i(f[t], null, !0);
                return function (t, i) {
                  i.on(n, function (i) {
                    var u = function () {
                      e(t, { $event: i });
                    };
                    hw[n] && r.$$phase ? t.$evalAsync(u) : t.$apply(u);
                  });
                };
              },
            };
          },
        ];
      }
    );
    var cw = [
        '$animate',
        '$compile',
        function (n, t) {
          return {
            multiElement: !0,
            transclude: 'element',
            priority: 600,
            terminal: !0,
            restrict: 'A',
            $$tlb: !0,
            link: function (i, r, u, f, e) {
              var h, s, o;
              i.$watch(u.ngIf, function (i) {
                i
                  ? s ||
                    e(function (i, f) {
                      s = f;
                      i[i.length++] = t.$$createComment('end ngIf', u.ngIf);
                      h = { clone: i };
                      n.enter(i, r.parent(), r);
                    })
                  : (o && (o.remove(), (o = null)),
                    s && (s.$destroy(), (s = null)),
                    h &&
                      ((o = ru(h.clone)),
                      n.leave(o).then(function () {
                        o = null;
                      }),
                      (h = null)));
              });
            },
          };
        },
      ],
      lw = [
        '$templateRequest',
        '$anchorScroll',
        '$animate',
        function (n, t, i) {
          return {
            restrict: 'ECA',
            priority: 400,
            terminal: !0,
            transclude: 'element',
            controller: ut.noop,
            compile: function (r, f) {
              var o = f.ngInclude || f.src,
                s = f.onload || '',
                e = f.autoscroll;
              return function (r, f, h, c, l) {
                var p = 0,
                  a,
                  v,
                  y,
                  w = function () {
                    v && (v.remove(), (v = null));
                    a && (a.$destroy(), (a = null));
                    y &&
                      (i.leave(y).then(function () {
                        v = null;
                      }),
                      (v = y),
                      (y = null));
                  };
                r.$watch(o, function (o) {
                  var v = function () {
                      u(e) && (!e || r.$eval(e)) && t();
                    },
                    h = ++p;
                  o
                    ? (n(o, !0).then(
                        function (n) {
                          if (!r.$$destroyed && h === p) {
                            var t = r.$new();
                            c.template = n;
                            n = l(t, function (n) {
                              w();
                              i.enter(n, null, f).then(v);
                            });
                            a = t;
                            y = n;
                            a.$emit('$includeContentLoaded', o);
                            r.$eval(s);
                          }
                        },
                        function () {
                          r.$$destroyed ||
                            h !== p ||
                            (w(), r.$emit('$includeContentError', o));
                        }
                      ),
                      r.$emit('$includeContentRequested', o))
                    : (w(), (c.template = null));
                });
              };
            },
          };
        },
      ],
      aw = [
        '$compile',
        function (t) {
          return {
            restrict: 'ECA',
            priority: -400,
            require: 'ngInclude',
            link: function (i, r, u, f) {
              rt.call(r[0]).match(/SVG/)
                ? (r.empty(),
                  t(ro(f.template, n.document).childNodes)(
                    i,
                    function (n) {
                      r.append(n);
                    },
                    { futureParentElement: r }
                  ))
                : (r.html(f.template), t(r.contents())(i));
            },
          };
        },
      ],
      vw = hi({
        priority: 450,
        compile: function () {
          return {
            pre: function (n, t, i) {
              n.$eval(i.ngInit);
            },
          };
        },
      }),
      yw = function () {
        return {
          restrict: 'A',
          priority: 100,
          require: 'ngModel',
          link: function (n, i, u, f) {
            var e = i.attr(u.$attr.ngList) || ', ',
              o = 'false' !== u.ngTrim,
              s = o ? p(e) : e;
            f.$parsers.push(function (n) {
              if (!r(n)) {
                var i = [];
                return (
                  n &&
                    t(n.split(s), function (n) {
                      n && i.push(o ? p(n) : n);
                    }),
                  i
                );
              }
            });
            f.$formatters.push(function (n) {
              if (c(n)) return n.join(e);
            });
            f.$isEmpty = function (n) {
              return !n || !n.length;
            };
          },
        };
      },
      gr = 'ng-valid',
      cc = 'ng-invalid',
      tr = 'ng-pristine',
      ef = 'ng-dirty',
      lc = 'ng-pending',
      nu = l('ngModel'),
      pw = [
        '$scope',
        '$exceptionHandler',
        '$attrs',
        '$element',
        '$parse',
        '$animate',
        '$timeout',
        '$rootScope',
        '$q',
        '$interpolate',
        function (n, i, e, s, h, c, l, a, v, y) {
          var d;
          this.$modelValue = this.$viewValue = Number.NaN;
          this.$$rawModelValue = void 0;
          this.$validators = {};
          this.$asyncValidators = {};
          this.$parsers = [];
          this.$formatters = [];
          this.$viewChangeListeners = [];
          this.$untouched = !0;
          this.$touched = !1;
          this.$pristine = !0;
          this.$dirty = !1;
          this.$valid = !0;
          this.$invalid = !1;
          this.$error = {};
          this.$$success = {};
          this.$pending = void 0;
          this.$name = y(e.name || '', !1)(n);
          this.$$parentForm = dr;
          var k = h(e.ngModel),
            tt = k.assign,
            nt = k,
            it = tt,
            g = null,
            b,
            p = this;
          this.$$setOptions = function (n) {
            if ((p.$options = n) && n.getterSetter) {
              var t = h(e.ngModel + '()'),
                i = h(e.ngModel + '($$$p)');
              nt = function (n) {
                var i = k(n);
                return f(i) && (i = t(n)), i;
              };
              it = function (n, t) {
                f(k(n)) ? i(n, { $$$p: t }) : tt(n, t);
              };
            } else if (!k.assign) throw nu('nonassign', e.ngModel, vt(s));
          };
          this.$render = o;
          this.$isEmpty = function (n) {
            return r(n) || '' === n || null === n || n !== n;
          };
          this.$$updateEmptyClasses = function (n) {
            p.$isEmpty(n)
              ? (c.removeClass(s, 'ng-not-empty'), c.addClass(s, 'ng-empty'))
              : (c.removeClass(s, 'ng-empty'), c.addClass(s, 'ng-not-empty'));
          };
          d = 0;
          uh({
            ctrl: this,
            $element: s,
            set: function (n, t) {
              n[t] = !0;
            },
            unset: function (n, t) {
              delete n[t];
            },
            $animate: c,
          });
          this.$setPristine = function () {
            p.$dirty = !1;
            p.$pristine = !0;
            c.removeClass(s, ef);
            c.addClass(s, tr);
          };
          this.$setDirty = function () {
            p.$dirty = !0;
            p.$pristine = !1;
            c.removeClass(s, tr);
            c.addClass(s, ef);
            p.$$parentForm.$setDirty();
          };
          this.$setUntouched = function () {
            p.$touched = !1;
            p.$untouched = !0;
            c.setClass(s, 'ng-untouched', 'ng-touched');
          };
          this.$setTouched = function () {
            p.$touched = !0;
            p.$untouched = !1;
            c.setClass(s, 'ng-touched', 'ng-untouched');
          };
          this.$rollbackViewValue = function () {
            l.cancel(g);
            p.$viewValue = p.$$lastCommittedViewValue;
            p.$render();
          };
          this.$validate = function () {
            if (!w(p.$modelValue) || !isNaN(p.$modelValue)) {
              var n = p.$$rawModelValue,
                t = p.$valid,
                i = p.$modelValue,
                r = p.$options && p.$options.allowInvalid;
              p.$$runValidators(n, p.$$lastCommittedViewValue, function (u) {
                r ||
                  t === u ||
                  ((p.$modelValue = u ? n : void 0),
                  p.$modelValue !== i && p.$$writeModelToScope());
              });
            }
          };
          this.$$runValidators = function (n, i, u) {
            function c() {
              var r = !0;
              return (
                t(p.$validators, function (t, u) {
                  var f = t(n, i);
                  r = r && f;
                  e(u, f);
                }),
                r
                  ? !0
                  : (t(p.$asyncValidators, function (n, t) {
                      e(t, null);
                    }),
                    !1)
              );
            }
            function l() {
              var r = [],
                u = !0;
              t(p.$asyncValidators, function (t, o) {
                var s = t(n, i);
                if (!s || !f(s.then)) throw nu('nopromise', s);
                e(o, void 0);
                r.push(
                  s.then(
                    function () {
                      e(o, !0);
                    },
                    function () {
                      u = !1;
                      e(o, !1);
                    }
                  )
                );
              });
              r.length
                ? v.all(r).then(function () {
                    s(u);
                  }, o)
                : s(!0);
            }
            function e(n, t) {
              h === d && p.$setValidity(n, t);
            }
            function s(n) {
              h === d && u(n);
            }
            d++;
            var h = d;
            (function () {
              var n = p.$$parserName || 'parse';
              if (r(b)) e(n, null);
              else
                return (
                  b ||
                    (t(p.$validators, function (n, t) {
                      e(t, null);
                    }),
                    t(p.$asyncValidators, function (n, t) {
                      e(t, null);
                    })),
                  e(n, b),
                  b
                );
              return !0;
            })()
              ? c()
                ? l()
                : s(!1)
              : s(!1);
          };
          this.$commitViewValue = function () {
            var n = p.$viewValue;
            l.cancel(g);
            (p.$$lastCommittedViewValue !== n ||
              ('' === n && p.$$hasNativeValidators)) &&
              (p.$$updateEmptyClasses(n),
              (p.$$lastCommittedViewValue = n),
              p.$pristine && this.$setDirty(),
              this.$$parseAndValidate());
          };
          this.$$parseAndValidate = function () {
            var t = p.$$lastCommittedViewValue,
              i,
              u,
              f;
            if ((b = r(t) ? void 0 : !0))
              for (i = 0; i < p.$parsers.length; i++)
                if (((t = p.$parsers[i](t)), r(t))) {
                  b = !1;
                  break;
                }
            w(p.$modelValue) && isNaN(p.$modelValue) && (p.$modelValue = nt(n));
            u = p.$modelValue;
            f = p.$options && p.$options.allowInvalid;
            p.$$rawModelValue = t;
            f &&
              ((p.$modelValue = t),
              p.$modelValue !== u && p.$$writeModelToScope());
            p.$$runValidators(t, p.$$lastCommittedViewValue, function (n) {
              f ||
                ((p.$modelValue = n ? t : void 0),
                p.$modelValue !== u && p.$$writeModelToScope());
            });
          };
          this.$$writeModelToScope = function () {
            it(n, p.$modelValue);
            t(p.$viewChangeListeners, function (n) {
              try {
                n();
              } catch (t) {
                i(t);
              }
            });
          };
          this.$setViewValue = function (n, t) {
            p.$viewValue = n;
            (p.$options && !p.$options.updateOnDefault) ||
              p.$$debounceViewValueCommit(t);
          };
          this.$$debounceViewValueCommit = function (t) {
            var r = 0,
              i = p.$options;
            i &&
              u(i.debounce) &&
              ((i = i.debounce),
              w(i)
                ? (r = i)
                : w(i[t])
                ? (r = i[t])
                : w(i['default']) && (r = i['default']));
            l.cancel(g);
            r
              ? (g = l(function () {
                  p.$commitViewValue();
                }, r))
              : a.$$phase
              ? p.$commitViewValue()
              : n.$apply(function () {
                  p.$commitViewValue();
                });
          };
          n.$watch(function () {
            var t = nt(n);
            if (
              t !== p.$modelValue &&
              (p.$modelValue === p.$modelValue || t === t)
            ) {
              p.$modelValue = p.$$rawModelValue = t;
              b = void 0;
              for (var r = p.$formatters, u = r.length, i = t; u--; )
                i = r[u](i);
              p.$viewValue !== i &&
                (p.$$updateEmptyClasses(i),
                (p.$viewValue = p.$$lastCommittedViewValue = i),
                p.$render(),
                p.$$runValidators(t, i, o));
            }
            return t;
          });
        },
      ],
      ww = [
        '$rootScope',
        function (n) {
          return {
            restrict: 'A',
            require: ['ngModel', '^?form', '^?ngModelOptions'],
            controller: pw,
            priority: 1,
            compile: function (t) {
              return (
                t.addClass(tr).addClass('ng-untouched').addClass(gr),
                {
                  pre: function (n, t, i, r) {
                    var u = r[0];
                    t = r[1] || u.$$parentForm;
                    u.$$setOptions(r[2] && r[2].$options);
                    t.$addControl(u);
                    i.$observe('name', function (n) {
                      u.$name !== n && u.$$parentForm.$$renameControl(u, n);
                    });
                    n.$on('$destroy', function () {
                      u.$$parentForm.$removeControl(u);
                    });
                  },
                  post: function (t, i, r, u) {
                    var f = u[0];
                    if (f.$options && f.$options.updateOn)
                      i.on(f.$options.updateOn, function (n) {
                        f.$$debounceViewValueCommit(n && n.type);
                      });
                    i.on('blur', function () {
                      f.$touched ||
                        (n.$$phase
                          ? t.$evalAsync(f.$setTouched)
                          : t.$apply(f.$setTouched));
                    });
                  },
                }
              );
            },
          };
        },
      ],
      bw = /(\s+|^)default(\s+|$)/,
      kw = function () {
        return {
          restrict: 'A',
          controller: [
            '$scope',
            '$attrs',
            function (n, t) {
              var i = this;
              this.$options = kt(n.$eval(t.ngModelOptions));
              u(this.$options.updateOn)
                ? ((this.$options.updateOnDefault = !1),
                  (this.$options.updateOn = p(
                    this.$options.updateOn.replace(bw, function () {
                      return (i.$options.updateOnDefault = !0), ' ';
                    })
                  )))
                : (this.$options.updateOnDefault = !0);
            },
          ],
        };
      },
      dw = hi({ terminal: !0, priority: 1e3 }),
      gw = l('ngOptions'),
      nb = /^\s*([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+group\s+by\s+([\s\S]+?))?(?:\s+disable\s+when\s+([\s\S]+?))?\s+for\s+(?:([\$\w][\$\w]*)|(?:\(\s*([\$\w][\$\w]*)\s*,\s*([\$\w][\$\w]*)\s*\)))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/,
      tb = [
        '$compile',
        '$document',
        '$parse',
        function (i, r, f) {
          function h(n, t, i) {
            function w(n, t, i, r, u) {
              this.selectValue = n;
              this.viewValue = t;
              this.label = i;
              this.group = r;
              this.disabled = u;
            }
            function l(n) {
              var t, i;
              if (!s && ri(n)) t = n;
              else {
                t = [];
                for (i in n)
                  n.hasOwnProperty(i) && '$' !== i.charAt(0) && t.push(i);
              }
              return t;
            }
            var r = n.match(nb),
              o,
              s,
              u;
            if (!r) throw gw('iexp', n, vt(t));
            o = r[5] || r[7];
            s = r[6];
            n = / as /.test(r[0]) && r[1];
            u = r[9];
            t = f(r[2] ? r[1] : o);
            var b = (n && f(n)) || t,
              k = u && f(u),
              h = u
                ? function (n, t) {
                    return k(i, t);
                  }
                : function (n) {
                    return fi(n);
                  },
              a = function (n, t) {
                return h(n, c(n, t));
              },
              v = f(r[2] || r[1]),
              d = f(r[3] || ''),
              y = f(r[4] || ''),
              p = f(r[8]),
              e = {},
              c = s
                ? function (n, t) {
                    return (e[s] = t), (e[o] = n), e;
                  }
                : function (n) {
                    return (e[o] = n), e;
                  };
            return {
              trackBy: u,
              getTrackByValue: a,
              getWatchables: f(p, function (n) {
                var f = [];
                n = n || [];
                for (var o = l(n), s = o.length, e = 0; e < s; e++) {
                  var t = n === o ? e : o[e],
                    u = n[t],
                    t = c(u, t),
                    u = h(u, t);
                  f.push(u);
                  (r[2] || r[1]) && ((u = v(i, t)), f.push(u));
                  r[4] && ((t = y(i, t)), f.push(t));
                }
                return f;
              }),
              getOptions: function () {
                for (
                  var k = [],
                    e = {},
                    o = p(i) || [],
                    s = l(o),
                    g = s.length,
                    f = 0;
                  f < g;
                  f++
                ) {
                  var t = o === s ? f : s[f],
                    n = c(o[t], t),
                    r = b(i, n),
                    t = h(r, n),
                    nt = v(i, n),
                    tt = d(i, n),
                    n = y(i, n),
                    r = new w(t, r, nt, tt, n);
                  k.push(r);
                  e[t] = r;
                }
                return {
                  items: k,
                  selectValueMap: e,
                  getOptionFromViewValue: function (n) {
                    return e[a(n)];
                  },
                  getViewValueFromOption: function (n) {
                    return u ? ut.copy(n.viewValue) : n.viewValue;
                  },
                };
              },
            };
          }
          var s = n.document.createElement('option'),
            l = n.document.createElement('optgroup');
          return {
            restrict: 'A',
            terminal: !0,
            require: ['select', 'ngModel'],
            link: {
              pre: function (n, t, i, r) {
                r[0].registerOption = o;
              },
              post: function (n, f, o, a) {
                function ut(n, t) {
                  n.element = t;
                  t.disabled = n.disabled;
                  n.label !== t.label &&
                    ((t.label = n.label), (t.textContent = n.label));
                  n.value !== t.value && (t.value = n.selectValue);
                }
                function tt() {
                  var i = y && d.readValue(),
                    n,
                    t,
                    r;
                  if (y)
                    for (n = y.items.length - 1; 0 <= n; n--)
                      (t = y.items[n]),
                        t.group ? cu(t.element.parentNode) : cu(t.element);
                  y = k.getOptions();
                  r = {};
                  w && f.prepend(v);
                  y.items.forEach(function (n) {
                    var t, i;
                    u(n.group)
                      ? ((t = r[n.group]),
                        t ||
                          ((t = l.cloneNode(!1)),
                          nt.appendChild(t),
                          (t.label = n.group),
                          (r[n.group] = t)),
                        (i = s.cloneNode(!1)))
                      : ((t = nt), (i = s.cloneNode(!1)));
                    t.appendChild(i);
                    ut(n, i);
                  });
                  f[0].appendChild(nt);
                  p.$render();
                  p.$isEmpty(i) ||
                    ((n = d.readValue()),
                    (k.trackBy || it ? st(i, n) : i === n) ||
                      (p.$setViewValue(n), p.$render()));
                }
                var d = a[0],
                  p = a[1],
                  it = o.multiple,
                  v,
                  g,
                  rt,
                  w,
                  b,
                  y,
                  k,
                  nt;
                for (a = 0, g = f.children(), rt = g.length; a < rt; a++)
                  if ('' === g[a].value) {
                    v = g.eq(a);
                    break;
                  }
                w = !!v;
                b = e(s.cloneNode(!1));
                b.val('?');
                k = h(o.ngOptions, f, n);
                nt = r[0].createDocumentFragment();
                it
                  ? ((p.$isEmpty = function (n) {
                      return !n || 0 === n.length;
                    }),
                    (d.writeValue = function (n) {
                      y.items.forEach(function (n) {
                        n.element.selected = !1;
                      });
                      n &&
                        n.forEach(function (n) {
                          (n = y.getOptionFromViewValue(n)) &&
                            (n.element.selected = !0);
                        });
                    }),
                    (d.readValue = function () {
                      var i = f.val() || [],
                        n = [];
                      return (
                        t(i, function (t) {
                          (t = y.selectValueMap[t]) &&
                            !t.disabled &&
                            n.push(y.getViewValueFromOption(t));
                        }),
                        n
                      );
                    }),
                    k.trackBy &&
                      n.$watchCollection(
                        function () {
                          if (c(p.$viewValue))
                            return p.$viewValue.map(function (n) {
                              return k.getTrackByValue(n);
                            });
                        },
                        function () {
                          p.$render();
                        }
                      ))
                  : ((d.writeValue = function (n) {
                      var t = y.getOptionFromViewValue(n);
                      t
                        ? (f[0].value !== t.selectValue &&
                            (b.remove(),
                            w || v.remove(),
                            (f[0].value = t.selectValue),
                            (t.element.selected = !0)),
                          t.element.setAttribute('selected', 'selected'))
                        : null === n || w
                        ? (b.remove(),
                          w || f.prepend(v),
                          f.val(''),
                          v.prop('selected', !0),
                          v.attr('selected', !0))
                        : (w || v.remove(),
                          f.prepend(b),
                          f.val('?'),
                          b.prop('selected', !0),
                          b.attr('selected', !0));
                    }),
                    (d.readValue = function () {
                      var n = y.selectValueMap[f.val()];
                      return n && !n.disabled
                        ? (w || v.remove(),
                          b.remove(),
                          y.getViewValueFromOption(n))
                        : null;
                    }),
                    k.trackBy &&
                      n.$watch(
                        function () {
                          return k.getTrackByValue(p.$viewValue);
                        },
                        function () {
                          p.$render();
                        }
                      ));
                w
                  ? (v.remove(), i(v)(n), v.removeClass('ng-scope'))
                  : (v = e(s.cloneNode(!1)));
                f.empty();
                tt();
                n.$watchCollection(k.getWatchables, tt);
              },
            },
          };
        },
      ],
      ib = [
        '$locale',
        '$interpolate',
        '$log',
        function (n, i, u) {
          var f = /{}/g,
            e = /^when(Minus)?(.+)$/;
          return {
            link: function (s, h, c) {
              function p(n) {
                h.text(n || '');
              }
              var b = c.count,
                k = c.$attr.when && h.attr(c.$attr.when),
                d = c.offset || 0,
                a = s.$eval(k) || {},
                g = {},
                nt = i.startSymbol(),
                tt = i.endSymbol(),
                it = nt + b + '-' + d + tt,
                y = ut.noop,
                l;
              t(c, function (n, t) {
                var i = e.exec(t);
                i &&
                  ((i = (i[1] ? '-' : '') + v(i[2])),
                  (a[i] = h.attr(c.$attr[t])));
              });
              t(a, function (n, t) {
                g[t] = i(n.replace(f, it));
              });
              s.$watch(b, function (t) {
                var i = parseFloat(t),
                  f = isNaN(i);
                f || i in a || (i = n.pluralCat(i - d));
                i === l ||
                  (f && w(l) && isNaN(l)) ||
                  (y(),
                  (f = g[i]),
                  r(f)
                    ? (null != t &&
                        u.debug(
                          "ngPluralize: no rule defined for '" + i + "' in " + k
                        ),
                      (y = o),
                      p())
                    : (y = s.$watch(f, p)),
                  (l = i));
              });
            },
          };
        },
      ],
      rb = [
        '$parse',
        '$animate',
        '$compile',
        function (n, i, r) {
          var u = l('ngRepeat'),
            f = function (n, t, i, r, u, f, e) {
              n[i] = r;
              u && (n[u] = f);
              n.$index = t;
              n.$first = 0 === t;
              n.$last = t === e - 1;
              n.$middle = !(n.$first || n.$last);
              n.$odd = !(n.$even = 0 == (t & 1));
            };
          return {
            restrict: 'A',
            multiElement: !0,
            transclude: 'element',
            priority: 1e3,
            terminal: !0,
            $$tlb: !0,
            compile: function (e, o) {
              var a = o.ngRepeat,
                nt = r.$$createComment('end ngRepeat', a),
                s = a.match(
                  /^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/
                ),
                v,
                c,
                p,
                w,
                d,
                g,
                l;
              if (!s) throw u('iexp', a);
              var b = s[1],
                tt = s[2],
                h = s[3],
                k = s[4],
                s = b.match(
                  /^(?:(\s*[\$\w]+)|\(\s*([\$\w]+)\s*,\s*([\$\w]+)\s*\))$/
                );
              if (!s) throw u('iidexp', b);
              if (
                ((v = s[3] || s[1]),
                (c = s[2]),
                h &&
                  (!/^[$a-zA-Z_][$a-zA-Z0-9_]*$/.test(h) ||
                    /^(null|undefined|this|\$index|\$first|\$middle|\$last|\$even|\$odd|\$parent|\$root|\$id)$/.test(
                      h
                    )))
              )
                throw u('badident', h);
              return (
                (l = { $id: fi }),
                k
                  ? (p = n(k))
                  : ((d = function (n, t) {
                      return fi(t);
                    }),
                    (g = function (n) {
                      return n;
                    })),
                function (n, r, e, o, s) {
                  p &&
                    (w = function (t, i, r) {
                      return (
                        c && (l[c] = t), (l[v] = i), (l.$index = r), p(n, l)
                      );
                    });
                  var b = y();
                  n.$watchCollection(tt, function (e) {
                    var o,
                      et,
                      ot = r[0],
                      tt,
                      st = y(),
                      ut,
                      rt,
                      ft,
                      p,
                      it,
                      l,
                      k;
                    if ((h && (n[h] = e), ri(e))) (it = e), (et = w || d);
                    else
                      for (k in ((et = w || g), (it = []), e))
                        wt.call(e, k) && '$' !== k.charAt(0) && it.push(k);
                    for (ut = it.length, k = Array(ut), o = 0; o < ut; o++)
                      if (
                        ((rt = e === it ? o : it[o]),
                        (ft = e[rt]),
                        (p = et(rt, ft, o)),
                        b[p])
                      )
                        (l = b[p]), delete b[p], (st[p] = l), (k[o] = l);
                      else {
                        if (st[p])
                          throw (
                            (t(k, function (n) {
                              n && n.scope && (b[n.id] = n);
                            }),
                            u('dupes', a, p, ft))
                          );
                        k[o] = { id: p, scope: void 0, clone: void 0 };
                        st[p] = !0;
                      }
                    for (tt in b) {
                      if (
                        ((l = b[tt]),
                        (p = ru(l.clone)),
                        i.leave(p),
                        p[0].parentNode)
                      )
                        for (o = 0, et = p.length; o < et; o++)
                          p[o].$$NG_REMOVED = !0;
                      l.scope.$destroy();
                    }
                    for (o = 0; o < ut; o++)
                      if (
                        ((rt = e === it ? o : it[o]),
                        (ft = e[rt]),
                        (l = k[o]),
                        l.scope)
                      ) {
                        tt = ot;
                        do tt = tt.nextSibling;
                        while (tt && tt.$$NG_REMOVED);
                        l.clone[0] != tt && i.move(ru(l.clone), null, ot);
                        ot = l.clone[l.clone.length - 1];
                        f(l.scope, o, v, ft, c, rt, ut);
                      } else
                        s(function (n, t) {
                          l.scope = t;
                          var r = nt.cloneNode(!1);
                          n[n.length++] = r;
                          i.enter(n, null, ot);
                          ot = r;
                          l.clone = n;
                          st[l.id] = l;
                          f(l.scope, o, v, ft, c, rt, ut);
                        });
                    b = st;
                  });
                }
              );
            },
          };
        },
      ],
      ub = [
        '$animate',
        function (n) {
          return {
            restrict: 'A',
            multiElement: !0,
            link: function (t, i, r) {
              t.$watch(r.ngShow, function (t) {
                n[t ? 'removeClass' : 'addClass'](i, 'ng-hide', {
                  tempClasses: 'ng-hide-animate',
                });
              });
            },
          };
        },
      ],
      fb = [
        '$animate',
        function (n) {
          return {
            restrict: 'A',
            multiElement: !0,
            link: function (t, i, r) {
              t.$watch(r.ngHide, function (t) {
                n[t ? 'addClass' : 'removeClass'](i, 'ng-hide', {
                  tempClasses: 'ng-hide-animate',
                });
              });
            },
          };
        },
      ],
      eb = hi(function (n, i, r) {
        n.$watch(
          r.ngStyle,
          function (n, r) {
            r &&
              n !== r &&
              t(r, function (n, t) {
                i.css(t, '');
              });
            n && i.css(n);
          },
          !0
        );
      }),
      ob = [
        '$animate',
        '$compile',
        function (n, i) {
          return {
            require: 'ngSwitch',
            controller: [
              '$scope',
              function () {
                this.cases = {};
              },
            ],
            link: function (r, u, f, e) {
              var c = [],
                h = [],
                o = [],
                s = [],
                l = function (n, t) {
                  return function () {
                    n.splice(t, 1);
                  };
                };
              r.$watch(f.ngSwitch || f.on, function (r) {
                for (var a, u = 0, f = o.length; u < f; ++u) n.cancel(o[u]);
                for (u = o.length = 0, f = s.length; u < f; ++u)
                  (a = ru(h[u].clone)),
                    s[u].$destroy(),
                    (o[u] = n.leave(a)).then(l(o, u));
                h.length = 0;
                s.length = 0;
                (c = e.cases['!' + r] || e.cases['?']) &&
                  t(c, function (t) {
                    t.transclude(function (r, u) {
                      s.push(u);
                      var f = t.element;
                      r[r.length++] = i.$$createComment('end ngSwitchWhen');
                      h.push({ clone: r });
                      n.enter(r, f.parent(), f);
                    });
                  });
              });
            },
          };
        },
      ],
      sb = hi({
        transclude: 'element',
        priority: 1200,
        require: '^ngSwitch',
        multiElement: !0,
        link: function (n, t, i, r, u) {
          r.cases['!' + i.ngSwitchWhen] = r.cases['!' + i.ngSwitchWhen] || [];
          r.cases['!' + i.ngSwitchWhen].push({ transclude: u, element: t });
        },
      }),
      hb = hi({
        transclude: 'element',
        priority: 1200,
        require: '^ngSwitch',
        multiElement: !0,
        link: function (n, t, i, r, u) {
          r.cases['?'] = r.cases['?'] || [];
          r.cases['?'].push({ transclude: u, element: t });
        },
      }),
      cb = l('ngTransclude'),
      lb = hi({
        restrict: 'EAC',
        link: function (n, t, i, r, u) {
          if (
            (i.ngTransclude === i.$attr.ngTransclude && (i.ngTransclude = ''),
            !u)
          )
            throw cb('orphan', vt(t));
          u(
            function (n) {
              n.length && (t.empty(), t.append(n));
            },
            null,
            i.ngTransclude || i.ngTranscludeSlot
          );
        },
      }),
      ab = [
        '$templateCache',
        function (n) {
          return {
            restrict: 'E',
            terminal: !0,
            compile: function (t, i) {
              'text/ng-template' == i.type && n.put(i.id, t[0].text);
            },
          };
        },
      ],
      vb = { $setViewValue: o, $render: o },
      yb = [
        '$element',
        '$scope',
        function (t, i) {
          var r = this,
            f = new wi();
          r.ngModelCtrl = vb;
          r.unknownOption = e(n.document.createElement('option'));
          r.renderUnknownOption = function (n) {
            n = '? ' + fi(n) + ' ?';
            r.unknownOption.val(n);
            t.prepend(r.unknownOption);
            t.val(n);
          };
          i.$on('$destroy', function () {
            r.renderUnknownOption = o;
          });
          r.removeUnknownOption = function () {
            r.unknownOption.parent() && r.unknownOption.remove();
          };
          r.readValue = function () {
            return r.removeUnknownOption(), t.val();
          };
          r.writeValue = function (n) {
            r.hasOption(n)
              ? (r.removeUnknownOption(),
                t.val(n),
                '' === n && r.emptyOption.prop('selected', !0))
              : null == n && r.emptyOption
              ? (r.removeUnknownOption(), t.val(''))
              : r.renderUnknownOption(n);
          };
          r.addOption = function (n, t) {
            if (8 !== t[0].nodeType) {
              pi(n, '"option value"');
              '' === n && (r.emptyOption = t);
              var i = f.get(n) || 0;
              f.put(n, i + 1);
              r.ngModelCtrl.$render();
              t[0].hasAttribute('selected') && (t[0].selected = !0);
            }
          };
          r.removeOption = function (n) {
            var t = f.get(n);
            t &&
              (1 === t
                ? (f.remove(n), '' === n && (r.emptyOption = void 0))
                : f.put(n, t - 1));
          };
          r.hasOption = function (n) {
            return !!f.get(n);
          };
          r.registerOption = function (n, t, i, f, e) {
            if (f) {
              var o;
              i.$observe('value', function (n) {
                u(o) && r.removeOption(o);
                o = n;
                r.addOption(n, t);
              });
            } else
              e
                ? n.$watch(e, function (n, u) {
                    i.$set('value', n);
                    u !== n && r.removeOption(u);
                    r.addOption(n, t);
                  })
                : r.addOption(i.value, t);
            t.on('$destroy', function () {
              r.removeOption(i.value);
              r.ngModelCtrl.$render();
            });
          };
        },
      ],
      pb = function () {
        return {
          restrict: 'E',
          require: ['select', '?ngModel'],
          controller: yb,
          priority: 1,
          link: {
            pre: function (n, i, r, f) {
              var e = f[1],
                o,
                h,
                s;
              if (e) {
                o = f[0];
                o.ngModelCtrl = e;
                i.on('change', function () {
                  n.$apply(function () {
                    e.$setViewValue(o.readValue());
                  });
                });
                r.multiple &&
                  ((o.readValue = function () {
                    var n = [];
                    return (
                      t(i.find('option'), function (t) {
                        t.selected && n.push(t.value);
                      }),
                      n
                    );
                  }),
                  (o.writeValue = function (n) {
                    var r = new wi(n);
                    t(i.find('option'), function (n) {
                      n.selected = u(r.get(n.value));
                    });
                  }),
                  (s = NaN),
                  n.$watch(function () {
                    s !== e.$viewValue ||
                      st(h, e.$viewValue) ||
                      ((h = ot(e.$viewValue)), e.$render());
                    s = e.$viewValue;
                  }),
                  (e.$isEmpty = function (n) {
                    return !n || 0 === n.length;
                  }));
              }
            },
            post: function (n, t, i, r) {
              var u = r[1],
                f;
              u &&
                ((f = r[0]),
                (u.$render = function () {
                  f.writeValue(u.$viewValue);
                }));
            },
          },
        };
      },
      wb = [
        '$interpolate',
        function (n) {
          return {
            restrict: 'E',
            priority: 100,
            compile: function (t, i) {
              var f, r;
              return (
                u(i.value)
                  ? (f = n(i.value, !0))
                  : ((r = n(t.text(), !0)), r || i.$set('value', t.text())),
                function (n, t, i) {
                  var u = t.parent();
                  (u =
                    u.data('$selectController') ||
                    u.parent().data('$selectController')) &&
                    u.registerOption(n, t, i, f, r);
                }
              );
            },
          };
        },
      ],
      bb = ft({ restrict: 'E', terminal: !1 }),
      ac = function () {
        return {
          restrict: 'A',
          require: '?ngModel',
          link: function (n, t, i, r) {
            r &&
              ((i.required = !0),
              (r.$validators.required = function (n, t) {
                return !i.required || !r.$isEmpty(t);
              }),
              i.$observe('required', function () {
                r.$validate();
              }));
          },
        };
      },
      vc = function () {
        return {
          restrict: 'A',
          require: '?ngModel',
          link: function (n, t, i, u) {
            if (u) {
              var f,
                e = i.ngPattern || i.pattern;
              i.$observe('pattern', function (n) {
                if (
                  (h(n) && 0 < n.length && (n = new RegExp('^' + n + '$')),
                  n && !n.test)
                )
                  throw l('ngPattern')('noregexp', e, n, vt(t));
                f = n || void 0;
                u.$validate();
              });
              u.$validators.pattern = function (n, t) {
                return u.$isEmpty(t) || r(f) || f.test(t);
              };
            }
          },
        };
      },
      yc = function () {
        return {
          restrict: 'A',
          require: '?ngModel',
          link: function (n, t, i, r) {
            if (r) {
              var u = -1;
              i.$observe('maxlength', function (n) {
                n = tt(n);
                u = isNaN(n) ? -1 : n;
                r.$validate();
              });
              r.$validators.maxlength = function (n, t) {
                return 0 > u || r.$isEmpty(t) || t.length <= u;
              };
            }
          },
        };
      },
      pc = function () {
        return {
          restrict: 'A',
          require: '?ngModel',
          link: function (n, t, i, r) {
            if (r) {
              var u = 0;
              i.$observe('minlength', function (n) {
                u = tt(n) || 0;
                r.$validate();
              });
              r.$validators.minlength = function (n, t) {
                return r.$isEmpty(t) || t.length >= u;
              };
            }
          },
        };
      };
    n.angular.bootstrap
      ? n.console &&
        console.log('WARNING: Tried to load angular more than once.')
      : (ul(),
        el(ut),
        ut.module(
          'ngLocale',
          [],
          [
            '$provide',
            function (n) {
              function t(n) {
                n += '';
                var t = n.indexOf('.');
                return -1 == t ? 0 : n.length - t - 1;
              }
              n.value('$locale', {
                DATETIME_FORMATS: {
                  AMPMS: ['AM', 'PM'],
                  DAY: 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday'.split(
                    ' '
                  ),
                  ERANAMES: ['Before Christ', 'Anno Domini'],
                  ERAS: ['BC', 'AD'],
                  FIRSTDAYOFWEEK: 6,
                  MONTH: 'January February March April May June July August September October November December'.split(
                    ' '
                  ),
                  SHORTDAY: 'Sun Mon Tue Wed Thu Fri Sat'.split(' '),
                  SHORTMONTH: 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(
                    ' '
                  ),
                  STANDALONEMONTH: 'January February March April May June July August September October November December'.split(
                    ' '
                  ),
                  WEEKENDRANGE: [5, 6],
                  fullDate: 'EEEE, MMMM d, y',
                  longDate: 'MMMM d, y',
                  medium: 'MMM d, y h:mm:ss a',
                  mediumDate: 'MMM d, y',
                  mediumTime: 'h:mm:ss a',
                  short: 'M/d/yy h:mm a',
                  shortDate: 'M/d/yy',
                  shortTime: 'h:mm a',
                },
                NUMBER_FORMATS: {
                  CURRENCY_SYM: '$',
                  DECIMAL_SEP: '.',
                  GROUP_SEP: ',',
                  PATTERNS: [
                    {
                      gSize: 3,
                      lgSize: 3,
                      maxFrac: 3,
                      minFrac: 0,
                      minInt: 1,
                      negPre: '-',
                      negSuf: '',
                      posPre: '',
                      posSuf: '',
                    },
                    {
                      gSize: 3,
                      lgSize: 3,
                      maxFrac: 2,
                      minFrac: 2,
                      minInt: 1,
                      negPre: '-¤',
                      negSuf: '',
                      posPre: '¤',
                      posSuf: '',
                    },
                  ],
                },
                id: 'en-us',
                localeID: 'en_US',
                pluralCat: function (n, i) {
                  var u = n | 0,
                    r = i;
                  return (
                    void 0 === r && (r = Math.min(t(n), 3)),
                    Math.pow(10, r),
                    1 == u && 0 == r ? 'one' : 'other'
                  );
                },
              });
            },
          ]
        ),
        e(n.document).ready(function () {
          tl(n.document, ge);
        }));
  })(window);
window.angular.$$csp().noInlineStyle ||
  window.angular
    .element(document.head)
    .prepend(
      '<style type="text/css">@charset "UTF-8";[ng\\:cloak],[ng-cloak],[data-ng-cloak],[x-ng-cloak],.ng-cloak,.x-ng-cloak,.ng-hide:not(.ng-hide-animate){display:none !important;}ng\\:form{display:block;}.ng-animate-shim{visibility:hidden;}.ng-anchor{position:absolute;}</style>'
    );
angular.module('slick', []).directive('slick', [
  '$timeout',
  function (n) {
    return {
      restrict: 'AEC',
      scope: {
        initOnload: '@',
        data: '=',
        currentIndex: '=',
        accessibility: '@',
        adaptiveHeight: '@',
        arrows: '@',
        asNavFor: '@',
        appendArrows: '@',
        appendDots: '@',
        autoplay: '@',
        autoplaySpeed: '@',
        centerMode: '@',
        centerPadding: '@',
        cssEase: '@',
        customPaging: '&',
        dots: '@',
        draggable: '@',
        easing: '@',
        fade: '@',
        focusOnSelect: '@',
        infinite: '@',
        initialSlide: '@',
        lazyLoad: '@',
        onBeforeChange: '&',
        onAfterChange: '&',
        onInit: '&',
        onReInit: '&',
        onSetPosition: '&',
        pauseOnHover: '@',
        pauseOnDotsHover: '@',
        responsive: '=',
        rtl: '@',
        slide: '@',
        slidesToShow: '@',
        slidesToScroll: '@',
        speed: '@',
        swipe: '@',
        swipeToSlide: '@',
        touchMove: '@',
        touchThreshold: '@',
        useCSS: '@',
        variableWidth: '@',
        vertical: '@',
        prevArrow: '@',
        nextArrow: '@',
      },
      link: function (t, i, r) {
        var e, u, f;
        return (
          (e = function () {
            return n(function () {
              var n;
              return (
                (n = jQuery(i)),
                n.slick('unslick'),
                n.find('.slick-list').remove(),
                n
              );
            });
          }),
          (u = function () {
            return n(function () {
              var n, f, u;
              return (
                (u = jQuery(i)),
                null != t.currentIndex && (n = t.currentIndex),
                (f = function (n, i) {
                  return t.customPaging({ slick: n, index: i });
                }),
                u.slick({
                  accessibility: 'false' !== t.accessibility,
                  adaptiveHeight: 'true' === t.adaptiveHeight,
                  arrows: 'false' !== t.arrows,
                  asNavFor: t.asNavFor ? t.asNavFor : void 0,
                  appendArrows: jQuery(t.appendArrows ? t.appendArrows : i),
                  appendDots: jQuery(t.appendDots ? t.appendDots : i),
                  autoplay: 'true' === t.autoplay,
                  autoplaySpeed:
                    null != t.autoplaySpeed
                      ? parseInt(t.autoplaySpeed, 10)
                      : 3e3,
                  centerMode: 'true' === t.centerMode,
                  centerPadding: t.centerPadding || '50px',
                  cssEase: t.cssEase || 'ease',
                  customPaging: r.customPaging ? f : void 0,
                  dots: 'true' === t.dots,
                  draggable: 'false' !== t.draggable,
                  easing: t.easing || 'linear',
                  fade: 'true' === t.fade,
                  focusOnSelect: 'true' === t.focusOnSelect,
                  infinite: 'false' !== t.infinite,
                  initialSlide: t.initialSlide || 0,
                  lazyLoad: t.lazyLoad || 'ondemand',
                  beforeChange: r.onBeforeChange ? t.onBeforeChange : void 0,
                  onReInit: r.onReInit ? t.onReInit : void 0,
                  onSetPosition: r.onSetPosition ? t.onSetPosition : void 0,
                  pauseOnHover: 'false' !== t.pauseOnHover,
                  responsive: t.responsive || void 0,
                  rtl: 'true' === t.rtl,
                  slide: t.slide || 'div',
                  slidesToShow:
                    null != t.slidesToShow ? parseInt(t.slidesToShow, 10) : 1,
                  slidesToScroll:
                    null != t.slidesToScroll
                      ? parseInt(t.slidesToScroll, 10)
                      : 1,
                  speed: null != t.speed ? parseInt(t.speed, 10) : 300,
                  swipe: 'false' !== t.swipe,
                  swipeToSlide: 'true' === t.swipeToSlide,
                  touchMove: 'false' !== t.touchMove,
                  touchThreshold: t.touchThreshold
                    ? parseInt(t.touchThreshold, 10)
                    : 5,
                  useCSS: 'false' !== t.useCSS,
                  variableWidth: 'true' === t.variableWidth,
                  vertical: 'true' === t.vertical,
                  prevArrow: t.prevArrow ? jQuery(t.prevArrow) : void 0,
                  nextArrow: t.nextArrow ? jQuery(t.nextArrow) : void 0,
                }),
                u.on('init', function (i) {
                  return (
                    r.onInit && t.onInit(),
                    null != n ? i.slideHandler(n) : void 0
                  );
                }),
                u.on('afterChange', function (i, r, u) {
                  return (
                    t.onAfterChange && t.onAfterChange(),
                    null != n
                      ? t.$apply(function () {
                          return (n = u), (t.currentIndex = u);
                        })
                      : void 0
                  );
                }),
                t.$watch('currentIndex', function (t) {
                  if (null != n && null != t && t !== n)
                    return u.slick('slickGoTo', t);
                })
              );
            });
          }),
          t.initOnload
            ? ((f = !1),
              t.$watch('data', function (n) {
                if (null != n) return f && e(), u(), (f = !0);
              }))
            : u()
        );
      },
    };
  },
]),
  (function () {
    var n = new angular.module('app', ['slick', 'core.module']);
  })(),
  (function () {
    var n = angular.module('core.module', []);
  })(),
  (function () {
    'use strict';
    var n = angular.module('core.module');
    n.service('core.cache.service', [
      'core.constants',
      function (n) {
        var t = this,
          r = function (n, t) {
            this.expiryDate = n;
            this.data = t;
          },
          u = function (n, t, i) {
            if (Modernizr.localstorage) {
              var u = e(i),
                f = JSON.stringify(new r(u, t));
              localStorage.setItem(n, f);
            }
          },
          i = function (n) {
            Modernizr.localstorage && localStorage.removeItem(n);
          },
          f = function (n) {
            if (Modernizr.localstorage && localStorage[n]) {
              var t = JSON.parse(localStorage[n]),
                r = moment();
              if (moment(t.expiryDate) >= r) return t.data;
              i(n);
            }
            return null;
          },
          e = function (t) {
            var i = n.defaults.cacheLifetime,
              r = i.short,
              u;
            return (
              i[t.toString().toLowerCase()] !== undefined
                ? (r = i[t.toString().toLowerCase()])
                : ((u = parseInt(t)), isNaN(u) || (r = u)),
              moment().add(r, 'm')
            );
          };
        t.get = function (n) {
          return f(n);
        };
        t.set = function (n, t, r) {
          i(n);
          u(n, t, r);
        };
        t.remove = function (n) {
          i(n);
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('core.module');
    n.constant('core.constants', {
      basePath: '/angularjs/app/',
      defaults: {
        timeout: 3e4,
        cacheLifetime: { short: 15, medium: 30, long: 60 },
      },
      formats: { shortDate: 'dd/mm/yyyy' },
    });
  })(),
  (function () {
    'use strict';
    var n = angular.module('core.module');
    n.directive('loading', [
      '$compile',
      '$timeout',
      'core.constants',
      function (n, t, i) {
        return {
          restrict: 'EA',
          scope: { whenNot: '=', text: '@', size: '@' },
          link: function (r, u) {
            r.timeout =
              r.timeout !== undefined ? r.timeout : i.defaults.timeout;
            r.size = r.size ? r.size : 'small';
            var f = function () {
                u.find('.loading').remove();
              },
              e = function () {
                t(function () {
                  var t = angular.element(
                      '<span class="loading ' +
                        r.size +
                        '-loading" ng-hide="whenNot"><span class="' +
                        r.size +
                        '-spinner"></span></span>'
                    ),
                    i = n(t)(r);
                  u.append(i);
                  r.text &&
                    u
                      .find('.loading')
                      .append("<span class='text'>" + r.text + '</span>');
                });
              };
            r.$watch('whenNot', function (n) {
              f();
              n || e();
            });
          },
        };
      },
    ]);
    n.directive('date', function () {
      var n = 'dd/mm/yyyy';
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: { fromToday: '=' },
        link: function (t, i, r, u) {
          if (!i.attr('type')) {
            i.attr('type', 'text');
            var f = { format: n };
            t.fromToday && (f.startDate = new Date());
            jQuery(i)
              .datepicker(f)
              .on('changeDate', function (t) {
                var r = moment(t.date).format(n.toUpperCase());
                u.$setViewValue(r);
                u.$viewValue = r;
                u.$render();
                jQuery(i).datepicker('hide');
              });
          }
        },
      };
    });
    n.directive('imgLoad', [
      '$timeout',
      'core.service',
      function (n, t) {
        var i = ['info-gallery', 'slide', 'spas-list.accordion', 'img'];
        return {
          restrict: 'EA',
          scope: { srcModel: '=', altModel: '=', bgParentClass: '@' },
          link: function (r, u) {
            r.$watch('srcModel', function (f) {
              f !== undefined &&
                n(function () {
                  for (var n = 0; n < i.length; n++)
                    if (jQuery(u).parent().hasClass(i[n])) {
                      t.setParentImageBg(u, r.srcModel);
                      break;
                    }
                  u.attr('src', r.srcModel);
                  r.altModel && u.attr('alt', r.altModel);
                });
            });
          },
        };
      },
    ]);
    n.directive('iframeLoad', [
      '$timeout',
      function (n) {
        return {
          restrict: 'EA',
          scope: { srcModel: '=' },
          link: function (t, i) {
            t.$watch('srcModel', function (r) {
              r !== undefined &&
                n(function () {
                  i.attr('src', t.srcModel);
                });
            });
          },
        };
      },
    ]);
    n.directive('pageSlider', [
      '$timeout',
      '$compile',
      function (n, t) {
        var i = 0;
        return {
          restrict: 'EA',
          replace: !0,
          scope: { images: '=', template: '@', config: '=' },
          link: function (r, u) {
            var f = function () {
              var f = 'info-gallery-directive-' + i,
                e,
                o;
              i++;
              e = new StringBuilder();
              e.append(
                '<div id="' +
                  f +
                  '" class="' +
                  f +
                  '" ng-include="\'' +
                  r.template +
                  '\'"></div>'
              );
              o = t(e.toString())(r);
              u.replaceWith(o);
              r.$on('$includeContentLoaded', function () {
                n(function () {
                  r.config || (r.config = {});
                  jQuery('.' + f).slick(r.config);
                });
              });
            };
            r.$watch('images', function (t) {
              t !== undefined &&
                n(function () {
                  f();
                });
            });
          },
        };
      },
    ]);
    n.directive('dropDown', [
      '$timeout',
      '$compile',
      function (n, t) {
        return {
          restrict: 'EA',
          scope: {
            items: '=',
            textKey: '@',
            valueKey: '@',
            onChange: '=',
            defaultText: '@',
            defaultValue: '=',
            cssClass: '@',
          },
          link: function (i, r) {
            var o = this,
              f = function () {
                var f = new StringBuilder(),
                  e;
                f.append(
                  '<select ng-model="selectedValue" ng-change="onIndexChanged()" class="form-control drop-down-directive ' +
                    (i.cssClass ? i.cssClass : '') +
                    '" style="width:100%;">'
                );
                i.defaultText &&
                  !i.defaultValue &&
                  f.append(
                    '<option value=""  selected="selected">' +
                      i.defaultText +
                      '</option>'
                  );
                i.items.each(function (n, t) {
                  var r = u(n[i.valueKey]) ? n[i.valueKey].toString() : n,
                    e = u([i.textKey]) ? n[i.textKey].toString() : n;
                  f.append(
                    '<option value="' +
                      r +
                      '" ' +
                      ((!i.defaultText && !i.defaultValue && t === 0) ||
                      i.defaultValue === r
                        ? 'selected="selected"'
                        : '') +
                      '>' +
                      e +
                      '</option>'
                  );
                });
                f.append('</select>');
                e = t(f.toString())(i);
                r.parent().append(e);
                n(function () {
                  customForm.customForms.replaceAll();
                });
              },
              u = function (n) {
                return n !== undefined && n !== null;
              },
              e = function () {
                delete i.selectedValue;
                r.parent().find('.drop-down-directive').remove();
                r.parent().find('.select-form-control').remove();
              };
            i.onIndexChanged = function () {
              if (i.onChange) i.onChange(i.selectedValue);
            };
            i.$watch('items', function (t) {
              t &&
                n(function () {
                  e();
                  f();
                });
            });
          },
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('core.module');
    n.filter('html', [
      '$sce',
      function (n) {
        return function (t) {
          try {
            if (typeof t == 'string')
              return n.trustAsHtml(t.replace(/\r\n?/g, '<br />'));
          } catch (i) {
            console.log('Unable to trustAsHtml', i);
          }
          return '';
        };
      },
    ]);
    n.filter('range', function () {
      return function (n, t) {
        var r, i;
        if (n) {
          for (r = [], i = 0; i < n; i++) r.push(t ? i : i + 1);
          return r;
        }
        return null;
      };
    });
    n.filter('tel', [
      '$sce',
      function (n) {
        return function (t) {
          var i, f, u, r;
          if (!t) return '';
          if (
            ((i = t.toString().trim().replace(/^\+/, '')),
            i.startsWith('44') && (i = i.slice(2)),
            i.match(/[^0-9]/))
          )
            return t;
          if (i.startsWith('01'))
            (f = i.slice(0, 3)), (u = i.slice(0, 5)), (r = i.slice(5));
          else if (i.startsWith('02'))
            (u = i.slice(0, 3)),
              (r = i.slice(3)),
              (r = r.slice(0, 4) + ' ' + r.slice(4));
          else return n.trustAsHtml(t);
          return n.trustAsHtml((u + ' ' + r).trim());
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('core.module');
    n.service('core.modal.service', [
      '$compile',
      '$rootScope',
      '$timeout',
      function (n, t, i) {
        var r = this,
          u = '/angularjs/app/shared/core/templates/',
          f;
        r.isOpen = !1;
        r.$selector = '#modal-window';
        f = function (n, t) {
          n.templatePath =
            t.type.toLowerCase() === 'error' &&
            (!t.templateUrl || t.templateUrl.length < 1)
              ? u + 'modal.error.html'
              : t.templateUrl;
        };
        r.open = function (e) {
          var o = t.$new(!0),
            h,
            c,
            s;
          if (
            ((o.showActionBtn =
              e.actionClick !== null && e.actionClick !== undefined),
            (o.title = e.title),
            (o.btnActionText = e.actionText ? e.actionText : 'Ok'),
            (o.model = e.modalModel ? e.modalModel : {}),
            f(o, e),
            e.type !== undefined)
          ) {
            h = e.type.toLowerCase();
            switch (h) {
              case 'error':
                o.typeCss = 'bg-danger';
                break;
              case 'success':
                o.typeCss = 'bg-success';
            }
          }
          c = angular.element(
            '<div class="modal fade" id="modal-window" tabindex="-1"><div class="modal-dialog" ng-include="\'' +
              u +
              'modal.html\'"></div></div>'
          );
          s = n(c)(o);
          angular.element('body').append(s);
          o.btnActionClick = function () {
            e.actionClick ? e.actionClick(o.model) : r.close();
          };
          o.closeClick = function () {
            r.close(o.model, e.onClose);
          };
          jq(r.$selector).on('show.bs.modal', function () {
            r.isOpen ||
              ((r.isOpen = !0),
              i(function () {
                jq(r.$selector)
                  .find('.modal-dialog')
                  .css({ width: e.width ? e.width : '760px' });
                e.onOpen && e.onOpen();
              }, 250));
          });
          s.modal({ backdrop: 'static', keyboard: !1 });
          s.modal('show');
        };
        r.close = function (n) {
          var t = angular.element(r.$selector).scope();
          if (
            (t && delete t.model,
            angular.element(r.$selector).modal('hide').remove(),
            angular.element('.modal-backdrop').remove(),
            (r.isOpen = !1),
            n)
          )
            try {
              n(t);
            } catch (i) {
              console.log(i);
            }
        };
        r.getScope = function () {
          return angular.element(r.$selector).scope();
        };
        r.setModel = function (n) {
          var t = r.getScope();
          t.model = n;
        };
        r.getModel = function () {
          var n = r.getScope();
          return n.model;
        };
        r.setBusy = function (n, t, i, u) {
          commonFactory.setBusy(r.$selector, !0, n, t, function (n) {
            i(n);
            u && r.close();
          });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('core.module');
    n.service('core.service', [
      '$rootScope',
      '$http',
      'core.validation.service',
      function (n, t, i) {
        var r = this;
        r.setParentImageBg = function (n, t) {
          jQuery(jQuery(n).parent()).css('background-image', 'url(' + t + ')');
        };
        r.getScopeParent = function (n, t) {
          var i = n.$parent === null || n.$parent === undefined;
          return i
            ? null
            : n && n[t]
            ? n[t]
            : n.$parent && n.$parent[t]
            ? n.$parent[t]
            : r.getScopeParent(n.$parent, t);
        };
        r.getUrlName = function () {
          return window.location.pathname.split('/').pop();
        };
        r.loading = {
          set: function (n, t) {
            n.loading = { state: !0, text: t !== undefined ? t : 'Loading' };
          },
          remove: function (n) {
            delete n.loading;
          },
        };
        r.dateValid = function (n, t) {
          return n ? (t ? moment(n, t).isValid() : moment(n)) : !1;
        };
        r.forKeys = function (n, t) {
          for (var i in n) if (i.toLowerCase() === t.toLowerCase()) return n[i];
          return null;
        };
        r.broadcast = {
          call: function (t, i) {
            n.$broadcast(t, i);
          },
          on: function (t, i) {
            n.$on(t, function (n, t) {
              i(t);
            });
          },
        };
        r.isFormValid = function (n) {
          return i.formValid(n);
        };
        r.$http = function (n, t, i, u, f, e, o, s) {
          var h = n.apply(null, t || []);
          h &&
            (f && r.loading.set(f, e),
            h
              .success(function (n) {
                if (i) {
                  if (o || o === undefined)
                    try {
                      n = JSON.parse(n);
                    } catch (t) {
                      console.log(t);
                    }
                  i(n);
                }
              })
              .error(function (n, i, r, u) {
                console.log('Error core.service: method: $http', {
                  methodArgs: t,
                  data: n,
                  status: i,
                  headers: r,
                  config: u,
                });
                s && s(n);
              })
              ['finally'](function () {
                u && u();
                f && r.loading.remove(f);
              }));
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('core.module');
    n.factory('core.validation.service', [
      'core.modal.service',
      'core.constants',
      function (n, t) {
        var i = this,
          r = function (i) {
            n.open({
              title: 'Field incomplete',
              type: 'error',
              templateUrl:
                t.basePath + 'shared/core/templates/modal.error.html',
              modalModel: { errorMessage: i },
            });
          };
        return (
          (i.formValid = function (n) {
            var t = !0,
              f = jq(n),
              u = f.find('.required'),
              e = u.find('.form-control');
            return (
              u.removeClass('has-error'),
              angular.forEach(e, function (n) {
                jq(n).is(':visible') &&
                  jq.trim(jq(n).val()).length < 1 &&
                  (t && (t = !1), i.setError(n));
              }),
              t ||
                r(
                  'Not all required fields have been complete, these are highlighted'
                ),
              t
            );
          }),
          (i.clearErrors = function (n) {
            angular.element(n).length > 0 &&
              angular.element(n).find('.required').removeClass('has-error');
          }),
          (i.clearError = function (n) {
            jq(n).closest('.required').removeClass('has-error');
          }),
          (i.setError = function (n, t) {
            jq(n).closest('.required').addClass('has-error');
            t && r(t);
          }),
          i
        );
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.constant('booking.constants', {
      baseFilePath: '/angularjs/app/components/booking/',
      baseTemplatePath: '/angularjs/app/components/booking/templates/',
      api: { baseUrl: '/api/', pageSize: 15 },
      booking: { availability: { suggestionDateOffsetDays: 6 } },
      payment: {
        currencyCode: 'GBP',
        methods: [
          { ID: 1, Name: 'CreditCard', DisplayName: 'Credit Card' },
          { ID: 2, Name: 'GiftCertificate', DisplayName: 'Gift Certificate' },
        ],
      },
    });
  })(),
  (function () {
    var n = angular.module('app');
    n.service('booking.core.data.service', [
      'booking.http.service',
      'locations.api',
      '$http',
      'booking.constants',
      function (n, t, i, r) {
        var f = this,
          u = null,
          e = null,
          o = [],
          s = function (n) {
            return i({
              url: r.api.baseUrl + 'settings/data/?locationId=' + n,
              method: 'get',
            });
          };
        f.getCountries = function (i) {
          u == null
            ? n.$http(
                t.getCountries,
                [],
                function (n) {
                  u = n;
                  i(u);
                },
                !0
              )
            : i(u);
        };
        f.getLocations = function (i) {
          e == null
            ? n.$http(
                t.get,
                [],
                function (n) {
                  e = n;
                  i(n);
                },
                !0
              )
            : i(e);
        };
        f.getSettings = function (t, i) {
          o[t]
            ? i(o[t])
            : n.$http(
                s,
                [t],
                function (n) {
                  o[t] = n;
                  i(n);
                },
                !0
              );
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('booking.http.service', [
      '$http',
      'core.service',
      'core.modal.service',
      function (n, t, i) {
        var r = this;
        r.$http = function (n, i, u, f, e, o, s, h, c) {
          h || (h = !0);
          f === undefined && (f = !1);
          t.$http(
            n,
            i,
            function (n) {
              (f && u) || (n.IsSuccess && u)
                ? u(n)
                : (r.handleErrors(n), c && c());
            },
            function () {
              e && e();
            },
            o,
            s,
            h,
            function (n) {
              r.handleErrors(n);
              c && c(n);
            }
          );
        };
        r.handleErrors = function (n) {
          i.open({
            title: 'Error',
            type: 'error',
            templateUrl:
              '/angularjs/app/shared/booking/templates/modal.api.error.html',
            modalModel: n,
          });
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.util.service', function () {
      var n = this;
      n.createDateTime = function (n, t) {
        return new Date(
          n.year(),
          n.month(),
          n.date(),
          t.split(':')[0],
          t.split(':')[1],
          0,
          0
        );
      };
      n.toDate = function (n, t) {
        if (t) {
          var i = n.substring(6),
            r = parseInt(i);
          return new Date(r);
        }
        return new Date(n);
      };
    });
  })(),
  (function () {
    var n = angular.module('app');
    n.service('locations.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'locations/';
        i.get = function () {
          return n({ method: 'get', url: r + 'get' });
        };
        i.getByUrlName = function (t) {
          return n({ method: 'get', url: r + 'get?urlname=' + t });
        };
        i.getCountries = function () {
          return n({ method: 'get', url: r + 'countries' });
        };
        i.getRegions = function () {
          return n({ method: 'get', url: r + 'regions' });
        };
        i.getByPostCode = function (t) {
          return n({
            method: 'get',
            url: r + 'search?postcode=' + t + '&distance=',
          });
        };
        i.findAddress = function (t) {
          return n({ method: 'get', url: r + 'address/?postcode=' + t });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('locations.controller', [
      '$timeout',
      'booking.http.service',
      'locations.api',
      function (n, t, i) {
        var r = this,
          u = function () {
            window.hasQueryString('postcode') &&
              ((r.postcode = decodeURI(
                window.getQueryString('postcode').value
              ).toUpperCase()),
              r.search());
            f();
          },
          f = function () {
            t.$http(
              i.getRegions,
              [],
              function (n) {
                r.regions = n;
              },
              !0
            );
          };
        r.search = function () {
          r.searching = !0;
          delete r.locations;
          t.$http(
            i.getByPostCode,
            [r.postcode],
            function (n) {
              r.locations = n;
            },
            !0
          );
        };
        r.goToBook = function (n) {
          console.log(n);
          switch (n) {
            case 4290:
              window.location = 'http://www.secure-booker.com/spalondonrlc/';
              break;
            case 3295:
              window.location = 'http://www.secure-booker.com/yorkhall/';
              break;
            case 30583:
              window.location =
                'http://www.secure-booker.com/spalondonkensington/';
              break;
            case 12811:
              window.location =
                'http://www.secure-booker.com/spalondonironmongerrow/';
              break;
            case 3296:
              window.location = 'http://www.secure-booker.com/SLSwisscottage/';
              break;
            case 7216:
              window.location =
                'http://www.secure-booker.com/SLMarshallstreet/';
              break;
            case 19984:
              window.location = 'http://www.secure-booker.com/GoslingParkSpa/';
              break;
            case 35829:
              window.location =
                'http://www.secure-booker.com/spalondontaunton/';
              break;
            case 35831:
              window.location =
                'http://www.secure-booker.com/spaexperiencewaltham/';
              break;
            case 38227:
              window.location = 'http://www.secure-booker.com/sebelfast/';
              break;
            case 19813:
            default:
              window.location =
                'http://www.secure-booker.com/spalondonwimbledon/';
          }
        };
        n(function () {
          u();
        });
      },
    ]);
    n.controller('location.controller', [
      '$timeout',
      'booking.http.service',
      'locations.api',
      'core.service',
      function (n, t, i, r) {
        var u = this,
          o = function () {
            var n = r.getUrlName();
            f(n);
            e();
          },
          f,
          e;
        u.goToBook = function (n) {
          switch (n) {
            case 4290:
              window.location = 'http://www.secure-booker.com/spalondonrlc/';
              break;
            case 3295:
              window.location = 'http://www.secure-booker.com/yorkhall/';
              break;
            case 30583:
              window.location =
                'http://www.secure-booker.com/spalondonkensington/';
              break;
            case 12811:
              window.location =
                'http://www.secure-booker.com/spalondonironmongerrow/';
              break;
            case 3296:
              window.location = 'http://www.secure-booker.com/SLSwisscottage/';
              break;
            case 35829:
              window.location =
                'http://www.secure-booker.com/spalondontaunton/';
              break;
            case 19984:
              window.location = 'http://www.secure-booker.com/GoslingParkSpa/';
              break;
            case 35831:
              window.location =
                'http://www.secure-booker.com/spaexperiencewaltham/';
              break;
            case 38227:
              window.location = 'http://www.secure-booker.com/sebelfast/';
              break;
            case 19813:
            default:
              window.location =
                'http://www.secure-booker.com/spalondonwimbledon/';
          }
        };
        f = function (n) {
          t.$http(
            i.getByUrlName,
            [n],
            function (n) {
              u.location = n;
              r.title.append(n.BusinessName);
            },
            !0
          );
        };
        e = function () {
          u.pageSliderConfigs = {
            images: { fade: !0, arrows: !1, dots: !0 },
            promoImages: {
              slidesToShow: 4,
              slidesToScroll: 1,
              autoplay: !0,
              autoplaySpeed: 8e3,
              infinite: !1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: { slidesToShow: 2, slidesToScroll: 1 },
                },
                {
                  breakpoint: 480,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ],
            },
          };
        };
        n(function () {
          o();
        });
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.directive('locationBookNow', [
      '$timeout',
      '$compile',
      'core.constants',
      function (n, t) {
        return {
          restrict: 'EA',
          replace: !0,
          scope: { locations: '=', baseUrl: '@' },
          link: function (i, r) {
            var u = function () {
              var n = t(
                '<div class="row"><div class="col-sm-5 col-lg-4"><drop-down items="locations" on-change="onChange" text-key="DisplayName" value-Key="ID" default-text="Book Now >>" css-class="primary white-arrow"></drop-down></div></div>'
              )(i);
              r.replaceWith(n);
            };
            i.onChange = function (n) {
              console.log(n);
              switch (parseInt(n)) {
                case 4290:
                  window.location =
                    'http://www.secure-booker.com/spalondonrlc/';
                  break;
                case 3295:
                  window.location = 'http://www.secure-booker.com/yorkhall/';
                  break;
                case 30583:
                  window.location =
                    'http://www.secure-booker.com/spalondonkensington/';
                  break;
                case 12811:
                  window.location =
                    'http://www.secure-booker.com/spalondonironmongerrow/';
                  break;
                case 3296:
                  window.location =
                    'http://www.secure-booker.com/SLSwisscottage/';
                  break;
                case 7216:
                  window.location =
                    'http://www.secure-booker.com/SLMarshallstreet/';
                  break;
                case 19984:
                  window.location =
                    'http://www.secure-booker.com/GoslingParkSpa/';
                  break;
                case 35829:
                  window.location =
                    'http://www.secure-booker.com/spalondontaunton/';
                  break;
                case 35831:
                  window.location =
                    'http://www.secure-booker.com/spaexperiencewaltham/';
                  break;
                case 38227:
                  window.location = 'http://www.secure-booker.com/sebelfast/';
                  break;
                case 19813:
                default:
                  window.location =
                    'http://www.secure-booker.com/spalondonwimbledon/';
              }
            };
            i.$watch('baseUrl', function (t) {
              t &&
                n(function () {
                  u();
                });
            });
          },
        };
      },
    ]);
    n.directive('locationMap', [
      '$timeout',
      '$compile',
      function (n, t) {
        return {
          restrict: 'EA',
          replace: !0,
          scope: { longitude: '=', latitude: '=' },
          link: function (i, r) {
            var u = function () {
              var e =
                  'http://maps.google.co.uk/maps?saddr=&daddr=' +
                  i.latitude.toString() +
                  ',' +
                  i.longitude.toString(),
                u = new StringBuilder(),
                f;
              u.append('<div class="map-block">');
              u.append('<div id="map"></div>');
              u.append('<div class="holder">');
              u.append(
                '<a class="btn btn-primary btn-lg" target="_blank" href="' +
                  e +
                  '">GET DIRECTIONS</a>'
              );
              u.append('</div>');
              u.append('</div>');
              f = t(u.toString())(i);
              r.replaceWith(f);
              n(function () {
                initMap(jQuery.trim(i.latitude), jQuery.trim(i.longitude));
              }, 700);
            };
            i.$watch('latitude', function (t) {
              t &&
                n(function () {
                  u();
                });
            });
          },
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.filter('address', [
      'htmlFilter',
      function (n) {
        return function (t, i) {
          if (t) {
            var u = new StringBuilder(),
              r = function (n) {
                n !== null &&
                  n !== undefined &&
                  n.length > 0 &&
                  u.appendWith(n, i);
              };
            return (
              r(t.Street1),
              r(t.Street2),
              r(t.State),
              r(t.City),
              r(t.Country.Name),
              r(t.Country.Zip),
              n(u.toString())
            );
          }
          return null;
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('treatments.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'treatments/';
        i.getByLocationByCategory = function (t, i) {
          return n({
            method: 'get',
            url: r + 'Get?locationId=' + t + '&categoryId=' + i,
          });
        };
        i.getByLocationId = function (t) {
          return n({ method: 'get', url: r + 'Get?locationId=' + t });
        };
        i.getSummarisedByUrlName = function (t) {
          return n({ method: 'get', url: r + 'Get/?urlName=' + t });
        };
        i.getCategories = function (t, i) {
          return n({
            method: 'get',
            url: r + 'categories/?locationId=' + t + '&culture=' + i,
          });
        };
        i.getCategoriesSummarised = function () {
          return n({ method: 'get', url: r + 'Summarised' });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('treatments.controller', [
      '$timeout',
      'treatments.api',
      'core.service',
      'locations.api',
      function (n, t, i) {
        var r = this,
          u = function () {
            var n = i.getUrlName();
            t.getSummarisedByUrlName(n).success(function (n) {
              r.treatments = n != null ? n : [];
            });
          };
        r.getPriceRange = function (n) {
          if (r.locationId != null)
            return (
              '&pound;' + n.LocationPrice.where('LocationID', r.locationId)[0]
            );
          var t = n.LocationPrices.minValue('Amount'),
            i = n.LocationPrices.maxValue('Amount');
          return t == i
            ? '&pound;' + t
            : '&pound;' + t + ' &mdash; &pound;' + i;
        };
        n(function () {
          u();
        });
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('treatments.thermalspa.controller', [
      '$timeout',
      'treatments.api',
      'core.service',
      'locations.api',
      function (n, t) {
        var i = this,
          r = function () {
            t.getSummarisedByUrlName('spa').success(function (n) {
              i.treatments = n != null ? n : [];
            });
          };
        i.getPriceRange = function (n) {
          if (i.locationId != null)
            return (
              '&pound;' + n.LocationPrice.where('LocationID', i.locationId)[0]
            );
          var t = n.LocationPrices.minValue('Amount'),
            r = n.LocationPrices.maxValue('Amount');
          return t == r
            ? '&pound;' + t
            : '&pound;' + t + ' &mdash; &pound;' + r;
        };
        n(function () {
          r();
        });
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('promotions.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'promotions/';
        i.get = function () {
          return n({ method: 'get', url: r + 'get' });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('promotions.controller', [
      '$timeout',
      '$scope',
      'booking.http.service',
      'promotions.api',
      'locations.api',
      function (n, t, i, r, u) {
        var f = this,
          e = function () {
            s(function (n) {
              o(function (t) {
                var r = n.distinct('LocationID').select('LocationID'),
                  i = [];
                r.each(function (n) {
                  var r = t.firstOrDefault('ID', n);
                  r !== null && i.push(r);
                });
                f.locations = i;
              });
            });
          },
          o = function (n) {
            i.$http(
              u.get,
              [],
              function (t) {
                n(t);
              },
              !0
            );
          },
          s = function (n) {
            i.$http(
              r.get,
              [],
              function (t) {
                f.promotions = t;
                n && n(t);
              },
              !0
            );
          };
        f.locationChanged = function (n) {
          t.locationID = n;
        };
        f.getBookNowLink = function (n) {
          console.log(n.LocationID);
          switch (n.LocationID) {
            case 4290:
              window.location = 'http://www.secure-booker.com/spalondonrlc/';
              break;
            case 3295:
              window.location = 'http://www.secure-booker.com/yorkhall/';
              break;
            case 30583:
              window.location =
                'http://www.secure-booker.com/spalondonkensington/';
              break;
            case 12811:
              window.location =
                'http://www.secure-booker.com/spalondonironmongerrow/';
              break;
            case 3296:
              window.location = 'http://www.secure-booker.com/SLSwisscottage/';
              break;
            case 7216:
              window.location =
                'http://www.secure-booker.com/SLMarshallstreet/';
              break;
            case 19984:
              window.location = 'http://www.secure-booker.com/GoslingParkSpa/';
              break;
            case 35829:
              window.location =
                'http://www.secure-booker.com/spalondontaunton/';
              break;
            case 35831:
              window.location =
                'http://www.secure-booker.com/spaexperiencewaltham/';
              break;
            case 38227:
              window.location = 'http://www.secure-booker.com/sebelfast/';
              break;
            case 19813:
            default:
              window.location =
                'http://www.secure-booker.com/spalondonwimbledon/';
          }
        };
        n(function () {
          e();
        });
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.filter('promoFilter', function () {
      return function (n, t) {
        if (
          !angular.isUndefined(n) &&
          !angular.isUndefined(t) &&
          t.length > 0
        ) {
          var r = parseInt(t),
            i = [];
          return (
            angular.forEach(n, function (n) {
              n.LocationID == r && i.push(n);
            }),
            i
          );
        }
        return n;
      };
    });
  })(),
  (function () {
    var n = angular.module('app');
    n.service('packages.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'packages/';
        i.get = function (t) {
          return n({ method: 'get', url: r + 'Get?locationId=' + t });
        };
        i.getSummarised = function () {
          return n({ method: 'get', url: r + 'Summarised' });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('packages.controller', [
      '$timeout',
      'booking.http.service',
      'packages.api',
      function (n, t, i) {
        var r = this,
          u = function () {
            t.$http(
              i.getSummarised,
              [],
              function (n) {
                r.packages = n;
              },
              !0
            );
          };
        r.getPriceRange = function (n) {
          if (r.locationId != null)
            return (
              '&pound;' + n.LocationPrice.where('LocationID', r.locationId)[0]
            );
          var t = n.LocationPrices.minValue('Amount'),
            i = n.LocationPrices.maxValue('Amount');
          return t == i
            ? '&pound;' + t
            : '&pound;' + t + ' &mdash; &pound;' + i;
        };
        n(function () {
          u();
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.address.controller', [
      'booking.checkout.service',
      function () {
        var n = this;
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.appointment.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var r = this,
          i = t.api.baseUrl + 'appointments/';
        r.availability = function (t, r, u, f, e) {
          return n({
            method: 'post',
            url: i + 'post',
            data: {
              startDateTimeString: moment(t).format('YYYY-MM-DDTHH:mm:ss'),
              endDateTimeString: moment(r).format('YYYY-MM-DDTHH:mm:ss'),
              locationId: u,
              itineraries: f,
              returnAllSlots: e,
            },
          });
        };
        r.book = {
          single: function (t) {
            return n({ method: 'post', url: i + 'single', data: t });
          },
          group: function (t) {
            return n({ method: 'post', url: i + 'group', data: t });
          },
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.availability.controller', [
      '$timeout',
      '$scope',
      'core.service',
      'booking.view.service',
      'booking.basket.service',
      'booking.values',
      'booking.availability.service',
      'core.validation.service',
      function (n, t, i, r, u, f, e, o) {
        var s = this,
          l = function () {
            s.basketValid = u.isValid();
            a();
          },
          a = function () {
            s.timeframes ||
              ((s.timeframes = f.appointment.timeframes),
              (s.timeOptions = f.appointment.timeOptions),
              v());
          },
          v = function () {
            s.timeframe = s.timeframes[0];
            t.customHours = s.timeOptions.hours[0];
            t.customMinutes = s.timeOptions.minutes[0];
            r.options.availability.date = t.selectedDate;
            h(s.timeframe);
            c(t.customHours, t.customMinutes);
          },
          h = function (n) {
            r.options.availability.timeframe = n !== null ? n : s.timeframes[0];
          },
          c = function (n, t) {
            var i = s.timeframes.firstOrDefault('isCustom', !0);
            i.startTime = n + ':' + t;
            h(i);
          };
        s.timeframeChanged = function () {
          s.timeframe && ((s.isCustom = s.timeframe.isCustom), h(s.timeframe));
        };
        s.search = function () {
          o.clearError('#date-container');
          i.dateValid(t.selectedDate, 'DD/MM/YYYY')
            ? e.get()
            : o.setError('#date-container', 'Date entered is invalid');
        };
        t.selectedDate = moment().format('DD/MM/YYYY');
        n(function () {
          l();
        });
        i.broadcast.on(u.$broadcastKeys.BasketUpdated, function () {
          s.basketValid = u.isValid();
        });
        t.$watch('selectedDate', function (n) {
          i.dateValid(n, 'DD/MM/YYYY') && (r.options.availability.date = n);
        });
        t.$watch('customHours', function (n) {
          n && c(n, t.customMinutes);
        });
        t.$watch('customMinutes', function (n) {
          n && c(t.customHours, n);
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.availability.selection.controller', [
      '$scope',
      '$timeout',
      'core.service',
      'booking.availability.service',
      'booking.view.service',
      'booking.basket.service',
      'treatments.api',
      'booking.http.service',
      function (n, t, i, r, u, f, e, o) {
        var s = this,
          h = null,
          c = function (n) {
            l(function (t) {
              s.loading = !1;
              s.availability = n;
              s.view = u.options;
              h = t;
            });
          },
          l = function (n) {
            o.$http(e.getByLocationId, [u.options.location.ID], function (t) {
              n(t.Treatments);
            });
          },
          a = function () {
            delete s.loading;
            delete s.availability;
            delete s.isSuggesting;
          };
        s.bookClick = function (n) {
          var t = [];
          s.availability.timeSlots.each(function (i) {
            var r = angular.copy(i);
            r.ItineraryTimeSlots = i.ItineraryTimeSlots.where(
              'StartDateTime',
              n
            );
            t.push(r);
          });
          u.setItineraryTimeSlot(t);
          u.setUI(u.uiViews.review.value);
        };
        s.getPackageTreatmentNameById = function (n) {
          var t = f.get()[0];
          return t !== null ? t.treatments.firstOrDefault('ID', n).Name : '';
        };
        s.getTreatmentNameById = function (n) {
          if (h !== null) {
            var t = h.firstOrDefault('ID', n);
            return t !== null ? t.Name : '';
          }
          return '';
        };
        n.getGuestSlots = function () {
          return new Array(s.availability.timeSlots.length);
        };
        n.getDuration = function (n) {
          var i = 0,
            t = 0,
            r = n.length;
          for (t; t < r; t++) i += n[t].Duration;
          return i;
        };
        i.broadcast.on(r.$broadcastKeys.TimeSlotsRequested, function () {
          t(function () {
            a();
            s.loading = !0;
          });
        });
        i.broadcast.on(r.$broadcastKeys.TimeSlotsAvailable, function (n) {
          t(function () {
            delete s.loading;
            n.hasTimeSlots ? c(n) : (r.getSuggestions(), (s.isSuggesting = !0));
          });
        });
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('booking.availability.service', [
      '$timeout',
      'booking.appointment.api',
      'booking.http.service',
      'core.service',
      'booking.view.service',
      'booking.basket.service',
      function (n, t, i, r, u, f) {
        var e = this,
          o;
        e.$broadcastKeys = {
          TimeSlotsRequested: '<TimeSlotsRequested>',
          TimeSlotsAvailable: '<TimeSlotsAvailable>',
          SuggestedTimeSlotsRequested: '<SuggestedTimeSlotsRequested>',
          SuggestedTimeSlotsAvailable: '<SuggestedTimeSlotsAvailable>',
        };
        o = function (o) {
          n(function () {
            var n = u.options.availability.getDatesFormatted();
            i.$http(
              t.availability,
              [
                n.startDateTime,
                n.endDateTime,
                u.options.location.ID,
                f.get(),
                o,
              ],
              function (n) {
                var t = o
                  ? e.$broadcastKeys.SuggestedTimeSlotsAvailable
                  : e.$broadcastKeys.TimeSlotsAvailable;
                r.broadcast.call(t, {
                  timeSlots: n.ItineraryTimeSlotsLists,
                  hasTimeSlots:
                    n.ItineraryTimeSlotsLists[0].ItineraryTimeSlots.length > 0,
                  isSuggestedTimeSlots: o,
                });
              }
            );
          });
        };
        e.get = function () {
          r.broadcast.call(e.$broadcastKeys.TimeSlotsRequested, {
            isSuggestedTimeSlots: !1,
          });
          o(!1);
        };
        e.getSuggestions = function () {
          r.broadcast.call(e.$broadcastKeys.SuggestedTimeSlotsRequested, {
            isSuggestedTimeSlots: !0,
          });
          o(!0);
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.availability.suggestions.controller', [
      '$timeout',
      'booking.availability.service',
      'core.service',
      'booking.view.service',
      'core.constants',
      'epochToDateFilter',
      'booking.constants',
      function (n, t, i, r, u, f, e) {
        var o = this,
          c = function (n) {
            r.options.availability.suggestedDates.active ||
              ((o.startDate = r.options.availability.date),
              (o.endDate = s(r.options.availability.date)
                .add(e.booking.availability.suggestionDateOffsetDays, 'days')
                .format(u.formats.shortDate.toUpperCase())),
              h(o.startDate, o.endDate));
            l(n);
          },
          l = function (n) {
            o.suggestedDates = a(n);
          },
          a = function (n) {
            var t = [];
            return (
              n.timeSlots[0].ItineraryTimeSlots.each(function (n) {
                var i = moment(f(n.StartDateTime));
                n.StartDateTimeString = i.format(
                  u.formats.shortDate.toUpperCase()
                );
                !t.where('StartDateTimeString', n.StartDateTimeString).length >
                  0 && t.push(n);
              }),
              t
            );
          },
          s = function (n) {
            return r.options.availability.asDate(n);
          },
          h = function (n, t) {
            r.options.availability.suggestedDates.startDate = n;
            r.options.availability.suggestedDates.endDate = t;
            r.options.availability.suggestedDates.active = !0;
          };
        o.goToWeek = function (n) {
          var i = e.booking.availability.suggestionDateOffsetDays;
          n
            ? ((o.startDate = s(o.endDate)
                .add(1, 'days')
                .format(u.formats.shortDate.toUpperCase())),
              (o.endDate = s(o.startDate)
                .add(i, 'days')
                .format(u.formats.shortDate.toUpperCase())))
            : ((o.endDate = s(o.startDate)
                .add(-1, 'days')
                .format(u.formats.shortDate.toUpperCase())),
              (o.startDate = s(o.startDate)
                .add(parseInt('-' + i), 'days')
                .format(u.formats.shortDate.toUpperCase())));
          h(o.startDate, o.endDate);
          t.getSuggestions();
        };
        o.viewDate = function (n) {
          r.options.availability.date = n.StartDateTimeString;
          r.options.availability.suggestedDates.active = !1;
          t.get();
        };
        i.broadcast.on(
          t.$broadcastKeys.SuggestedTimeSlotsRequested,
          function () {
            o.loadingSuggestions = !0;
          }
        );
        i.broadcast.on(
          t.$broadcastKeys.SuggestedTimeSlotsAvailable,
          function (t) {
            delete o.loadingSuggestions;
            n(function () {
              c(t);
            });
          }
        );
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.basket.controller', [
      '$timeout',
      'booking.checkout.service',
      'core.service',
      'booking.view.service',
      'booking.basket.service',
      'booking.series.api',
      'booking.http.service',
      'booking.account.service',
      'basket.api',
      function (n, t, i, r, u, f, e, o, s) {
        var h = this,
          c = function () {
            h.basket = u.getBasketView();
            h.totalCost = u.getTotal();
            h.view = r.options;
            h.basketValid = u.isValid();
            h.checkAuthenticated();
            h.discount = 0;
          },
          l = function () {
            delete h.basket;
            delete h.totalCost;
            delete h.view;
            delete h.basketValid;
            delete h.user;
            delete h.onlineCartId;
            delete h.discount;
            delete h.discountApplied;
            u.updateOnlineCartId(null);
          };
        h.checkAuthenticated = function () {
          o.getAuthenticated(function (n) {
            h.user = n;
          });
        };
        h.clear = function () {
          u.clear();
        };
        h.createOnlineCart = function () {
          var t = [],
            n = 0,
            i = h.basket.length;
          for (n; n < i; n++) t.push(h.basket[n].SeriesID);
          e.$http(
            s.create,
            [h.basket[0].LocationID, t],
            function (n) {
              if (n.IsSuccess) {
                h.onlineCartId = n.OnlineCart.ID;
                var t = 0,
                  i = n.OnlineCart.Series.length;
                for (t; t < i; t++) h.basket[t].ID = n.OnlineCart.Series[t].ID;
                u.updateOnlineCartId(n.OnlineCart.ID);
                delete h.discountApplied;
              }
            },
            !0
          );
        };
        h.checkoutClick = function () {
          h.view.isSeries && h.createOnlineCart();
          r.setUI(r.uiViews.review.value);
        };
        h.removeSeriesItem = function (n) {
          e.$http(
            f.remove,
            [n, h.onlineCartId],
            function (t) {
              t.IsSuccess && (h.basket = h.basket.remove('ID', n));
            },
            !0
          );
        };
        h.removeItem = function (n) {
          h.view.isSeries
            ? u.removeSeries(n.ID)
            : u.remove(n.guest, n.treatment.ID);
        };
        h.applyCode = function () {
          h.view.isSeries
            ? e.$http(
                f.addSpecial,
                [h.specialCode, h.onlineCartId],
                function (n) {
                  h.discountApplied = n.IsSuccess ? !0 : !1;
                },
                !0
              )
            : ((h.discountApplied = !0), u.updateDiscountCode(h.specialCode));
        };
        i.broadcast.on(o.$broadcastKeys.UserUpdated, function (n) {
          h.user = n;
        });
        i.broadcast.on(r.$broadcastKeys.GuestsChanged, function () {
          u.clear();
        });
        i.broadcast.on(r.$broadcastKeys.TypeChanged, function () {
          u.clear();
        });
        i.broadcast.on(u.$broadcastKeys.BasketUpdated, function () {
          n(function () {
            u.get().length > 0
              ? c()
              : (l(),
                r.options.ui !== r.uiViews.selection.value &&
                  r.setUI(r.uiViews.selection.value));
          });
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.basket.service', [
      'core.service',
      'booking.view.service',
      function (n, t) {
        var i = this,
          r = [],
          u = function () {
            n.broadcast.call(i.$broadcastKeys.BasketUpdated, r);
          },
          e = function (n) {
            var t = 0;
            return (
              n.each(function (n) {
                t += n.Price.Amount;
              }),
              t
            );
          },
          f = function (n, t) {
            n.totalCost = t;
          },
          o = function (n, t, i) {
            var r = {
              guest: n,
              treatments: t,
              isPackage: i !== undefined,
              packageName: i ? i.Name : null,
              packageID: i ? i.ID : null,
            };
            return f(r, i ? i.Price.Amount : e(t)), r;
          };
        i.$broadcastKeys = { BasketUpdated: '<BasketUpdated>' };
        i.addTreatment = function (n, t) {
          var i = this.getByGuest(n);
          i !== null
            ? ((i.treatments = i.treatments.update(t, 'ID')),
              f(i, e(i.treatments)))
            : (i = o(n, [t]));
          r = r.update(i, 'guest');
          u();
        };
        i.addPackage = function (n) {
          var t = o(1, n.Treatments, n);
          this.clear(!0);
          r.push(t);
          u();
        };
        i.addSeries = function (n) {
          f(n, n.TagPrice.Amount);
          r.push(n);
          u();
        };
        i.clear = function (n) {
          r = [];
          n || u();
        };
        i.get = function () {
          return r;
        };
        i.getByGuest = function (n) {
          return r.firstOrDefault('guest', n);
        };
        i.getByTreatmentId = function (n, t) {
          var i = this.getByGuest(n);
          return i !== null ? i.treatments.firstOrDefault('ID', t) : null;
        };
        i.getBasketView = function () {
          if (t.options.isSeries) return r;
          var n = [];
          return (
            r.each(function (t) {
              t.treatments.each(function (i) {
                n.push({
                  guest: t.guest,
                  isPackage: t.isPackage,
                  packageName: t.packageName,
                  totalCost: t.totalCost,
                  treatment: i,
                });
              });
            }),
            n
          );
        };
        i.getTotal = function () {
          return r.sum('totalCost');
        };
        i.isValid = function () {
          return r.length < 1
            ? !1
            : t.options.isSeries
            ? !0
            : t.options.guests === r.select('guest').length;
        };
        i.remove = function (n, t) {
          var f = function (n) {
              r = r.remove('guest', n);
            },
            i;
          t
            ? ((i = this.getByGuest(n)),
              i !== null &&
                ((i.treatments = i.treatments.remove('ID', t)),
                i.treatments.length < 1 && f(n)))
            : f(n);
          u();
        };
        i.removeSeries = function (n) {
          r = r.remove('ID', n);
          u();
        };
        i.updateOnlineCartId = function (n) {
          i.onlineCartId = n;
        };
        i.getOnlineCartId = function () {
          return i.onlineCartId;
        };
        i.updateDiscountCode = function (n) {
          i.discountCode = n;
        };
        i.getDiscountCode = function () {
          return i.discountCode;
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.basket.summary.controller', [
      '$timeout',
      'booking.http.service',
      'booking.checkout.service',
      'booking.basket.service',
      'core.service',
      'booking.view.service',
      'basket.api',
      function (n, t, i, r, u, f) {
        var e = this,
          o = function (n) {
            e.basket = r.getBasketView();
            e.checkout = i.get();
            e.totalCost = r.getTotal();
            e.isMultiGuest = e.basket.distinct('guest').length > 1;
            e.view = f.options;
            e.itineraryList = n;
          },
          s = function () {
            delete e.basket;
            delete e.totalCost;
            delete e.isMultiGuest;
            delete e.view;
            delete e.itineraryList;
          };
        e.getSlot = function (n) {
          return e.isMultiGuest
            ? e.itineraryList[n.guest - 1].ItineraryTimeSlots[0]
            : e.itineraryList[
                n.guest - 1
              ].ItineraryTimeSlots[0].TreatmentTimeSlots.firstOrDefault(
                'TreatmentID',
                n.treatment.ID
              );
        };
        e.applyCode = function () {
          e.discountApplied = !0;
          r.updateDiscountCode(e.specialCode);
        };
        u.broadcast.on(f.$broadcastKeys.ItineraryTimeSlotChanged, function (t) {
          s();
          n(function () {
            o(t);
          });
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.checkout.service', [
      'booking.view.service',
      function (n) {
        var t = this,
          i = {
            LocationID: null,
            Guests: [],
            ItineraryTimeSlotList: null,
            Customer: null,
            AppointmentPayment: { PaymentItem: {} },
            IsGroupBooking: !1,
          },
          r = function () {
            t.current = angular.copy(i);
            t.current.LocationID = n.options.location.ID;
            for (var r = 0; r < n.options.guests; r++) i.Guests.push({});
            return (
              (t.current.IsGroupBooking = n.options.guest > 1),
              (t.current.ItineraryTimeSlotList =
                n.options.availability.ItineraryTimeSlotList),
              t.current
            );
          };
        t.current = null;
        t.get = function () {
          return t.current === null && r(), t.current;
        };
        t.reset = function () {
          t.current = null;
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.confirm.controller', [
      '$timeout',
      'core.service',
      'booking.checkout.service',
      'booking.view.service',
      'booking.confirm.service',
      'booking.basket.service',
      function (n, t, i, r, u, f) {
        var e = this,
          o = function () {
            e.basket = f.getBasketView();
            e.view = r.options;
            u.confirm(function (n) {
              if (
                ((e.confirmation = n),
                delete e.confirming,
                e.confirmation.FinalTotal &&
                  (e.totalCost = e.confirmation.FinalTotal.Amount),
                e.confirmation.Items)
              ) {
                var t = 0,
                  i = e.confirmation.Items.length;
                for (e.totalCost = 0, t; t < i; t++)
                  e.totalCost +=
                    e.confirmation.Items[t].AppointmentFinalTotal.Amount;
              }
              e.view.isSeries && s();
            });
          },
          s = function () {
            e.basket = f.getBasketView();
            e.totalCost = 0;
            var n = 0,
              t = e.basket.length;
            for (n; n < t; n++)
              e.totalCost += e.basket[n].Order.GrandTotal.Amount;
          };
        e.reset = function () {
          window.location.href = '/book/';
        };
        t.broadcast.on(r.$broadcastKeys.ConfirmView, function () {
          n(function () {
            e.confirming = !0;
            o();
          });
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.confirm.service', [
      'booking.http.service',
      'booking.checkout.service',
      'booking.appointment.api',
      'booking.series.api',
      'booking.basket.service',
      'booking.user.service',
      'booking.util.service',
      'booking.view.service',
      function (n, t, i, r, u, f, e, o) {
        var a = this,
          s;
        a.confirm = function (n) {
          o.options.isSeries ? c.confirm(n) : h.confirm(n);
        };
        var l = function (n) {
            var t = f.getAuthToken();
            t && (n.access_token = t);
          },
          c = {
            confirm: function (n) {
              var t = 0,
                i = u.get();
              i.each(function (r) {
                r.SeriesNumber ||
                  r.OrderNumber ||
                  (c.prepare(r),
                  c.purchase(r, function (u) {
                    angular.extend(r, u);
                    t++;
                    t === i.length && n(!0);
                  }));
              });
            },
            prepare: function (n) {
              var i = t.get();
              i.Customer.PhoneType = i.Customer.WorkPhone
                ? 'Work'
                : i.Customer.CellPhone
                ? 'Mobile'
                : 'Home';
              i.Customer.Phone =
                i.Customer.PhoneType === 'Home'
                  ? i.Customer.HomePhone
                  : i.Customer.PhoneType === 'Mobile'
                  ? i.Customer.CellPhone
                  : i.Customer.WorkPhone;
              n.CustomerEmail = i.Customer.Email;
              n.CustomerFirstName = i.Customer.FirstName;
              n.CustomerLastName = i.Customer.LastName;
              n.CustomerPhone = i.Customer.Phone;
              n.PaymentItem = i.AppointmentPayment.PaymentItem;
              n.OnlineCartID = u.getOnlineCartId();
              l(n);
            },
            purchase: function (t, i) {
              n.$http(
                r.purchase,
                [t],
                function (n) {
                  i(n);
                },
                !1,
                null,
                null,
                null,
                null,
                function () {
                  o.setUI(o.uiViews.payment.value);
                }
              );
            },
          },
          h = {
            confirm: function (r) {
              var u = t.get();
              h.prepare(u);
              n.$http(
                i.book[u.IsGroupBooking ? 'group' : 'single'],
                [u],
                function (n) {
                  h.reset();
                  var t = h.onConfirmed(n);
                  r(t);
                },
                !1,
                null,
                null,
                null,
                null,
                function () {
                  o.setUI(o.uiViews.payment.value);
                }
              );
            },
            itineraries: {
              prepare: function (n) {
                n.Guests.length > 1
                  ? this.prepareForGroup(n)
                  : this.prepareForSingle(n);
              },
              prepareForGroup: function (n) {
                n.ItineraryItems = [];
                n.IsGroupBooking = !0;
                n.Guests.each(function (t, i) {
                  n.ItineraryTimeSlotList[i].ItineraryTimeSlots.each(function (
                    r
                  ) {
                    r.TreatmentTimeSlots.each(function (r) {
                      t.IsPrimaryPayer = i === 0;
                      r.Guest = t;
                      n.ItineraryItems.push(r);
                    });
                  });
                });
              },
              prepareForSingle: function (n) {
                s || (s = angular.copy(n.ItineraryTimeSlotList));
                n.ItineraryTimeSlotList[0] = s[0].ItineraryTimeSlots[0];
                n.Customer || (n.Customer = n.Guests[0]);
              },
            },
            onConfirmed: function (n) {
              var t, r, i;
              return n.Appointment
                ? n.Appointment
                : (n.Items.each(function (n, t) {
                    t === 0 && (r = n.StartDateTime);
                    var u = e.toDate(n.EndDateTime, !0);
                    (!i || e.toDate(i, !0) < e.toDate(n.EndDateTime, !0)) &&
                      (i = n.EndDateTime);
                  }),
                  (t = n),
                  (t.StartDateTime = r),
                  (t.EndDateTime = i),
                  t);
            },
            prepare: function (n) {
              this.itineraries.prepare(n);
              n.AppointmentPayment.PaymentItem.CreditCard &&
                (n.AppointmentPayment.PaymentItem.CreditCard.BillingZip =
                  n.AppointmentPayment.PaymentItem.Zip);
              var t = u.getDiscountCode();
              t && (n.AppointmentPayment.CouponCode = t);
              l(n);
            },
            reset: function () {
              s = null;
            },
          };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.controller', [
      'booking.core.data.service',
      'booking.view.service',
      'booking.constants',
      'booking.availability.service',
      'core.service',
      'booking.account.service',
      'booking.user.service',
      function (n, t, i, r, u, f, e) {
        var o = this,
          s = !1,
          h = function () {
            t.resetOptions();
            t.setUI(t.uiViews.selection.value);
            l();
            o.view = t.options;
            a();
            n.getLocations(function (n) {
              o.locations = n;
              c();
            });
          },
          c = function () {
            var n = window.getQueryString('locationid');
            n !== null &&
              ((o.location = o.locations.firstOrDefault('ID', n.value)),
              o.locationChanged(!0));
          },
          l = function () {
            if (!s) {
              var n = null,
                i = window.getQueryString('treatmentName'),
                r = window.getQueryString('treatmentid'),
                u = window.getQueryString('seriesid'),
                f = window.getQueryString('packageName');
              (i !== null || r !== null) &&
                (n = t.bookingTypes.treatments.value);
              f && (n = t.bookingTypes.packages.value);
              u && (n = t.bookingTypes.series.value);
              n !== null && t.setType(n);
              s = !0;
            }
          },
          a = function () {
            var n = angular.element('input[type="hidden"][id$=hdnId]'),
              t,
              i;
            n.length === 1 &&
              n[0].value.length > 0 &&
              ((t = n[0].value.split('@')[0]),
              (i = n[0].value.split('@')[1]),
              e.set(t, i));
          };
        o.locationChanged = function () {
          console.log(o.location.ID);
          switch (o.location.ID) {
            case 4290:
              window.location = 'http://www.secure-booker.com/spalondonrlc/';
              break;
            case 3295:
              window.location = 'http://www.secure-booker.com/yorkhall/';
              break;
            case 30583:
              window.location =
                'http://www.secure-booker.com/spalondonkensington/';
              break;
            case 12811:
              window.location =
                'http://www.secure-booker.com/spalondonironmongerrow/';
              break;
            case 3296:
              window.location = 'http://www.secure-booker.com/SLSwisscottage/';
              break;
            case 7216:
              window.location =
                'http://www.secure-booker.com/SLMarshallstreet/';
              break;
            case 19984:
              window.location = 'http://www.secure-booker.com/GoslingParkSpa/';
              break;
            case 35829:
              window.location =
                'http://www.secure-booker.com/spalondontaunton/';
              break;
            case 35831:
              window.location =
                'http://www.secure-booker.com/spaexperiencewaltham/';
              break;
            case 38227:
              window.location = 'http://www.secure-booker.com/sebelfast/';
              break;
            case 19813:
            default:
              window.location =
                'http://www.secure-booker.com/spalondonwimbledon/';
          }
        };
        o.bookingTypeClick = function (n) {
          t.setType(n);
        };
        o.backToView = function (n) {
          t.setUI(n);
        };
        o.hasBasket = function () {
          return (
            o.view.isSeries ||
            o.view.ui === t.uiViews.selection.value ||
            o.view.ui === t.uiViews.availability.value
          );
        };
        o.hasBasketSummary = function () {
          return (
            !o.view.isSeries &&
            (o.view.ui === t.uiViews.review.value ||
              t.options.ui === t.uiViews.confirm.value)
          );
        };
        o.reset = function () {
          window.location.href = '/book/';
        };
        h();
        u.broadcast.on(r.$broadcastKeys.TimeSlotsRequested, function () {
          t.setUI(t.uiViews.availability.value);
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.directive('booking', [
      'booking.constants',
      function (n) {
        return {
          restrict: 'EA',
          transclude: !0,
          templateUrl:
            n.baseTemplatePath +
            'booking.container.html?date=' +
            escape(new Date()),
        };
      },
    ]);
    n.directive('treatmentSummary', [
      '$timeout',
      'booking.constants',
      'booking.view.service',
      'core.service',
      'booking.basket.service',
      'booking.values',
      'booking.user.service',
      'booking.account.service',
      function (n, t, i, r, u, f, e, o) {
        return {
          restrict: 'EA',
          scope: { treatment: '=' },
          controllerAs: 'ctrl',
          controller: [
            '$scope',
            function (t) {
              var s = this,
                c = function () {
                  s.view = i.options;
                  h();
                },
                h = function () {
                  s.user = e.get();
                },
                l = function () {
                  delete s.view;
                },
                a = function (n) {
                  return angular.element(
                    '#geo-cb-' + t.treatment.ID + '-' + n
                  )[0];
                };
              s.register = function () {
                o.IsNewAccountValid('#register-form', s.Customer) &&
                  ((s.registering = !0),
                  o.register(
                    s.Customer,
                    i.options.location.ID,
                    function () {
                      o.login(
                        s.Customer.Email,
                        s.Customer.Password,
                        i.options.location.ID,
                        function () {
                          h();
                          o.displayLoginSuccessMessage(s.customer);
                          delete s.registerVisible;
                        }
                      );
                    },
                    function () {
                      delete s.registering;
                    }
                  ));
              };
              s.onLogin = function () {
                h();
                delete s.loginVisible;
              };
              s.guestClick = function (n) {
                s.treatmentSelected(n)
                  ? u.remove(n, t.treatment.ID)
                  : u.addTreatment(n, t.treatment);
              };
              s.treatmentSelected = function (n) {
                return (
                  t.treatment && u.getByTreatmentId(n, t.treatment.ID) !== null
                );
              };
              t.$watch('treatment.ID', function (n) {
                n && (l(), c());
              });
              r.broadcast.on(i.$broadcastKeys.GuestsChanged, function () {
                n(function () {
                  customForm.customForms.replaceAll();
                });
              });
              r.broadcast.on(i.$broadcastKeys.TypeChanged, function (t) {
                t.isTreatment &&
                  (f.guests.setActive(f.guests.items[0].key),
                  n(function () {
                    customForm.customForms.replaceAll();
                  }));
              });
            },
          ],
          templateUrl:
            t.baseTemplatePath +
            'partials/treatment.summary.html?date=' +
            escape(new Date()),
        };
      },
    ]);
    n.directive('addressForm', [
      'core.validation.service',
      'locations.api',
      'core.service',
      'core.modal.service',
      'booking.constants',
      function (n, t, i, r, u) {
        return {
          restrict: 'EA',
          scope: { addressModel: '=' },
          link: function (f, e) {
            var o = function (n) {
                var t = n.selectedAddress;
                r.close(function () {
                  var n = t.split(',');
                  f.addressModel.Street1 = n[0];
                  f.addressModel.Street2 = n[1];
                  f.addressModel.City = n[n.length - 2];
                });
              },
              s = function (n) {
                var t = [];
                return (
                  angular.forEach(n, function (n) {
                    for (
                      var r = new StringBuilder(), u = n.split(','), i = 0;
                      i < u.length;
                      i++
                    )
                      jq.trim(u[i]).length > 0 && r.appendWith(u[i], ',');
                    r.toString().length > 0 && t.push(r.toString());
                  }),
                  t
                );
              };
            f.findAddress = function () {
              if (f.addressModel.Zip && f.addressModel.Zip.length > 0) {
                var h = { addresses: undefined };
                r.open({
                  title: 'Select address',
                  type: 'default',
                  templateUrl:
                    u.baseTemplatePath + 'partials/modal.find.address.html',
                  modalModel: h,
                  onOpen: function () {
                    i.$http(t.findAddress, [f.addressModel.Zip], function (n) {
                      h.addresses = n.Addresses ? s(n.Addresses) : [];
                    });
                  },
                  actionClick: o,
                  actionText: 'Select',
                });
              } else
                n.setError(e.find('.postcode'), 'You must enter a postcode');
            };
          },
          templateUrl:
            u.baseTemplatePath +
            'partials/address.form.html?date=' +
            escape(new Date()),
        };
      },
    ]);
    n.directive('paymentForm', [
      '$timeout',
      'booking.constants',
      'booking.http.service',
      'booking.core.data.service',
      function (n, t, i, r) {
        return {
          restrict: 'EA',
          scope: {
            paymentModel: '=',
            locationId: '=',
            totalCost: '=',
            forSeries: '=',
            forGiftCard: '=',
          },
          controllerAs: 'ctrl',
          controller: [
            '$scope',
            function (i) {
              var u = this,
                f = function () {
                  e();
                },
                e = function () {
                  r.getSettings(i.locationId, function (n) {
                    u.settings = n;
                    s(n);
                    o(n);
                  });
                },
                o = function () {
                  (i.paymentModel && i.paymentModel.Method) ||
                    (i.paymentModel = { Method: u.paymentMethods[0] });
                  i.paymentModel.Amount = {
                    Amount: i.totalCost,
                    CurrencyCode: t.payment.currencyCode,
                  };
                },
                s = function (n) {
                  u.paymentMethods =
                    i.forSeries || i.forGiftCard
                      ? n.paymentMethods
                      : n.paymentMethods.whereNot('Name', 'series');
                };
              u.methodChange = function (n) {
                i.paymentModel.Method = n;
              };
              i.$watch('totalCost', function (t) {
                t &&
                  n(function () {
                    f();
                  });
              });
            },
          ],
          templateUrl:
            t.baseTemplatePath +
            'partials/payment.form.html?date=' +
            escape(new Date()),
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.filter('epochToDate', [
      '$filter',
      function (n) {
        return function (t, i, r) {
          if (t && t.length > 0)
            try {
              var f = t.split('-'),
                e = f[0].substring(6),
                o = parseInt(f[1].split(')')[0]) / 100,
                u = new Date(0);
              return (u.setMilliseconds(e), u.setHours(u.getHours() - o), i)
                ? n('date')(u, i, r)
                : u;
            } catch (s) {
              console.log(s, { epochDate: t });
            }
          return '';
        };
      },
    ]);
    n.filter('epochToTime', [
      '$filter',
      'epochToDateFilter',
      function (n, t) {
        return function (i, r) {
          if (i && i.length > 0) {
            var u = t(i);
            return (
              r && (u = new Date(u.getTime() + r * 6e4)),
              n('date')(u, 'shortTime', '0')
            );
          }
          return '';
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.packages.controller', [
      '$timeout',
      'booking.http.service',
      'packages.api',
      'booking.view.service',
      'booking.basket.service',
      'core.service',
      function (n, t, i, r, u, f) {
        var e = this,
          s = function () {
            delete e.packages;
            delete e.package;
          },
          o = function (n) {
            s();
            t.$http(
              i.get,
              [n],
              function (n) {
                e.packages = n;
                h(n);
              },
              !0
            );
          },
          h = function (n) {
            var t = window.getQueryString('packageid'),
              i = window.getQueryString('packagename'),
              u = window.getQueryString('duration');
            (t !== null || i !== null) &&
              ((e.package =
                t !== null
                  ? n.firstOrDefault('ID', t.value)
                  : n
                      .where('Name', decodeURI(i.value))
                      .firstOrDefault('Duration', u.value)),
              e.package && (r.setTab(r.bookingTypes.packages.value), e.book()));
          };
        e.book = function () {
          u.addPackage(e.package);
        };
        f.broadcast.on(r.$broadcastKeys.LocationChanged, function (n) {
          n != null && r.options.isPackage && o(n.ID);
        });
        f.broadcast.on(r.$broadcastKeys.TypeChanged, function (n) {
          n.isPackage && r.options.location != null && o(r.options.location.ID);
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.payment.controller', [
      '$timeout',
      'booking.checkout.service',
      'core.service',
      'booking.view.service',
      'booking.basket.service',
      'booking.series.api',
      'booking.http.service',
      function (n, t, i, r, u, f, e) {
        var o = this,
          s = function () {
            if (((o.view = r.options), (o.totalCost = u.getTotal()), !o.form)) {
              var n = t.get();
              o.form = n.AppointmentPayment.PaymentItem;
            }
          };
        o.addSeriesPayment = function () {
          e.$http(
            f.addPayment,
            [o.form, u.getOnlineCartId()],
            function (n) {
              n.IsSuccess && r.setUI(r.uiViews.confirm.value);
            },
            !0
          );
        };
        o.confirm = function () {
          i.isFormValid('#payment-form') &&
            ((t.current.AppointmentPayment.PaymentItem = o.form),
            r.setUI(r.uiViews.confirm.value));
        };
        i.broadcast.on(r.$broadcastKeys.PaymentView, function () {
          n(function () {
            delete o.settings;
            s();
          });
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.review.controller', [
      '$timeout',
      'booking.view.service',
      'core.service',
      'booking.checkout.service',
      'booking.core.data.service',
      'booking.account.service',
      'basket.api',
      function (n, t, i, r, u, f) {
        var e = this,
          o = null,
          c = function () {
            e.view = t.options;
            e.checkout = r.get();
            u.getCountries(function (n) {
              o = n;
              l();
            });
          },
          l = function () {
            f.getAuthenticated(
              function (n) {
                n && (s(n), h(n));
              },
              function () {
                e.checkedUser = !0;
              }
            );
          },
          a = function () {
            delete e.checkedUser;
          },
          s = function (n) {
            n.Address.Country = o.firstOrDefault(
              'ID',
              i.forKeys(n.Address.Country, 'Id')
            );
            n.PhoneType = n.WorkPhone
              ? 'Work'
              : n.CellPhone
              ? 'Mobile'
              : 'Home';
            n.Phone =
              n.PhoneType === 'Home'
                ? n.HomePhone
                : n.PhoneType === 'Mobile'
                ? n.CellPhone
                : n.WorkPhone;
          },
          h = function (n) {
            e.user = n;
            e.checkout.Customer = n;
            e.view.isSeries || (e.checkout.Guests[0] = n);
          };
        e.allowPayment = function () {
          return !t.options.isSeries || (t.options.isSeries && e.user != null);
        };
        e.onLogin = function (n) {
          n && (s(n), h(n), i.broadcast.call(f.$broadcastKeys.UserUpdated, n));
        };
        e.paymentView = function () {
          i.isFormValid('#review-container') &&
            t.setUI(t.uiViews.payment.value);
        };
        e.register = function () {
          f.IsNewAccountValid('#review-container', e.checkout.Customer) &&
            ((e.registering = !0),
            f.register(
              e.checkout.Customer,
              t.options.location.ID,
              function () {
                f.login(
                  e.checkout.Customer.Email,
                  e.checkout.Customer.Password,
                  t.options.location.ID,
                  function (n) {
                    e.onLogin(n);
                  }
                );
              },
              function () {
                delete e.registering;
              }
            ));
        };
        i.broadcast.on(t.$broadcastKeys.ReviewView, function () {
          n(function () {
            a();
            c();
          });
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.series.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'series/';
        i.get = function (t) {
          return n({ method: 'get', url: r + 'Get/?locationid=' + t });
        };
        i.purchase = function (t) {
          return n({ method: 'post', url: r + 'post/', data: t });
        };
        i.addSpecial = function (t, i) {
          return n({
            method: 'post',
            url: r + 'addSpecial/',
            data: { CouponCode: t, OnlineCartID: i },
          });
        };
        i.remove = function (t, i) {
          return n({
            method: 'post',
            url: r + 'remove/',
            data: { ID: t, OnlineCartID: i },
          });
        };
        i.addPayment = function (t, i) {
          return n({
            method: 'post',
            url: r + 'addPayment/',
            data: { PaymentItem: t, OnlineCartID: i },
          });
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.series.controller', [
      'core.service',
      'booking.http.service',
      'treatments.api',
      'booking.series.api',
      'booking.basket.service',
      'booking.view.service',
      function (n, t, i, r, u, f) {
        var e = this,
          s = !1,
          o = function (n) {
            c(n, function (n) {
              e.series = n.series;
              e.treatments = n.treatments;
              e.categories = n.categories;
              y();
            });
          },
          h = function () {
            delete e.series;
            delete e.treatments;
            delete e.categories;
          },
          c = function (n, t) {
            a(n, function (i) {
              var r = i.Treatments;
              l(n, function (n) {
                t({
                  treatments: r,
                  categories: v(n, r),
                  series: n,
                  totalRecords: n.TotalResultsCount,
                });
              });
            });
          },
          l = function (n, i) {
            t.$http(
              r.get,
              [n],
              function (n) {
                i(n);
              },
              !0
            );
          },
          a = function (n, r) {
            t.$http(
              i.getByLocationId,
              [n],
              function (n) {
                r(n);
              },
              !0
            );
          },
          v = function (n, t) {
            var i = [];
            return (
              n.each(function (n) {
                var r = t.where(
                  'ID',
                  n.SeriesRedeemableItems[0].TreatmentID
                )[0];
                r && i.push(r.Category);
              }),
              i.distinct('ID')
            );
          },
          y = function () {
            var n, t;
            s ||
              ((n = window.getQueryString('seriesid')),
              n !== null &&
                ((t = e.series.firstOrDefault('ID', n.value)),
                t !== null &&
                  (f.setTab(f.bookingTypes.series.value), e.addToBasket(t))));
          };
        e.getCategoryName = function (n) {
          return e.treatments.where('ID', n)[0].Category.Name;
        };
        e.addToBasket = function (n) {
          u.addSeries(n);
        };
        n.broadcast.on(f.$broadcastKeys.LocationChanged, function (n) {
          n != null && f.options.isSeries && (h(), o(n.ID));
        });
        n.broadcast.on(f.$broadcastKeys.TypeChanged, function (n) {
          n.isSeries && f.options.location != null && o(f.options.location.ID);
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.filter('seriesFilter', function () {
      return function (n, t, i) {
        if (!angular.isUndefined(n) && !angular.isUndefined(i) && i !== null) {
          var r = [];
          return (
            angular.forEach(n, function (n) {
              var u = t.where('ID', n.SeriesRedeemableItems[0].TreatmentID)[0];
              u.Category.ID === i && r.push(n);
            }),
            r
          );
        }
        return n;
      };
    });
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.controller('booking.treatments.controller', [
      '$timeout',
      'booking.http.service',
      'booking.values',
      'booking.view.service',
      'treatments.api',
      'core.service',
      'booking.basket.service',
      function (n, t, i, r, u, f, e) {
        var o = this,
          h = !1,
          c = function () {
            o.guests = i.guests;
            o.view = r.options;
            o.view.location && s(r.options.location);
          },
          l = function () {
            r.options.category = null;
            delete o.categories;
            delete o.categorySelected;
            delete o.treatments;
            delete o.treatment;
          },
          s = function (n) {
            l();
            t.$http(
              u.getCategories,
              [n.ID, n.CultureName],
              function (n) {
                o.categories = n;
                v();
              },
              !0
            );
          },
          a = function (n, i, r) {
            t.$http(
              u.getByLocationByCategory,
              [n, i],
              function (n) {
                o.treatments = n.Treatments;
                r && r(o.treatments);
              },
              !0
            );
          },
          v = function () {
            if (!h) {
              var t = window.getQueryString('categoryid'),
                i = window.getQueryString('treatmentname'),
                n = window.getQueryString('treatmentid'),
                u = window.getQueryString('duration');
              t !== null &&
                (n !== null || i !== null) &&
                ((o.category = o.categories.firstOrDefault('ID', t.value)),
                o.categoryChanged(function (t) {
                  o.treatment =
                    n !== null
                      ? t.firstOrDefault('ID', n.value)
                      : t
                          .where('Name', decodeURI(i.value))
                          .firstOrDefault('Duration', u.value);
                  o.treatment &&
                    (r.setTab(r.bookingTypes.treatments.value),
                    e.addTreatment(1, o.treatment));
                  h = !0;
                }));
            }
          };
        o.guestItemClick = function (n) {
          r.setGuests(n.key);
          i.guests.setActive(n.key);
        };
        o.categoryChanged = function (n) {
          delete o.treatment;
          r.options.category = o.category;
          o.categorySelected = r.options.category != null;
          o.categorySelected && a(r.options.location.ID, o.category.ID, n);
        };
        n(function () {
          c();
        });
        f.broadcast.on(r.$broadcastKeys.LocationChanged, function (n) {
          n == null ||
            r.options.isPackage ||
            r.options.isSeries ||
            r.options.wasReferred ||
            s(n);
        });
        f.broadcast.on(r.$broadcastKeys.TypeChanged, function (n) {
          n.isTreatment && (l(), c(), s(r.options.location));
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.value('booking.values', {
      guests: {
        items: [{ key: 1, active: !0 }, { key: 2 }, { key: 3 }, { key: 4 }],
        getActive: function () {
          return this.items.where('active', !0)[0];
        },
        setActive: function (n) {
          this.items.each(function (t) {
            t.active = t.key === n;
          });
        },
      },
      appointment: {
        timeframes: [
          { id: 1, text: 'Anytime', startTime: '00:00', endTime: '23:59' },
          {
            id: 2,
            text: 'Morning (Open to noon)',
            startTime: '00:00',
            endTime: '12:00',
          },
          {
            id: 3,
            text: 'Afternoon (Noon to 5pm)',
            startTime: '12:00',
            endTime: '17:00',
          },
          {
            id: 4,
            text: 'Evening (5pm - Closing)',
            startTime: '17:00',
            endTime: '23:59',
          },
          {
            id: 5,
            text: 'Specific Time',
            startTime: '10:00',
            endTime: '23:59',
            isCustom: !0,
          },
        ],
        timeOptions: {
          hours: [
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
          ],
          minutes: ['00', '15', '30', '45'],
        },
      },
    });
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.value('booking.view.model', {
      wasReferred: !1,
      ui: null,
      location: null,
      category: null,
      guests: 1,
      isPackage: !1,
      isSeries: !1,
      availability: {
        date: null,
        suggestedDates: { active: !1, startDate: null, endDate: null },
        timeframe: null,
        ItineraryTimeSlotList: null,
        asDate: function (n) {
          return moment(angular.copy(n), 'DD/MM/YYYY');
        },
        createDateTime: function (n, t) {
          return new Date(
            n.year(),
            n.month(),
            n.date(),
            t.split(':')[0],
            t.split(':')[1],
            0,
            0
          );
        },
        createStartEndDateTimes: function (n, t, i, r) {
          return {
            startDateTime: this.createDateTime(this.asDate(n), t),
            endDateTime: this.createDateTime(this.asDate(i), r),
          };
        },
        getDatesFormatted: function () {
          return this.suggestedDates.active
            ? this.createStartEndDateTimes(
                this.suggestedDates.startDate,
                '00:00',
                this.suggestedDates.endDate,
                '23:59'
              )
            : this.createStartEndDateTimes(
                this.date,
                this.timeframe.startTime,
                this.date,
                this.timeframe.endTime
              );
        },
      },
    });
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.view.service', [
      '$timeout',
      'core.service',
      'core.constants',
      'booking.util.service',
      'booking.view.model',
      function (n, t, i, r, u) {
        var f = this,
          o = function (i) {
            n(function () {
              switch (i.toLowerCase()) {
                case f.uiViews.review.value:
                  t.broadcast.call(f.$broadcastKeys.ReviewView, {});
                  break;
                case f.uiViews.payment.value:
                  t.broadcast.call(f.$broadcastKeys.PaymentView, {});
                  break;
                case f.uiViews.confirm.value:
                  t.broadcast.call(f.$broadcastKeys.ConfirmView, {});
              }
            });
          },
          e = function (n) {
            f.options.guests = 1;
            f.options.isPackage =
              n.toLowerCase() === f.bookingTypes.packages.value;
            f.options.isSeries =
              n.toLowerCase() === f.bookingTypes.series.value;
          };
        f.$broadcastKeys = {
          LocationChanged: '<LocationChanged>',
          TypeChanged: '<TypeChanged>',
          GuestsChanged: '<GuestsChanged>',
          ItineraryTimeSlotChanged: '<ItineraryTimeSlotChanged>',
          ReviewView: '<ReviewView>',
          PaymentView: '<PaymentView>',
          ConfirmView: '<ConfirmView>',
        };
        f.uiViews = {
          selection: { value: 'selection' },
          availability: { value: 'availability' },
          review: { value: 'review' },
          payment: { value: 'payment' },
          confirm: { value: 'confirm' },
        };
        f.bookingTypes = {
          treatments: { value: 'treatments' },
          packages: { value: 'packages' },
          series: { value: 'series' },
        };
        f.setUI = function (n) {
          f.options.ui = n.toLowerCase();
          o(n);
          window.scrollTo(0, 0);
        };
        f.setLocation = function (i) {
          f.options.location = i;
          i != null && ((f.options.category = null), (f.options.guests = 1));
          n(function () {
            t.broadcast.call(f.$broadcastKeys.LocationChanged, i);
          }, 1e3);
        };
        f.setGuests = function (n) {
          f.options.guests = n;
          t.broadcast.call(f.$broadcastKeys.GuestsChanged, n);
        };
        f.setType = function (n) {
          e(n);
          t.broadcast.call(f.$broadcastKeys.TypeChanged, {
            isPackage: f.options.isPackage,
            isSeries: f.options.isSeries,
            isTreatment: !f.options.isPackage && !f.options.isSeries,
          });
        };
        f.setTab = function (n) {
          e(n);
        };
        f.setItineraryTimeSlot = function (n) {
          f.options.availability.ItineraryTimeSlotList = n;
          t.broadcast.call(f.$broadcastKeys.ItineraryTimeSlotChanged, n);
        };
        f.resetOptions = function () {
          f.options = angular.copy(u);
        };
        f.options = null;
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('giftcards.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'giftCertificates/';
        i.getTemplates = function (t) {
          return n({ method: 'get', url: r + 'Get?locationid=' + t });
        };
        i.purchase = function (t) {
          return n({ method: 'post', url: r + 'Post', data: t });
        };
        i.create = function (t) {
          return n({ method: 'post', url: r + 'Create', data: t });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('giftcards.controller', [
      '$timeout',
      'booking.http.service',
      'treatments.api',
      'giftcards.api',
      'packages.api',
      'locations.api',
      'core.service',
      function (n, t, i, r, u, f, e) {
        var o = this,
          s;
        o.locations = [];
        o.templates = null;
        o.selectedLocation = null;
        o.selectedGiftType = null;
        o.giftType = [
          { value: 'fixed', text: 'Fixed Amount' },
          { value: 'package', text: 'Package' },
          { value: 'treatment', text: 'Treatment' },
        ];
        o.responsive = [
          { breakpoint: 768, settings: { slidesToShow: 4, slidesToScroll: 4 } },
        ];
        o.ToDate = moment().format('DD/MM/YYYY');
        o.locationChanged = function (n) {
          o.selectedLocation = n;
          var f = 0,
            e = o.locations.length;
          for (f; f < e; f++)
            o.locations[f].ID == n &&
              (o.locationCulture = o.locations[f].CultureName);
          t.$http(
            r.getTemplates,
            [n],
            function (n) {
              o.templates = n.GiftCertificateTemplates;
            },
            !0
          );
          t.$http(
            u.get,
            [n],
            function (n) {
              o.packages = n;
            },
            !0
          );
          t.$http(
            i.getCategories,
            [n, o.locationCulture],
            function (n) {
              o.categories = n;
            },
            !0
          );
        };
        o.giftTypeChanged = function (n) {
          o.selectedGiftType = n;
        };
        o.selectTemplate = function (n) {
          console.log('called');
          o.selectedTemplateId = n;
        };
        o.amountChanged = function () {
          o.currentAmount = { Amount: o.amount, CurrencyCode: 'GBP' };
        };
        o.packageChanged = function (n) {
          o.packageId = n;
          var t = 0,
            i = o.packages.length;
          for (t; t < i; t++)
            o.packages[t].ID == n && (o.currentAmount = o.packages[t].Price);
        };
        o.treatmentChanged = function (n) {
          o.treatmentId = n;
          var t = 0,
            i = o.treatments.length;
          for (t; t < i; t++)
            o.treatments[t].ID == n &&
              (o.currentAmount = o.treatments[t].Price);
        };
        o.categoryChanged = function (n) {
          t.$http(
            i.getByLocationByCategory,
            [o.selectedLocation, n],
            function (n) {
              o.treatments = n.Treatments;
            },
            !0
          );
        };
        o.purchaseCertificate = function () {
          e.isFormValid('#gift-form') &&
            !o.purchasing &&
            ((o.purchasing = !0),
            (o.payment.Amount = o.currentAmount),
            t.$http(
              r.purchase,
              [
                {
                  CustomerEmail: o.customer.Email,
                  CustomerFirstName: o.customer.FirstName,
                  CustomerLastName: o.customer.LastName,
                  CustomerPhone: o.customer.Phone,
                  SendEmailReceipt: !0,
                  PaymentItem: o.payment,
                  Amount: o.selectedGiftType == 'fixed' ? o.amount : null,
                  PackageId:
                    o.selectedGiftType == 'package' ? o.packageId : null,
                  TreatmentId:
                    o.selectedGiftType == 'treatment' ? o.treatmentId : null,
                  GiftFrom: o.From,
                  GiftMessage: o.Message,
                  GiftTo: o.To,
                  GiftTemplateId: o.selectedTemplateId,
                  LocationID: o.selectedLocation,
                  NotificationToEmail: o.ToEmail,
                  NoticationDateToSend: Date.now(),
                },
              ],
              function (n) {
                o.purchasing = !1;
                n.IsSuccess
                  ? ((o.error = !1),
                    (o.success = !0),
                    (o.giftcertificateid = n.GiftCertificateNumber),
                    (o.orderno = n.OrderNumber),
                    (window.dataLayer = window.dataLayer || []),
                    window.dataLayer.push({
                      ecommerce: {
                        purchase: {
                          actionField: {
                            id: n.OrderNumber,
                            affiliation: 'Spa Experience',
                            revenue: parseFloat(
                              o.payment.Amount.Amount
                            ).toFixed(2),
                            tax: '0.00',
                            shipping: '0.00',
                            coupon: '',
                          },
                          products: [
                            {
                              name: 'Spa Experience Gift Card',
                              id: 'spagiftcard',
                              price: parseFloat(
                                o.payment.Amount.Amount
                              ).toFixed(2),
                              brand: 'Spa Experience',
                              category: 'Gift Card',
                              variant: '',
                              quantity: 1,
                              coupon: '',
                            },
                          ],
                        },
                      },
                      event: 'giftcardpurchase',
                    }))
                  : ((o.error = !0), (o.message = n.ErrorMessage));
              },
              !0
            ));
        };
        s = function () {
          t.$http(
            f.get,
            [],
            function (n) {
              o.locations = n;
            },
            !0
          );
        };
        n(function () {
          s();
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.account.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'account/';
        i.login = function (t, i, u) {
          return n({
            method: 'post',
            url: r + 'login',
            data: { Email: t, Password: i, locationId: u },
          });
        };
        i.logout = function (t) {
          return n({ method: 'get', url: r + 'logout?userAccessToken=' + t });
        };
        i.register = function (t) {
          return n({ method: 'post', url: r + 'register', data: t });
        };
        i.getAuthenticated = function () {
          return n({ method: 'get', url: r + 'authenticated' });
        };
        i.getPurchasedSeries = function (t) {
          return n({
            method: 'post',
            url: r + 'getPurchasedSeries',
            data: { LocationID: t },
          });
        };
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.directive('loginForm', [
      'booking.account.service',
      'booking.constants',
      function (n, t) {
        return {
          restrict: 'EA',
          scope: { locationId: '=', onLogin: '&' },
          controllerAs: 'ctrl',
          controller: [
            '$scope',
            function (t) {
              var i = this;
              i.loginClick = function () {
                var r = i.form;
                i.waiting = !0;
                n.login(
                  r.Email,
                  r.Password,
                  t.locationId,
                  function (i) {
                    n.displayLoginSuccessMessage(i);
                    t.onLogin && t.onLogin()(i);
                  },
                  function () {
                    delete i.waiting;
                  }
                );
              };
            },
          ],
          templateUrl:
            t.baseFilePath +
            'account/templates/login.html?date=' +
            escape(new Date()),
        };
      },
    ]);
    n.directive('customerForm', [
      '$timeout',
      'booking.core.data.service',
      'booking.constants',
      function (n, t, i) {
        return {
          restrict: 'EA',
          scope: {
            customerModel: '=',
            allowAccountCreation: '=',
            isRegistration: '@',
            isPrimaryPayer: '=',
            registrationRequired: '=',
          },
          link: function (t, i) {
            n(function () {
              t.registrationRequired || t.isRegistration
                ? (i.find('.customerPassword1').addClass('required'),
                  i.find('.customerPassword2').addClass('required'))
                : (i.find('.customerPassword1').removeClass('required'),
                  i.find('.customerPassword2').removeClass('required'));
            });
          },
          controllerAs: 'ctrl',
          controller: [
            '$scope',
            function (i) {
              var r = this,
                u = function () {
                  (i.registrationRequired || i.isRegistration) &&
                    ((i.registrationRequired = !0),
                    (i.allowAccountCreation = !0),
                    (i.isPrimaryPayer = !0));
                  t.getCountries(function (n) {
                    r.countries = n;
                  });
                };
              n(function () {
                u();
              });
            },
          ],
          templateUrl:
            i.baseFilePath +
            'account/templates/registration.html?date=' +
            escape(new Date()),
        };
      },
    ]);
  })(),
  (function () {
    'use  strict';
    var n = angular.module('app');
    n.service('booking.account.service', [
      'booking.http.service',
      'booking.account.api',
      'booking.values',
      'core.modal.service',
      'core.validation.service',
      'booking.user.service',
      'core.cache.service',
      'booking.constants',
      'core.constants',
      function (n, t, i, r, u, f, e, o) {
        var s = this;
        s.$broadcastKeys = { UserUpdated: '<UserUpdated>' };
        s.login = function (i, r, u, e, o) {
          n.$http(
            t.login,
            [i, r, u],
            function (n) {
              n.Customer.Customer.CustomerID = n.Customer.CustomerID;
              f.set(n.Customer.CustomerID, n.access_token);
              e && e(n.Customer.Customer);
            },
            !1,
            function () {
              o && o();
            },
            null,
            null,
            !1
          );
        };
        s.loginComplete = function (n) {
          s.displayLoginSuccessMessage(n);
        };
        s.logout = function (i) {
          var r = f.get();
          n.$http(t.logout, [r.accessToken], function (n) {
            f.remove();
            i && i(n);
          });
        };
        s.displayLoginSuccessMessage = function (n, t) {
          r.open({
            title: 'Login Success',
            templateUrl:
              o.baseFilePath + 'account/templates/modal.login.success.html',
            type: 'success',
            modalModel: n,
            onOpen: function () {
              t && t();
            },
          });
        };
        s.register = function (i, u, f, e) {
          i.locationId = u;
          n.$http(
            t.register,
            [i],
            function (n) {
              n.IsSuccess
                ? f(n)
                : r.open({
                    title: 'Registration failed',
                    type: 'error',
                    modalModel: {
                      errorMessage: n.ErrorMessage
                        ? n.ErrorMessage
                        : 'Registration was unsuccessful, an unknown error occurred',
                    },
                  });
            },
            !1,
            function () {
              e && e();
            }
          );
        };
        s.IsNewAccountValid = function (n, t) {
          var i = u.formValid(n);
          return i && t.Password && t.Password.length > 0
            ? t.Password2 &&
              t.Password2.length > 0 &&
              t.Password === t.Password2
              ? !0
              : (r.open({
                  title: 'Validation Error',
                  type: 'error',
                  modalModel: {
                    errorMessage:
                      'Your passwords do not match, please re-type your passwords to ensure they match. Passwords are case sensitive and must contain at least one number',
                  },
                }),
                !1)
            : i;
        };
        s.getAuthenticated = function (i, r) {
          n.$http(
            t.getAuthenticated,
            [],
            function (n) {
              n.Customer &&
                (n.Customer.Customer.CustomerID = n.Customer.CustomerID);
              var t = n.Customer ? n.Customer.Customer : null;
              i && i(t);
            },
            !1,
            function () {
              r && r();
            },
            null,
            null,
            null,
            function (n) {
              console.log(n);
            }
          );
        };
        s.unAuthorisedAccess = function (n) {
          r.open({
            title: 'Unauthorised',
            type: 'error',
            modalModel: {
              errorMessage:
                'Current user is not authorised to view account information',
            },
            templateUrl:
              '/angularjs/app/shared/core/templates/modal.error.html',
            onOpen: function () {
              n && n();
            },
          });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('booking.myaccount.controller', [
      '$timeout',
      'booking.http.service',
      'booking.account.api',
      'locations.api',
      'core.service',
      function (n, t, i, r) {
        var u = this,
          f = function () {
            t.$http(
              r.get,
              [],
              function (n) {
                u.locations = n;
              },
              !0
            );
          };
        u.getPurchasedSeries = function (n) {
          t.$http(
            i.getPurchasedSeries,
            [n],
            function (n) {
              u.series = n;
            },
            !0
          );
        };
        n(function () {
          f();
        });
      },
    ]);
  })(),
  (function () {
    'use strict';
    var n = angular.module('app');
    n.service('booking.user.service', [
      'core.cache.service',
      'booking.account.api',
      function (n) {
        var t = this,
          i = function (n, t) {
            return { customerId: n, accessToken: t };
          };
        t.cacheKey = '<bookingUser>';
        t.get = function () {
          return n.get(t.cacheKey);
        };
        t.getUserId = function () {
          var n = t.get();
          return n !== null ? n.customerId : null;
        };
        t.getAuthToken = function () {
          var n = t.get();
          return n !== null ? n.accessToken : null;
        };
        t.set = function (r, u) {
          n.set(t.cacheKey, i(r, u), 'medium');
        };
        t.remove = function () {
          n.remove(t.cacheKey);
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.service('basket.api', [
      '$http',
      'booking.constants',
      function (n, t) {
        var i = this,
          r = t.api.baseUrl + 'basket/';
        i.get = function () {
          return n({ method: 'get', url: r + 'Get' });
        };
        i.purchase = function (t) {
          return n({ method: 'post', url: r + 'Post', data: t });
        };
        i.create = function (t, i) {
          return n({
            method: 'post',
            url: r + 'Create',
            data: { LocationID: t, SeriesIDs: i },
          });
        };
        i.associate = function (t) {
          return n({
            method: 'post',
            url: r + 'Associate',
            data: { OnlineCartID: t },
          });
        };
        i.calculateAppointmentCost = function (t) {
          return n({
            method: 'post',
            url: r + 'CalculateAppointment',
            data: t,
          });
        };
      },
    ]);
  })(),
  (function () {
    var n = angular.module('app');
    n.controller('basket.controller', [
      '$timeout',
      'booking.http.service',
      'treatments.api',
      'basket.api',
      'packages.api',
      'locations.api',
      'core.service',
      function (n, t, i, r) {
        var f = this,
          u = function () {
            t.$http(
              r.get,
              [],
              function (n) {
                console.log(n);
              },
              !0
            );
          };
        n(function () {
          u();
        });
      },
    ]);
  })();
var jq = jQuery.noConflict();
jq(document).ready(function () {
  var n, t, u, i, r, f;
  jq('.sidebar-nav li').has('ul').addClass('has-drop');
  jq('.sidebar-nav .has-drop > div > a').append(
    '<span class="opener">Open Drop</span>'
  );
  // customForm.lib.domReady(function () {
  //   customForm.customForms.replaceAll();
  // });
  jq('.container.banners').each(function () {
    var n = jq(this);
    n.text().replace(/\s|&nbsp;/g, '').length == 0 &&
      (n.hide(), n.prev().addClass('no-banner'));
  });
  jq(
    '.main-gallery .img, .video, .info-section .block > div, .team-list .box, .visual-block, .spas-list .img'
  ).each(function () {
    var n = 'url(' + jq(this).find('img').attr('src') + ')';
    jq(this).css('background-image', n);
  });
  jq('.main-nav > ul > li:not(.search) > a')
    .mouseenter(function () {
      var n = jq(this);
      n.closest('li').addClass('active').siblings('li').removeClass('active');
      menuLine();
    })
    .mouseleave(function () {
      var n = jq(this);
      n.closest('li').removeClass('active');
      jq('.main-nav .line').css({ opacity: '0' });
    });
  jq(document).on(
    'click',
    '.navbar .btn-search, .search-form .close-form, .searchbar-btn__close',
    function () {
      console.log('check 1');
      return jq('.navbar').toggleClass('search-open'), !1;
    }
  );
  jq('.main-gallery').length > 0 &&
    jq('.main-gallery').slick({
      fade: !0,
      arrows: !1,
      dots: !0,
      autoplay: !0,
      autoplaySpeed: 7e3,
    });
  // jq('.litebox').liteBox();
  jq('.spa-gallery').length > 0 &&
    jq('.spa-gallery:not(.style-02) .slides').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: !0,
      autoplaySpeed: 8e3,
      responsive: [
        { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 1 } },
        { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
  jq('.shop-gallery').length > 0 &&
    jq('.shop-gallery .slides').slick({
      slidesToShow: 5,
      slidesToScroll: 5,
      autoplay: !0,
      autoplaySpeed: 8e3,
      dots: !0,
      responsive: [
        { breakpoint: 1280, settings: { slidesToShow: 4, slidesToScroll: 4 } },
        { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2, slidesToScroll: 2, dots: !1 },
        },
        {
          breakpoint: 480,
          settings: { slidesToShow: 1, slidesToScroll: 1, dots: !1 },
        },
      ],
    });
  jq('.sfPageWrapper').length > 0 ||
    ((n = jq('.main-nav').clone()),
    n.attr('id', 'mobile-menu'),
    n.mmenu({ offCanvas: { position: 'left', zposition: 'front' } }),
    jq('.mm-listview > li').wrapInner('<span></span>'),
    n.find('.mm-navbar').append('<a class="btn-close" href="#">Close menu</a>'),
    n.find('.mm-listview').append('<li></li><li></li>'),
    jq('.top-buttons')
      .clone()
      .appendTo('.navbar, .mm-listview > li:nth-last-child(2)'),
    jq('.top-nav').clone().appendTo('.mm-listview > li:last-child'),
    (t = n.data('mmenu')),
    jq('.main-nav .btn-close').click(function () {
      return t.close(), !1;
    }),
    t.bind('opened', function () {
      setTimeout(function () {
        jq('body').addClass('menu-opened');
      }, 700);
    }),
    t.bind('closed', function () {
      jq('body').removeClass('menu-opened');
    }));
  jq('.thumbnail .img').click(function () {
    jq(window).width() < 768 && jq(this).toggleClass('active');
  });
  jq('.alert .btn-close').click(function () {
    return jq(this).closest('.alert').fadeOut(300), !1;
  });
  jq('.team-list .box').click(function () {
    jq(window).width() < 768 && jq(this).toggleClass('active');
  });
  u = jq('.search-form-wrap').detach();
  jq('.main-nav li.search').prepend(u);
  jq('.alert-slider').slick({
    fade: !0,
    arrows: !1,
    dots: !1,
    autoplay: !0,
    autoplaySpeed: 8e3,
  });
  jq('#quicksearch').length > 0 &&
    (jq('#quicksearch').keyup(function () {
      var t = jq(this).val().toLowerCase(),
        n = 0;
      t == ''
        ? (jq('.faq-list > div').show(),
          (n = 1),
          jq('.no-results-found').remove())
        : (jq('.faq-list > div').each(function () {
            var i = jq(this).text().toLowerCase(),
              r = i.indexOf(t);
            r >= 0
              ? (jq(this).show(), (n = 1), jq('.no-results-found').remove())
              : jq(this).hide();
          }),
          jq('.filter div').removeClass('selected'),
          jq('.filter div:first-child').addClass('selected'));
      n == 0 &&
        jq('.faq-list').append(
          '<div class="no-results-found">No results found.</div>'
        );
    }),
    (i = ''),
    jq('.faq-list')
      .children('div')
      .each(function () {
        var n = jq(this).find('.sfCategoriesList').html();
        jq(this).find('.sfCategoriesList').length > 0 && (i += n);
      }),
    i.length > 0
      ? jq('.filter-cat').append(
          '<ul class="filter"><li class="selected" data-filter=".sflistitem"><span>All</span></a></li>' +
            i +
            '</ul>'
        )
      : jq('.filter-cat').hide(),
    (r = {}),
    (f = ''),
    jq('.filter-cat')
      .children('.filter')
      .children('li')
      .each(function () {
        var n = jq(this).text().replace(/\s/g, '').toLowerCase(),
          t = '.' + n;
        r[n]
          ? jq(this).remove()
          : ((r[n] = !0), jq(this).not(':first-child').attr('data-filter', t));
      }),
    jq('.faq-list')
      .children('div')
      .each(function () {
        jq(this)
          .find('.sfCategoriesList')
          .children()
          .each(function () {
            var n = jq(this).text().replace(/\s/g, '').toLowerCase();
            n != '' && jq(this).closest('.sflistitem').addClass(n);
          });
      }),
    jq('.filter')
      .find('li')
      .click(function () {
        jq('.filter li').removeClass('selected');
        jq(this).addClass('selected');
        jq('.faq-list .sflistitem').addClass('hide-acc').hide();
        var n = jq(this).attr('data-filter');
        return (
          jq(n).removeClass('hide-acc').slideDown(500),
          jq('#quicksearch').val(''),
          jq('.no-results-found').length > 0 &&
            jq('.no-results-found').remove(),
          !1
        );
      }));
  jq('.sf_pagerNumeric.custom-pager')
    .find('.rdpNumPart')
    .prev()
    .children('a')
    .text('<<');
  jq('.sf_pagerNumeric.custom-pager')
    .find('.rdpNumPart')
    .next()
    .children('a')
    .text('>>');
  jq('.book-selection').on('change', function () {
    window.location = this.value;
  });
  if (jq('#Newsletter_TA3338879016_subscribeButton').length > 0)
    jq('#Newsletter_TA3338879016_subscribeButton').on('click', function () {
      ga('create', 'UA-80039731-1', 'auto', 'better2Tracker');
      ga('better2Tracker.send', 'event', {
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'newsletter subscribe',
      });
    });
});
jq(window).load(function () {
  var n;
  menuLine();
  jq('.footer-list li').each(function () {
    jq(this).prev().length &&
      jq(this).position().top != jq(this).prev().position().top &&
      jq(this).addClass('first-in-row').next().removeClass('first-in-row');
  });
  jq(
    '.info-gallery .slide, .spas-list.accordion .img, .thumbnail .img .inner'
  ).each(function () {
    var n = 'url(' + jq(this).find('img').attr('src') + ')';
    jq(this).css('background-image', n);
  });
  jq('.info-gallery').length > 0 &&
    jq('.info-gallery')
      .not('.info-gallery-directive')
      .slick({ fade: !0, arrows: !1, dots: !0 });
  jq('#card-number, #expiry-mm, #expiry-yy, #security-code').keydown(function (
    n
  ) {
    jq.inArray(n.keyCode, [46, 8, 9]) !== -1 ||
      (n.keyCode >= 35 && n.keyCode <= 39) ||
      ((n.shiftKey || n.keyCode < 48 || n.keyCode > 57) &&
        (n.keyCode < 96 || n.keyCode > 105) &&
        n.preventDefault());
  });
  n = 100;
  jq('.massage-list').length && (n = 50);
  appear({
    elements: function () {
      return document.getElementsByClassName('appear');
    },
    appear: function (n) {
      jq(n).addClass('visible-element');
    },
    bounds: n,
    reappear: !1,
  });
});
jq(window).resize(function () {
  menuLine();
  jq('.footer-list li').each(function () {
    jq(this).prev().length &&
    jq(this).position().top != jq(this).prev().position().top &&
    jq(this).removeClass('first-in-row')
      ? jq(this).addClass('first-in-row').next()
      : jq(this).removeClass('first-in-row');
  });
});